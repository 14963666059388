import cloudStorages from "./graphql-queries/cloud-storages/cloud-storages.graphql";
import cloudStorageItems from "./graphql-queries/cloud-storages/cloud-storage-items.graphql";
import authorizeCloudStorage from "./graphql-queries/cloud-storages/authorize-cloud-storage.graphql";
import deleteCloudStorage from "./graphql-queries/cloud-storages/delete-cloud-storage.graphql";
import updateCloudStorage from "./graphql-queries/cloud-storages/update-cloud-storage.graphql";
import uploadCloudStorageDocument from "./graphql-queries/cloud-storages/upload-cloud-storage-document.graphql";
import { CLOUD_STORAGE } from "./constants.js";

class CloudStoragesApi {
  constructor(graphqlApi) {
    this.graphqlApi = graphqlApi;
  }

  getAll() {
    return this.graphqlApi.apolloClient
      .query({ query: cloudStorages })
      .then((response) => {
        const cloudStorages = response.data.cloudStorages;
        return cloudStorages;

      })
      .catch(this.graphqlApi.responseErrorParser);
  }

  getStorageItems(id, pageSize, pageCursor, parentFolderId = null) {
    return this.graphqlApi.apolloClient
      .query({
        query: cloudStorageItems,
        variables: {
          id,
          pageSize,
          pageCursor,
          searchInput: {
            parent: parentFolderId,
          },
        },
      })
      .then((response) => {
        const { id, items } = response.data.cloudStorage;
        return { id, items };
      })
      .catch(this.graphqlApi.responseErrorParser);
  }

  authorizeStorage(id, addedAt, name, provider, accessToken, refreshToken) {
    return this.graphqlApi.apolloClient
      .mutate({
        mutation: authorizeCloudStorage,
        variables: {
          input: { id, addedAt, name, provider, accessToken, refreshToken, hostName:location.origin },
        },
      })
      .then((response) => {
        const { success } = response.data.authorizeCloudStorage;
        return success;
      })
      .catch(this.graphqlApi.responseErrorParser);
  }

  deleteStorage(storageId) {
    return this.graphqlApi.apolloClient
      .mutate({
        mutation: deleteCloudStorage,
        variables: { input: { id: storageId } },
      })
      .then((response) => {
        const { success } = response.data;
        return success;
      })
      .catch(this.graphqlApi.responseErrorParser);
  }

  updateStorage(storageId, name) {
    return this.graphqlApi.apolloClient
      .mutate({
        mutation: updateCloudStorage,
        variables: { input: { id: storageId, name } },
      })
      .then((response) => {
        const { success } = response.data;
        return success;
      })
      .catch(this.graphqlApi.responseErrorParser);
  }

  // prettier-ignore
  uploadDocument(projectId, cloudStorageId, cloudStorageFileIds, documentIds) {
    return this.graphqlApi.apolloClient
      .mutate({
        mutation: uploadCloudStorageDocument,
        variables: {
          input: { projectId, cloudStorageId, cloudStorageFileIds, documentIds },
        },
      })
      .then((response) => {
        const {
          uploadCloudStorageDocument: { processingStatuses },
        } = response.data;
        return processingStatuses;
      })
      .catch(this.graphqlApi.responseErrorParser);
  }
}

export default CloudStoragesApi;
