<template>
    <div class="bg-white">
        <main class="mx-auto w-full max-w-7xl px-6 pb-16 pt-10 sm:pb-24 lg:px-8">
            <img class="mx-auto h-10 w-auto sm:h-12" src="../../assets/images/logo-auth.png" alt="Your Company" />
            <div class="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
                <p class="text-base font-semibold leading-8 text-indigo-600">Oops!</p>
                <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Something went wrong.</h1>
            </div>
            <div class="mx-auto mt-16 flow-root max-w-lg sm:mt-20">
                <div class="flex justify-center">
                    <ul role="list" class="-mt-6 divide-y divide-gray-900/5 border-b border-gray-900/5">
                        <li v-for="(error, index) in errorList" :key="error.id" class="py-4">
                            <!-- Click on the span to toggle visibility -->
                            <span @click="error.visible = !error.visible" class="cursor-pointer text-blue-500">
                                show more =>
                            </span>

                            <!-- Use v-if to toggle the <p> tag visibility -->
                            <p v-if="error.visible" class="mt-2 text-gray-700">
                                {{ error.data }}
                            </p>
                        </li>
                    </ul>
                </div>
                <div @click="signOut" class="mt-10 flex justify-center">
                    <a href="#" class="text-sm font-semibold leading-6 text-indigo-600">
                        <span aria-hidden="true">&larr;</span>
                        Back to login page.
                    </a>
                </div>
            </div>
        </main>

    </div>
</template>

<script>
import { computed, inject, onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { BROADCAST_MESSAGE, ERROR_TOAST, INFO_TOAST } from "../../core/constants.js";
export default {
    name: "error-page",
    components: {
    },
    setup() {
        const $auth0 = inject("$auth0");
        const errorList = ref([]);
        const route = useRoute();
        onMounted(() => {
            document.querySelectorAll("#splash-screen").forEach(el => el.remove());
            let errorMessage = {};

            try {
                const errorParam = route.query.error || '{}';
                errorMessage = JSON.parse(decodeURIComponent(errorParam)); 
            } catch (e) {
                console.error("Failed to parse error message:", e);
            }

            errorList.value = [{ id: 1, data: errorMessage }]
        })

        function signOut() {
            $auth0.signOut().catch(console.error);
        }
        return {
            signOut,
            errorList
        }
    }
};
</script>

<style lang="scss" scoped></style>