<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.9993 7.70801H11.4577V4.16634M8.12435 12.708H11.8743M8.12435 10.208H11.8743M13.541 16.0413H6.45768C5.53721 16.0413 4.79102 15.2951 4.79102 14.3747V5.62467C4.79102 4.7042 5.53721 3.95801 6.45768 3.95801H11.666L15.2077 7.49967V14.3747C15.2077 15.2951 14.4615 16.0413 13.541 16.0413Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "all-documents-icon"
}
</script>

<style scoped>

</style>
