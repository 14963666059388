<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.99967 16.0418C13.3364 16.0418 16.0413 13.3369 16.0413 10.0002C16.0413 6.66344 13.3364 3.9585 9.99967 3.9585M9.99967 16.0418C6.66295 16.0418 3.95801 13.3369 3.95801 10.0002C3.95801 6.66344 6.66295 3.9585 9.99967 3.9585M9.99967 16.0418C11.0352 16.0418 12.708 13.7502 12.708 10.0002C12.708 6.25016 11.0352 3.9585 9.99967 3.9585M9.99967 16.0418C8.96414 16.0418 7.29134 13.7502 7.29134 10.0002C7.29134 6.25016 8.96414 3.9585 9.99967 3.9585M4.16634 10.0002H15.833" stroke="#3B82F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "public-icon",
};
</script>

<style scoped>

</style>
