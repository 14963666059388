<template>
  <div class="search-field-box">
    <input type="text" class="search-input" placeholder="Search for projects">
  </div>
</template>

<script>
export default {
  name: "FoliaSearchSidebar"
}
</script>

<style lang="scss">
.search-field-box {
  padding: 10px !important;
  min-width: 95%;

  .search-input {
    width: 100%;
    border: 1px solid #e2e8f0;
    border-radius: 5px;
    padding: 10px 10px 10px 40px;
    background: #fffdfd url("../../../assets/images/search-btn.svg") no-repeat 10px;
    &::placeholder {
      color: #cbd5e1;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .search-input:after {
    content: 'yyy';
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url("../../../assets/images/search-btn.svg") center no-repeat;
    background-size: contain;
  }
}
</style>
