<template>
  <div class="no_access_centered">
    <p>You do not have access</p>
    <button @click="onClick">Retry again</button>
  </div>
</template>

<script>
import { inject } from "vue";

export default {
  name: "no_access",
  setup() {
    const $auth0 = inject("$auth0");
    function onClick() {
      $auth0.signOutCustom().catch(console.error);
    }

    return {
      onClick,
    };
  },
};
</script>

<style scoped lang="scss">
.no_access_centered {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
