<template>
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    stroke="#64748B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.791992 7.62467C0.791992 7.62467 1.00033 11.3747 6.00033 11.3747C11.0003 11.3747 11.2087 7.62467 11.2087 7.62467M6.00033 11.7913V13.0413M6.00033 8.87467C4.50455 8.87467 3.29199 7.66211 3.29199 6.16634V3.66634C3.29199 2.17057 4.50455 0.958008 6.00033 0.958008C7.4961 0.958008 8.70866 2.17057 8.70866 3.66634V6.16634C8.70866 7.66211 7.4961 8.87467 6.00033 8.87467Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "audio-icon",
};
</script>
