<template>
  <div class="spinner-container">
    <div
      class="loader"
      :style="{
        'width': width,
        'height': height,
      }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  props:[
    'containerHeight',
    'width',
    'height',
  ]
};
</script>

<style lang="scss" scoped>
.spinner-container {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    background: url("../../assets/images/loading-icon.gif") no-repeat;
    background-position: center 70%;
  }
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}
</style>
