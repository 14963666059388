<template>
  <button
    :disabled="disabled"
    class="action-button"
    :style="{
      width: width,
      height: height,
      color: fontColor,
      'background-color': backgroundColor,
      'min-height': minHeight,
    }"
  >
    {{ text }}
    <Loader class="mt-1 p-1 max-h-5" v-if="isLoading"></Loader>
  </button>
</template>

<script>
import Loader from '../common/loaders/defaultLoader.vue';

export default {
  
  name: "ActionButton",
  props: ["width", "height", "minHeight", "text", "backgroundColor", "fontColor", "disabled","isLoading"],
  components:{Loader}
};
</script>

<style lang="scss">
.action-button {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  border:none;
  border-radius: 4px;
  font-size: 14px;
  margin: 0 10px;
  padding-left: 3px;
}
.action-button:hover:not(:disabled) {
  box-shadow: 0 0 8px 4px #00000010;
}

.action-button:disabled {
  background-color: silver !important;
}
</style>
