<template>
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 1L8 1L8 5" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.00623 2.75712C3.77931 3.21103 2.72611 4.07135 2.00531 5.20846C1.28452 6.34556 0.935172 7.69785 1.00992 9.06157C1.08466 10.4253 1.57945 11.7266 2.41972 12.7693C3.26 13.8121 4.40024 14.5398 5.66865 14.8429C6.93706 15.146 8.26492 15.0081 9.45216 14.4498C10.6394 13.8915 11.6217 12.9432 12.251 11.7477C12.8804 10.5523 13.1227 9.17441 12.9415 7.82176C12.7602 6.4691 12.1653 5.21494 11.2462 4.24823L8.33956 1" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "revert",
};
</script>
