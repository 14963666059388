<template>
  <svg width="19" height="8" viewBox="0 0 19 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0003 0.498047C7.47756 0.498047 5.00618 1.34908 2.99592 2.85268V0.498047H0.661133V6.33503C0.661133 6.9806 1.18413 7.50242 1.82853 7.50242H7.66551V5.16763H3.83878C5.53268 3.6827 7.74139 2.83284 10.0003 2.83284C12.4577 2.83284 14.8648 3.82979 16.6043 5.56805L18.255 3.91735C16.0813 1.74366 13.0729 0.498047 10.0003 0.498047Z" fill="#64748B"/>
  </svg>
</template>

<script>
export default {
  name: "undo",
};
</script>
