import { createRouter, createWebHistory } from "vue-router";
import { build } from "./version.json";
import { ApplicationRequiredError } from "./core/graphql-api.js";

import Home from "./views/home/Home.vue";
import ApplicationView from "./views/ApplicationView.vue";
import Project from "./views/project/Project.vue";
import Document from "./views/document/Document.vue";
import No_access from "./views/auth/no_access.vue";
import GlobalSearch from "./views/global-search/global-search.vue";
import { BROADCAST_MESSAGE, WARN_TOAST } from "./core/constants.js";
import Welcome from "./views/auth/Welcome.vue";
import AccountSettings from "./views/common/AccountSettings.vue";
import LogIn from "./views/auth/LogIn.vue";
import WaitList from "./views/auth/WaitList.vue";
import OAuth2StorageProviderCallback from "./views/auth/auth-callbacks/OAuth2StorageProviderCallback.vue";
import {Repo} from "@automerge/automerge-repo";
import {BroadcastChannelNetworkAdapter} from "@automerge/automerge-repo-network-broadcastchannel";
import {BrowserWebSocketClientAdapter} from "@automerge/automerge-repo-network-websocket";
import {IndexedDBStorageAdapter} from "@automerge/automerge-repo-storage-indexeddb";
import Store from "./store/index.js";
import {toRaw} from "vue";
import SubscriptionSettings from './views/common/SubscriptionSettings.vue'
import ProfileService from "./service/profileService.js";
import ErrorPage from './views/auth/Error.vue';

const {
  VITE_AUTOMERGE_SYNC_SERVER: automergeUrl,
} = import.meta.env;

const routes = [
  {
    path: "/signup",
    name: "signup",
    redirect: (to) => ({ path: "/login", query: { to: to.name } }),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/login",
    name: "login",
    component: LogIn,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/welcome",
    name: "welcome",
    component: Welcome,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/wait-list",
    name: "wait-list",
    component: WaitList,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/error-page",
    name: "error-page",
    component: ErrorPage,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/google_oauth2_callback",
    name: "google_oauth2_callback",
    component: OAuth2StorageProviderCallback,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/ms_oauth2_callback",
    name: "ms_oauth2_callback",
    component: OAuth2StorageProviderCallback,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/box_oauth2_callback",
    name: "box_oauth2_callback",
    component: OAuth2StorageProviderCallback,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/dropbox_oauth2_callback",
    name: "dropbox_oauth2_callback",
    component: OAuth2StorageProviderCallback,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/",
    name: "application-view",
    component: ApplicationView,
    children: [
      {
        path: "/",
        name: "home",
        component: Home,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/account-settings",
        name: "account-settings",
        component: AccountSettings,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/search",
        name: "global-search",
        component: GlobalSearch,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/p/:projectId",
        name: "project",
        component: Project,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/p/:projectId/d/:documentId",
        name: "document",
        component: Document,
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/subscriptions",
        name: "subscriptions",
        component: SubscriptionSettings,
        meta: {
          requireAuth: true,
        },
      }
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    component: No_access,
    meta: {
      requireAuth: false,
    },
  }

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default {
  install(app, options) {
    const {
      globalProperties: { $auth0, $fetch },
    } = app.config;
    let updateStarted = false;
    router.install(app);

    router.beforeEach(async (to, from, next) => {
      // console.log("ROUTER", to.path, to.meta);
      await $auth0.init();
      if (to.meta.requireAuth === false) return next();

      try {
        if (to.params.projectId)
          to.params.projectId = to.params.projectId.toLowerCase();
        if (to.params.documentId)
          to.params.documentId = to.params.documentId.toLowerCase();
        // console.log(to.params);
      } catch (e) {
        console.error(e.message);
      }

      const { code, state } = to.query;
      if (to.path === "/" && code && state) {
        try {
          localStorage.setItem("build", `${build}`);
          await $auth0.signInRedirectCallback(to.fullPath);
          const fallbackPath = localStorage.getItem("fallbackPath");
          localStorage.removeItem("fallbackPath");
          if (fallbackPath) {
            try {
              const { projectId, documentId } = JSON.parse(fallbackPath);
              if (projectId && documentId) {
                return next(`/p/${projectId}/d/${documentId}`);
              } else if (projectId && !documentId) {
                return next(`/p/${projectId}`);
              }
            } catch (e) {
              console.log("warning:", e.message);
            }
          }
        } catch (e) {
          console.log("warning:", e.message);
        }
        return next("/");
      }

      const user = await $auth0.getUser();
      if (!user.isAuthenticated) {
        const { projectId, documentId } = to.params;
        if (projectId) {
          // prettier-ignore
          localStorage.setItem("fallbackPath", JSON.stringify({ projectId, documentId }));
          return await $fetch
            .inputSyncUser()
            .then(() => $fetch.inputSyncProject(true, projectId))
            .then(() => next())
            .catch(() => next("/login"));
        }
        return next("/login");
      } else if (!user.profile.email_verified) {        
        return next("/welcome");
      }

      try {
          if (!localStorage.getItem("profileChecked")) {            
            const profileService = new ProfileService();
            const {name = null, email = '', profilePicture = '', profileStatus} = await profileService.getMyProfile();
            localStorage.setItem("profileChecked", "yes");
            localStorage.setItem("profileStatus", profileStatus)
          }
          
          // TODO handle this properly. Possible status are APPROVED, WAITLIST and BLOCKED. 
          // BLOCKED users should be sent to an error page. See FOLIA-126
          if (localStorage.getItem("profileStatus") != 'APPROVED') {
            return next("/wait-list");
          }

        // This is hack to make sure automerge get auth token.
        const adaptor = toRaw(Store.getters.automergeNetworkAdaptor)
        const networkUrl = automergeUrl + "?Authentication=" + localStorage.getItem("accessToken");
        adaptor.url = networkUrl;
      } catch (e) {
        // if (e.name === ApplicationRequiredError.name) {
        //   // Wrong logic. Send all to an error page
        //   return next("/wait-list");
        // } else {
        //   if (to.path === "/welcome") return next();
        //   return next("/welcome");
        // }        
        const errorMessage = encodeURIComponent(JSON.stringify(e));  
        return next(`/error-page?error=${errorMessage}`);
      }

      const storedBuild = localStorage.getItem("build");
      if (storedBuild && storedBuild !== `${build}`) {
        if (updateStarted) return;
        updateStarted = true;

        localStorage.setItem("build", `${build}`);
        const restartTimeout = 5000;
        $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
          type: WARN_TOAST,
          title: "UPDATE",
          message: `A new version of the application has been found, so all user data will be removed and the page will reload after all outgoing sync operations.`,
          close_timeout: restartTimeout,
        });
        await $auth0.signOut();
        await new Promise((resolve) => setTimeout(resolve, restartTimeout));
      }

      next();
    });
  },
};
