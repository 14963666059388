<template>
  <div class="sharing-dialog" v-if="isOpen">
    <div class="sharing-dialog-overlay" @click.stop="$emit('close')" />

    <div class="sharing-dialog-container">
      <div class="m-5">
        <div class="absolute right-6 cursor-pointer" @click.stop="$emit('close')">
          <svg width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.375 5.625L5.62497 14.375M5.62497 5.625L14.375 14.375" stroke="#334155" stroke-width="1.5"
              stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
        <div class="">
          <h3 class="text-left text-lg">Share project</h3>
          <p class="text-left mt-2 text-sm font-normal text-gray-500">Invite collaborators and manage access to this
            project</p>
        </div>
      </div>
      <div class="ml-5">
        <div class="sm:hidden">
          <select id="tabs" name="tabs" @change="changeTab"
            class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
            <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
          </select>
        </div>
        <div class="hidden sm:block">
          <div class="border-b border-gray-200">
            <nav class="-mb-px flex space-x-8 cursor-pointer" aria-label="Tabs">
              <a v-for="tab in tabs" :key="tab.name" @click="changeTab(tab)"
                :class="[tab.current ? 'border-orange-500 text-black-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium']"
                :aria-current="tab.current ? 'page' : undefined">
                <span v-if="tab.name == 'Invited Users'">
                  <svg v-if="tab.current" width="20" height="20" viewBox="0 0 20 20" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_12_2511)">
                      <path
                        d="M2.5 4.375H17.5V15C17.5 15.1658 17.4342 15.3247 17.3169 15.4419C17.1997 15.5592 17.0408 15.625 16.875 15.625H3.125C2.95924 15.625 2.80027 15.5592 2.68306 15.4419C2.56585 15.3247 2.5 15.1658 2.5 15V4.375Z"
                        stroke="#F04E23" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M17.5 4.375L10 11.25L2.5 4.375" stroke="#F04E23" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_12_2511">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_14_360)">
                      <path
                        d="M2.5 4.375H17.5V15C17.5 15.1658 17.4342 15.3247 17.3169 15.4419C17.1997 15.5592 17.0408 15.625 16.875 15.625H3.125C2.95924 15.625 2.80027 15.5592 2.68306 15.4419C2.56585 15.3247 2.5 15.1658 2.5 15V4.375Z"
                        stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M17.5 4.375L10 11.25L2.5 4.375" stroke="#64748B" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_14_360">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                </span>
                <span v-else>
                  <svg v-if="tab.current" width="15" height="16" viewBox="0 0 15 16" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.12996 3.46C9.12996 4.96 7.91996 6.17 6.41996 6.17C4.91996 6.17 3.70996 4.96 3.70996 3.46C3.70996 1.96 4.91996 0.75 6.41996 0.75C7.91996 0.75 9.12996 1.96 9.12996 3.46Z"
                      stroke="#F04E23" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11.2 8.64999V14.65" stroke="#F04E23" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path d="M8.19995 11.65H14.2" stroke="#F04E23" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path
                      d="M8.20006 8.62999C7.67006 8.51999 6.71006 8.44999 6.20006 8.46999C2.97006 8.52999 1.52006 9.95999 0.880056 11.17C0.440056 12.01 1.17006 12.83 2.12006 12.83H6.20006"
                      stroke="#F04E23" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.13 5.46C12.13 6.96 10.92 8.17 9.41996 8.17C7.91996 8.17 6.70996 6.96 6.70996 5.46C6.70996 3.96 7.91996 2.75 9.41996 2.75C10.92 2.75 12.13 3.96 12.13 5.46Z"
                      stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M14.2 10.65V16.65" stroke="#64748B" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path d="M11.2 13.65H17.2" stroke="#64748B" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path
                      d="M11.2001 10.63C10.6701 10.52 9.71006 10.45 9.20006 10.47C5.97006 10.53 4.52006 11.96 3.88006 13.17C3.44006 14.01 4.17006 14.83 5.12006 14.83H9.20006"
                      stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
                <span class="ml-1">{{ tab.name }}</span>
              </a>
            </nav>
          </div>
        </div>
        <div>
          <InviteParticipants class="pt-1 pr-3" :projectId="projectId" @sendInvite="sendInvite" />
        </div>
        <div>
          <Loader class="mt-2" v-if="isLoading"></Loader>
          <ParticipantsList class="pr-3" :projectId="projectId" :currentUserRole="userCurrentRole"
            :participantsList="data" @changePermission="changeRole" />

        </div>
      </div>
      <div class="text-sm bg-gray-50 rounded-b-lg">
        <button @click="copyLink" class="flex border rounded-lg p-2 bg-white m-3 hover:bg-gray-200">
          <svg class="mt-0.5 mr-1" width="17" height="17" viewBox="0 0 16 17" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.1666 9.33333L11.9999 8.5C13.1045 7.39543 13.1045 5.60457 11.9999 4.5C10.8954 3.39543 9.1045 3.39543 7.99994 4.5L7.1666 5.33333M4.83327 7.66666L3.99994 8.5C2.89537 9.60457 2.89537 11.3954 3.99994 12.5C5.1045 13.6046 6.89537 13.6046 7.99994 12.5L8.83327 11.6667M9.49994 7L6.49994 10"
              stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          Copy Link
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  computed,
  inject,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch,
} from 'vue'
import InviteParticipants from './InviteParticipants.vue'
import ParticipantsList from './ParticipantsList.vue'
import MakeAsPublic from './MakeAsPublic.vue'
import { CONTRIBUTOR, EDITOR, VIEWER, OWNER, REJECTED, BROADCAST_MESSAGE } from "../../../core/constants.js";
import { useRoute } from "vue-router";
import ProjectService from '../../../service/projectService';
import Loader from '../../common/loaders/defaultLoader.vue';
export default {
  name: 'SharingDialog',
  props: { projectId: String, isOpen: Boolean, currentUserRole: String },
  components: {
    InviteParticipants,
    ParticipantsList,
    MakeAsPublic,
    Loader
  },
  emits: ["fetchProjects", "close"],
  setup(props, context) {
    const $fetch = inject('$fetch');
    const $auth0 = inject("$auth0");
    const route = useRoute();
    const data = reactive({
      collaborators: [],
      isOwner: false,
      publicStatus: false,
    })
    const inviteEmail = ref('');
    const inviteRole = ref(VIEWER);
    const tabs = ref([
      { name: 'Invited Users', current: true } ])
      const isLoading = ref(false);
    const order = {
      [OWNER]: 0,
      [EDITOR]: 1,
      [CONTRIBUTOR]: 2,
      [VIEWER]: 3,
    }
    watch(() => props.isOpen, (val) => {
      if (val) fetchCollaborators(props.projectId).catch(console.error);
    });
    const projectService = new ProjectService();

    onMounted(() => {
      $fetch.on(BROADCAST_MESSAGE.COLLABORATORS_UPDATED, collaboratorsUpdatedMessage);
      fetchCollaborators(props.projectId).catch(console.error);
    });

    onBeforeUnmount(() => {
      $fetch.off(BROADCAST_MESSAGE.COLLABORATORS_UPDATED, collaboratorsUpdatedMessage)
    });

    async function fetchCollaborators(projectId) {
      if (props?.isOpen) {
        data.collaborators=[];
        isLoading.value = true;
        let fetchAll = true;
        let isOwner = false;
        let publicStatus ="PUBLIC";
        let collaborators = [];
        let endCursor = null;

        while(fetchAll){
          const { collaborators:c = [], isOwner:owner, publicStatus:ps,pageInfo } =
          await projectService.getProjectCollaborators({ projectId,endCursor });
          collaborators = [...collaborators, ...c ]; 
          publicStatus=ps;
          isOwner=owner;
          fetchAll= pageInfo.hasNextPage;
          endCursor=pageInfo.endCursor
        } 
        data.isOwner = isOwner;
        data.publicStatus = publicStatus;        
        data.collaborators = collaborators.sort((a, b) => {
            return order[a.role] - order[b.role];
          })
        isLoading.value = false;

      }


    }

    function collaboratorsUpdatedMessage(projectId) {
      if (projectId !== props.projectId) return;
      fetchCollaborators(projectId).catch(console.error);
    }

    async function sendInvite(inviteObject) {
      const { emails, permission } = inviteObject;
      isLoading.value= true;
      for (const email of emails) {
        await projectService.shareProject({
          projectId: projectId.value, collaboratorEmail: email,
          collaboratorRole: permission
        });
      }
      isLoading.value= false;

      inviteEmail.value = '';
      inviteRole.value = VIEWER;
      fetchCollaborators(projectId.value).catch(console.error);
    }

    async function changeRole(newRoleObject) {
      const { email, role } = newRoleObject[0];
      for (let i = 0; i < data.collaborators.length; i++) {
        if (data.collaborators[i].email === email) {
          data.collaborators[i].role = role
        }
      }
      let newRole = role;
      if (role === "REVOKED" || role === "REJECT") {
        newRole = null;
      }

      await sendInvite({ emails: [email], permission: newRole })
      // switch (route.name) {
      //   case 'global-search':
      //     if (role === REJECTED) {
      //       context.emit('close');
      //       context.emit('removeRejectedProject', projectId.value);
      //       return;
      //     }
      //     break;
      //   default:
      //     if (role === REJECTED) {
      //       context.emit('close');
      //       return;
      //     }
      // }
      fetchCollaborators(projectId.value).catch(console.error);
    }

    async function togglePublicStatus() {
      data.publicStatus = !data.publicStatus;
      const publicStatus = data.publicStatus ? "PUBLIC" : "PRIVATE";
      await projectService.updateProjectVisibility({ projectId: projectId.value, publicStatus });
      context.emit('fetchProjects')
    }

    const projectId = computed(() => {
      return props.projectId;
    });

    const userCurrentRole = computed(() => {
      return props.currentUserRole;
    });

    const roles = computed(() => {
      return [VIEWER, CONTRIBUTOR, EDITOR];
    });

    const rolesExtended = computed(() => {
      return [EDITOR, CONTRIBUTOR, VIEWER];
    });
    const changeTab = (tab) => {
      const selectedTabName = tab.name;
      tabs.value.forEach(tab => {
        tab.current = (tab.name === selectedTabName);
      });
    };
    const sharingLink = computed(() => {
      return window.location.origin + `/p/${props.projectId}`
    });
    const copyLink = () => {
      navigator.clipboard.writeText(sharingLink.value).then(() => {
        window.alert("Link has been copied");
      }).catch((error) => {
        window.alert('Link can not be copied: ' + error.message);
      });
    }
    return {
      projectId,
      data,
      userCurrentRole,
      roles,
      rolesExtended,
      OWNER,
      inviteEmail,
      inviteRole,
      tabs,
      isLoading,
      sendInvite,
      changeRole,
      togglePublicStatus,
      changeTab,
      copyLink
    }
  },
}
</script>

<style scoped lang="scss">
.sharing-dialog {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  .sharing-dialog-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    mix-blend-mode: normal;
    opacity: 0.4;
  }

  .sharing-dialog-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 640px;
    height: 600px;
    background: #ffffff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.12);
    border-radius: 10px;

    .sharing-dialog-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 24px 10px;

      .header-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #18181e;
      }

      .header-btn {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #E2E8F0;
        border-radius: 100px;
        cursor: pointer;

        &:hover {
          background-color: #f1f1f1;
        }
      }
    }

    .sharing-dialog-add-participant {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 24px 20px;
    }

    .sharing-dialog-list-of-participants {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding: 18px 24px 22px 24px;
      overflow-y: auto;
    }

    .sharing-dialog-public {
      padding: 20px 24px 26px 24px;
    }
  }
}
</style>
