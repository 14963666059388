<template>
  <div class="user-info-sidebar-container" @click="showUserInfoModal()">
    <Avatar class="user-avatar" :owner="userData.name || userData.email"/>
    <div class="user-text-info-container">
      <div class="user-name">{{userData.name}}</div>
      <div class="user-email">
        <span>{{userData.email}}</span>
      </div>
    </div>
  </div>
  <UserInfoModal v-if="showUserModal"/>
</template>
<script>
import Avatar from "../Avatar.vue";
import UserInfoModal from "../../app-bar/UserInfoModal.vue";
import {inject, onMounted, ref,onDeactivated} from "vue";
export default {
  name: "UserInfoSidebar",
  components: {
    Avatar,
    UserInfoModal,
  },
  setup () {
    const userData = ref('');
    const showUserModal = ref(false);
    onMounted(()=> { getUserData()})
    onDeactivated(()=> showUserInfoModal())
    function getUserData() {
      const $fetch = inject("$fetch");
      $fetch.getUserProfile().then((profile) => {
        userData.value = profile
      })
    }
    function showUserInfoModal() {
      showUserModal.value = !showUserModal.value
    }

    return {
      userData,
      showUserInfoModal,
      showUserModal,
    }
  },
}
</script>

<style lang="scss">
  .user-info-sidebar-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .user-avatar {
      font-family: 'Inter',sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      width: 34px;
      min-width: 34px;
      height: 34px;
      margin: 0 5px;
      border-radius: 50%;
      border: 1px solid white;
      background-color: #2990ff;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .user-text-info-container {
      display: flex;
      width: 150px;
      max-width: 100%;
      flex-direction: column;

      justify-content: flex-start;
      align-items: flex-start;
      color: #334155;
      text-overflow: ellipsis;
      overflow: hidden;

      .user-name {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #334155;
      }

      .user-email {
        font-size: 12px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #64748b;
        white-space: nowrap;
        overflow: hidden;
        span {
          text-decoration: none;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .user-info-sidebar-container:hover {
    cursor: pointer;
  }
</style>
