<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.50033 3.16602L11.5003 6.16602H9.16699C8.7988 6.16602 8.50033 5.86754 8.50033 5.49935V3.16602ZM8.50033 3.16602H4.50033C3.76395 3.16602 3.16699 3.76297 3.16699 4.49935V11.4993C3.16699 12.2357 3.76395 12.8327 4.50033 12.8327H6.83366M11.4317 11.4307L12.8337 12.8327M10.1572 11.8131C11.0722 11.8131 11.8141 11.0713 11.8141 10.1562C11.8141 9.24115 11.0722 8.49935 10.1572 8.49935C9.24213 8.49935 8.50033 9.24115 8.50033 10.1562C8.50033 11.0713 9.24213 11.8131 10.1572 11.8131Z" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "document-text-category-icon",
};
</script>

<style scoped>

</style>
