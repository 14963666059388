<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.95801 4.79159C3.95801 4.33135 4.3311 3.95825 4.79134 3.95825H7.70801C8.16825 3.95825 8.54134 4.33135 8.54134 4.79159V7.70825C8.54134 8.16849 8.16825 8.54159 7.70801 8.54159H4.79134C4.3311 8.54159 3.95801 8.16849 3.95801 7.70825V4.79159Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.95801 12.2916C3.95801 11.8313 4.3311 11.4583 4.79134 11.4583H7.70801C8.16825 11.4583 8.54134 11.8313 8.54134 12.2916V15.2083C8.54134 15.6685 8.16825 16.0416 7.70801 16.0416H4.79134C4.3311 16.0416 3.95801 15.6685 3.95801 15.2083V12.2916Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.458 4.79159C11.458 4.33135 11.8311 3.95825 12.2913 3.95825H15.208C15.6682 3.95825 16.0413 4.33135 16.0413 4.79159V7.70825C16.0413 8.16849 15.6682 8.54159 15.208 8.54159H12.2913C11.8311 8.54159 11.458 8.16849 11.458 7.70825V4.79159Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.458 12.2916C11.458 11.8313 11.8311 11.4583 12.2913 11.4583H15.208C15.6682 11.4583 16.0413 11.8313 16.0413 12.2916V15.2083C16.0413 15.6685 15.6682 16.0416 15.208 16.0416H12.2913C11.8311 16.0416 11.458 15.6685 11.458 15.2083V12.2916Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "GridViewIcon"
}
</script>

<style scoped>

</style>
