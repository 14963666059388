<template>
  <div>
    <div class="public-toggle">
      <div
        class="public-switch"
        :class="{
          'switch-on': publicStatus,
          'switch-off': !publicStatus
        }"
        @click.stop="$emit('togglePublicStatus')"
      >
        <div class="public-switch-point" />️
      </div>
      <span class="public-label">
         <span>Anyone with the link can view</span>
      </span>
      <button
        tabindex="-1"
        class="copy-link-button"
        :class="{
          'enabled': publicStatus,
          'disabled': !publicStatus
        }"
        @click.stop="copyLink"
      >Copy link</button>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  name: "MakeAsPublic",
  props: {
    projectId: String,
    publicStatus: Boolean,
  },
  setup(props) {
    const sharingLink = computed(()=> {
      return window.location.origin + `/p/${props.projectId}`
    });

    function  copyLink() {
      if (props.publicStatus) {
        navigator.clipboard.writeText(sharingLink.value).then(() => {
          window.alert("Link has been copied");
        }).catch((error) => {
          window.alert('Link can not be copied: ' + error.message);
        });
      }
    }

    return {
      copyLink,
    }
  },
}
</script>

<style scoped lang="scss">
.public-header {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #18181E;
}
.public-toggle {
  display: flex;
  align-items: center;
  margin-top: 10px;

  .public-switch {
    display: flex;
    align-items: center;
    padding: 3px;
    border-radius: 100px;
    width: 44px;
    height: 22px;

    .public-switch-point {
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background: #FFFFFF;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.08);
      cursor: pointer;

      &.loading {
        background-image: url("../../../assets/images/loading-icon.gif");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 13px;
      }
    }

    &.switch-on {
      justify-content: flex-end;
      background-color: #2990FF;
    }
    &.switch-off {
      justify-content: flex-start;
      background-color: #979797;
    }
  }
  .public-label {
    flex-grow: 1;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #334155;
  }
  .copy-link-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 34px;
    background: #FFFFFF;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;


    border: 2px solid;
    border-color: rgba(197, 200, 202, 0.5);
    transition: border-color 250ms ease-in-out;

    &.enabled {
      color: #343541;
      cursor: pointer;
      &:hover {
        border-color: rgba(197, 200, 202, 1);
        transition: border-color 200ms ease-in-out;
      }
    }
    &.disabled {
      color: rgba(52, 53, 65, 0.5);
      cursor: default;
    }
  }
}
</style>
