<script>
import { ref } from "vue";

export default {
  name: "TokenExpiredWarning",
  setup(props) {
    const hintIsShown = ref(false);
    return {
      hintIsShown,
    }
  }
}
</script>

<template>
  <div class="token-expired-warning">
    <div class="token-expired-warning-icon" @mouseover="hintIsShown = true" @mouseout="hintIsShown = false">
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8.99609" cy="8.99658" r="7.25" fill="#94A3B8" stroke="#94A3B8" stroke-width="1.5"/>
        <circle cx="8.99805" cy="12.8699" r="0.75" fill="white"/>
        <line x1="8.99805" y1="5.11987" x2="8.99805" y2="10.1199" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
      </svg>
      <div class="token-expired-warning-triangle" :class="{shown: hintIsShown}">
        <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.4462 0.924343C6.24669 -0.0660073 7.75657 -0.0660082 8.55706 0.924342L13.0638 6.5H0.939453L5.4462 0.924343Z" fill="#64748B"/>
        </svg>
      </div>
    </div>

    <div class="token-expired-warning-hint" :class="{shown: hintIsShown}">
      Authentication required. Click on storage name to enter account credentials.
    </div>
  </div>
</template>

<style scoped lang="scss">
.token-expired-warning {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //border: solid 1px red;

  .token-expired-warning-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    cursor: auto;

    .token-expired-warning-triangle {
      position: absolute;
      top: 15px;
      left: 1px;
      width: 20px;
      height: 20px;
      display: none;
      align-items: center;
      justify-content: center;

      &.shown {
        display: flex;
      }
    }
  }

  .token-expired-warning-hint {
    position: absolute;
    top: 28px;
    left: -92px;
    width: 205px;
    display: none;
    padding: 8px 16px 8px 16px;
    border-radius: 6px;
    background-color: #64748B;
    z-index: 2;

    color: white;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

    &.shown {
      display: flex;
    }
  }
}
</style>