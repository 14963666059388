<template>
  <div
    class="avatar-container"
    :class="{'guest-avatar': isGuest}"
    @click="$emit('click')"
  >
    <span v-if="!isGuest">{{ getUserInitials }}</span>
  </div>
</template>

<script>
import {ref, computed, inject, onMounted, onBeforeUnmount} from "vue";
import { getInitials } from "./utils.js";
import {BROADCAST_MESSAGE} from "../../core/constants.js";

export default {
  name: "Avatar",
  props: ["owner"],
  setup(props) {
    const isGuest = ref(false);
    const nameToUse = ref("");

    const getUserInitials = computed(() => {
      if (props.owner.toLowerCase() === "guest") return "?";
      return getInitials(props.owner);
    });

    return {
      isGuest, getUserInitials,
    };
  },
};
</script>

<style scoped lang="scss">
.avatar-container {
  border-radius: 50%;
  overflow: hidden;
  font-size: 12px;
}

.guest-avatar {
  background: url("../../assets/images/account.svg") center no-repeat;
  background-size: contain;
}
</style>
