<template>
  <div class="dark-background">
    <div class="edit-project-modal" ref="editProjectModalRef">
      <div class="modal-title">
        <span>Edit Project Details</span>
      </div>
      <div class="input-container">
        <div class="field-label" :class="{'active-field-label':nameProjectInputInFocus}">Project Name</div>
        <input ref="nameProjectInput" placeholder="Project Name" type="text" class="input-field" v-model="nameProject" />
      </div>
      <div class="input-container">
        <div class="description-field-label" :class="{'active-description-field-label':descriptionTextareaInFocus}">
          <span class="label">Project Description</span>
          <span class="info-label">Optional</span>
        </div>
        <textarea
          ref="descriptionTextarea"
          class="description-field"
          cols="20" rows="5"  maxlength="500"
          v-model="descriptionProject"
        >
        </textarea>
      </div>
      <div class="button-container">
        <div class="action-btn-container">
          <ActionButton
            class="cancel-btn"
            :width="'72px'"
            :height="'36px'"
            :text="'Cancel'"
            :fontColor="'#334155'"
            :background-color="'#FFFFFF'"
            @click="$emit('close')"
          />
          <ActionButton
            class="edit-project"
            :class="{'inactive-create-btn':!nameProject}"
            :width="'130px'"
            :height="'36px'"
            :text="'Save Changes'"
            :fontColor="'#FFFFFF'"
            :background-color="'#334155'"
            @click="editProject"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, onBeforeUnmount, onMounted, ref } from "vue";
import ActionButton from "../common/ActionButton.vue";
import linkIcon from "../../assets/vue-icons/link-icon.vue";
export default {
  name: "EditProjectModal",
  props:[
    'project',
  ],
  components: {
    ActionButton,
    linkIcon,
  },
  emits: ["close"],
  setup(props, context) {
    const $fetch = inject('$fetch')
    const nameProject = ref('')
    const descriptionProject = ref('')
    const nameProjectInput = ref(null)
    const descriptionTextarea = ref(null)
    const editProjectModalRef = ref(null)
    const nameProjectInputInFocus = ref(false);
    const descriptionTextareaInFocus = ref(false);
    onMounted(() => {
      editProjectModalRef.value.addEventListener('focusin', focusIn);
      nameProjectInput.value.addEventListener('focusout', focusOutInput)
      descriptionTextarea.value.addEventListener('focusout', focusOutTextArea)
      nameProjectInput.value.focus()

      nameProject.value = props.project.name;
      descriptionProject.value = props.project.description;
    })
    onBeforeUnmount(() => {
      editProjectModalRef.value.removeEventListener('focusin', focusIn);
      nameProjectInput.value.removeEventListener('focusout', focusOutInput)
      descriptionTextarea.value.removeEventListener('focusout', focusOutTextArea)
    })

    function  focusOutInput() {
      nameProjectInputInFocus.value = false
    }
    function focusOutTextArea() {
      descriptionTextareaInFocus.value = false
    }
    function  focusIn(e) {
      if (e.target === nameProjectInput.value) {
        descriptionTextareaInFocus.value = false
        nameProjectInputInFocus.value = true
      }
      if (e.target ===  descriptionTextarea.value) {
        nameProjectInputInFocus.value = false
        descriptionTextareaInFocus.value = true
      }
    }

    function editProject() {
      if (!nameProject.value) return;
      context.emit("close", {
        name: nameProject.value,
        description: descriptionProject.value,
      });
      return false
    }
    return {
      nameProject,
      descriptionProject,
      editProject,
      descriptionTextareaInFocus,
      descriptionTextarea,
      editProjectModalRef,
      nameProjectInput,
      nameProjectInputInFocus,
    }
  }
};
</script>

<style lang="scss" scoped>
  .dark-background {
    position: fixed;
    z-index: 200;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.25);
    .edit-project-modal {
      position: relative;
      display: flex;
      z-index: 250 !important;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      border-radius: 12px;
      background-color: white;
      width: 550px;
      height: 412px;
      padding: 24px 24px 16px 24px;
      .modal-title {
        color: #334155;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
      }
      .input-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .field-label {
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #64748B;
        }
        .active-field-label {
          color: #2990FF;
        }
        .description-field-label {
          display: flex;
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
          .label {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #64748B;
          }
          .info-label {
            font-weight: 600;
            font-size: 10px;
            line-height: 12px;
            color: #94A3B8;
          }
        }
        .active-description-field-label {
          .label {
            color: #2990FF;
          }
        }
        .input-field {
          background: #F1F5F9;
          width: 100% !important;
          height: 44px;
          margin-top: 8px;
          margin-bottom: 20px;
          box-sizing: border-box;
          padding: 0 8px;
          border: 0;
          border-radius: 6px;

          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #334155;
          font-family: inherit;

          &:focus {
            outline: none;
            border: 2px solid #2990FF;
          }
        }
        .description-field {
          width: 100%;
          max-width: 500px;
          min-width: 460px;
          height: 100%;
          margin-top: 8px;
          margin-bottom: 20px;
          background: #F1F5F9;
          box-sizing: border-box;
          border-radius: 6px;
          padding: 8px;
          border: 0;
          resize: none;

          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #334155;
          font-family: inherit;

          &:focus {
            outline: none;
            border: 2px solid #2990FF;
          }
        }
      }
      & > .input-container {
        margin-top: 16px;
      }
      & > .input-container ~ .input-container {
        margin-top: 16px;
      }
      .button-container {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 10px;
        background: #F8FAFC;
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        align-items:center;
        justify-content: flex-end;
        height: 80px;
        border-radius: 0 0 12px 12px;

        .copy-link-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100px;
          height: 36px;
          background: #FFFFFF;
          border-radius: 4px;
          border: 1px solid #E2E8F0;
          font-weight: 600;
          font-size: 14px;
          cursor: pointer;
          color: #334155;
        }
        .action-btn-container {
          display: flex;
          flex-direction: row;
        }
        .cancel-btn {
          border: 1px solid #E2E8F0;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          border-radius: 8px;
        }
        .edit-project {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          border-radius: 8px;
        }
        .inactive-create-btn {
          background: #E2E8F0 !important;
          color: #64748B !important;
          cursor: default;
        }
      }
    }
  }
</style>
