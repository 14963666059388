<template>
  <div class="project-info-item-container" :class="{'active-project-info-container':$route.params.projectId === project.id}" @click.stop="openProject()">
    <div class="project-info-title" :title="project.name">
      <span>{{ project.name }}</span>
    </div>
    <div class="project-info-last-edited">
      <span>{{"Last Edited" + lastEdited}}</span>
    </div>
  </div>
</template>

<script>
import {computed} from "vue";
import { useRouter } from "vue-router";
import { formattedTime } from "../../utils.js";

export default {
  name: "ProjectInfoComponent",
  props:['project'],
  setup(props) {
    const router = useRouter();
    const lastEdited = computed(()=> {
      return  formattedTime(props.project.updatedAt)
    })
    function openProject() {
      router.push("/p/" + props.project.id);
    }
    return {
      lastEdited,
      openProject,
    }
  }
}
</script>

<style scoped lang="scss">
  .project-info-item-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 54px;
    margin: 10px;
    padding: 10px;
    border-radius: 6px;
    color: #334155;
    overflow: hidden;
    .project-info-title {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      justify-content: flex-start;
      white-space: nowrap;
      font-weight: 500;
      text-align: start;

      font-size: 14px;
      line-height: 20px;

      span {
        text-overflow: ellipsis;
      }
    }
    .project-info-last-edited {
      display: flex;
      justify-content: flex-start;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }
  .active-project-info-container {
    color: #2990FF;
    background-color:#EAF4FF;
  }
  .project-info-item-container:hover {
    background-color:#EAF4FF;
    cursor: pointer;
  }
</style>
