<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.50033 11.4993L12.8337 5.16602L10.8337 3.16602L4.50033 8.49935M7.50033 11.4993L3.16699 12.8327M7.50033 11.4993L4.50033 8.49935M3.16699 12.8327L4.50033 8.49935M3.16699 12.8327H12.8337" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "highlight-anno-category-icon",
};
</script>

<style scoped>

</style>
