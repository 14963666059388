<template>
  <div class="user-info-overlay-container" @click="$emit('hide')"/>
  <div class="user-info-modal-container" :key="renderKey">
    <div class="user-info-container">
      <Avatar
        :owner="ownerTitle"
        class="user-avatar"
      />
      <div class="user-info-values">
        <div class="user-name">
          {{userName}}
        </div>
        <div class="user-email">
          {{userEmail}}
        </div>
      </div>
    </div>
    <div class="user-info-modal-item-container">
      <div class="user-info-modal-item" v-if="!isAuthenticated" @click="signIn">
        <div class="modal-item-icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#334155"><title>login-variant</title><path d="M19,3H5C3.89,3 3,3.89 3,5V9H5V5H19V19H5V15H3V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M10.08,15.58L11.5,17L16.5,12L11.5,7L10.08,8.41L12.67,11H3V13H12.67L10.08,15.58Z" /></svg>
        </div>
        <div class="modal-item-title">Sign In</div>
      </div>
      <div class="user-info-modal-item" v-if="isAuthenticated" @click="$router.push({name:'account-settings'}); $emit('hide')">
        <div class="modal-item-icon">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.68398 12.4676L5.60132 12.6113C6.27849 12.7173 6.7621 13.3375 6.71223 14.0363L6.64464 14.9834C6.62496 15.2593 6.77194 15.5191 7.01538 15.6392L7.69387 15.9735C7.93731 16.0936 8.228 16.0487 8.42682 15.8614L9.10859 15.217C9.61122 14.7418 10.3868 14.7418 10.8901 15.217L11.5719 15.8614C11.7707 16.0493 12.0607 16.0936 12.3048 15.9735L12.9846 15.6385C13.2274 15.5191 13.3737 15.26 13.3541 14.9847L13.2865 14.0363C13.2366 13.3375 13.7202 12.7173 14.3974 12.6113L15.3147 12.4676C15.5818 12.426 15.797 12.2213 15.8574 11.9514L16.0247 11.201C16.0851 10.9312 15.9781 10.6513 15.755 10.4955L14.9892 9.95921C14.4243 9.56319 14.2517 8.78992 14.5929 8.18246L15.0555 7.35952C15.19 7.11989 15.1677 6.81985 14.9991 6.60371L14.5299 6.00161C14.3613 5.78548 14.0804 5.6962 13.8219 5.77742L12.9348 6.05531C12.2792 6.26071 11.5804 5.91637 11.3284 5.2646L10.9885 4.38327C10.8888 4.12551 10.6454 3.95636 10.3744 3.95703L9.62237 3.95905C9.35137 3.95972 9.10859 4.13021 9.01016 4.38864L8.67879 5.2599C8.42944 5.9157 7.72733 6.26272 7.06984 6.05598L6.14595 5.76601C5.88676 5.68412 5.6046 5.77407 5.43596 5.99155L4.97008 6.59431C4.80144 6.81247 4.7811 7.11318 4.91824 7.35281L5.39134 8.17776C5.73977 8.7859 5.56917 9.56654 5.00092 9.96458L4.24435 10.4949C4.02124 10.6513 3.91429 10.9312 3.97466 11.2003L4.14198 11.9508C4.20169 12.2213 4.41692 12.426 4.68398 12.4676Z" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.3256 8.67281C12.0577 9.40495 12.0577 10.5924 11.3256 11.3246C10.5934 12.0567 9.40593 12.0567 8.67378 11.3246C7.94164 10.5924 7.94164 9.40495 8.67378 8.67281C9.40593 7.94066 10.5934 7.94066 11.3256 8.67281Z" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="modal-item-title">Account Settings</div>
      </div>
      <div class="user-info-modal-item" v-if="isAuthenticated" @click="signOut">
        <div class="modal-item-icon">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.1247 7.29036L16.0413 9.9987L13.1247 12.707M15.833 9.9987H8.95801M12.708 3.95703H5.62467C4.7042 3.95703 3.95801 4.70322 3.95801 5.6237V14.3737C3.95801 15.2942 4.7042 16.0404 5.62467 16.0404H12.708" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="modal-item-title">
          <span v-if="logoutInProgress">wait until sync</span>
          <span v-else>Log Out</span>
        </div>
      </div>
    </div>
    <div class="privacy-policy-container">
      <div class="privacy-policy" @click="readPrivacyPolicy">
        {{'Privacy Policy'}}
      </div>
      <div class="dot"/>
      <div class="terms-of-service" @click="readTermsOfUse">
        {{'Terms of Service'}}
      </div>
      <div class="dot"/>
      <div class="app-version">
        {{appVersion}}
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, onMounted, ref } from "vue";
import version from "../../version.json";
import { v4 as uuid } from "uuid";
import Avatar from "../common/Avatar.vue";
import DeleteAccountModal from "./DeleteAccountModal.vue";
import ProfileService from "../../service/profileService";

export default {
  name: "UserInfoModal",
  components: { Avatar },
  emits: ["hide"],
  setup(props, {emit}) {
    const $auth0 = inject("$auth0");
    const $fetch = inject("$fetch");
    const ownerTitle = ref('')
    const userName = ref('');
    const userEmail = ref('');
    const renderKey = ref(uuid());
    const logoutInProgress = ref(false);
    const isAuthenticated = ref(false);
    const profileService = new ProfileService();
    onMounted(async () => {
      const user = await $auth0.getUser();
      isAuthenticated.value = user.isAuthenticated;
      if (isAuthenticated.value) {
        await fetchOwner()
      }
    })

    async function signIn() {
      await $auth0.signIn();
      // const user = await $auth0.getUser();
      // isAuthenticated.value = user.isAuthenticated;
      // setTimeout(() => emit("hide"), 1000);
    }
    async  function  fetchOwner () {
        userName.value = localStorage.getItem('name')||'';
        userEmail.value = localStorage.getItem('email')||'';
        ownerTitle.value= userName.value||userEmail.value;
        const {name = null, email = '', profilePicture = ''} = await profileService.getMyProfile();
        userName.value = name;
        userEmail.value = email;
        ownerTitle.value= name||email;

    }

    async function readPrivacyPolicy() {
      window.open("https://www.folia.com/legal/privacy", "_blank");
      emit("hide");
    }
    const appVersion = computed(() => {
      const { major, minor, build } = version;
      const trimmedMajor = major.toString().slice(0, 3);
      const trimmedMinor = minor.toString().slice(0, 3);
      const trimmedBuild = build.toString().slice(0, 3);
      return `v ${trimmedMajor}.${trimmedMinor}.${trimmedBuild}`;
    });

    async function readTermsOfUse() {
      window.open("https://www.folia.com/legal", "_blank");
      emit("hide");
    }

    async function profile() {
    }

    async function signOut() {
      if (logoutInProgress.value === true) return;
      logoutInProgress.value = true;
      await $auth0.signOut();
      const user = await $auth0.getUser();
      isAuthenticated.value = user.isAuthenticated;
    }

    return {
      renderKey, isAuthenticated, logoutInProgress,
      ownerTitle, userName, userEmail, appVersion,

      signIn, readPrivacyPolicy, readTermsOfUse, profile, signOut,
    };
  },
};
</script>

<style lang="scss">
.user-info-overlay-container {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 2;
}
.user-info-modal-container {
  width: 336px;
  height: 170px;
  user-select: text !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 53px;
  right: 16px;
  padding: 10px 0 ;
  z-index: 3;

  background: #FFFFFF;
  border: 1px solid rgba(51, 65, 85, 0.15);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  .user-info-container {
    width: 100%;
    padding: 8px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 56px;
    .user-avatar {
      width: 30px;
      height: 30px;
      background-color: #8cb3d5;
      color: white;
      border-radius: 15px;
      padding: 5px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      border: 2px solid #e2e8f0;
    }
    .user-info-values {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      text-align:start;
      padding-left: 8px;
      width: 100%;
      height: 36px;
      .user-name {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #334155;
      }
      .user-email {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #64748B;
      }
    }
  }
  .user-info-modal-item-container {
    border-top: 1px solid #E2E8F0;
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .privacy-policy-container {
    display: flex;
    padding: 8px;
    position: absolute;
    width: 100%;
    border-top: 1px solid #E2E8F0;
    bottom: 0;
    flex-direction: row;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #64748B;
    justify-content: center;
    align-items: center;
    .privacy-policy {
      cursor: pointer;
    }
    .terms-of-service {
      cursor: pointer;
    }
    .dot {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: #CBD5E1;
      margin-left: 8px;
      margin-right: 8px;
    }
    .app-version {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
    }
  }
  .user-info-modal-item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer;

    .modal-item-icon {
      width: 20px;
      height: 20px;
    }
    .modal-item-title {
      margin-left: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #334155;
    }
  }
  .user-info-modal-item:hover {
    background-color: rgba(51, 65, 85, 0.15);
  }
}
</style>
