import Dexie from "dexie";

import { reactive } from "vue";

const cacheVersion = 1;
const indexDBName = "FOLIA_CACHE";

export const state = reactive({
  cachedProjects: [],
  cachedDocuments: [],
  indexDB: null,
  currentOpenedProject: {},
  isProjectLoading: true,
  isDocumentsLoading: true,
  cachedStamps: [],
});

export const GET_CACHE = {
  getCachedProjects() {
    let projects = state.cachedProjects;
    const storedData = localStorage.getItem("projectCache");
    const currentUserEmail = localStorage.getItem("email");
    if (projects.length === 0) {
      try {
        const projectData = JSON.parse(storedData);
        if (projectData[currentUserEmail]?.projects) {
          projects = projectData[currentUserEmail].projects;
        }
      } catch (error) {
        SET_CACHE.clearCache();
      }
    }
    return projects.filter((p) => p.id !== undefined && p.id !== null);
  },
  getCachedDocuments() {
    if (state.cachedDocuments.length === 0) {
      const storedData = localStorage.getItem("projectCache");
      const currentUserEmail = localStorage.getItem("email");
      let docs = [];
      try {
        const projectData = JSON.parse(storedData);
        if (projectData[currentUserEmail]?.documents) {
          docs = projectData[currentUserEmail].documents;
        }
      } catch (error) {
        SET_CACHE.clearCache();
      }
      state.cachedDocuments = docs;
      return docs;
    }
    return state.cachedDocuments;
  },
  getCachedCurrentOpenedProject() {
    return state.currentOpenedProject;
  },
  getProjectLoadingStatus() {
    return state.isProjectLoading;
  },
  getCachedStamps() {
    try {
      const stamps = JSON.parse(localStorage.getItem("cachedStamps")) ?? [];
      return stamps;
    } catch (error) {
      return [];
    }
  },
};

export const SET_CACHE = {
  async initCache(userEmail) {
    try {
      if (state.indexDB === undefined || state.indexDB === null) {
        const indexDB = new Dexie(indexDBName);
        indexDB
          .version(cacheVersion)
          .stores({ users: `email,name,profile`, projects: "email,projects" });
        // indexDB.users.add({
        //   email: userEmail,
        //   profile: { name: "sahan", profilePicture: null },
        // });
        await indexDB.open();
        state.indexDB = indexDB;
      }
    } catch (error) {
      console.log("error creating index db", error);
    }
  },
  clearCache() {
    // state.indexDB[userEmail].clear();
    const currentUserEmail = localStorage.getItem("email");
    localStorage.setItem(
      "projectCache",
      JSON.stringify({
        [currentUserEmail]: {
          projects: [],
          documents: [],
        },
      })
    );
    return true;
  },
  setCurrentProjectLoadingState() {
    state.isProjectLoading = true;
    return;
  },
  setDocumentsLoadingState() {
    state.isDocumentsLoading = true;
    return;
  },
  cacheProjects({ project, user }) {
    let projects = state.cachedProjects;
    const storedData = localStorage.getItem("projectCache");
    const currentUserEmail = localStorage.getItem("email");
    try {
      const projectData = JSON.parse(storedData);
      if (projectData[currentUserEmail]?.projects) {
        projects = projectData[currentUserEmail].projects;
      }
    } catch (error) {}
    function addUniqueProject(project) {
      const existingProjectIndex = projects.findIndex(
        (p) => p.id === project.id
      );
      if (existingProjectIndex !== -1) {
        projects.splice(existingProjectIndex, 1);
      }
      projects.push(project);
      projects.sort((a, b) => b.timestamp - a.timestamp);
      return projects;
    }

    const uniqueProjects = addUniqueProject({
      ...project,
      timestamp: Date.now(),
    });
    state.cachedProjects = uniqueProjects;
    saveProjectsCachedDataInStorage();
  },
  cacheCurrentProject({ project, user }) {
    state.currentOpenedProject = project;
    state.isProjectLoading = false;
    saveCurrentProjectCachedDataInStorage();
  },

  cacheDocuments({ documents: docs }) {
    let documents = state.cachedDocuments;
    const storedData = localStorage.getItem("projectCache");
    const currentUserEmail = localStorage.getItem("email");

    try {
      const projectData = JSON.parse(storedData);
      if (projectData[currentUserEmail]?.documents) {
        documents = projectData[currentUserEmail].documents;
      }
    } catch (error) {
      this.clearCache();
    }
    function addUniqueDocument(document) {
      const existingProjectIndex = documents.findIndex(
        (d) => d.id === document.id
      );
      if (existingProjectIndex !== -1) {
        documents.splice(existingProjectIndex, 1);
      }
      documents.push(document);
      documents.sort((a, b) => b.timestamp - a.timestamp);
      return documents;
    }
    docs.forEach((doc) => {
      documents = addUniqueDocument({ ...doc, timestamp: Date.now() });
    });
    state.cachedDocuments = documents;
    saveDocumentsCachedDataInStorage();
    state.isDocumentsLoading = false;
  },
  deleteCachedProject(projectId) {
    let projects = state.cachedProjects;
    let projectData = {};
    const storedData = localStorage.getItem("projectCache");
    const currentUserEmail = localStorage.getItem("email");
    try {
      projectData = JSON.parse(storedData);
      if (projectData[currentUserEmail]?.projects) {
        projects = projectData[currentUserEmail].projects;
      }
    } catch (error) {
      this.clearCache();
    }
    const updatedProjects = projects.filter(
      (p) => p.id.toString() !== projectId.toString()
    );
    projectData[currentUserEmail].projects = updatedProjects;
    state.cachedProjects = updatedProjects;
    state.isProjectLoading = false;
    localStorage.setItem("projectCache", JSON.stringify(projectData));
  },
  deleteCachedDocument(documentId) {
    let documents = state.cachedDocuments;
    let projectData = {};
    const storedData = localStorage.getItem("projectCache");
    const currentUserEmail = localStorage.getItem("email");

    try {
      projectData = JSON.parse(storedData);
      if (projectData[currentUserEmail]?.documents) {
        documents = projectData[currentUserEmail].documents;
      }
    } catch (error) {
      this.clearCache();
    }
    const updatedDocs = documents.filter(
      (d) => d.id.toString() !== documentId.toString()
    );
    projectData[currentUserEmail] = {
      ...projectData[currentUserEmail],
      documents: updatedDocs,
    };
    state.cachedDocuments = updatedDocs;
    localStorage.setItem("projectCache", JSON.stringify(projectData));
    state.isDocumentsLoading = false;
    return true;
  },
  cacheStamps(stampArray) {
    try {
      const stamps = JSON.parse(localStorage.getItem("cachedStamps")) ?? [];
      stampArray.forEach((stampObj) => {
        const { id: newStampId } = stampObj;
        if (!stamps.some((s) => s.id === newStampId)) {
          stamps.push(stampObj);
        }
      });
      localStorage.setItem("cachedStamps", JSON.stringify(stamps));
    } catch (error) {}
  },
  deleteStampFromCache(stampId) {
    try {
      const stamps = JSON.parse(localStorage.getItem("cachedStamps")) ?? [];
      const modifiedStamps = stamps.filter((s) => s.id !== stampId);
      localStorage.setItem("cachedStamps", JSON.stringify(modifiedStamps));
    } catch (error) {}
  },
  makeRecentStamp(id) {
    try {
      const stamps = JSON.parse(localStorage.getItem("cachedStamps")) ?? [];
      const modifiedStamps = stamps.map((s) => {
        if (s.id === id) {
          s.recent = Date.now();
        }
        return s;
      });
      localStorage.setItem("cachedStamps", JSON.stringify(modifiedStamps));
    } catch (error) {}
  },
  updateStamp(stamp) {
    try {
      const stamps = JSON.parse(localStorage.getItem("cachedStamps")) ?? [];
      const modifiedStamps = stamps.map((s) => {
        if (s.id === stamp.id) {
          const newData = {
            ...s,
            ...stamp
          }
          return newData
        }
        return s;
      });
      localStorage.setItem("cachedStamps", JSON.stringify(modifiedStamps));
    } catch (error) {}
  },
};

const saveCurrentProjectCachedDataInStorage = (payload) => {
  try {
    const storedData = localStorage.getItem("projectCache");
    const currentUserEmail = localStorage.getItem("email");
    if (storedData) {
      const projectData = JSON.parse(storedData);
      if (projectData[currentUserEmail]) {
        projectData[currentUserEmail].currentOpenedProject =
          state.currentOpenedProject;
        localStorage.setItem("projectCache", JSON.stringify(projectData));
      }
    }
  } catch (error) {
    this.clearCache();
  }
};

const saveProjectsCachedDataInStorage = () => {
  try {
    const storedData = localStorage.getItem("projectCache");
    const currentUserEmail = localStorage.getItem("email");
    if (storedData) {
      const projectData = JSON.parse(storedData);
      if (projectData[currentUserEmail]) {
        projectData[currentUserEmail].projects = state.cachedProjects;
        localStorage.setItem("projectCache", JSON.stringify(projectData));
      }
    } else {
      localStorage.setItem(
        "projectCache",
        JSON.stringify({
          [currentUserEmail]: { projects: state.cachedProjects },
        })
      );
    }
  } catch (error) {
    this.clearCache();
  }
};

const saveDocumentsCachedDataInStorage = () => {
  try {
    const storedData = localStorage.getItem("projectCache");
    const currentUserEmail = localStorage.getItem("email");
    if (storedData) {
      const projectData = JSON.parse(storedData);
      if (projectData[currentUserEmail]) {
        projectData[currentUserEmail].documents = state.cachedDocuments;
        localStorage.setItem("projectCache", JSON.stringify(projectData));
      }
    } else {
      localStorage.setItem(
        "projectCache",
        JSON.stringify({
          [currentUserEmail]: { documents: state.cachedDocuments },
        })
      );
    }
  } catch (error) {
    this.clearCache();
  }
};
const store = { GET_CACHE, SET_CACHE };
export default store;
