<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.12467 8.95831V13.5416M11.0413 8.95831V13.5416M7.29134 6.45831V5.62498C7.29134 4.70451 8.03753 3.95831 8.95801 3.95831H10.208C11.1285 3.95831 11.8747 4.70451 11.8747 5.62498V6.45831M3.95801 6.45831H15.208M5.4923 14.5194L4.79134 6.45831H14.3747L13.6737 14.5194C13.5988 15.3806 12.8778 16.0416 12.0133 16.0416H7.1527C6.28819 16.0416 5.56719 15.3806 5.4923 14.5194Z" stroke="#F43F5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "delete-icon",
};
</script>

<style scoped>

</style>
