<script>
import { v4 as uuid } from "uuid";
import { inject, onBeforeUnmount, onMounted, reactive, ref, watch } from "vue";
import { BROADCAST_MESSAGE, CLOUD_STORAGE, ERROR_TOAST } from "../../../core/constants.js";
import ViewStyleSwitcher from "../../common/buttons/ViewStyleSwitcher.vue";
import ContextMenuButton from "../../common/buttons/ContextMenuButton.vue";

const EXPORTABLE_STATUS = {
  EXCEEDS_MAXIMUM_SIZE: "EXCEEDS_MAXIMUM_SIZE",
  EXPORTABLE: "EXPORTABLE",
  MIME_TYPE_NOT_SUPPORTED: "MIME_TYPE_NOT_SUPPORTED",
}

export default {
  name: "CloudStorageFinder",
  components: { ContextMenuButton, ViewStyleSwitcher },
  emits: ["closeFinder", "uploadCloudFiles", "renameStorage", "removeStorage"],
  props: {
    storage: Object,
    projectId: String,
  },
  setup(props, { emit }) {
    const $fetch = inject("$fetch");
    const $uploadFiles = inject("$uploadFiles");
    const listContainerRef = ref(null);
    const gridContainerRef = ref(null);
    const state = reactive({
      selectedFiles: new Set(),
      displayStyle: localStorage.getItem("finder-view-style") || "TABLE",
      folderItems: [],
      folderId: null,
      fetching: false,
      uploading: false,
    });

    watch(() => state.displayStyle, val => {
      localStorage.setItem("finder-view-style", val)
    });

    const finderStack = new class FinderStack {
      #back = [];
      #forward = [];
      constructor() {
      }

      get canBack() {
        return this.#back.length > 1;
      }

      get canForward() {
        return this.#forward.length > 0;
      }

      get path() {
        const path = this.#back
          .map((item, index, array) => {
            return { backSteps: array.length - index - 1, name: item.name };
          })
          .filter(folder => !!folder.name);

        if (path.length > 4) {
          path.splice(1, path.length - 3, { backSteps: 0, name: '...' });
        }
        return path;
      }

      goBack(times = 1) {
        state.selectedFiles.clear();
        Array(times).fill('*').forEach(() => {
          const prev = this.#back.pop();
          this.#forward.push(prev);
        });
        this.calcOutList();
      }

      goForward() {
        state.selectedFiles.clear();
        const next = this.#forward.shift();
        this.#back.push(next);
        this.calcOutList();
      }

      goHome() {
        state.selectedFiles.clear();
        const rest = this.#back.splice(1);
        this.#forward = rest.concat(this.#forward);
        this.calcOutList();
      }

      push(id, name, generator) {
        this.#forward = [];
        this.#back.push({ id, name, items: [], generator });
        this.calcOutList();
        return this.currentFolder;
      }

      fillFolder(folderId, chunk) {
        const folder = this.#back.find(folder => folder.id === folderId);
        if (!folder) return;
        folder.items = folder.items.concat(chunk.map(item => {
          item.isFolder = item.__typename === "CloudStorageFolder"
            || item.mimeType === "application/vnd.google-apps.shortcut";
          // item.isNotAccessible = [
          //   EXPORTABLE_STATUS.MIME_TYPE_NOT_SUPPORTED, EXPORTABLE_STATUS.EXCEEDS_MAXIMUM_SIZE
          // ].includes(item.exportableStatus);
          item.isNotAccessible = [
            EXPORTABLE_STATUS.EXCEEDS_MAXIMUM_SIZE
          ].includes(item.exportableStatus);
          return item;
        }));
        this.calcOutList();
      }

      calcOutList() {
        const folder = this.#back[this.#back.length - 1];
        state.folderItems = folder?.items || [];
        state.folderId = folder?.id || null;
      }

      get currentFolder() {
        return this.#back[this.#back.length - 1];
      }

      getFolderById(id) {
        return this.#back.find(folder => folder.id === id);
      }
    };

    onMounted(() => {
      getItems(null, "");
      listContainerRef.value.addEventListener("scroll", scrollHandler);
      gridContainerRef.value.addEventListener("scroll", scrollHandler);
    });

    onBeforeUnmount(() => {
      listContainerRef.value.removeEventListener("scroll", scrollHandler);
      gridContainerRef.value.removeEventListener("scroll", scrollHandler);
    });

    function scrollHandler(e) {
      const { scrollHeight, scrollTop, clientHeight } = e.target;
      if (Math.round(scrollHeight) - Math.round(scrollTop + clientHeight) < 2) {
        getItems(finderStack.currentFolder.id, finderStack.currentFolder.name);
      }
    }

    function getItems(parentFolderId, parentFolderName) {
      let folder = finderStack.getFolderById(parentFolderId);
      if (!folder) {
        const folderGenerator = $fetch.getCloudStorageItems(props.storage.id, parentFolderId)();
        folder = finderStack.push(parentFolderId, parentFolderName, folderGenerator);
      }

      state.fetching = true;
      folder.generator.next()
        .then(chunk => {
          if (chunk.value) finderStack.fillFolder(parentFolderId, chunk.value);
        })
        .catch(error => {
          $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
          type: ERROR_TOAST,
          title: "Error fetching drive items.",
          message: error.message,
        })
        }).finally(() => state.fetching = false);
    }

    function itemOnClick(item) {
      if (item.isNotAccessible) return;

      if (item.isFolder) {
        state.selectedFiles.clear();
        getItems(item.id, item.name);
      } else {
        if (state.selectedFiles.has(item)) {
          state.selectedFiles.delete(item);
        } else {
          state.selectedFiles.add(item);
        }
      }
    }

    function startUpload() {
      const files = Array.from(state.selectedFiles).map(file => {
        const { id: cloudFileId, name } = file;
        const id = uuid();
        return { id, projectId: props.projectId, cloudFileId, name };
      });
      state.uploading = true;

      emit('closeFinder');
      $uploadFiles.uploadCloudFiles(props.projectId, props.storage.id, files)
        .catch(console.error)
        .finally(() => {
          state.uploading = false;
          state.selectedFiles.clear();
        });
    }

    function selectContextMenu(option) {
      if (option === "rename") emit("renameStorage");
      if (option === "remove") emit("removeStorage");
    }

    return {
      CLOUD_STORAGE, EXPORTABLE_STATUS,
      state, finderStack, listContainerRef, gridContainerRef,
      itemOnClick, startUpload, selectContextMenu
    }
  }
}
</script>

<template>
  <div class="cloud-storage-finder-overlay" @click.stop="$emit('closeFinder')">
    <div class="cloud-storage-finder" @click.stop="">
      <div class="finder-header">
        <div
          class="storage-icon"
          :class="{
            'google-drive': storage.provider === CLOUD_STORAGE.GOOGLE_DRIVE,
            'one-drive': storage.provider === CLOUD_STORAGE.ONE_DRIVE,
            'dropbox': storage.provider === CLOUD_STORAGE.DROPBOX_DRIVE,
            'box': storage.provider === CLOUD_STORAGE.BOX_DRIVE,
          }"
        ></div>
        <div class="storage-header-title">
          <span class="title-storage-name">{{storage.name}}</span>
          <span class="title-text">Select the documents you want to add to this project. Maximum file size: 200MB</span>
        </div>
        <div class="storage-header-buttons">
          <ContextMenuButton @select="selectContextMenu">
            <template v-slot:rename>Rename</template>
            <template v-slot:remove>Remove</template>
          </ContextMenuButton>

        </div>
      </div>
      <div class="finder-navigator">
        <div class="navigator-buttons">
          <button
            class="buttons-back-forward"
            @click.stop="finderStack.goBack"
            :disabled="!finderStack.canBack"
          >
            <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.25 0.75L0.75 4L4.25 7.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
          <button
            class="buttons-back-forward"
            @click.stop="finderStack.goForward"
            :disabled="!finderStack.canForward"
          >
            <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.75 0.75L4.25 4L0.75 7.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
          <div v-if="finderStack.path.length > 0"
               class="buttons-home"
               @click.stop="finderStack.goHome"
          >
            <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 10.2843L9.954 1.32925C10.394 0.89025 11.106 0.89025 11.545 1.32925L20.5 10.2843M3.25 8.03425V18.1593C3.25 18.7803 3.754 19.2843 4.375 19.2843H8.5V14.4093C8.5 13.7883 9.004 13.2843 9.625 13.2843H11.875C12.496 13.2843 13 13.7883 13 14.4093V19.2843H17.125C17.746 19.2843 18.25 18.7803 18.25 18.1593V8.03425M7 19.2843H15.25" stroke="#94A3B8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div v-if="finderStack.path.length > 0" class="buttons-path">
            <button v-for="folder of finderStack.path"
                    @click.stop="finderStack.goBack(folder.backSteps)"
                    class="path-folder"
                    :class="{ accessible: folder.backSteps > 0 }"
            >{{ folder.name }}</button>
          </div>
        </div>
        <ViewStyleSwitcher v-model:displayStyle="state.displayStyle" />
      </div>
      <div class="finder-body-table" :class="{'shown': state.displayStyle === 'TABLE'}" ref="listContainerRef">
        <div
          v-for="item of state.folderItems"
          class="body-table-item"
          :class="{
            'selected': state.selectedFiles.has(item),
            'disabled': item.isNotAccessible,
          }"
          @click.stop="itemOnClick(item)"
        >
          <div class="table-item-icon" :class="{'folder': item.isFolder, 'file': !item.isFolder}"></div>
          <div class="table-item-name">
            <span class="file-name">{{item.name}}</span>
            <span v-if="item.exportableStatus === EXPORTABLE_STATUS.EXCEEDS_MAXIMUM_SIZE"
                  class="reason"
                  :class="{
                    'selected': state.selectedFiles.has(item),
                    'disabled': item.isNotAccessible,
                  }"
            >File exceeds maximum size allowed</span>
            <!-- <span v-if="item.exportableStatus === EXPORTABLE_STATUS.MIME_TYPE_NOT_SUPPORTED"
                  class="reason"
                  :class="{
                    'selected': state.selectedFiles.has(item),
                    'disabled': item.isNotAccessible,
                  }"
            >File format not supported</span> -->
          </div>
          <div v-if="state.selectedFiles.has(item)" class="table-item-selected"></div>
        </div>
      </div>
      <div class="finder-body-cards" :class="{'shown': state.displayStyle === 'GRID'}" ref="gridContainerRef">
        <div
          v-for="item of state.folderItems"
          class="body-cards-item"
          :class="{
            'selected': state.selectedFiles.has(item),
            'disabled': item.isNotAccessible,
          }"
          @click.stop="itemOnClick(item)"
        >
          <div class="cards-item-preview" :class="{ 'folder': item.isFolder, 'file': !item.isFolder }"></div>
          <div class="cards-item-name">{{item.name}}</div>
        </div>
      </div>
      <div class="finder-body-fetching" v-if="state.fetching" />
      <div class="finder-footer">
        <button
          class="close"
          @click.stop="$emit('closeFinder')"
        >Cancel</button>
        <button
          class="upload"
          :disabled="state.selectedFiles.size === 0 || state.uploading"
          @click.stop="startUpload"
        >
          <div v-if="state.uploading" class="loading" />
          <span v-else>Upload</span>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.cloud-storage-finder-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  .cloud-storage-finder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    position: absolute;
    width: 812px;
    filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.15));
    border-radius: 12px;
    background: #FFFFFF;

    .finder-header {
      height: 88px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;
      border-bottom: solid 1px #E2E8F0;
      border-radius: 12px 12px 0 0;
      gap: 10px;

      .storage-icon {
        //border: solid 1px black;
        width: 37px;
        height: 37px;

        &.google-drive {
          background: url("../../../assets/images/clouds/google-drive.png") no-repeat center;
          background-size: contain;
        }
        &.one-drive {
          background: url("../../../assets/images/clouds/onedrive.png") no-repeat center;
          background-size: contain;
        }
        &.dropbox {
          background: url("../../../assets/images/clouds/dropbox.png") no-repeat center;
          background-size: contain;
        }
        &.box {
          background: url("../../../assets/images/clouds/box.png") no-repeat center;
          background-size: contain;
        }
      }

      .storage-header-title {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .title-storage-name {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: #334155;
        }

        .title-text {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #64748B;
        }
      }

      .storage-header-buttons {
        display: flex;
        gap: 6px;
      }
    }

    .finder-navigator {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      border-bottom: solid 1px #E2E8F0;

      .navigator-buttons {
        display: flex;
        align-items: center;
        gap: 5px;
        overflow: hidden;
        width: 90%;

        .buttons-back-forward {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 36px;
          background: white;
          border-radius: 8px;
          cursor: pointer;
          stroke: #64748B;
          border: none;
          outline: none;

          &:disabled {
            stroke: #CBD5E1;
          }

          &:hover {
            background-color: #F8FAFC;
          }
        }

        .buttons-home {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 36px;
          height: 36px;
          background: white;
          border-radius: 8px;
          cursor: pointer;
          stroke: #64748B;

          &.disabled {
            stroke: #CBD5E1;
          }

          &:hover {
            background-color: #F8FAFC;
          }
        }

        .buttons-path {
          width: 100%;
          height: 35px;
          display: flex;
          align-items: center;
          overflow: hidden;
          gap: 5px;

          & .path-folder {
            min-height: 30px;
            max-width: 150px;
            display: flex;
            align-items: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #94A3B8;
            background-color: transparent;
            border: none;
            border-radius: 8px;
            cursor: pointer;
            padding: 0;
            margin: 0;

            &:before {
              content: "/";
              margin-right: 5px;
              color: #94A3B8;
            }
            &:last-of-type {
              color: #334155;
              overflow: visible;
              cursor: default;
            }
          }

          & .path-folder.accessible {
            &:hover:not(:last-of-type) {
              color: #334155;
              background-color: #F8FAFC;
            }
          }
        }

        ._buttons-path {
          display: flex;
          align-items: center;
          gap: 5px;

          span.path-folder {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            max-width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #94A3B8;

            &:before {
              content: "/";
              margin-right: 5px;
              color: #94A3B8;
            }

            &:last-of-type {
              color: #334155;
              overflow: visible;
            }
          }
        }
      }
    }

    .finder-body-fetching {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: url("../../../assets/images/loading-icon.gif") center no-repeat;
    }

    .finder-body-table {
      display: none;
      min-height: 400px;
      max-height: 400px;
      width: 100%;
      overflow: auto;
      background-color: #F1F5F9;

      &.shown {
        display: block;
      }

      .body-table-item {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 64px;
        padding: 12px 25px 12px 25px;
        border-bottom: 1px solid #CBD5E1;
        gap: 10px;
        cursor: pointer;

        &.selected {
          background-color: #D4E9FF;
        }

        &.disabled {
          cursor: not-allowed;
        }

        .table-item-icon {
          border-radius: 3px;
          box-shadow: -2px 2px 5px 0px #dedede;

          &.folder {
            min-width: 35px;
            min-height: 29px;
            background: url("../../../assets/images/clouds/item-folder.png") no-repeat center;
            background-size: contain;
          }
          &.file {
            min-width: 32.35px;
            min-height: 40px;
            background: url("../../../assets/images/clouds/item-file.png") no-repeat center;
            background-size: contain;
          }
        }

        .table-item-name {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          width: 100%;
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          user-select: none;

          .file-name {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #334155;

            &.disabled {
              color: #94A3B8;
            }
          }

          .reason {
            font-style: italic;
            font-weight: 500;
            font-size: 11px;
            line-height: 16px;
            color: #334155;

            &.disabled {
              color: #94A3B8;
            }
          }
        }

        .table-item-selected {
          position: absolute;
          right: 20px;
          width: 20px;
          height: 20px;
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='12' fill='%232990FF'/%3E%3Cpath d='M6.69727 10.8713L10.9399 15.114' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M17.3027 8.74999L10.9388 15.114' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 20px;
        }
      }
    }

    .finder-body-cards {
      min-height: 400px;
      max-height: 400px;
      width: 100%;
      overflow: auto;
      display: none;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 12px;
      padding: 16px 24px;
      background-color: #F1F5F9;

      &.shown {
        display: flex;
      }

      .body-cards-item {
        width: 140px;
        height: 192px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px 8px 8px;
        gap: 12px;
        border-radius: 8px;
        cursor: pointer;

        &.disabled {
          cursor: not-allowed;
          * { color: silver !important; }
        }

        &.selected {
          background-color: #D4E9FF;
        }

        .cards-item-preview {
          height: 125px;
          //border: solid 1px silver;

          &.folder {
            width: 124px;
            background: url("../../../assets/images/clouds/item-folder.png") no-repeat center;
            background-size: contain;
          }

          &.file {
            box-shadow: -2px 2px 5px 0px #dedede;
            width: 100px;
            background: url("../../../assets/images/clouds/item-file.png") no-repeat center;
            background-size: contain;
          }
        }

        .cards-item-name {
          width: 124px;
          height: 35px;
          overflow: hidden;
          text-overflow: ellipsis;
          //border: solid 1px silver;

          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          color: #334155;
        }
      }
    }

    .finder-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 16px;
      height: 68px;
      width: 100%;
      background: #F8FAFC;
      border-radius: 0px 0px 12px 12px;
      gap: 8px;

      button {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;

        width: 80px;
        height: 36px;
        border-radius: 8px;
        cursor: pointer;

        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;

        &:active {
          transform: translate(1px, 1px);
        }

        &.close {
          color: #334155;
          background: #FFFFFF;
          border: 1px solid #E2E8F0;
        }

        &.upload {
          border: 1px solid #334155;
          background: #334155;
          color: white;

          .loading {
            width: 16px;
            height: 16px;
            background: url("../../../assets/images/loading-icon.gif") center;
            background-size: contain;
          }

          &:disabled {
            border: 1px solid #E2E8F0;
            background: #E2E8F0;
            color: #64748B;
          }
        }
      }
    }
  }
}
</style>