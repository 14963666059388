<template>
  <div class="dark-background">
    <div class="delete-project-modal">
      <div class="close-modal" @click="$emit('close')">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.625 3.375L3.375 8.625M3.375 3.375L8.625 8.625" stroke="#334155" stroke-width="1.5"
            stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <div class="delete-project-container">
        <div class="warning-logo">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.90406 24.7072L12.4301 3.71318C13.9059 0.769647 18.1075 0.770415 19.5822 3.71449L30.0986 24.7085C31.4309 27.3683 29.497 30.5 26.5222 30.5H5.47979C2.50433 30.5 0.57045 27.3671 1.90406 24.7072Z"
              fill="#FECDD3" />
            <path
              d="M16.9996 24C16.9996 24.5523 16.5519 25 15.9996 25C15.4474 25 14.9996 24.5523 14.9996 24C14.9996 23.4477 15.4474 23 15.9996 23C16.5519 23 16.9996 23.4477 16.9996 24Z"
              fill="#FECDD3" />
            <path
              d="M15.9996 12V16M12.4301 3.71318L1.90406 24.7072C0.57045 27.3671 2.50433 30.5 5.47979 30.5H26.5222C29.497 30.5 31.4309 27.3683 30.0986 24.7085L19.5822 3.71449C18.1075 0.770415 13.9059 0.769647 12.4301 3.71318ZM16.9996 24C16.9996 24.5523 16.5519 25 15.9996 25C15.4474 25 14.9996 24.5523 14.9996 24C14.9996 23.4477 15.4474 23 15.9996 23C16.5519 23 16.9996 23.4477 16.9996 24Z"
              stroke="#F43F5E" stroke-width="2" />
          </svg>
        </div>
        <div class="delete-title">Delete Project</div>
        <div class="delete-text">
          Are you sure you want to delete <span class="project-name">{{ projectName }}</span>? This will also delete all
          the
          files in this project.

          <p>You cannot undo this action.</p>
        </div>
        <div class="button-container">
          <ActionButton class="cancel-btn" :width="'106px'" :height="'36px'" :text="'Nevermind'" :fontColor="'#334155'"
            :background-color="'#FFFFFF'" @click="$emit('close')" />
          <ActionButton class="delete-project-btn" :width="'128px'" :height="'36px'" :fontColor="'#FFFFFF'"
            :text="'Delete Project'" :disabled="isProjectDeleting" :isLoading="isProjectDeleting" :background-color="'#F43F5E'" @click="deleteProject" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from "../common/ActionButton.vue";
import { inject, onMounted, ref } from "vue";
import { BROADCAST_MESSAGE, ERROR_TOAST, SUCCESS_TOAST } from "../../core/constants.js";
import ProjectService from '../../service/projectService';
import Loader from '../common/loaders/defaultLoader.vue';

export default {
  name: "DeleteProjectModal",
  props: [
    'projectId',
    'projectName'
  ],
  components: {
    ActionButton,
    Loader
  },
  setup(props, context) {
    const $fetch = inject('$fetch');
    const $auth0 = inject("$auth0");
    const projectName = ref('');
    const projectService = new ProjectService();
    const isProjectDeleting = ref(false)
    onMounted(() => {
      // fetchProject();
      projectName.value = props.projectName;
    })

    function fetchProject() {
      $fetch.getProject(props.projectId).then(project => {
        projectName.value = project.name;
      })
    }

    async function deleteProject() {
      if(!isProjectDeleting.value){
        const { projectId, projectName } = props;
        isProjectDeleting.value = true;
        const { deleted = true, message:msg = 'Folia encountered an error when attempting to delete the project. Please try again.' } 
        = await projectService.deleteProject(projectId);
        if (deleted) {
          const message = `<span>The project <span class="message-toast-name">${projectName}</span> has been successfully deleted</span>`;
          $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
            type: SUCCESS_TOAST, title: 'Successfully deleted project ', message
          })
        } else {
          $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
            type: ERROR_TOAST, title: 'Error deleting project', msg
          })
        }
        isProjectDeleting.value = false;
        context.emit('close',deleted);
      } 
    }
    return {
      deleteProject,
      projectName,
      isProjectDeleting
    }
  }
};
</script>

<style lang="scss" scoped>
.dark-background {
  position: fixed;
  z-index: 200;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);

  .delete-project-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 12px;
    background-color: white;
    width: 440px;
    height: 330px;
    padding: 24px 24px 16px 24px;

    .close-modal {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      border: 1px solid #E2E8F0;
      border-radius: 6px;
      width: 24px;
      height: 24px;
      top: 24px;
      right: 24px;
      cursor: pointer;

      &:hover {
        background-color: #f1f1f1;
      }
    }

    .delete-project-container {
      width: 100%;
      height: 100%;
      padding-top: 10px;

      .delete-title {
        margin-top: 16px;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #334155;
      }

      .delete-text {
        margin-top: 24px;
        margin-bottom: 24px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #334155;

        .project-name {
          font-weight: 600;
        }
      }

      .button-container {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: #F8FAFC;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 80px;
        border-radius: 0 0 12px 12px;

        .cancel-btn {
          border: 1px solid #E2E8F0;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          border-radius: 8px;
        }

        .delete-project-btn {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          border-radius: 8px;
        }
      }
    }
  }
}
</style>
