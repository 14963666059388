<template>
  <div class="dark-background">
    <div class="edit-document-modal" ref="editDocumentModalRef">
      <div class="modal-title">
        <span>Rename Document</span>
      </div>
      <div class="input-container">
        <div class="field-label" :class="{'active-field-label':nameDocumentInputInFocus}">Document Name</div>
        <input ref="nameDocumentInput" placeholder="Project Name" type="text" class="input-field" v-model="nameDocument" />
      </div>
      <div class="button-container">
        <div class="action-btn-container">
          <ActionButton
            class="cancel-btn"
            :width="'72px'"
            :height="'36px'"
            :text="'Cancel'"
            :fontColor="'#334155'"
            :background-color="'#FFFFFF'"
            @click="$emit('close')"
          />
          <ActionButton
            class="edit-project"
            :class="{'inactive-create-btn':!nameDocument}"
            :width="'130px'"
            :height="'36px'"
            :text="'Save Changes'"
            :fontColor="'#FFFFFF'"
            :background-color="'#334155'"
            @click="editDocument"
          />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import ProjectService from "../../service/projectService";
import ActionButton from "../common/ActionButton.vue";
import { inject, onBeforeUnmount, onMounted, ref } from "vue";
import { BROADCAST_MESSAGE, ERROR_TOAST, SUCCESS_TOAST } from "../../core/constants.js";

export default {
  name: "EditDocumentModal",
  props:[
    'documentName',
    'documentId',
    'projectId',
  ],
  components: {
    ActionButton,
  },
  emits: ["fetchDocuments"],
  setup(props,context) {
    const $fetch  = inject('$fetch')
    const nameDocument = ref(props.documentName)
    const nameDocumentInput = ref(null)
    const editDocumentModalRef = ref(null)
    const nameDocumentInputInFocus = ref(false)
    onMounted(() => {
      editDocumentModalRef.value.addEventListener('focusin',focusIn);
      nameDocumentInput.value.addEventListener('focusout',focusOutInput)
      nameDocumentInput.value.focus()
    })
    onBeforeUnmount(() => {
      editDocumentModalRef.value.removeEventListener('focusin',focusIn);
      nameDocumentInput.value.removeEventListener('focusout',focusOutInput)
    })
    function focusIn(e) {
      if (e.target === nameDocumentInput.value ) {
        nameDocumentInputInFocus.value = true
      }
    }
    function focusOutInput() {
      nameDocumentInputInFocus.value = false
    }
    async function editDocument () {
      if (!nameDocument.value) return
      const addedAt = new Date().toISOString();
      const projectService = new ProjectService();
      const res = await projectService.updateDocumentName({projectId:props.projectId,documentId:props.documentId,name:nameDocument.value});
      context.emit('close');
      if (res) {
        const type = SUCCESS_TOAST;
        const title = 'Successfully renamed the document';
        const message = `<span><span class="message-toast-name">
                      </span> Name has been updated to ${nameDocument.value}</span>`;
        const toast = {
          type, title, message,
        }
        $fetch.dispatch(BROADCAST_MESSAGE.TOAST, toast);
        context.emit('fetchDocuments', {reloadDocuments:true});
      } else {
        message = 'Folia encountered an error when attempting to rename this document. Please try again.';
        const toast = {
          type: ERROR_TOAST, title: 'Error renaming the document', message
        }
        $fetch.dispatch(BROADCAST_MESSAGE.TOAST, toast);
      }
      // $fetch.updateDocumentName(props.documentId, addedAt, nameDocument.value).then(() => {
      //   context.emit('close');
      //   context.emit('fetchDocuments', props.projectId);
      // });
    }
    return {
      editDocument,
      nameDocument,
      nameDocumentInput,
      editDocumentModalRef,
      nameDocumentInputInFocus,
    }
  }
 };
</script>

<style lang="scss" scoped>
  .dark-background {
    position: fixed;
    z-index: 200;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.25);
    .edit-document-modal {
      position: relative;
      display: flex;
      z-index: 250 !important;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      border-radius: 12px;
      background-color: white;
      width: 550px;
      height: 250px;
      padding: 24px 24px 16px 24px;
      .modal-title {
        color: #334155;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
      }
      .input-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .field-label {
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #64748B;
        }
        .active-field-label {
          color: #2990FF;
        }
        .input-field {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #334155;
          background: #F1F5F9;
          width: 100% !important;
          height: 44px;
          margin-top: 8px;
          margin-bottom: 20px;
          box-sizing: border-box;
          padding: 0 8px;
          border: 0;
          border-radius: 6px;
          &:focus {
            outline: none;
            border: 2px solid #2990FF;
          }
        }
      }
      & > .input-container {
        margin-top: 16px;
      }
      & > .input-container ~ .input-container {
        margin-top: 16px;
      }
      .button-container {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 10px;
        background: #F8FAFC;
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        align-items:center;
        justify-content: flex-end;
        height: 80px;
        border-radius: 0 0 12px 12px;

        .copy-link-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100px;
          height: 36px;
          background: #FFFFFF;
          border-radius: 4px;
          border: 1px solid #E2E8F0;
          font-weight: 600;
          font-size: 14px;
          cursor: pointer;
          color: #334155;
        }
        .action-btn-container {
          display: flex;
          flex-direction: row;
        }
        .cancel-btn {
          border: 1px solid #E2E8F0;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          border-radius: 8px;
        }
        .edit-project {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          border-radius: 8px;
        }
        .inactive-create-btn {
          background: #E2E8F0 !important;
          color: #64748B !important;
          cursor: default;
        }
      }
    }
  }
</style>
