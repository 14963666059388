<template>
  <div class="avatar-stack-container">
    <Avatar
      v-if="ownerUser"
      :owner="ownerUser?.profile?.username || ownerUser?.email"
      :title="ownerUser?.profile?.username || ownerUser?.email"
      class="user-avatar"
    />
    <div v-if="filteredCollaborators.length > 0" class="dot"/>
    <div class="avatars-collaborators-stack">
      <Avatar
        v-for="collaborator in filteredCollaborators.slice(0, 4)"
        :owner="collaborator.profile?.username || collaborator.email"
        :title="collaborator.profile?.username || collaborator.email"
        class="user-avatar"
      />
      <div class="collaborators-count" v-if="filteredCollaborators.length > 4">
          <span>{{"+"+ (filteredCollaborators.length - 4)}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "./Avatar.vue";
import { computed, ref } from "vue";
export default {
  name: "AvatarStack",
  props: ['collaborators'],
  components: {
    Avatar,
  },
  setup(props) {
    const ownerData = ref({})
    const ownerUser = computed(()=>{
      return props.collaborators.find(collaborator => collaborator.role === 'OWNER')
    })
    const filteredCollaborators = computed(()=>{
      return props.collaborators.filter(collaborator => collaborator.role !== 'OWNER')
    })
    return {
      filteredCollaborators,
      ownerData,
      ownerUser,
    }
  }
};
</script>

<style lang="scss" scoped>
  .avatar-stack-container {
    width: 50%;
    height: 50%;
    margin-left: -1px;
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .dot {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      margin: 5px;
      background-color: silver;
    }
    .avatars-collaborators-stack {
      display: flex;
      flex-direction: row;
      margin-left: 10px;
      &:first-child {
        z-index: 5;
      }
      &:nth-child(2) {
        z-index: 4;
      }
      &:nth-child(3) {
        z-index: 3;
      }
      &:nth-child(4) {
        z-index: 2;
      }
      &:nth-child(5) {
        z-index: 1;
      }
      &:last-child {z-index: 0}
      &:hover {
        .user-avatar {
          margin-right:5px;
        }
      }
      .user-avatar {
        width: 30px;
        height: 30px;
        margin-left: -10px;
        background-color: #8cb3d5;
        color: white;
        border-radius: 15px;
        padding: 5px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        border: 2px solid #FFFFFF;
      }
      .collaborators-count {
        border-radius: 50%;
        overflow: hidden;
        font-size: 12px;
        width: 30px;
        height: 30px;
        margin-left: -10px;
        background-color: #3f4a65;
        color: white;
        padding: 5px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        font-weight: 600;
        line-height: 16px;
        -webkit-box-pack: center;
        justify-content: center;
        border: 2px solid #FFFFFF;
      }
    }
    .user-avatar {
      width: 30px;
      height: 30px;
      background-color: #8cb3d5;
      color: white;
      border-radius: 15px;
      padding: 5px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      border: 2px solid #e2e8f0;
    }
  }
</style>
