<template>
  <div class="app-container">
    <router-view></router-view>
    <AlertToasts />      
  </div>
</template>

<script>
import AlertToasts from "./views/alert-toasts/AlertToasts.vue";
import './style.css'
export default {
  components: { AlertToasts },
  setup() {
    return {};
  },
};
</script>

<!-- <style lang="scss" scoped>
.app-container {
  height: 100vh;
  width: 100vw;
  background: #F8FAFC;
  display: flex;
  align-items: center;
  //flex-direction: column;
  @media only screen and (max-width: 768px) {
    margin: 6px;
  }
}
</style>
<style lang="scss">
html {
  height: 100%;
  font-family: 'Inter', sans-serif;

  button {
    font-family: Inter, sans-serif;
  }
}
body {
  height: 100%;
  margin: 0;
}
* {
  box-sizing: border-box;
}
</style> -->
