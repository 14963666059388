<template>
  <svg width="484" height="140" viewBox="0 0 484 140" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_17355_126163)">
      <rect x="0.5" width="145" height="92" fill="#93C5FD"/>
      <rect x="0.5" y="92" width="145" height="48" fill="white"/>
    </g>
    <g clip-path="url(#clip1_17355_126163)">
      <rect x="169.5" width="145" height="92" fill="#C4B5FD"/>
      <rect x="169.5" y="92" width="145" height="48" fill="white"/>
    </g>
    <g clip-path="url(#clip2_17355_126163)">
      <rect x="338.5" width="145" height="92" fill="#F9A8D4"/>
      <rect x="338.5" y="92" width="145" height="48" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_17355_126163">
        <rect x="0.5" width="145" height="140" rx="8" fill="white"/>
      </clipPath>
      <clipPath id="clip1_17355_126163">
        <rect x="169.5" width="145" height="140" rx="8" fill="white"/>
      </clipPath>
      <clipPath id="clip2_17355_126163">
        <rect x="338.5" width="145" height="140" rx="8" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "card-empty-stack",
};
</script>

<style scoped>

</style>
