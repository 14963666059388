import { dataDog } from "../core/folia-datadog-log.js";
import ServiceWorkerProxy from "./service-worker-proxy.js";

const GET = "GET";
const POST = "POST";
const PUT = "PUT";
const DELETE = "DELETE";
const APPLICATION_JSON = "application/json";
const APPLICATION_STREAM = "application/octet-stream";

async function fetchWrapper(app, method, path, data, recursiveCounter = 0) {
  const user = await app.config.globalProperties.$auth0.getUser();
  const contentType =
    data instanceof Blob ? APPLICATION_STREAM : APPLICATION_JSON;
  const body = data instanceof Blob ? data : JSON.stringify(data);
  const init = {
    method,
    headers: {
      "content-type": contentType,
      "graphql-server": import.meta.env.VITE_GRAPHQL_SERVER,
      "graphql-access-token": user.accessToken,
      "user-email": `${user.profile.email}`.toLowerCase(),
      "refresh-token": localStorage.getItem("refresh_token"),
      "auth-server": import.meta.env.VITE_AUTH_SERVER,
    },
  };
  if (method === POST || method === PUT) {
    init.body = body;
  }

  const response = await fetch(path, init);
  if (response.ok === false && response.status === 401) {
    // console.error("FETCH WRAPPER", response.status);
    await app.config.globalProperties.$auth0.signIn();
  } else if (response.ok === false) {
    throw new Error(response.statusText);
  }

  const responseContentType = response.headers.get("content-type");
  if (responseContentType === APPLICATION_JSON) {
    const json = await response.json();
    dataDog.log(`fetch:${method}:${path}`, { json });
    return json;
  } else if (responseContentType === APPLICATION_STREAM) {
    const blob = response.blob;
    dataDog.log(`fetch:${method}:${path}`, { blob: !!blob });
    return blob;
  } else {
    const text = await response.text();
    dataDog.log(`fetch:${method}:${path}`, { text });
    return text;
  }
}
async function syncErrorsHandler(app, e) {
  // console.warn("sync Errors Handler", e.message);
  dataDog.error("fetch", e);
  if (e.message === "UNAUTHORIZED") {
    return;
  } else if (e.message === "NO ACCESS") {
    await app.config.globalProperties.$router.replace("/");
  }
  throw e;
}

export default {
  install(app) {
    const graphqlServer = import.meta.env.VITE_GRAPHQL_SERVER;
    const serviceWorkerProxy = new ServiceWorkerProxy(app, graphqlServer);

    app.config.globalProperties.$fetch = serviceWorkerProxy;
    app.provide("$fetch", serviceWorkerProxy);
  },
};
