<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0003 0.660156C4.86376 0.660156 0.661133 4.27908 0.661133 8.83193C0.661133 13.3848 4.86376 17.0037 10.0003 17.0037C10.4673 17.0037 10.9342 17.0037 11.2844 16.887L17.0047 19.3385V14.2019C18.4055 12.8011 19.3395 10.9332 19.3395 8.83193C19.3395 4.27908 15.1368 0.660156 10.0003 0.660156Z" fill="#64748B"/>
  </svg>
</template>

<script>
export default {
  name: "conversation-icon",
};
</script>
