import foliaButtonWithTooltipHTML from "./folia-button-with-tooltip.html?raw";

class FoliaButtonWithTooltip extends HTMLElement {
  canBeShown = false;

  onMouseOver = this.showToolTip.bind(this);
  onMouseOut = this.hideToolTip.bind(this);
  onClick = this.doEvent.bind(this);

  constructor() {
    super();
    const template = document.createElement("template");
    template.innerHTML = foliaButtonWithTooltipHTML;
    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
  }

  static get observedAttributes() {
    return [
      "name",
      "icon",
      "tooltip",
      "top",
      "shortcut",
      "disabled",
      "selected",
      "color",
      "size",
    ];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    switch (name) {
      case "name": {
        this.name = newValue;
        break;
      }
      case "icon": {
        const buttonEl = this.shadowRoot.querySelector(".folia-button");
        if (!buttonEl) return;
        buttonEl.classList.forEach((className, index, classList) => {
          if (className.startsWith("icon_")) classList.remove(className);
        });
        buttonEl.classList.add(`icon_${newValue}`.toLowerCase());
        break;
      }
      case "tooltip": {
        this.canBeShown = Boolean(newValue);
        this.shadowRoot
          .querySelectorAll(".folia-button-tooltip-text")
          .forEach((el) => {
            el.innerText = newValue;
          });
        break;
      }
      case "top": {
        const isTop = newValue === "true" || newValue === "";
        const tooltip = this.shadowRoot.querySelector(".folia-button-tooltip");
        if (!tooltip) return;
        tooltip.classList.toggle("top", isTop);
        tooltip.classList.toggle("bottom", !isTop);
        break;
      }
      case "shortcut": {
        const shortcutEl = this.shadowRoot.querySelector(
          ".folia-button-shortcut"
        );
        if (!shortcutEl) return;
        shortcutEl.classList.toggle("shown", Boolean(newValue));
        shortcutEl.innerText = newValue;
        break;
      }
      case "disabled": {
        const isDisabled = newValue === "true" || newValue === "";
        const button = this.shadowRoot.querySelector(".folia-button");
        const toolTip = this.shadowRoot.querySelector(".folia-button-tooltip");
        if (button) button.toggleAttribute("disabled", isDisabled);
        if (toolTip) {
          toolTip.classList.toggle("disabled", isDisabled);
          toolTip.classList.toggle("shown", false);
        }
        break;
      }
      case "selected": {
        const isSelected = newValue === "true" || newValue === "";
        this.shadowRoot.querySelectorAll(".folia-button").forEach((el) => {
          el.classList.toggle("selected", isSelected);
        });
        break;
      }
      case "color": {
        this.shadowRoot.querySelectorAll(".folia-button").forEach((el) => {
          el.style.setProperty("--color", newValue);
        });
        break;
      }
      case "size": {
        this.shadowRoot.querySelectorAll(".folia-button").forEach((el) => {
          el.style.setProperty("--size", `${newValue}px`);
        });
        this.getRootNode().style.setProperty("--size", `${newValue}px`);
        break;
      }
      default:
        break;
    }
  }

  connectedCallback() {
    const button = this.shadowRoot.querySelector(".folia-button");
    button.addEventListener("mouseover", this.onMouseOver, { passive: false });
    button.addEventListener("mouseout", this.onMouseOut, { passive: false });

    button.addEventListener("click", this.onClick, { passive: false });
  }

  disconnectedCallback() {
    const button = this.shadowRoot.querySelector(".folia-button");
    button.removeEventListener("mouseover", this.onMouseOver, {
      passive: false,
    });
    button.removeEventListener("mouseout", this.onMouseOut, { passive: false });
    button.removeEventListener("click", this.onClick, { passive: false });
  }

  showToolTip(e) {
    this.delayTimer = setTimeout(() => {
      const toolTip = this.shadowRoot.querySelector(".folia-button-tooltip");
      toolTip.classList.toggle("shown", this.canBeShown);
    }, 400);
  }

  hideToolTip(e) {
    clearTimeout(this.delayTimer);
    const toolTip = this.shadowRoot.querySelector(".folia-button-tooltip");
    toolTip.classList.toggle("shown", false);
  }

  doEvent(e) {
    e.stopPropagation();

    this.dispatchEvent(
      new CustomEvent("click", {
        bubbles: true,
        cancelable: true,
        composed: true,
      })
    );
  }
}

if ("customElements" in window) {
  customElements.define("folia-button", FoliaButtonWithTooltip);
} else {
  throw new Error("Custom html element <folia-button> is not supported.");
}

export { FoliaButtonWithTooltip };
