<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.125 11.6666C14.8573 11.6666 15.5668 13.4565 15.8532 14.7466C16.0079 15.4433 15.4444 16.0416 14.7307 16.0416H13.9583M12.2917 8.54159C13.5573 8.54159 14.375 7.51557 14.375 6.24992C14.375 4.98427 13.5573 3.95825 12.2917 3.95825M11.0153 16.0416H4.81808C4.34766 16.0416 3.97751 15.6513 4.07125 15.1903C4.33004 13.9176 5.17527 11.6666 7.91667 11.6666C10.6581 11.6666 11.5033 13.9176 11.7621 15.1903C11.8558 15.6513 11.4857 16.0416 11.0153 16.0416ZM10.2083 6.24992C10.2083 7.51557 9.18233 8.54159 7.91667 8.54159C6.65102 8.54159 5.62501 7.51557 5.62501 6.24992C5.62501 4.98427 6.65102 3.95825 7.91667 3.95825C9.18233 3.95825 10.2083 4.98427 10.2083 6.24992Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "shared-with-me-nav-icon",
};
</script>

<style scoped>

</style>
