<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    stroke="#64748B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.958008 13.0413H13.0413M10.5413 0.958008V6.16634C10.5413 8.12235 8.95568 9.70801 6.99967 9.70801C5.04367 9.70801 3.45801 8.12235 3.45801 6.16634V0.958008"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "underline-icon",
};
</script>
