<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.625 5.62492C5.625 4.70444 6.37119 3.95825 7.29167 3.95825H12.7083C13.6288 3.95825 14.375 4.70444 14.375 5.62492V16.0416L10 12.2916L5.625 16.0416V5.62492Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "bookmark-icon",
};
</script>

<style scoped>

</style>
