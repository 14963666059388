import moment from "moment";

export const formattedTime = (updatedAtTime) => {
  if (updatedAtTime) {
    const today = new Date();
    const date = new Date(updatedAtTime);
    const tsYear = date.getFullYear();
    const tsMonth = date.getMonth();
    const tsDate = date.getDate();
    const yesterday = new Date(today.getTime() - 86400000);
    const early = moment(date).format("lll");
    let time = "";
    if (
      tsYear === today.getFullYear() &&
      tsMonth === today.getMonth() &&
      tsDate === today.getDate()
    ) {
      return `Today ${time} at ${moment(date).format("LT")}`;
    }
    if (
      tsYear === yesterday.getFullYear() &&
      tsMonth === yesterday.getMonth() &&
      tsDate === yesterday.getDate()
    ) {
      return `Yesterday ${time} at ${moment(date).format("LT")}`;
    }        
    return `${moment(date).format("MMM DD")}, at ${moment(date).format("hh:mm A")}`; //`${early} ${time}`;
  } else {
    return "No Updates";
  }
};

export const getInitials = (username = "") => {
  const dividers = [" ", ".", "_", "-", "+"];
  const name = username.startsWith("@")
    ? username.substring(1).toUpperCase()
    : username.toUpperCase().split("@")[0];
  const nameDividerPosition = [...name].findIndex((ch, index) => {
    return dividers.includes(ch);
  });

  return (name[0] || "") + (name[Math.max(0, nameDividerPosition) + 1] || "");
};
