const { VITE_MS_AUTH_CLIENT_ID } = import.meta.env;

class MSOAuth2Client {
  static titleName = "One Drive";
  #tenant = "common";
  #clientId = VITE_MS_AUTH_CLIENT_ID;
  #redirectUri = location.origin + "/ms_oauth2_callback";
  #scope = ["User.Read", "offline_access", "Files.Read.All"];

  constructor() {}

  async getAuthWithRedirect(transitedState) {
    // prettier-ignore
    const authUrl = new URL(`https://login.microsoftonline.com/${this.#tenant}/oauth2/v2.0/authorize`);
    authUrl.searchParams.set("client_id", this.#clientId);
    authUrl.searchParams.set("response_type", "code");
    authUrl.searchParams.set("response_mode", "query");
    authUrl.searchParams.set("prompt", "select_account");
    authUrl.searchParams.set("redirect_uri", this.#redirectUri);
    authUrl.searchParams.set("scope", this.#scope.join(" "));
    // prettier-ignore
    authUrl.searchParams.set("state", window.btoa(JSON.stringify(transitedState)));

    window.open(authUrl.toString(), "_self");
  }

  async callbackHandler(query = {}) {
    const { code, error, error_description } = query;
    if (error) {
      throw new Error(`${error}:${error_description}`);
    }
    return { access_token: code, refresh_token: "" };
  }
}

export default MSOAuth2Client;
