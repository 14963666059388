<template>
  <div>
    <folia-button
        v-if="!showTextProperties"
        v-for="(tool, index) of toolsList"
        :name="tool.name"
        :icon="tool.icon"
        :tooltip="tool.title"
        :shortcut="tool.shortcut"
        :selected="toolsList[state.selectedToolIndex].name === tool.name"
        @click="changeTool(index)"
    ></folia-button>
    <div class="vertical-divider" v-if="toolsList.length > 1 && !showTextProperties"/>
    <ColorButton
      v-if="!showTextProperties"
      v-for="(preset, index) of state.presets"
      :ref="`color_btn_${index}`"
      :color="preset.color"
      :lineWidth="preset.lineWidth"
      :isSelected="index === state.presetIndex"
      :canDeletePreset="canDeletePreset"
      @btnOnCLick="selectPreset(index)"
      @updateColor="updatePresetColor"
      @updateWidth="updatePresetLineWidth"
      @deletePreset="deletePreset"
    />

    <FontBlock
      v-if="showTextProperties"
      :textProps="state.presets[state.presetIndex]"
      :color="state.presets[state.presetIndex].color"
      @updateColor="updatePresetTextProps({color: $event})"
      @updateTextProperties="updatePresetTextProps"
    />
    <ToolButton
      v-if="canCreatePreset"
      :tool="PLUS_BTN"
      @btnOnCLick="addNewPreset"
    />

    <div class="space-expander"></div>
    <div class="vertical-divider"/>
    <folia-button
        :name="CLOSE_BTN.name"
        :tooltip="CLOSE_BTN.title"
        :icon="CLOSE_BTN.icon"
        @click="$emit('closeBar')"
    ></folia-button>
  </div>
</template>

<script>
import {ICON, TOOL_PRESETS, TOOL} from "../../common/constants.js";
import ToolButton from "../../common/ToolButton.vue";
import { computed, onMounted, reactive, ref, watch } from "vue";
import ColorButton from "./ColorButton.vue";
import FontBlock from "./FontBlock.vue";
export default {
  name: "ToolPaintProperties",
  components: {ColorButton, ToolButton, FontBlock},
  props: {
    selectedGroup: Object,
  },
  emits: [
    "updateDrawing", "deleteSelectedAnnotations", "closeBar", "changeTool", "updatePresets"
  ],
  setup(props, {emit}) {
    const DELETE_BTN =  { title: "Delete", icon: ICON.DELETE  },
          CLOSE_BTN =   { name: "close", title: "Close",  icon: ICON.CLOSE  },
          PLUS_BTN =    { title: "Add",    icon: ICON.PLUS    };
    const state = reactive({
      selectedToolIndex: 0,
      presets: [],
      presetIndex: 0
    })

    onMounted(() => {
      loadPresets(props.selectedGroup).catch(console.error);
    });

    watch(() => props.selectedGroup, group => {
      state.selectedToolIndex = 0;
      loadPresets(group).catch(console.error);
    })

    async function loadPresets(group) {
      const tool = group.tools[state.selectedToolIndex];
      let toolPresets = tool.presets.list, activePreset = 0;
      try {
        const storedPresets = JSON.parse(localStorage.getItem("tool-presets"));
        activePreset = (storedPresets["activePresets"] || []).find(item => item.kind === tool.name)?.index || activePreset;
        toolPresets = storedPresets && Array.isArray(storedPresets[tool.presets.name]) && storedPresets[tool.presets.name].length
          ? storedPresets[tool.presets.name]
          : toolPresets;
      } catch (e) { console.error("error while load stored presets", e.message) }

      state.presets = toolPresets.map(preset => ({...preset}));
      state.presetIndex = activePreset;
      emit("changeTool", tool, state.presets[state.presetIndex]);
      emit("updateDrawing", state.presets[state.presetIndex]);
    }

    async function savePresets() {
      try {
        const tool = props.selectedGroup.tools[state.selectedToolIndex];
        const storedPresets = JSON.parse(localStorage.getItem("tool-presets")) || {};
        storedPresets[tool.presets.name] = state.presets.map((preset, index) => {
          preset.addedAt = new Date().toISOString();
          preset.index = index;
          return preset;
        });
        const activePreset = (storedPresets["activePresets"] || [])
          .find(preset => preset.kind === tool.name);
        if (activePreset) {
          activePreset.index = state.presetIndex;
          activePreset.addedAt = new Date().toISOString();
        } else {
          storedPresets["activePresets"] = [...(storedPresets["activePresets"] || []), {
            index: state.presetIndex,
            kind: tool.name,
            addedAt: new Date().toISOString(),
          }]
        }
        emit("updatePresets", storedPresets);
      } catch (e) { console.error("error while store presets", e.message) }
    }

    function changeTool(index) {
      state.selectedToolIndex = index;
      loadPresets(props.selectedGroup)
        .then(() => savePresets())
        .catch(console.error);
    }
    function selectPreset(index) {
      state.presetIndex = index;
      const preset = state.presets[index];
      emit("updateDrawing", preset);
      savePresets();
    }
    function updatePresetLineWidth(lineWidth) {
      const preset = state.presets[state.presetIndex];
      if (!preset) return;
      preset.lineWidth = lineWidth
      emit("updateDrawing", preset);
      savePresets();
    }
    function updatePresetColor(color) {
      const preset = state.presets[state.presetIndex];
      if (!preset) return;
      preset.color = color
      emit("updateDrawing", preset);
      savePresets();
    }
    function updatePresetTextProps(props) {
      const preset = state.presets[state.presetIndex];
      if (!preset) return;
      for (const [key, value] of Object.entries(props)) {
        preset[key] = value;
      }
      emit("updateDrawing", preset);
      savePresets();
    }
    function addNewPreset() {
      const tool = props.selectedGroup.tools[state.selectedToolIndex];
      if (!tool.presets.template) return;
      state.presets.push({
        ...tool.presets.template,
        index: state.presets.length,
        addedAt: new Date().toISOString(),
      })
      state.presetIndex = state.presets.length - 1;
    }
    function deletePreset() {
      state.presets = state.presets.filter((_, index) => index !== state.presetIndex);
      state.presetIndex = Math.min(state.presetIndex, state.presets.length - 1);
      savePresets();
    }

    const toolsList = computed(() => {
      return props.selectedGroup.tools?.length > 1 ? props.selectedGroup.tools : []
    });
    const showLineWidthProperties = computed(() => {
      if (!state.presets.length) return false;
      const preset = state.presets[state.presetIndex];
      return Object.keys(preset).some(key => key === "lineWidth");
    });
    const showColorProperties = computed(() => {
      if (!state.presets.length) return false;
      const preset = state.presets[state.presetIndex];
      return Object.keys(preset).some(key => key === "color");
    });
    const showTextProperties = computed(() => {
      if (!state.presets.length) return false;
      const preset = state.presets[state.presetIndex];
      if (!preset) return false;
      return Object.keys(preset).some(key => key === "fontFamily");
    });
    const canCreatePreset = computed(() => {
      return false;
      const tool = props.selectedGroup.tools[state.selectedToolIndex];
      return state.presets.length < tool?.presets.max && tool?.presets.template;
    });
    const canDeletePreset = computed(() => {
      return false;
      const tool = props.selectedGroup.tools[state.selectedToolIndex];
      return state.presets.length > tool?.presets.min && tool?.presets.template;
    });

    return {
      DELETE_BTN, CLOSE_BTN, PLUS_BTN,
      toolsList, state, showLineWidthProperties, showColorProperties,
      showTextProperties, canCreatePreset, canDeletePreset,

      selectPreset, changeTool, updatePresetLineWidth, updatePresetColor,
      updatePresetTextProps, addNewPreset, deletePreset
    }
  }
}
</script>

<style scoped lang="scss">
.hint {
  position: absolute;
  left: -130px;
  background: rgba(229, 229, 229, 0.7);
  padding: 5px 15px;
  border-radius: 20px;
  min-width: 50px;
}
.space-expander {
  flex-grow: 1;
}
.vertical-divider {
  width: 0;
  height: 16px;
  border: 1px solid #e2e8f0;

  &.invisible {
    border: solid 1px transparent;
  }
}

</style>