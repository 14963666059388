<script>
import { inject, reactive, ref, computed, onMounted, onBeforeUnmount, watch } from "vue";
import AppBarVue from "./app-bar/app-bar.vue";
import UploadMonitor from "./common/UploadMonitor.vue";
import { useRoute } from "vue-router";
import PublicLinkAccessBanner from "./PublicLinkAccessBanner.vue";
import { getters, mutations } from "../store/index.js";
import leftSidePanelState from "../store/left-side-panel-state.js";
import LeftSidePanel from "./common/left-side-panel/LeftSidePanel.vue";

export default {
  name: "ApplicationView",
  components: { LeftSidePanel, PublicLinkAccessBanner, AppBarVue, UploadMonitor },
  setup() {
    const $fetch = inject("$fetch");
    const route = useRoute();
    const shortPanelEl = ref(null);
    const highPanelEl = ref(null);
    const appViewEl = ref(null);
    const state = reactive({
      shortLeftPanelShown: false,
      highLeftPanelPinned: false,
    });
    const uploadMonitorShown = computed(() => {
      return getters.uploadMonitorShown;
    });
    const leftPanelCanBeOpened = computed(() => {
      if (!["project", "document", "global-search"].includes(route.name)) return false;
      if (route.name === "global-search") return !!leftSidePanelState.fromProject;
      return true;
    });
    const isPanelPinned = computed(() => {
      // console.log(leftPanelCanBeOpened.value, leftSidePanelState.isPanelPinned);
      return leftPanelCanBeOpened.value && leftSidePanelState.isPanelPinned;
    });
    let mouseTimeout;

    onMounted(() => {
      document.querySelectorAll("#splash-screen").forEach(el => el.remove());
      $fetch.version().then(ver =>{ 
        // console.info(ver)
      });
      window.addEventListener("click", windowOnClick);
      window.addEventListener("mousemove", onMouseMove);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("click", windowOnClick);
      window.removeEventListener("mousemove", onMouseMove);
    });
    watch(() => route.name, (routeName) => {
      if (!["project", "document", "global-search"].includes(routeName)) {
        state.shortLeftPanelShown = false;
      };
    });
    watch(() => state.shortLeftPanelShown, (shown) => {
      if (!shortPanelEl) return;
      if (shown) {
        shortPanelEl.value.classList.toggle("shown", true);
        shortPanelEl.value.classList.toggle("hidden", false);
      } else {
        shortPanelEl.value.classList.toggle("shown", false);
        shortPanelEl.value.classList.toggle("hidden", true);
      }
    })

    function hideUploadMonitor() {
      mutations.toggleUploadMonitorShown(false);
    }
    function windowOnClick(e) {
      if (e.target.tagName === "FOLIA-PAGE") return;
      window.foliaPdfViewer?.eventBus?.dispatch("reset-objects-selection");
    }
    function onMouseMove(e) {
      if (!("projectId" in route.params)) return;
      if (leftSidePanelState.isPanelPinned) return;

      let { left, top, right, bottom } = shortPanelEl?.value?.getBoundingClientRect()??{};
      clearTimeout(mouseTimeout);
      if (state.shortLeftPanelShown === false) {
        if (e.x <= 20 && e.y >= top && e.y <= bottom) state.shortLeftPanelShown = true;
      } else {
        mouseTimeout = setTimeout(() => {
          const isInsidePanel = e.x >= left && e.y >= top && e.x <= right && e.y <= bottom;
          if (!isInsidePanel) state.shortLeftPanelShown = false;
        }, 10);
      }

    }
    function unpinLeftSidePanel() {
      leftSidePanelState.unpinLeftSidePanel();
    }
    function pinLeftSidePanel() {
      leftSidePanelState.pinLeftSidePanel();
    }

    return {
      isPanelPinned, shortPanelEl, highPanelEl, appViewEl, state, route,
      uploadMonitorShown, leftPanelCanBeOpened,
      hideUploadMonitor, unpinLeftSidePanel, pinLeftSidePanel,
    };
  },
};
</script>
<style lang="scss">
.panel-header-logo {
  width: 100px;
  height: 48px;
  cursor: pointer;
  background-image: url("../assets/images/logo.svg");
  background-repeat: no-repeat;
  background-position: 16px center;
  background-size: 56px 28px;
}
</style>
<!-- <template>
  <div ref="shortPanelEl" class="short-side-bar-container">
    <LeftSidePanel />
  </div>
  <div ref="highPanelEl"
       class="high-side-bar-container"
       :class="{ 'pinned': isPanelPinned, 'unpinned': !isPanelPinned }"
  >
    <div class="panel-header">
      <div class="panel-header-logo" @click="$router.push({ name: 'home' })"/>
      <folia-button
          size="28"
          icon="close_side_panel"
          tooltip="Unlock Left Panel"
          selected
          @click.stop="unpinLeftSidePanel"
      ></folia-button>
    </div>
    <LeftSidePanel />
  </div>

  <div ref="appViewEl"
       class="application-view"
       :class="{
          'with-left-panel': isPanelPinned,
          'without-left-panel': !isPanelPinned
       }" >
    <PublicLinkAccessBanner />
    <AppBarVue :leftPanelCanBeOpened="leftPanelCanBeOpened"
               :isPanelPinned="isPanelPinned"
               @pinLeftSidePanel="pinLeftSidePanel()" />
    <div class="application-view-body">
      <router-view :key="route.fullPath"></router-view>
    </div>
    <UploadMonitor @hide="hideUploadMonitor" v-if="uploadMonitorShown" />
  </div>
</template> -->
<!-- 
<style lang="scss" scoped>

.application-view {
  width: 100vw;
  height: 100vh;
  margin-left: 0px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.with-left-panel {
    margin-left: 240px;
    transition: all 0.25s ease-out;
  }

  &.without-left-panel {
    margin-left: 0px;
    transition: all 0.25s ease-out;
  }

  .application-view-body {
    width: 100%;
    height: 100%;
    display:flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    overflow-y: auto;
  }
}

.short-side-bar-container {
  display: flex;
  z-index: 2500;
  width: 245px;
  left: -245px;

  position: absolute;
  height: 610px;
  padding: 4px;

  border-radius: 4px;
  border-right: 1px solid #E2E8F0;
  background: #FFFFFF;
  box-shadow: 4px 0px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);

  &.shown {
    left: 0;
    transition: all 0.25s ease-out;
  }

  &.hidden {
    transition: all 0.25s ease-out;
    left: -245px;
  }
}

.high-side-bar-container {
  display: flex;
  flex-direction: column;
  z-index: 2;
  position: absolute;
  left: -240px;
  top: 0;
  width: 240px;
  height: 100vh;

  border-right: 1px solid #E2E8F0;
  background: rgba(255, 255, 255);
  box-shadow: -1px 0px 0px 0px #E2E8F0 inset;

  &.pinned {
    left: 0px;
    transition: all 0.25s ease-out;
  }

  &.unpinned {
    left: -240px;
    transition: all 0.25s ease-out;
  }

  .panel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    width: 100%;
    padding: 16px;

    .panel-header-logo {
      width: 100px;
      height: 48px;
      cursor: pointer;
      background-image: url("../assets/images/logo.svg");
      background-repeat: no-repeat;
      background-position: 16px center;
      background-size: 56px 28px ;
    }
  }
}

.app-container {
  @media only screen and (max-width: 768px) {
    & .application-view-body{
    flex-direction: column;
    overflow: hidden;
    }
  }
}
</style> -->


<!-- <template>

  <div class="min-h-full">

    <div v-if="!isPanelPinned" ref="highPanelEl" class="high-side-bar-container max-w-96"
      :class="{ 'pinned': isPanelPinned, 'unpinned': !isPanelPinned, 'hidden': isPanelPinned }">
      <div class="panel-header">
        <div class="panel-header-logo" @click="$router.push({ name: 'home' })" />
        <folia-button size="28" icon="close_side_panel" tooltip="Unlock Left Panel" selected
          @click.stop="unpinLeftSidePanel"></folia-button>
      </div>
      <LeftSidePanel />
    </div>
    <div class="">
      <AppBarVue :leftPanelCanBeOpened="leftPanelCanBeOpened" :isPanelPinned="isPanelPinned"
        @pinLeftSidePanel="pinLeftSidePanel()" />

      <div class="mx-auto  bg-gray-50">
        <router-view :key="route.fullPath"></router-view>
      </div>
    </div>


  </div>
</template> -->


<template>
  <div class="flex min-h-full">
    <!-- Left Panel -->
    <div v-if="isPanelPinned" ref="highPanelEl" class="high-side-bar-container transition-all duration-300 border-r z-20" :class="{
      'pinned': isPanelPinned,
      'unpinned': !isPanelPinned,
      'w-0': !isPanelPinned,
      'w-60': isPanelPinned
    }">
      <div class="panel-header flex pt-2 ">
        <div class="panel-header-logo" @click="$router.push({ name: 'home' })"></div>
        <folia-button class="ml-24" size="28" icon="close_side_panel" tooltip="Unlock Left Panel" selected
          @click.stop="unpinLeftSidePanel"></folia-button>
      </div>
      <LeftSidePanel />
    </div>

    <!-- Main Content Area -->
    <div class="flex-1 transition-all duration-300">
      <AppBarVue :leftPanelCanBeOpened="leftPanelCanBeOpened" :isPanelPinned="isPanelPinned"
        @pinLeftSidePanel="pinLeftSidePanel()" />
      <div class="mx-auto bg-gray-50">
        <router-view :key="route.fullPath"></router-view>
      </div>
      <UploadMonitor @hide="hideUploadMonitor" v-if="uploadMonitorShown" />
    </div>
  </div>
</template>
