<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" stroke="#64748B" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.25 0.75V15.25M0.75 8H11.25M0.75 8L4.25 4.75M0.75 8L4.25 11.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "expand",
};
</script>
