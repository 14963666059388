<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6347 14.6689H6.03115L2.17875 10.8165C1.71179 10.3495 1.71179 9.64911 2.17875 9.18215L10.3505 1.01037C10.8175 0.543417 11.5179 0.543417 11.9849 1.01037L17.8218 6.84735C18.2888 7.31431 18.2888 8.01475 17.8218 8.48171L11.6347 14.6689ZM19.3395 17.0037H0.661133V19.3385H19.3395V17.0037Z" fill="#64748B"/>
  </svg>
</template>

<script>
export default {
  name: "eraser",
};
</script>
