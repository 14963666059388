import { inject } from "vue";
import {
  BROADCAST_MESSAGE,
  CONTRIBUTOR,
  EDITOR,
  ERROR_TOAST,
  OWNER,
} from "../../core/constants.js";
class Folia_Drag_N_Drop {
  projectId;
  projectName;
  userRole;
  dropContainer;
  dropZone;

  static ALLOWED_ROLES = [OWNER, EDITOR, CONTRIBUTOR];

  onDragEnterBind = this.onDragEnter.bind(this);
  onDragLeaveBind = this.onDragLeave.bind(this);
  onDragOverBind = this.onDragOver.bind(this);
  onDropBind = this.onDrop.bind(this);

  constructor() {
    this.$uploadFiles = inject("$uploadFiles");
    this.$fetch = inject("$fetch");
    this.$auth0 = inject("$auth0");
  }

  attach(dropContainer, projectId, projectName, userRole) {
    dropContainer.addEventListener("dragenter", this.onDragEnterBind);
    this.dropContainer = dropContainer;
    this.projectId = projectId;
    this.projectName = projectName;
    this.userRole = userRole;
  }

  release() {
    this.onDragLeave();
    this.dropContainer.removeEventListener("dragenter", this.onDragEnterBind);
  }

  updateRole(userRole) {
    this.userRole = userRole;
  }

  onDragEnter() {
    let dropZone = document.querySelector(".drag-n-drop-zone");
    if (!dropZone) {
      dropZone = document.createElement("div");
      dropZone.className = "drag-n-drop-zone";
      dropZone.addEventListener("dragover", this.onDragOverBind, {
        passive: false,
      });
      dropZone.addEventListener("dragleave", this.onDragLeaveBind, {
        passive: false,
      });
      dropZone.addEventListener("drop", this.onDropBind, {
        passive: false,
      });
      this.dropZone = this.dropContainer.appendChild(dropZone);
    }

    let projectName = this.projectName;
    let css_var = "70%";
    switch (this.dropContainer.className) {
      case "project-container-internal": {
        css_var = "790px";
        break;
      }
      case "project-card": {
        css_var = "200px";
        projectName =
          projectName.length > 50
            ? projectName.substring(0, 50) + "..."
            : projectName;
        break;
      }
      default:
        break;
    }
    dropZone.style.setProperty("--drag-n-drop-text-height", css_var);

    if (Folia_Drag_N_Drop.ALLOWED_ROLES.includes(this.userRole)) {
      dropZone.classList.add("allow");
      dropZone.innerText = `Drop files to instantly upload them to:\n ${projectName}`;
    } else {
      dropZone.classList.add("deny");
      dropZone.innerText =
        "This file could not be uploaded.\n You don’t have permissions to upload files to this project.";
    }
  }
  onDragOver(e) {
    e.preventDefault();
  }
  onDragLeave() {
    if (this.dropZone) {
      this.dropZone.removeEventListener("dragover", this.onDragOverBind, {
        passive: false,
      });
      this.dropZone.removeEventListener("drop", this.onDropBind, {
        passive: false,
      });
      this.dropZone.removeEventListener("dragleave", this.onDragLeaveBind, {
        passive: false,
      });
      this.dropZone.remove();
      this.dropZone = null;
    }
  }
  onDrop(e) {
    e.preventDefault();
    this.onDragLeave();
    if (!Folia_Drag_N_Drop.ALLOWED_ROLES.includes(this.userRole)) return;
    this.$uploadFiles.uploadDocuments(this.projectId, e.dataTransfer.files);
  }
}

export default Folia_Drag_N_Drop;
