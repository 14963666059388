<script>

import { computed, inject, onBeforeUnmount, onMounted, reactive, ref } from "vue";
import documentCard from "../project/DocumentCard.vue";
import ActionButton from "../common/ActionButton.vue";
import DocumentDetails from "../document/DocumentDetails.vue";
import emptySearchResultIcon from "../../assets/vue-icons/empty-search-result-icon.vue";
import documentTextCategoryIcon from "../../assets/vue-icons/search-category-icon/document-text-category-icon.vue";
import highlightSearchIcon from "../../assets/vue-icons/highlight-search-icon.vue";
import textBoxSearchIcon from "../../assets/vue-icons/text-box-search-icon.vue";
import SharingDialog from "../common/Sharing/SharingDialog.vue";
import TextAnnoCategoryIcon from "../../assets/vue-icons/search-category-icon/text-anno-category-icon.vue";
import ProjectCard from "../home/ProjectCard.vue";
import { useRoute, useRouter } from "vue-router";
import { mutations } from "../../store/index.js";
import {BROADCAST_MESSAGE, ERROR_TOAST, PUBLIC_VIEWER, SUCCESS_TOAST, VIEWER} from "../../core/constants.js";
import { openingDocument } from "../../store/open-document.js";
import GlobalSearchTabs from "./global-search-tabs.vue";
import CommentSearchIcon from "../../assets/vue-icons/comment-search-icon.vue";
import { ROLE_PERMISSIONS} from '../../core/constants';
import ProjectService from '../../service/projectService';
import DeleteProjectModal from '../home/DeleteProjectModal.vue';
import DeleteDocumentModal from '../project/DeleteDocumentModal.vue';

const titles = {
  ProjectSearchResult: "Projects",
  DocumentSearchResult: "Documents",
  DocumentTextSearchResult: "Document text",
  DocumentObjectSearchResult: "Annotations",
};

export default {
  name: "global-search",
  components: {
    CommentSearchIcon,
    GlobalSearchTabs,
    ProjectCard,
    documentCard,
    SharingDialog,
    ActionButton,
    DocumentDetails,
    documentTextCategoryIcon,
    highlightSearchIcon,
    textBoxSearchIcon,
    TextAnnoCategoryIcon,
    emptySearchResultIcon,
    DeleteProjectModal,
    DeleteDocumentModal
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const $fetch = inject('$fetch');
    const $auth0 = inject("$auth0");

    const unsubscribe = reactive({fn: null});
    const currentSearchTab = ref(titles.ProjectSearchResult);
    const sharedProject = ref(null);
    const deletedProject = ref(null);
    const isLoading = ref(false);
    const currentUserEmail = ref("");
    const deletedDocument = ref(null);

    const showDocumentTextGroup = ref(true);
    const filteredDocumentText = reactive({});
    const detailsDocument = ref(null);
    const projectService = new ProjectService()
    const result = reactive({
      ProjectSearchResult: [],
      DocumentSearchResult: [],
      DocumentObjectSearchResult: [],
      DocumentTextSearchResult: [],
    });
    const searchString = computed(() => {
      return route.query.queryString;
    });
    const foundCount = computed(() => {
      return Object.values(result).reduce((acc, item) => {
        return acc + item.length;
      }, 0);
    });
    const searchCategories = computed(() => {
      return Object.keys(result).map(key => {
        return { key, title: titles[key] };
      });
    });
    const isProjectDeleting = ref(false);

    onMounted(async () => {
      initializeSearch()
    });

    const initializeSearch = async()=>{
      currentSearchTab.value = localStorage.getItem("global-search-tab") || titles.ProjectSearchResult;
      expandedDocumentGroup.value = localStorage.getItem("global-search-expanded-text-group") || null;
      isLoading.value = true;
      const user = await $auth0.getUser();
      currentUserEmail.value = `${user.profile.email}`.toLowerCase();
      const previousRoute = localStorage.getItem('previousGlobalSearchRoute');
      if (!previousRoute) {
        localStorage.setItem('previousGlobalSearchRoute',window.history?.state?.back || "/")
      }
      await startSearch(route.query.queryString)
    }
    onBeforeUnmount(() => {
      if (unsubscribe.fn) unsubscribe.fn();
    });

    const computedProjectSearchResults = computed(() => {
      return result.ProjectSearchResult
        .filter(entry => !entry.entryIsHidden)
        .sort((a, b) => {
          const aTime = new Date(a.project.updatedAt).getTime();
          const bTime = new Date(b.project.updatedAt).getTime();
          return bTime - aTime;
        });
    });
    const computedDocumentSearchResults = computed(() => {
      return result.DocumentSearchResult
        .filter(entry => !entry.entryIsHidden)
        .sort((a, b) => {
          const aTime = new Date(a.document.updatedAt).getTime();
          const bTime = new Date(b.document.updatedAt).getTime();
          return bTime - aTime;
        })
        .map(entry => {
          entry.document.projectId = entry.project.id;
          entry.document.flattened = [VIEWER, PUBLIC_VIEWER].includes(entry.project.userRole);
          return entry;
        });
    });
    const expandedDocumentGroup = ref(null);
    const computedDocumentTextSearchResults = computed(() => {
      const { total, groups } = result.DocumentTextSearchResult
        .reduce((acc, entry) => {
          acc.groups[entry.document.id] ||= {
            id: entry.document.id, name: entry.document.name,
            entries: [], expand: false,
          };
          entry.styledTextEntry = entry.textEntry.replace(new RegExp(searchString.value, "gi"), `<span style="font-weight: 800; color: #F04E23;">${searchString.value}</span>`);
          acc.groups[entry.document.id].entries.push(entry);
          acc.groups[entry.document.id].expand = expandedDocumentGroup.value === entry.document.id;
          acc.total += 1;
          return acc;
        }, { total: 0, groups: {} });

      return {
        total,
        groups: Object.values(groups).sort((a, b) => a.name.localeCompare(b.name)),
      };
    });
    const computedDocumentObjectSearchResults = computed(() => {
      return result.DocumentObjectSearchResult.map(entry => {
        entry.styledTextEntry = entry.documentObject.text.replace(new RegExp(searchString.value, "gi"), `<span style="font-weight: 800; color: #F04E23;">${searchString.value}</span>`);
        return entry;
      });
    });

    function toggleDocumentGroup(id) {
      expandedDocumentGroup.value = expandedDocumentGroup.value === id ? null : id;
      localStorage.setItem("global-search-expanded-text-group", id);
    }
    function gotoDocumentText(item) {
      openingDocument
        .resetClarification()
        .setOpenedPage(item.page + 1)
        .openLiveSearchWithQuery(searchString.value);
      router.push(`/p/${item.project.id}/d/${item.document.id}`);
    }
    function gotoDocumentObject(item) {
      router.push(`/p/${item.project.id}/d/${item.document.id}?page=${item.documentObject.page + 1}&object=${item.documentObject.id}`);
    }
    async function downloadDocument({projectId, documentId,flatten,original = false,fileName = 'file.pdf'}) {
      const type = SUCCESS_TOAST;
        const title = 'Your file is being downloaded';
        const message = `<span><span class="message-toast-name">
                      ${fileName}
                      </span>.</span>`;
        const toast = {
          type, title, message,
        }
        $fetch.dispatch(BROADCAST_MESSAGE.TOAST, toast);
      const { content, originMimeType = 'application/pdf' } = await projectService.downloadDocument({ documentId ,flatten, original,fileName})
      if(content === null) {
        $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
          title:'File download failed',
          type:'ERROR_TOAST',
          message:`Please try agin later.`
        });
        return;
      }
      function downloadPDF() {
        const base64PDF = content.replace(/^data:application\/pdf;base64,/, '');
        const binaryPDF = atob(base64PDF);
        const arrayBuffer = new ArrayBuffer(binaryPDF.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryPDF.length; i++) {
          uint8Array[i] = binaryPDF.charCodeAt(i);
        }

        const blob = new Blob([uint8Array], { type:original?originMimeType:'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = original?`${fileName}`:`${fileName}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      downloadPDF();
    }

    function checkEmptySearchResult(currentSearchCategory) {
      const searchCategoryKey = Object.keys(titles).find(key => titles[key] === currentSearchCategory)
      return result[searchCategoryKey].length === 0
    }
    function showDocumentDetails(entry) {      
      detailsDocument.value = entry;
    }

    function removeDoc(entry) {   
      deletedDocument.value = {
        id: entry.document.id,
        name: entry.document.name,
        projectId:entry.project.id
      };
    }
    const resetDataSet = ()=>{
      result.DocumentObjectSearchResult=[];
      result.DocumentSearchResult=[];
      result.DocumentTextSearchResult=[];
      result.ProjectSearchResult=[]
    }
     function deleteDocument(confirm) {
      const { id: documentId, name: documentName } = deletedDocument.value;
      hideDocumentSearchResult(documentId)
      deletedDocument.value = null;
      resetDataSet()
      initializeSearch();

    }
    function removeProject(entry) {
      deletedProject.value = {
        id: entry.project.id,
        name: entry.project.name,
      };
    }
    async function deleteProject(deleted = false) {
      if(deleted){        
        const projectId = deletedProject.value.id;
        hideProjectSearchResult(projectId);
        deletedProject.value = null;
        // $fetch.deleteProject(projectId)
        resetDataSet()
        initializeSearch();
      }else {
        deletedProject.value = null;
      }
  
    }

    function hideDocumentSearchResult(id) {
      const document = result.DocumentSearchResult.find(entry => entry.document.id === id);
      if (document) document.entryIsHidden = true;

      const documentObject = result.DocumentObjectSearchResult.find(entry => entry.document.id === id)
      if (documentObject) documentObject.entryIsHidden = true;

      const documentText = result.DocumentTextSearchResult.find(entry => entry.document.id === id);
      if (documentText) documentText.entryIsHidden = true;
    }
    function hideProjectSearchResult(id) {
      const project = result.ProjectSearchResult.find(entry => entry.project.id === id);      
      if (project) project.entryIsHidden = true;

      const document = result.DocumentSearchResult.find(entry => entry.project.id === id);
      if (document) document.entryIsHidden = true;

      const documentObject = result.DocumentObjectSearchResult.find(entry => entry.project.id === id);
      if (documentObject) documentObject.entryIsHidden = true;

      const documentText = result.DocumentTextSearchResult.find(entry => entry.project.id === id);
      if (documentText) documentText.entryIsHidden = true;
    }

    function shareProject(entry) {
      sharedProject.value  = entry.project;
    }
    function rejectProjectShare(entry) {
      entry.entryIsHidden = true;
      $fetch.shareProject(entry.project.id, currentUserEmail.value, "REJECTED")
        // .then(() => $fetch.outputSyncCollaborators(entry.project.id))
        .catch(console.error);
    }
    function openProject(item) {
      router.push(`/p/${item.project.id}`);
    }
    function openDocument(item) {
      router.push(`/p/${item.project.id}/d/${item.document.id}`);
    }
    function openPage(item) {
      router.push(`/p/${item.project.id}/d/${item.document.id}?page=${item.page + 1}`);
    }
    function openObject(item) {
      router.push(`/p/${item.project.id}/d/${item.document.id}?page=${item.documentObject.page + 1}`);
    }
    function toggleDocumentFavoriteStatus(entry) {
      const { document, project: { id: projectId } } = entry;
      const addedAt = new Date().toISOString();
      const isFavorite = !document.favorite.isFavorite;
      document.favorite = { addedAt, isFavorite  };
      $fetch.setDocumentFavoriteStatus(document.id, addedAt, isFavorite);
    }


    function startSearch(query) {
      if (unsubscribe.fn) unsubscribe.fn();
      $fetch.subscribeOnSearch(
        query,
        searchChunk,
        searchError,
        searchCompleted
      ).then(unsubscribeFn => unsubscribe.fn = unsubscribeFn);
    }
    function setSearchTab(categoryFilter) {
      currentSearchTab.value = categoryFilter
    }
    function closeGlobalSearch() {
      const previousRoute = localStorage.getItem("previousGlobalSearchRoute");
      localStorage.removeItem("previousGlobalSearchRoute");
      localStorage.removeItem("global-search-tab");
      localStorage.removeItem("global-search-expanded-text-group");
      router.push(previousRoute);
    }
    function searchError(error) {
      $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
        type: ERROR_TOAST,
        title: "Search error",
        message: error.message
      })
      unsubscribe.fn = null;
      isLoading.value = false;
    }
    function clearSearch() {
      mutations.changeGlobalSearchStatus(true)
    }
    function searchChunk(messageData = {}) {
      if (messageData.__typename === "DocumentSearchResult") {
        messageData['project']['permissions']=[];
        const { sessionUserRole } = messageData.project;
        messageData.project.userRole = sessionUserRole;
        ROLE_PERMISSIONS.some((p) => {
          const { role, permissions: value } = p;
          if (role === sessionUserRole) {
            messageData.project.permissions = value;
          }
        });
        messageData.document.origin  = {mimeType:messageData.document.originMimeType??'application/pdf'};
        if (messageData.document.preview != null) {
          messageData.document.preview = `data:image/png;base64,${messageData.document.preview}`;
        }
      }
      if (messageData.__typename === "ProjectSearchResult") {
        messageData['project']['permissions']=[];
        const { sessionUserRole } = messageData.project;        
        messageData.project.userRole = sessionUserRole;
        ROLE_PERMISSIONS.some((p) => {
          const { role, permissions: value } = p;
          if (role === sessionUserRole) {
            messageData.project.permissions = value;
          }
        });
        result[messageData.__typename].push(messageData);
        /*$fetch.getProject(messageData.project.id)
            .then(project => {
              result[messageData.__typename].push({ __typename: messageData.__typename, project });
            })
            .catch(console.error);*/
      } else {
        result[messageData.__typename].push(messageData);
      }
    }
    function searchCompleted() {
      unsubscribe.fn = null;
      checkEmptyResultCategory();
      isLoading.value = false;
      filteredSearchDocumentText();
    }
    function filteredSearchDocumentText() {
      result.DocumentTextSearchResult.forEach(item => {
        if (!filteredDocumentText[item.document.id]) {
          filteredDocumentText[item.document.id] = {
            showDocumentGroup: false,
            documents: []
          }
        }
        filteredDocumentText[item.document.id].documents.push(item)
      })
      const filteredDocumentTextLength = Object.keys(filteredDocumentText).length;
      if (filteredDocumentTextLength === 1) {
        const [id] = Object.keys(filteredDocumentText);
        filteredDocumentText[id].showDocumentGroup = true;
      }
    }
    function checkEmptyResultCategory() {
      if (checkEmptySearchResult(currentSearchTab.value)) {
        for (const elem in titles) {
          if (result[elem].length !== 0) {
            currentSearchTab.value =  titles[elem];
            break;
          }
        }
      }
    }

    return {
      searchString, isLoading, foundCount, searchCategories, result,
      currentSearchTab, titles, deletedDocument, sharedProject, deletedProject,
      detailsDocument, showDocumentTextGroup, filteredDocumentText,

      computedProjectSearchResults, computedDocumentSearchResults,
      computedDocumentTextSearchResults, computedDocumentObjectSearchResults,

      clearSearch, setSearchTab, checkEmptySearchResult,
      openProject, openDocument, shareProject,
      removeProject, showDocumentDetails, removeDoc, gotoDocumentText,
      gotoDocumentObject, toggleDocumentGroup, rejectProjectShare,
      deleteDocument, deleteProject, hideProjectSearchResult, downloadDocument,
      closeGlobalSearch, toggleDocumentFavoriteStatus,
    }
  }
}
</script>

<template>
  <div class="global-search min-h-[90vh] w-full flex flex-col">
    <div class="global-search-header p-8">
      <div class="found-title">
        <div class="found-title-left">
          <div v-if="isLoading" class="global-search-loading"/>
          <span>Showing  results for ‘ {{searchString}} ’</span>
        </div>
        <div class="close-global-search-btn" @click="closeGlobalSearch()">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.25 6.75L6.75 17.25M6.75 6.75L17.25 17.25" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
    </div>
    <GlobalSearchTabs
      @setSearchTab="setSearchTab"
      :currentTab="currentSearchTab"
      :projects="computedProjectSearchResults"
      :documents="computedDocumentSearchResults"
      :texts="computedDocumentTextSearchResults"
      :objects="computedDocumentObjectSearchResults"

    />

    <div class="empty-search-result" v-if="checkEmptySearchResult(currentSearchTab) && !isLoading">
      <div class="empty-search-icon">
        <emptySearchResultIcon />
      </div>
      <span class="empty-search-result-text">
          We couldn’t find any results for <span style="font-weight: 600;">“{{searchString}}”</span>  in {{currentSearchTab}}
        </span>
      <ActionButton
        class="clear-search-btn"
        :width="'119px'"
        :height="'36px'"
        :text="'Clear Search'"
        :fontColor="'#334155'"
        :background-color="'#FFFFFF'"
        @click="clearSearch()"
      />
    </div>
    <div class="global-search-body p-8 " v-if="!checkEmptySearchResult(currentSearchTab)">
      <div v-if="currentSearchTab === titles.ProjectSearchResult && computedProjectSearchResults.length > 0" class="project-view-grid grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 2xl:gap-10">
        <div v-for="entry of computedProjectSearchResults">
          <ProjectCard
              :key="entry.project.id"
              :data="entry.project"
              @reject="rejectProjectShare(entry)"
              @share="shareProject(entry)"
              @rename=""
              @remove="removeProject(entry)"
          />
<!--          <ProjectCard-->
<!--            :data="entry.project"-->
<!--            @reject="rejectProjectShare(entry)"-->
<!--            @share="shareProject(entry)"-->
<!--            @remove="removeProject(entry)"-->
<!--          />-->
        </div>
      </div>

      <div v-if="currentSearchTab === titles.DocumentSearchResult && computedDocumentSearchResults.length > 0" class="document-container-grid">
        <document-card
          v-for="entry of computedDocumentSearchResults"
          :doc="entry.document"
          :permissions="entry.project.permissions"
          :projectName="entry.project.name"
          :projectId="entry.project.id"
          :user-role="entry.project.userRole"
          @show-document-details="showDocumentDetails(entry)"
          @remove="removeDoc(entry)"
          @download="downloadDocument"
          @toggleFavoriteStatus="toggleDocumentFavoriteStatus(entry)"
        />
      </div>

      <div v-if="currentSearchTab === titles.DocumentTextSearchResult && computedDocumentTextSearchResults.total > 0" class="document-text-view">
        <span class="results-counter">{{computedDocumentTextSearchResults.total + " Results"}}</span>
        <div v-for="group of computedDocumentTextSearchResults.groups">
          <div class="document-group-title-container" @click="toggleDocumentGroup(group.id)">
            <div class="caret-down-icon" v-if="group.expand">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.7087 8.95898L10.0003 11.8757L7.29199 8.95898" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="caret-up-icon" v-else>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.95801 7.29102L11.8747 9.99935L8.95801 12.7077" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="document-group">
              <span>{{ group.name}}</span>
              <div class="counter-document-group">
                {{group.entries.length}}
              </div>
            </div>
          </div>

          <div v-if="group.expand" class="document-group-container">
              <div class="document-text-item" v-for="entry of group.entries" @click.stop="gotoDocumentText(entry)">
                <div class="document-text-icon">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.75 4.75L17.25 9.25H13.75C13.1977 9.25 12.75 8.80228 12.75 8.25V4.75ZM12.75 4.75H6.75C5.64543 4.75 4.75 5.64543 4.75 6.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25H10.25M17.1471 17.1471L19.25 19.25M15.2353 17.7206C16.6079 17.7206 17.7206 16.6079 17.7206 15.2353C17.7206 13.8627 16.6079 12.75 15.2353 12.75C13.8627 12.75 12.75 13.8627 12.75 15.2353C12.75 16.6079 13.8627 17.7206 15.2353 17.7206Z" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <div class="document-text-container">
                  <span class="document-object-text" v-html="entry.styledTextEntry"></span>
                  <span class="document-name">{{ entry.project.name }} / {{ entry.document.name }}{{ ' on page ' }}{{ entry.page + 1 }}</span>
                </div>
              </div>
            </div>
        </div>
      </div>

      <div v-if="currentSearchTab === titles.DocumentObjectSearchResult && computedDocumentObjectSearchResults.length > 0" class="document-object-view">
        <span class="results-counter">{{ computedDocumentObjectSearchResults.length + " Results" }}</span>
        <div class="document-object-item" v-for="entry of result['DocumentObjectSearchResult']" @click.stop="gotoDocumentObject(entry)">
          <div class="document-object-icon-text">
            <textBoxSearchIcon v-if="entry.documentObject.__typename === 'TextBoxAnnotation'"/>
            <highlightSearchIcon v-if="entry.documentObject.__typename === 'HighlightAnnotation'"/>
            <comment-search-icon v-if="entry.documentObject.__typename === 'ReplyAnnotation'"/>
          </div>
          <div class="document-text-object-container">
            <span class="document-object-text" v-html="entry.styledTextEntry"></span>
            <span class="document-name">{{entry.project.name}} / {{entry.document.name}}{{' on page '}}{{entry.documentObject.page + 1}}</span>
          </div>
        </div>
      </div>
    </div>
    <SharingDialog
      v-if="Boolean(sharedProject)"
      :projectId="sharedProject.id"
      :currentUserRole="sharedProject.userRole"
      :isOpen="Boolean(sharedProject)"
      @removeRejectedProject="hideProjectSearchResult"
      @close="sharedProject = null"
    />
    <DocumentDetails
      v-if="Boolean(detailsDocument)"
      :document-id="detailsDocument.document.id"
      :userRole="detailsDocument.project?.sessionUserRole || 'PUBLIC_VIEWER'"
      @close="detailsDocument = null"
      @downloadDocument="(documentId, flattened) => downloadDocument(detailsDocument.project.id, documentId, flattened)"
    />
    <!-- <folia-simple-modal
        v-if="deletedDocument"
        type="dangerous"
        header="Delete Document"
        confirmation-button="Delete"
        dismiss-button="Cancel"
        @confirm="deleteDocument(true)"
        @dismiss="deleteDocument()"
    >Are you sure you want to delete <b>{{ deletedDocument.name }}</b>?</folia-simple-modal> -->
    <!-- <folia-simple-modal
        v-if="deletedProject"
        type="dangerous"
        header="Delete Project"
        confirmation-button="Delete"
        dismiss-button="Cancel"
        @confirm="deleteProject(true)"
        @dismiss="deleteProject()"
    >Are you sure you want to delete <b>{{ deletedProject.name }}</b>?</folia-simple-modal> -->
  </div>
  <DeleteDocumentModal v-if="deletedDocument" :documentName="deletedDocument.name" :documentId="deletedDocument.id"
        :projectId="deletedDocument.projectId" @removeDeleteDocument="deleteDocument"
        @close="deletedDocument = null" />
  <DeleteProjectModal v-if="deletedProject" :projectId="deletedProject.id" :projectName="deletedProject.name"
  @close="deleteProject"></DeleteProjectModal>

</template>

<style scoped lang="scss">
.global-search {
  // display: flex;
  // flex-direction: column;
  // width: 100%;
  // height: 100%;

  .global-search-header {
    background-color: white;
    border-bottom: 1px solid #E2E8F0;
    display: flex;
    align-self: center;
    flex-direction: column;
    justify-content: flex-start;
    // padding-left: 8%;
    width: 100%;

    .found-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
      color: #64748B;
      margin: 10px 0;

      .found-title-left {
        display: flex;
        align-items: center;
        gap: 20px;

        .global-search-loading {
          display: inline-block;
          width: 24px;
          height: 24px;
          background: url("../../assets/images/loading-icon.gif") center no-repeat;
        }
      }

      .close-global-search-btn {
        display: flex;
        position: absolute;
        right: 0;
        align-items: center;
        cursor: pointer;
        justify-content: center;
        height: 56px;
        width: 56px;
      }
    }
  }
  .empty-search-result {
    width: 100%;
    height: calc(100vh - 188px);
    gap: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .empty-search-icon {
      width: 120px;
      height: 86px;
    }

    .empty-search-result-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #334155;
    }

    .clear-search-btn {
      font-weight: 600;
      border: 1px solid #E2E8F0;
      border-radius: 8px;
    }
  }

  .loader-search {
    width: 100%;
    height: calc(100vh - 188px);
    display: flex;
    justify-content: center;
    align-items: center;
    .loader {
      background: url("../../assets/images/loading-icon.gif") no-repeat;
      width: 24px;
      height: 24px;
      background-position: center 70%;
    }
  }

  .global-search-body {
    display: grid;
    grid-template-rows: auto 1fr;
    width: 100%;
    height: 75vh;
    // padding-left: 8%;
    // padding-right: 8%;

    .project-view-grid {
      background: #F8FAFC !important;
      // display: grid;
      // grid-template-columns: repeat(auto-fill, minmax(410px, 1fr));
      // grid-auto-rows: minmax(311px, 311px);
      // gap: 24px;
      // overflow-y: auto !important;
      // //margin-bottom: 150px;
      // margin-top: 15px;

      .loader-project-card {
        min-width: 410px;
        min-height: 104px;
        border-radius: 12px;
        box-shadow: 0 1px 2px #e6e6e6;
        max-width: 410px;
        max-height: 309px;
        background: #FFFFFF;

        .loader-container {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .loader {
            background: url("../../assets/images/loading-icon.gif") no-repeat;
            width: 24px;
            height: 24px;
            background-position: center 70%;
          }
        }
      }
    }

    .document-container-grid {
      background: #F8FAFC !important;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
      grid-auto-rows: minmax(286px, 286px);
      gap: 24px;
      overflow-y: auto !important;
      //margin-bottom: 175px;
      margin-top: 15px;

      .loader-document-card {
        width: 270px;
        height: 286px;
        background: #FFFFFF;
        border: 1px solid #E2E8F0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;

        .loader-container {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .loader {
            background: url("../../assets/images/loading-icon.gif") no-repeat;
            width: 24px;
            height: 24px;
            background-position: center 70%;
          }
        }
      }
    }

    .document-text-view {
      width: 100%;
      height: auto;
      display: flex;
      overflow: auto;
      text-align: start;
      margin-bottom: 175px;
      gap: 8px;
      justify-content: flex-start;
      flex-direction: column;

      .document-group-title-container {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        .document-group {
          display: flex;
          flex-direction: row;
          align-items: baseline;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #334155;
          .counter-document-group {
            display: flex;
            margin-left: 6px;
            height: 16px;
            color: #334155;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            padding: 0 4px;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background: #E2E8F0;
            border-radius: 4px;
          }
        }
      }
      .document-group-container {
        gap: 8px;
        display: flex;
        margin-top: 5px;
        flex-direction: column;
      }
      .document-text-item {
        display: flex;
        flex-direction:row;
        width: 80%;
        text-align:start;
        border-radius: 4px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        border: 1px solid #E2E8F0;
        background: #FFFFFF;
        justify-content: flex-start;
        padding: 16px;
        .document-text-icon {
          margin-right: 16px;
        }
        &:hover {
          background: #f3f4f5;
          cursor: pointer;
        }
        .document-text-container {
          display: flex;
          flex-direction: column;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #334155;
          .document-name {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #64748B;
          }
        }
      }
    }

    .results-counter {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #64748B;
      margin-top:24px;
      margin-bottom: 24px;
    }

    .document-object-view {
      width: 100%;
      height: auto;
      display: flex;
      overflow: auto;
      gap: 8px;
      margin-bottom: 175px;
      text-align:start;
      justify-content: flex-start;
      flex-direction: column;
      .document-object-item {
        display: flex;
        width: 80%;
        flex-direction:row;
        text-align:start;
        border-radius: 4px;
        border: 1px solid #E2E8F0;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        background: #FFFFFF;
        justify-content: flex-start;
        padding: 16px;
        .document-object-icon-text {
          margin-right: 16px;
        }
        &:hover {
          background: #f3f4f5;
          cursor: pointer;
        }
        .document-text-object-container {
          display: flex;
          flex-direction: column;

          .document-object-text {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #334155;
          }
          .document-name {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #64748B;
          }
        }
      }
    }
  }
}
</style>
