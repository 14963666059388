import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client/core/index.js";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions/index.js";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities/index.js";

import { ANNO_TYPES } from "./constants.js";
import version from "../version.json";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";

// queries
import query_getVersion from "./graphql-queries/get-version.graphql";
import query_getOwner from "./graphql-queries/get-owner.graphql";
// import query_getProjects from "./graphql-queries/get-projects.graphql";
// import query_getProjects_v2 from "./graphql-queries/get-projects-v2.graphql";
// import query_getProjects_v3 from "./graphql-queries/get-projects-v3.graphql";
// import query_getProject from "./graphql-queries/get-project.graphql";
// import query_getProject_v2 from "./graphql-queries/get-project-v2.graphql";
// import query_getProjectDocs from "./graphql-queries/get-project-documents.graphql";
// import query_getProjectCollaborators from "./graphql-queries/get-project-collaborators.graphql";
import query_getDocumentAnnotations from "./graphql-queries/get-document-annotations.graphql";
import query_getDocumentContent from "./graphql-queries/get-document-content.graphql";
import query_getDocumentPreview from "./graphql-queries/get-document-preview.graphql";
import query_getDocumentAnnotationDocId from "./graphql-queries/get-document-annotation-doc-id.graphql";
import query_getDocumentChanges_v2 from "./graphql-queries/get-document-changes-v2.graphql";
import query_getUploadStatus from "./graphql-queries/get-upload-status.graphql";
import query_getToolPresets from "./graphql-queries/presets/get-tool-presets.graphql";

// fmc apis
import query_getWaitList from "./graphql-queries/fmc/get-wait-list.graphql";
import query_getFoliaUsers from "./graphql-queries/fmc/get-folia-users.graphql";
import mutation_approveProfileApplication from "./graphql-queries/fmc/approve-application.graphql";
// end of fmc api

// mutations
import mutation_updateOwnerProfile from "./graphql-queries/update-profile.graphql";
import mutation_createProject from "./graphql-queries/project/create-project.graphql";
import mutation_deleteProject from "./graphql-queries/project/delete-project.graphql";
import mutation_updateProjectTitle from "./graphql-queries/project/update-project-title.graphql";
import mutation_changeCollaboratorRole from "./graphql-queries/change-collaborator-role.graphql";
import mutation_updateProjectPublicStatus from "./graphql-queries/project/update-project-public-status.graphql";
import mutation_uploadDoc from "./graphql-queries/upload-document.graphql";
import mutation_uploadDocMp from "./graphql-queries/uploaf-document-mp.graphql";
import mutation_deleteDoc from "./graphql-queries/project/delete-document.graphql";
import mutation_updateDocTitle from "./graphql-queries/update-document-title.graphql";
import mutation_uploadAnnotationBlob from "./graphql-queries/annotations/upload-annotation-blob.graphql";

import mutation_copyDocument2AnotherProject from "./graphql-queries/project/copy-document-2-another-project.graphql";
// import mutation_duplicateProject from "./graphql-queries/duplicate-project.graphql";
// import mutation_projectAccessRequest from "./graphql-queries/projectAccessRequest.graphql";
import mutation_updateDocumentAnnotationDocId from "./graphql-queries/update-document-annotation-doc-id.graphql";

import mutation_addArrow from "./graphql-queries/annotations/addArrow.graphql";
import mutation_addCircle from "./graphql-queries/annotations/addCircle.graphql";
import mutation_addComment from "./graphql-queries/annotations/addComment.graphql";
import mutation_addHighlight from "./graphql-queries/annotations/addHighlight.graphql";
import mutation_addImage from "./graphql-queries/annotations/addImage.graphql";
import mutation_addInk from "./graphql-queries/annotations/addInk.graphql";
import mutation_addReply from "./graphql-queries/annotations/addReply.graphql";
import mutation_addSquare from "./graphql-queries/annotations/addSquare.graphql";
import mutation_addTextBox from "./graphql-queries/annotations/addTextBox.graphql";
import mutation_deleteAnnotation from "./graphql-queries/annotations/deleteAnnotation.graphql";
import mutation_updateImage from "./graphql-queries/annotations/updateImage.graphql";
import mutation_downloadDocument from "./graphql-queries/download-document.graphql";
import mutation_deleteAccount from "./graphql-queries/delete-account.graphql";
import mutation_resendVerificationEmail from "./graphql-queries/resend-verification-email.graphql";
import mutation_setDocumentAsFavorite from "./graphql-queries/favorites/set-document-as-favorite.graphql";
import mutation_updateToolPresets from "./graphql-queries/presets/update-tool-presets.graphql";

//subscriptions
import subscribeOnDocument from "./graphql-queries/subscribe-on-document-changes.graphql";
import subscribeOnSearch from "./graphql-queries/subscribe-on-search.graphql";
import subscribeOnSearch_v2 from "./graphql-queries/subscribe-on-search-v2.graphql";
import CloudStoragesApi from "./cloud-storages-api.js";

//commenting
import getRepliesReadStatus from "./graphql-queries/annotations/commenting/getRepliesReadStatus.graphql";
import changeRepliesReadStatus from "./graphql-queries/annotations/commenting/changeReplyReadStatus.graphql";
// revamp changes
import query_getProjects_v4 from "./graphql-queries/get-projects-v4.graphql";
import query_getProject_v4 from "./graphql-queries/get-project-v4.graphql";
import query_getProjectCollaborators_v4 from "./graphql-queries/get-project-collaborators-v4.graphql";
import mutation_createProject_v4 from "./graphql-queries/create-project-v4.graphql";
import mutation_shareProject_v4 from "./graphql-queries/share-project-v4.graphql";
import mutation_deleteProject_v4 from "./graphql-queries/delete-project-v4.graphql";
import query_getDocuments_v4 from "./graphql-queries/get-documents-v4.graphql";
import query_downloadDocument_v4 from "./graphql-queries/download-document-v4.graphql";
import query_downloadSourceDocument_v4 from "./graphql-queries/download-source-document-v4.graphql";
import query_downloadAnnotatedDocumentContent from "./graphql-queries/download-annotated-document-content.graphql";
import query_get_my_profile_v4 from "./graphql-queries/get-my-profile-v4.graphql";
import mutation_updateProjectVisibility_v4 from "./graphql-queries/update-project-visibility-v4.graphql";
import query_get_executive_summary_v4 from "./graphql-queries/get-executive-summary.graphql";
import mutation_deleteDoc_v4 from "./graphql-queries/delete-document-v4.graphql";
import query_downloadAnnotationBlob from "./graphql-queries/annotations/download-annotation-blob.graphql";
import query_getDocument from "./graphql-queries/get-document-v4.graphql";
import mutation_duplicateProject_v4 from "./graphql-queries/duplicate-project-v4.graphql";
import update_userprofile_v4 from "./graphql-queries/update-userprofile-v4.graphql";
import markDocumentFavorite_v4 from "./graphql-queries/mark-fav-document-v4.graphql";
import query_getStamps from "./graphql-queries/stampsv2/get-stamps.graphql";
import query_getStampPreview from "./graphql-queries/stampsv2/get-stamp-preview.graphql";
import mutate_createStamp from "./graphql-queries/stampsv2/createStamp.graphql";
import mutate_renameStamp from "./graphql-queries/stampsv2/rename-stamp.graphql";
import mutate_deleteStamp from "./graphql-queries/stampsv2/deleteStamp.graphql";



const addAnnotationMutations = {
  [ANNO_TYPES.ARROW]: mutation_addArrow,
  [ANNO_TYPES.CIRCLE]: mutation_addCircle,
  [ANNO_TYPES.COMMENT]: mutation_addComment,
  [ANNO_TYPES.HIGHLIGHT]: mutation_addHighlight,
  [ANNO_TYPES.IMAGE]: mutation_updateImage,
  [ANNO_TYPES.INK]: mutation_addInk,
  [ANNO_TYPES.REPLY]: mutation_addReply,
  [ANNO_TYPES.SQUARE]: mutation_addSquare,
  [ANNO_TYPES.TEXT_BOX]: mutation_addTextBox,
};

export class NetworkError extends Error {
  static name = "NO_CONNECTION";

  constructor(message) {
    super();
    this.message = message;
    this.name = NetworkError.name;
  }
}
export class UnauthorizedError extends Error {
  static name = "UNAUTHORIZED";

  constructor(message) {
    super();
    this.message = message;
    this.name = UnauthorizedError.name;
  }
}
export class ProfileAccessError extends Error {
  static name = "PROFILE_ACCESS_DENIED";

  constructor(message) {
    super();
    this.name = ProfileAccessError.name;
    this.message = message;
  }
}
export class ProjectAccessError extends Error {
  static name = "PROJECT_ACCESS_DENIED";

  constructor(message) {
    super();
    this.name = ProjectAccessError.name;
    this.message = message;
  }
}
export class DocumentAccessError extends Error {
  static name = "DOCUMENT_ACCESS_DENIED";
  constructor(message) {
    super();
    this.name = DocumentAccessError.name;
    this.message = message;
  }
}
export class ApplicationRequiredError extends Error {
  static name = "APPLICATION_REQUIRED";
  constructor(message) {
    super();
    this.name = ApplicationRequiredError.name;
    this.message = message;
  }
}

export class GraphqlAPI {
  constructor(serverUrl, accessToken) {
    this.accessToken = accessToken;
    const httpOptions = {
      timeout: 15000,
      uri: serverUrl,
    };
    if (accessToken) {
      httpOptions.headers = { authorization: `Bearer ${accessToken}` };
    }

    const httpsLink = new HttpLink({
      uri: serverUrl,
      ...httpOptions,
    });

    const wssLink = new GraphQLWsLink(
      createClient({
        url: serverUrl.replace("http", "ws"),
        keepAlive: 10_000, // ping server every 10 seconds
        shouldRetry: (errOrCloseEvent) => true,
        reconnect: true,
        retryAttempts: 1000,
        connectionParams: {
          authToken: accessToken,
        },
      })
    );

    const singleLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wssLink,
      split(
        (operation) => operation.getContext().hasUpload,
        createUploadLink(httpOptions),
        httpsLink
      )
    );

    this.apolloClient = new ApolloClient({
      link: singleLink,
      cache: new InMemoryCache({ addTypename: false }),
      name: "web",
      version: `${version.major}.${version.minor}.${version.build}`,
      defaultOptions: {
        watchQuery: { fetchPolicy: "no-cache", errorPolicy: "none" },
        query: { fetchPolicy: "no-cache", errorPolicy: "none" },
        mutate: { fetchPolicy: "no-cache", errorPolicy: "none" },
      },
    });
  }

  isTokenValid(token) {
    return token === this.accessToken;
  }

  async responseErrorParser(response) {
    const {
      errors,
      graphQLErrors: [graphQLError] = [],
      networkError,
    } = response;

    // prettier-ignore
    // console.log("responseErrorParser", {errors, graphQLError, networkError, responseName: response.name,});

    if (networkError) {
      if (/401/g.test(networkError.message)) {
        throw new UnauthorizedError("UNAUTHORIZED");
      }
      throw new NetworkError(networkError.message);
    } else if (graphQLError) {
      const {
        extensions: { classification },
        message,
      } = graphQLError;
      // console.warn("graphQLError", { classification, message });
      if (classification === "UNAUTHORIZED") {
        throw new UnauthorizedError(message);
      } else if (classification === "PROFILE_ACCESS_DENIED") {
        throw new ProfileAccessError(message);
      } else if (classification === "PROJECT_ACCESS_DENIED") {
        throw new ProjectAccessError(message);
      } else if (classification === "DOCUMENT_ACCESS_DENIED") {
        throw new DocumentAccessError(message);
      } else if (classification === "APPLICATION_REQUIRED") {
        throw new ApplicationRequiredError(message);
      } else {
        throw new Error(`${classification}: ${message}`);
      }
    } else if (errors) {
      console.warn("Unknown error", errors);
      throw new Error("Unknown error");
    }
  }

  version() {
    return this.apolloClient
      .query({ query: query_getVersion })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }

  cloudStorages = new CloudStoragesApi(this);

  getOwnerProfile() {
    return this.apolloClient
      .query({ query: query_getOwner })
      .then((response) => {
        const {
          owner: { profile },
          collaboratorRolesPermissions,
        } = response.data;
        return { profile, rolesPermissions: collaboratorRolesPermissions };
      })
      .catch(this.responseErrorParser);
  }
  updateOwnerProfile(name) {
    return this.apolloClient
      .mutate({
        mutation: mutation_updateOwnerProfile,
        variables: { input: { name } },
      })
      .then((response) => response.data?.updateProfile)
      .catch(this.responseErrorParser);
  }
  // prettier-ignore
  getProjects_v2(projectsCursor, collaboratorsCursor, documentsCursor) {
    const projectsPageSize = 10, subQueryPageSize = 20;
    // console.log("in getProjects_v2", JSON.parse(JSON.stringify({projectsPageSize, projectsCursor, subQueryPageSize, collaboratorsCursor, documentsCursor})))
    return this.apolloClient
      .query({
        query: query_getProjects_v2,
        variables: {
          projectsPageSize,
          projectsCursor,
          subQueryPageSize,
          collaboratorsCursor,
          documentsCursor,
        },
      })
      .then((response) => {
        // console.log("out getProjects_v2", JSON.parse(JSON.stringify(response.data)))
        return response.data;
      })
      .catch(this.responseErrorParser);
  }
  getProjects_v3(projectsCursor) {
    const projectsPageSize = 20;
    return this.apolloClient
      .query({
        query: query_getProjects_v3,
        variables: { projectsPageSize, projectsCursor },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  getProject_v2(projectId, pageSize, collaboratorsCursor, documentsCursor) {
    return this.apolloClient
      .query({
        query: query_getProject_v2,
        // prettier-ignore
        variables: { projectId, pageSize, collaboratorsCursor, documentsCursor },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  getProject(projectId) {
    return this.apolloClient
      .query({
        query: query_getProject,
        variables: { projectId },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  getDocumentChanges_v2(props) {
    const { projectId, documentId, changeKey, pageSize, changesCursor } = props;

    return this.apolloClient
      .query({
        query: query_getDocumentChanges_v2,
        // prettier-ignore
        variables: { projectId, documentId, changeKey, pageSize, changesCursor },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  createProject(projectId, name, description, createdAt) {
    return this.apolloClient
      .mutate({
        mutation: mutation_createProject,
        variables: { input: { projectId, name, description, createdAt } },
      })
      .then((response) => response.data?.createProject)
      .catch(this.responseErrorParser);
  }
  deleteProject(projectId, deletedAt) {
    return this.apolloClient
      .mutate({
        mutation: mutation_deleteProject,
        variables: { input: { projectId } },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  updateProjectTitle(projectId, name, description, addedAt) {
    return this.apolloClient
      .mutate({
        mutation: mutation_updateProjectTitle,
        variables: { input: { id: projectId, name, description } },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }

  uploadAnnotationBlob(id, documentId, filename, content) {
    return this.apolloClient
      .mutate({
        mutation: mutation_uploadAnnotationBlob,
        variables: { input: { id, documentId, filename, content } },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  updateProjectPublicStatus(projectId, publicStatus) {
    return this.apolloClient
      .mutate({
        mutation: mutation_updateProjectPublicStatus,
        variables: { input: { projectId, publicStatus } },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  getProjectCollaborators(projectId, pageSize, cursor) {
    return this.apolloClient
      .query({
        query: query_getProjectCollaborators,
        variables: { projectId, pageSize, cursor },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  changeRole(projectId, collaboratorEmail, collaboratorRole) {
    return this.apolloClient
      .mutate({
        mutation: mutation_changeCollaboratorRole,
        variables: {
          input: { projectId, collaboratorEmail, collaboratorRole },
        },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  getProjectDocuments(projectId, pageSize, cursor) {
    return this.apolloClient
      .query({
        query: query_getProjectDocs,
        variables: { projectId, pageSize, cursor },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  // prettier-ignore
  uploadDocument(projectId, documentId, filename, mimeType, base64content, createdAt) {
    return this.apolloClient
      .mutate({
        mutation: mutation_uploadDoc,
        variables: {
          input: {
            projectId, documentId, filename, mimeType, createdAt,
            content: base64content.split(",")[1],
          },
        },
      })
      .then((response) => response.data)
      .then((data) => data?.uploadDocument?.processingStatus)
      .catch(this.responseErrorParser);
  }
  getUploadStatus() {
    return this.apolloClient
      .query({
        query: query_getUploadStatus,
      })
      .then(({ data }) => data?.owner.documentProcessingStatuses)
      .catch(this.responseErrorParser);
  }

  getDocumentAnnotations(projectId, documentId) {
    return this.apolloClient
      .query({
        query: query_getDocumentAnnotations,
        variables: { projectId, documentId },
      })
      .then((response) => {
        const { annotations } = response.data.getAnnotations;
        return annotations;
      })
      .catch(this.responseErrorParser);
  }

  getDocumentContent(projectId, documentId) {
    return this.apolloClient
      .query({
        query: query_getDocumentContent,
        variables: { projectId, documentId },
      })
      .then((response) => {
        const content = response.data?.project?.document?.content;
        return content ? `data:application/pdf;base64,${content}` : null;
      })
      .catch(this.responseErrorParser);
  }
  getDocumentPreview(projectId, documentId) {
    return this.apolloClient
      .query({
        query: query_getDocumentPreview,
        variables: { projectId, documentId },
      })
      .then((response) => {
        const preview = response.data?.project?.document?.preview;
        return preview ? `data:image/png;base64,${preview}` : null;
      })
      .catch(this.responseErrorParser);
  }
  deleteDocument(projectId, documentId, deletedAt) {
    return this.apolloClient
      .mutate({
        mutation: mutation_deleteDoc,
        variables: {
          input: { projectId, documentId, deletedAt },
        },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  downloadDocument({ projectId, documentId, flatten }) {
    return this.apolloClient
      .mutate({
        mutation: mutation_downloadDocument,
        variables: { input: { projectId, documentId, flatten } },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  updateDocumentTitle(projectId, documentId, name) {
    return this.apolloClient
      .mutate({
        mutation: mutation_updateDocTitle,
        variables: { input: { projectId, documentId, name } },
      })
      .then((response) => {
        const {
          updateDocumentTitle: {
            document: { name, updatedAt },
          },
        } = response.data;
        return { name, updatedAt };
      })
      .catch(this.responseErrorParser);
  }

  updateDocumentAnnotationDocId(projectId, documentId, annotationDocumentId) {
    return this.apolloClient
      .mutate({
        mutation: mutation_updateDocumentAnnotationDocId,
        variables: { input: { projectId, documentId, annotationDocumentId } },
      })
      .then((response) => {
        const {
          updateDocumentAnnotationDocId: {
            document: { name, annotationDocumentId },
          },
        } = response.data;
        return { name, annotationDocumentId };
      })
      .catch(this.responseErrorParser);
  }

  getDocumentAnnotationDocId(documentId) {
    return this.apolloClient
      .query({
        query: query_getDocumentAnnotationDocId,
        variables: { documentId },
      })
      .then((response) => {
        return response.data?.document?.annotationDocumentId;
      })
      .catch(this.responseErrorParser);
  }

  addAnnotation(annotationType, annotationInput, newbie) {
    // console.log("addAnnotation", { annotationType, annotationInput, newbie });
    const mutation =
      newbie && annotationType === ANNO_TYPES.IMAGE
        ? mutation_addImage
        : addAnnotationMutations[annotationType];
    if (!mutation) throw new Error("wrong annotation type");

    return this.apolloClient
      .mutate({ mutation, variables: { input: annotationInput } })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  deleteAnnotation(projectId, documentId, annotationId, deletedAt) {
    return this.apolloClient
      .mutate({
        mutation: mutation_deleteAnnotation,
        variables: {
          input: { projectId, documentId, annotationId, deletedAt },
        },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  // prettier-ignore
  copyDocument2AnotherProject(documentId, sourceProjectId, targetProjectId, withAnnotations) {
    return this.apolloClient
      .mutate({
        mutation: mutation_copyDocument2AnotherProject,
        variables: {
          input: { documentId, sourceProjectId, targetProjectId, withAnnotations },
        },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  duplicateProject(projectId) {
    return this.apolloClient
      .mutate({
        mutation: mutation_duplicateProject_v4,
        variables: { id: projectId },
      })
      .then((response) => {
        const {
          data: { duplicateProject: { project: { id } = {} } = {} },
        } = response;
        return id;
      })
      .catch(this.responseErrorParser);
  }
  sendProjectAccessRequest(projectId) {
    return this.apolloClient
      .mutate({
        mutation: mutation_projectAccessRequest,
        variables: { input: { projectId } },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  deleteAccount() {
    return this.apolloClient
      .mutate({ mutation: mutation_deleteAccount })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  subscribeOnDocument(projectId, documentId, changeKey) {
    return this.apolloClient.subscribe({
      query: subscribeOnDocument,
      variables: { input: { projectId, documentId, changeKey } },
    });
  }
  subscribeOnSearch(query) {
    console.log("Using subscribe on search for query " + query);
    return this.apolloClient.subscribe({
      query: subscribeOnSearch_v2,
      variables: { input: { query } },
    });
  }
  resendVerificationEmail(email) {
    return this.apolloClient
      .mutate({
        mutation: mutation_resendVerificationEmail,
        variables: { profileEmail: email },
      })
      .catch(this.responseErrorParser);
  }
  setDocumentAsFavorite(...args) {
    let [documentId, isFavorite = false, addedAt] = args;
    addedAt ||= new Date().toISOString();

    return this.apolloClient
      .mutate({
        mutation: mutation_setDocumentAsFavorite,
        variables: {
          input: { addedAt, documentId, isFavorite },
        },
      })
      .catch(this.responseErrorParser);
  }
  getToolPresets() {
    return this.apolloClient
      .query({ query: query_getToolPresets })
      .then((response) => {
        const { toolPresets } = response.data;
        return toolPresets;
      })
      .catch(this.responseErrorParser);
  }
  updateToolPresets(presets) {
    return this.apolloClient
      .mutate({
        mutation: mutation_updateToolPresets,
        variables: { input: presets },
      })
      .then((response) => {
        const { addToolPresets } = response.data;
        return addToolPresets;
      })
      .catch(this.responseErrorParser);
  }
  getRepliesReadStatus(projectId, documentId, changeKey, cursor, pageSize) {
    return this.apolloClient
      .query({
        query: getRepliesReadStatus,
        variables: { projectId, documentId, changeKey, cursor, pageSize },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  changeRepliesReadStatus(statuses) {
    return this.apolloClient
      .mutate({
        mutation: changeRepliesReadStatus,
        variables: { input: statuses },
      })
      .then((response) => response.data.success)
      .catch(this.responseErrorParser);
  }
  getProjectsUnreadCounts(pageSize, projectsCursor, documentsCursor) {
    return this.apolloClient
      .mutate({
        query: getAllProjectsUnreadCounts,
        variables: {
          pageSize: pageSize,
          pCursor: projectsCursor,
          dCursor: documentsCursor,
        },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  getProjectUnreadCounts(projectId, pageSize, cursor) {
    return this.apolloClient
      .query({
        query: getProjectUnreadCounts,
        variables: {
          projectId: projectId,
          pageSize: pageSize,
          cursor: cursor,
        },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }

  // ----- stamps requests ------
  getStampsChanges(changeKey, cursor, pageSize) {
    return this.apolloClient
      .query({
        query: getOwnerStampsQuery,
        variables: { changeKey, cursor, pageSize },
      })
      .then((response) => {
        const {
          owner: { documentStamps },
        } = response.data;
        return documentStamps;
      })
      .catch(this.responseErrorParser);
  }
  addStamp(name, pageSize, stampData) {
    const stampType = stampData.__typename;
    const input = {
      name,
      pageSize,
      [stampType]: stampData,
    };
    return this.apolloClient
      .mutate({
        mutation: addStampMutation,
        variables: { input },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  deleteStamp(deletedAt, stampId) {
    return this.apolloClient
      .mutate({
        mutation: deleteStampMutation,
        variables: { input: { deletedAt, stampId } },
      })
      .then((response) => true)
      .catch((e)=>{console.log(e);
        return false
      });
  }
  getFavoriteStamps(changeKey, cursor, pageSize) {
    return this.apolloClient
      .query({
        query: getFavoriteStampsQuery,
        variables: { changeKey, cursor, pageSize },
      })
      .then((response) => {
        const {
          owner: { favoriteStamps },
        } = response.data;
        return favoriteStamps;
      })
      .catch(this.responseErrorParser);
  }
  updateFavoriteStamp(addedAt, isFavorite, stampId) {
    return this.apolloClient
      .mutate({
        mutation: favoriteStampMutation,
        variables: { input: { addedAt, isFavorite, stampId } },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  // -----------------------------

  // --------- folia management console api -------------
  getWaitList() {
    return this.apolloClient
      .query({ query: query_getWaitList })
      .then((response) => {
        return response.data;
      });
  }
  getFoliaUsers() {
    return this.apolloClient
      .query({ query: query_getFoliaUsers })
      .then((response) => {
        return response.data;
      });
  }
  approveProfileApplication(email) {
    return this.apolloClient
      .mutate({
        mutation: mutation_approveProfileApplication,
        variables: { profileEmail: email },
      })
      .catch(this.responseErrorParser);
  }
  // --------- end of folia management console api -------------

  // revamp changes by sahan
  getProjects_v4(filters) {
    const { projectsCursor = null, projectsPageSize = 20 } = filters;
    return this.apolloClient
      .query({
        query: query_getProjects_v4,
        variables: { projectsPageSize, projectsCursor },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  getProject_v4(filters) {
    const { projectId } = filters;
    return this.apolloClient
      .query({
        query: query_getProject_v4,
        variables: { projectId },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  createProject_v4(payload) {
    const { name, description } = payload;
    return this.apolloClient
      .mutate({
        mutation: mutation_createProject_v4,
        variables: { input: { name, description } },
      })
      .then((response) => response.data?.createProject)
      .catch(this.responseErrorParser);
  }
  shareProject_v4(payload) {
    const { projectId, collaboratorEmail, collaboratorRole } = payload;

    const protocol = window.location.protocol;
    const domain = window.location.hostname;
    const port = window.location.port || (protocol === "https:" ? "443" : "80");
    const endpoint = `${protocol}//${domain}:${port}`;

    return this.apolloClient
      .mutate({
        mutation: mutation_shareProject_v4,
        variables: {
          input: {
            projectId,
            collaboratorEmail,
            collaboratorRole,
            endpoint: endpoint,
          },
        },
      })
      .then((response) => response.data?.createProject)
      .catch(this.responseErrorParser);
  }
  updateProjectVisibility_v4(payload) {
    const { projectId, publicStatus } = payload;
    return this.apolloClient
      .mutate({
        mutation: mutation_updateProjectVisibility_v4,
        variables: { input: { projectId, publicStatus } },
      })
      .then((response) => response.data?.createProject)
      .catch(this.responseErrorParser);
  }
  getProjectCollaborators_v4(filters) {
    const { projectId, pageSize, endCursor } = filters;
    return this.apolloClient
      .query({
        query: query_getProjectCollaborators_v4,
        variables: { projectId, pageSize, endCursor },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  async deleteProject_v4(projectId) {
    try {
      const response = await this.apolloClient.mutate({
        mutation: mutation_deleteProject_v4,
        variables: { input: { id: projectId } },
      });

      return response.data;
    } catch (error) {
      console.log({ error });
      return { deleted: false, message: error?.message };
    }
  }

  async duplicateProject_v4(projectId) {
    try {
      const response = await this.apolloClient.mutate({
        mutation: mutation_duplicateProject_v4,
        variables: { input: { id: projectId } },
      });

      return response.data;
    } catch (error) {
      console.log({ error });
      return { deleted: false, message: error?.message };
    }
  }

  uploadDocumentMp(payload) {
    const { file } = payload;
    return this.apolloClient
      .mutate({
        mutation: mutation_uploadDocMp,
        variables: {
          file,
        },
        context: {
          hasUpload: true,
        },
      })
      .then((response) => response.data)
      .then((data) => data?.fileUpload?.tempPath)
      .catch(this.responseErrorParser);
  }
  uploadDocument_v4(payload) {
    const { projectId, filename, mimeType, base64content, uploadPath } =
      payload;
    return this.apolloClient
      .mutate({
        mutation: mutation_uploadDoc,
        variables: {
          input: {
            projectId,
            filename,
            mimeType,
            uploadPath,
            content: base64content?.split(",")[1],
          },
        },
      })
      .then((response) => response.data)
      .then((data) => data?.uploadDocument?.processingStatus)
      .catch(this.responseErrorParser);
  }
  getDocuments(filters) {
    const {
      projectId,
      pageSize,
      cursor,
      sortBy = "DATE_ADDED",
      sortDirection = "ASC",
    } = filters;
    return this.apolloClient
      .query({
        query: query_getDocuments_v4,
        variables: { projectId, pageSize, cursor, sortBy, sortDirection },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }

  downloadDocument_v4({ documentId }) {
    return this.apolloClient
      .query({
        query: query_downloadDocument_v4,
        variables: { documentId },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  downloadSourceDocument_v4({ documentId }) {
    return this.apolloClient
      .query({
        query: query_downloadSourceDocument_v4,
        variables: { documentId },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  downloadAnnotatedDocumentContent({ documentId,flatten }) {
    return this.apolloClient
      .query({
        query: query_downloadAnnotatedDocumentContent,
        variables: { documentId,flatten },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }

  downloadAnnotationBlob(id) {
    return this.apolloClient
      .query({
        query: query_downloadAnnotationBlob,
        variables: { id },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }

  getMyProfile() {
    return this.apolloClient
      .query({
        query: query_get_my_profile_v4,
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }

  getExecutiveSummary({ projectId, invoke }) {
    return this.apolloClient
      .query({
        query: query_get_executive_summary_v4,
        variables: { projectId, invoke },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }

  deleteDocument_v4(payload) {
    const { projectId, documentId } = payload;
    return this.apolloClient
      .mutate({
        mutation: mutation_deleteDoc_v4,
        variables: {
          input: { projectId, documentId },
        },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  markDocumentFavorite_v4(payload) {
    const { email, isFavorite, tenantId, documentId } = payload;
    return this.apolloClient
      .mutate({
        mutation: markDocumentFavorite_v4,
        variables: {
          email,
          isFavorite,
          tenantId,
          documentId,
        },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }
  getDocumentData({ documentId }) {
    return this.apolloClient
      .query({
        query: query_getDocument,
        variables: { documentId },
      })
      .then((response) => response.data)
      .catch(this.responseErrorParser);
  }

  updateUserProfile_v4(payload) {
    const { email, name } = payload;
    return this.apolloClient
      .mutate({
        mutation: update_userprofile_v4,
        variables: {
          input: {
            email,
            name,
          },
        },
      })
      .then((response) => {
        console.log("GraphQL Response:", response);
        return response.data;
      })
      .catch(this.responseErrorParser);
  }

  getCloudStorages() {
    return this.cloudStorages.getAll();
  }

  getStamps() {
    return this.apolloClient
      .query({
        query: query_getStamps,
        // variables: { documentId},
      })
      .then((response) => response.data)
      .catch((e) => {
        console.log(e);
        return [];
      });
  }
  getStampPreview(payload) {
    return this.apolloClient
      .query({
        query: query_getStampPreview,
        variables: { input:{...payload} },
      })
      .then((response) => response.data)
      .catch((e) => {
        console.log(e);
        return {generateStampPreview:null};
      });
  }

  createStamp(payload) {
    return this.apolloClient
      .mutate({
        mutation: mutate_createStamp,
        variables: { input:{...payload} },
      })
      .then((response) => response.data)
      .catch((e) => {
        console.log(e);
        return {generateStampPreview:null};
      });
  }

  deleteStamp(id) {
    return this.apolloClient
      .mutate({
        mutation: mutate_deleteStamp,
        variables: { id },
      })
      .then((response) => response.data)
      .catch((e) => {
        console.log(e);
        return {generateStampPreview:null};
      });
  }

  renameStamp(payload) {
    return this.apolloClient
      .mutate({
        mutation: mutate_renameStamp,
        variables: { input:{...payload} },
      })
      .then((response) => response.data)
      .catch((e) => {
        console.log(e);
        return {generateStampPreview:null};
      });
  }
}
