<script>
import leftPanelState from "../../../store/left-side-panel-state.js";
import ProjectSwitcher from "./ProjectSwitcher.vue";
import Expand from "../../../assets/vue-icons/tools/expand.vue";
import { computed, inject, nextTick, onBeforeUnmount, onMounted, reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { BROADCAST_MESSAGE } from "../../../core/constants.js";
import { GET_CACHE, state as cacheState } from '../../../store/cacheLayer';
import ProjectService from "../../../service/projectService";
import { state as applicationState,getApplicationState } from '../../../store/applicationState';

export default {
  name: "LeftSidePanel",
  components: { Expand, ProjectSwitcher },
  props: [],
  emits: [],

  setup(props) {
    const $fetch = inject("$fetch");
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      documents: [],
    });
    const leftSidePanelEL = ref(null);
    const currentProjectId = computed(() => {
      return route.params.projectId || leftPanelState.fromProject;
    });
    const currentDocumentId = computed(() => {
      return route.params.documentId;
    });
    const favorite_documents = computed(() => {
      return state.documents
        .filter(doc => doc.isFavorite)
        .sort(sortShownDocuments);
    });
    const not_favorite_documents = computed(() => {
      return state.documents
        .filter(doc => !doc.isFavorite)
        .sort(sortShownDocuments);
    });
    const projectService = new ProjectService();
    onMounted(() => {
      getDocuments();
      $fetch.on(BROADCAST_MESSAGE.PROJECT_UPDATED, getDocuments);
      $fetch.on(BROADCAST_MESSAGE.DOCUMENT_UPDATED, getDocument);
      nextTick().then(() => {
        leftSidePanelEL.value.addEventListener("scroll", onScrollHandler);
      });
    });

    onBeforeUnmount(() => {
      leftSidePanelEL.value.removeEventListener("scroll", onScrollHandler);
      $fetch.off(BROADCAST_MESSAGE.PROJECT_UPDATED, getDocuments);
      $fetch.off(BROADCAST_MESSAGE.DOCUMENT_UPDATED, getDocument);
    });

    watch(() => route.params.projectId, (projectId) => {
      leftPanelState.expandDocumentsList();
      leftPanelState.expandFavoriteDocumentsList();
      getDocuments();
    });
    watch(() => cacheState.isDocumentsLoading, (d) => {
      getDocuments()
    })

    watch(()=>applicationState.favoriteDocumentChange,()=>{
      const {docId = null, isFavorite} = getApplicationState.setFavoriteDocumentChange();
      // console.log({docId,isFavorite});
      if(docId!==null){
      const documents = state.documents;
      const document = documents.find(doc => doc.id === docId);
      if (!document) return;
      document.isFavorite = isFavorite;
      }
    })
    let endOfScrollTimer = null;
    function onScrollHandler(e) {
      leftSidePanelEL.value.style.setProperty("--thumb-color", "silver");
      clearTimeout(endOfScrollTimer);
      endOfScrollTimer = setTimeout(() => {
        leftSidePanelEL.value.style.setProperty("--thumb-color", "transparent");
      }, 250);
    }

    function sortShownDocuments(a, b) {
      const aUpdatedTime = new Date(a.updatedAt).getTime();
      const bUpdatedTime = new Date(b.updatedAt).getTime();
      return bUpdatedTime - aUpdatedTime;
    }

    function getDocuments() {
      if (!currentProjectId.value) {
        state.documents = [];
        return Promise.resolve();
      }
      // Get docs from the cache
      const allDocs = GET_CACHE.getCachedDocuments()
      const projectDocuments = allDocs.filter(d => d.projectId.toString() === currentProjectId.value.toString());
      // console.log({ projectDocuments });
      state.documents = projectDocuments.map(d => {
        d.favorite = { isFavorite: false };
        return d;
      });
      return
      // return $fetch.getDocuments(currentProjectId.value).then(documents => {
      //   state.documents = documents;
      // }).catch(console.error);
    }

    function getDocument(documentId) {
      return $fetch.getDocument(documentId).then(doc => {
        const documentIndex = state.documents.findIndex(d => d.id === doc.id);
        if (documentIndex === -1) return;
        state.documents[documentIndex] = doc;
      }).catch(console.error);
    }

    function switch2document(documentId) {
      const projectId = route.params.projectId || leftPanelState.fromProject;
      router.push(`/p/${projectId}/d/${documentId}`);
    }

    async function changeFavoriteStatus(documentId, isFavorite) {
      const email = localStorage.getItem("email");
      const tenantId = localStorage.getItem("tenantId");
      const document = state.documents.find(doc => doc.id === documentId);
      if (!document) return;
      document.isFavorite = isFavorite;
      await projectService.MarkDocumentFavorite({documentId,isFavorite,tenantId,email});
    }

    function toggleDocumentsListView() {
      leftPanelState.documentsCollapsed
        ? leftPanelState.expandDocumentsList()
        : leftPanelState.collapseDocumentsList();
    }

    function toggleFavoriteDocumentsListView() {
      leftPanelState.favoriteDocumentsCollapsed
        ? leftPanelState.expandFavoriteDocumentsList()
        : leftPanelState.collapseFavoriteDocumentsList();
    }

    return {
      leftSidePanelEL,
      leftPanelState, state, currentProjectId, currentDocumentId, not_favorite_documents, favorite_documents,
      switch2document, changeFavoriteStatus, toggleDocumentsListView, toggleFavoriteDocumentsListView
    }
  }
}
</script>

<template>
  <div class="left-side-panel" ref="leftSidePanelEL">
    <ProjectSwitcher @hide-panel="" />
    <div class="documents-list">

      <!-- favorite documents -->
      <div class="documents-block" v-if="favorite_documents.length">
        <div class="block-header">
          <span>FAVORITE DOCUMENTS</span>
          <button class="expand-button" @click="toggleFavoriteDocumentsListView"
            :class="{ 'collapse': !leftPanelState.favoriteDocumentsCollapsed }"></button>
        </div>
        <div class="block-items" :class="{ 'collapsed': leftPanelState.favoriteDocumentsCollapsed }">
          <div class="block-item" v-for="document of favorite_documents" :key="document.id"
            :class="{ 'active': document.id === currentDocumentId }" @click="switch2document(document.id)">
            <div class="item-title">
              <div class="title-icon"></div>
              <span class="title-text">{{ document.name }}</span>
            </div>
            <button class="item-action favorite" @click.stop="changeFavoriteStatus(document.id, false)"></button>
          </div>
        </div>
      </div>

      <!-- rest of documents -->
      <div class="documents-block" v-if="not_favorite_documents.length">
        <div class="block-header">
          <span>ALL DOCUMENTS</span>
          <button class="expand-button" @click="toggleDocumentsListView"
            :class="{ 'collapse': !leftPanelState.documentsCollapsed }"></button>
        </div>
        <div class="block-items" :class="{ 'collapsed': leftPanelState.documentsCollapsed }">
          <div class="block-item" v-for="document of not_favorite_documents" :key="document.id"
            :class="{ 'active': document.id === currentDocumentId }" @click="switch2document(document.id)">
            <div class="item-title">
              <div class="title-icon"></div>
              <span class="title-text">{{ document.name }}</span>
            </div>
            <button class="item-action not-favorite" @click.stop="changeFavoriteStatus(document.id, true)"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@keyframes collapseAnimation {
  0% {
    max-height: 100vh;
  }

  100% {
    max-height: 0;
  }
}

@keyframes expandAnimation {
  0% {
    max-height: 0;
  }

  100% {
    max-height: 100vh;
  }
}

::-webkit-scrollbar {
  appearance: none;
  width: 5px;
}

::-webkit-scrollbar-track {}

::-webkit-scrollbar-thumb {
  appearance: none;
  display: block;
  width: 5px;
  border-radius: 5px;
  background-color: var(--thumb-color);
}

//::-webkit-scrollbar-thumb:hover {
//  background-color: silver;
//}

.left-side-panel {
  --thumb-color: transparent;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  user-select: none;
  overflow-y: auto;
  background-color: #ffffff;

  .documents-list {
    display: flex;
    flex-direction: column;
    padding: 8px;
    gap: 12px;
    //max-height: 300px;
    //overflow-y: scroll;

    .documents-block {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .block-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px;

        span {
          color: #1E293B;
          font-family: Inter, sans-serif;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: 12px;
          letter-spacing: 0.6px;
        }

        .expand-button {
          border: none;
          width: 16px;
          height: 16px;
          background-color: transparent;
          background-image: url("data:image/svg+xml, %3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.6663 10.6667L7.99967 5.33333L3.33301 10.6667' stroke='%231E293B' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          cursor: pointer;

          &:active {
            transform: translate(1px, 1px);
          }

          &.collapse {
            transform: rotate(180deg);
          }
        }
      }

      .block-items {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        gap: 4px;

        &.expanded {
          max-height: 100vh;
          animation: expandAnimation 0.25s;
        }

        &.collapsed {
          max-height: 0;
          animation: collapseAnimation 0.25s;
        }

        .block-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 4px 8px 4px 8px;
          border-radius: 8px;
          color: #334155;

          &.active {
            background-color: #EAF4FF;

            .item-title {
              .title-text {
                color: #2990FF;
              }

              .title-icon {
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 9.25H13.75V5M9.75 15.25H14.25M9.75 12.25H14.25M16.25 19.25H7.75C6.64543 19.25 5.75 18.3546 5.75 17.25V6.75C5.75 5.64543 6.64543 4.75 7.75 4.75H14L18.25 9V17.25C18.25 18.3546 17.3546 19.25 16.25 19.25Z' stroke='%232990FF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
              }
            }
          }

          &:hover {
            background-color: #F1F5F9;
            cursor: pointer;
          }

          .item-title {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .title-icon {
              width: 24px;
              height: 24px;
              background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 9.25H13.75V5M9.75 15.25H14.25M9.75 12.25H14.25M16.25 19.25H7.75C6.64543 19.25 5.75 18.3546 5.75 17.25V6.75C5.75 5.64543 6.64543 4.75 7.75 4.75H14L18.25 9V17.25C18.25 18.3546 17.3546 19.25 16.25 19.25Z' stroke='%23475569' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
              background-size: 24px;
            }

            .title-text {
              font-family: Inter, sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;

              min-width: 150px;
              max-width: 150px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .item-action {
            border: none;
            background-color: transparent;
            width: 24px;
            height: 24px;
            border-radius: 5px;

            &:hover {
              background-color: #d8dce0;
              cursor: pointer;
            }

            &:active {
              transform: translate(1px, 1px);
            }

            &.favorite {
              background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 5L13.6897 10.3103H19L14.6552 13.6897L16.1034 19L12 15.6207L7.89655 19L9.34483 13.6897L5 10.3103H10.3103L12 5Z' fill='%23F59E0B' stroke='%23F59E0B' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            }

            &.not-favorite {
              background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 5L13.6897 10.3103H19L14.6552 13.6897L16.1034 19L12 15.6207L7.89655 19L9.34483 13.6897L5 10.3103H10.3103L12 5Z' stroke='%2394A3B8' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            }
          }
        }
      }
    }
  }
}
</style>