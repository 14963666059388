import { reactive } from "vue";
import { UploadFiles } from "../plugins/upload-files.js";
import { Repo } from "@automerge/automerge-repo";
import {BrowserWebSocketClientAdapter} from "@automerge/automerge-repo-network-websocket";

const state = reactive({
  uploadMonitorShown: false,
  uploadedFiles: [],
  downloadFilesStatus: new Set(),

  isGlobalSearchShown: false,
  isSideBarShown: false,
  isAuthenticated: false,
  caches: {
    home: null,
    projects: {},
  },
  search: {
    ProjectSearchResult: [],
    DocumentSearchResult: [],
    DocumentTextSearchResult: [],
    DocumentObjectSearchResult: [],
  },
  reloadDocumentView: false,
  automergeRepo: Repo,
  automergeNetworkAdaptor: BrowserWebSocketClientAdapter,
  annotationBlobs: {}
});

export const getters = {
  getGlobalSearchStatus() {
    return state.isGlobalSearchShown;
  },
  getGlobalSearchResults() {
    return state.search;
  },
  getStatusSideBar() {
    return state.isSideBarShown;
  },
  get isAuthenticated() {
    return state.isAuthenticated;
  },
  caches: {
    home() {
      return state.caches.home;
    },
    project(projectId) {
      return state.caches.projects[projectId];
    },
  },
  get uploadMonitorShown() {
    return state.uploadMonitorShown;
  },
  get uploadedFiles() {
    return state.uploadedFiles.slice();
  },
  downloadFileStatus(documentId) {
    return state.downloadFilesStatus.has(documentId);
  },
  get reloadDocumentView() {
    return state.reloadDocumentView;
  },
  get automergeRepo() {
    return state.automergeRepo;
  },
  get automergeNetworkAdaptor() {
    return state.automergeNetworkAdaptor;
  },
  getAnnotationBlob(id) {
    return state.annotationBlobs[id];
  }
};

export const mutations = {
  setAutomergeRepo(repo) {
    state.automergeRepo = repo;
  },
  setAutomergeNetworkAdaptor(adaptor) {
    state.automergeNetworkAdaptor = adaptor;
  },
  reloadDocumentView(viewState = false) {
    state.reloadDocumentView = viewState;
  },
  setDownloadFileStatus(documentId) {
    state.downloadFilesStatus.add(documentId);
  },
  unsetDownloadFileStatus(documentId) {
    state.downloadFilesStatus.delete(documentId);
  },
  changeGlobalSearchStatus(globalSearchStatus) {
    state.isGlobalSearchShown = globalSearchStatus;
  },
  addSearchResults(searchCategory, searchResults) {
    state.search[searchCategory].push(searchResults);
  },
  changeSideBarStatus() {
    state.isSideBarShown = !state.isSideBarShown;
  },
  toggleAuthStatus(isAuthenticated) {
    if (isAuthenticated === false) {
      state.caches.home = null;
      state.caches.project = {};
    }
    state.isAuthenticated = isAuthenticated;
  },
  caches: {
    home(data) {
      state.caches.home = data;
    },
    project(projectId, data) {
      state.caches.projects[projectId] = data;
    },
  },
  toggleUploadMonitorShown(status) {
    if (status === undefined) {
      state.uploadMonitorShown = !state.uploadMonitorShown;
    } else {
      state.uploadMonitorShown = Boolean(status);
    }
    if (state.uploadMonitorShown === false) {
      state.uploadedFiles = state.uploadedFiles.filter(
        (file) => ![UploadFiles.DONE, UploadFiles.ERROR].includes(file.status)
      );
    }
  },
  addFiles2Upload(documents) {
    console.log("adding files to upload", documents);
    state.uploadedFiles = [...state.uploadedFiles, ...documents];
    if (documents?.length > 0) state.uploadMonitorShown = true;
  },
  updateUploadStatus(documentId, status, message) {
    const document = state.uploadedFiles.find((doc) => doc.id === documentId);
    if (!document) return;
    document.status = status;
    if (message) document.message = message;
  },
  cancelWaitingFiles() {
    state.uploadedFiles = state.uploadedFiles.filter(
      (item) => item.status !== UploadFiles.JUST_ADDED
    );
  },
  addAnnotationBlob(id, blob) {
    state.annotationBlobs[id] = blob;
  }
};

const store = { getters, mutations };
export default store;
