<template>
  <div class="document-project-container" :class="{'active-document-project-container':$route.params.documentId === documents.id}" @click="openDocument">
    <div class="document-icon">
      <documentIcon/>
    </div>
    <div class="title-document-project">
      {{documents.name}}
    </div>
  </div>
</template>

<script>
import documentIcon from "../../../../assets/vue-icons/document-icon.vue";
import {useRoute, useRouter} from "vue-router";
import {computed} from "vue";
export default {
  name: "ActiveProjectDocument",
  props: ['documents'],
  components: {
    documentIcon,
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    function openDocument() {
      if (isDeleted.value) return;
      // const path = route.params.projectId + "/" + props.documents.id;
      // router.push(path);
      router.push({path:`/${route.params.projectId}/${props.documents.id}`})
    }
    const isDeleted = computed(() => {
      return props.documents.deleted;
    });
    return {
      openDocument,
      isDeleted,
    }
  }

}
</script>

<style lang="scss">
  .document-project-container {
    display:flex;
    flex-direction: row;
    width: 200px;
    height: 35px;
    min-height: 35px;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 5px;
    text-align: center;
    align-items: center;
    padding: 5px;
    border-radius: 6px;
    .document-icon {
      color:#64748B;
      margin-top: 5px;
      margin-right: 12px;
    }
    .title-document-project {
      font-weight: 400;
      font-size: 14px;
      color: #64748B;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .document-project-container:hover {
    cursor: pointer;
    background-color:#EAF4FF;
    color: #2990FF;
    .title-document-project {
      color: #2990FF;
    }
    .document-icon {
      color: #2990FF;
    }
  }
  .active-document-project-container {
    background-color:#EAF4FF;
    color: #2990FF;
    .title-document-project {
      color: #2990FF;
    }
    .document-icon {
      color: #2990FF;
    }
  }
</style>
