<template>
  <div
    class="sidebar-container"
    :class="{ 'sidebar-container-opened': isOpened }"
  >
    <div class="sidebar-header">
      <div class="folia-logo"></div>
      <div class="close-sidebar" @click="closedSidebar">
        <arrowBackLeft />
      </div>
    </div>
    <div class="sidebar-main-header-nav-menu">
      <div
        class="element-menu"
        :class="{ 'element-menu-active': $route.name === 'home' }"
        @click="goToPage('home')"
      >
        <div class="home-icon">
          <homeIcon :class="{ 'home-icon-active': $route.name === 'home' }" />
        </div>
        Home
      </div>
      <div class="element-menu">
        <div class="search-icon">
          <searchSidebarIcon />
        </div>
        Search
      </div>
    </div>
    <div class="active-project-name" :class="{'opened-active-project':popupProjectShown}" v-if="titleProject" ref="popupProjectContainer">
      <span @click="popupProjectShown = !popupProjectShown">{{ titleProject }}</span>
      <div
        class="arrow-bottom-btn"
      >
        <arrowBottomBtn
          @click="popupProjectShown = !popupProjectShown"
        />
      </div>
      <div v-if="popupProjectShown" class="popup-container">
        <PopupProjectProject
          @close="popupProjectShown = !popupProjectShown"
        />
      </div>
    </div>
    <div class="active-project-actions" v-if="titleProject">
      <div class="active-project-actions-item">
        <div class="active-project-action-item-logo">
          <executiveSummaryIcon/>
        </div>
        <div class="active-project-item-text">
          <span>{{"Executive Summary"}}</span>
        </div>
      </div>
      <div class="active-project-actions-item"
           :class="{'active-project-actions-item-active':$route.params.projectId && !$route.params.documentId}"
           @click="$router.push('/' + $route.params.projectId)"
      >
        <div class="active-project-action-item-logo">
          <allDocumentsIcon/>
        </div>
        <div class="active-project-item-text">
          <span>{{"All Documents"}}</span>
        </div>
      </div>
      <div class="active-project-actions-item">
        <div class="active-project-action-item-logo">
          <libraryIcon/>
        </div>
        <div class="active-project-item-text">
          <span>{{"Library"}}</span>
        </div>
      </div>
    </div>
    <div class="sidebar-body-container">

      <div class="favorite-project-container" v-if="false">
        <span>Favorite documents</span>
        <div class="favorite-project-body">
          <RecentlyProjectDocument
            v-for="document in documentsArray.data"
            :documents="document"
          />
        </div>
      </div>
      <div
        class="recently-accessed-project-container"
        v-if="documentsArray.data.length !== 0"
      >
        <span>Recently accessed</span>
        <div class="recently-accessed-project-body">
          <RecentlyProjectDocument
            v-for="document in documentsArray.data"
            :documents="document"
          />
        </div>
      </div>
      <div class="user-info" v-if="isOpened">
        <UserInfoSidebar />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, inject, onMounted, onBeforeUnmount } from "vue";
import * as uuid from "uuid";
import { useRoute, useRouter } from "vue-router";
import arrowBottomBtn from "../../../assets/vue-icons/arrow-bottom-btn.vue";
import searchSidebarIcon from "../../../assets/vue-icons/search-sidebar-icon.vue";
import homeIcon from "../../../assets/vue-icons/home-icon.vue";
import allDocumentsIcon from "../../../assets/vue-icons/all-documents-icon.vue";
import bellIcon from "../../../assets/vue-icons/bell-icon.vue";
import executiveSummaryIcon from "../../../assets/vue-icons/executive-summary-icon.vue";
import arrowBackLeft from "../../../assets/vue-icons/arrow-back-left.vue";
import infoIcon from "../../../assets/vue-icons/info-icon.vue";
import libraryIcon from "../../../assets/vue-icons/library-icon.vue";
import FoliaSearchSidebar from "./FoliaSearchSidebar.vue";
import PopupProjectProject from "./PopupProjectProject/PopupProjectProject.vue";
import UserInfoSidebar from "./UserInfoSidebar.vue";
import RecentlyProjectDocument from "./ActiveProjectDocument/RecentlyProjectDocument.vue";
import { BROADCAST_MESSAGE } from "../../../core/constants.js";

export default {
  name: "SideBar",
  components: {
    arrowBottomBtn,
    bellIcon,
    arrowBackLeft,
    infoIcon,
    FoliaSearchSidebar,
    libraryIcon,
    homeIcon,
    allDocumentsIcon,
    executiveSummaryIcon,
    searchSidebarIcon,
    PopupProjectProject,
    RecentlyProjectDocument,
    UserInfoSidebar,
  },
  props: ["isOpened"],

  setup(props, ctx) {
    const router = useRouter();
    const route = useRoute();
    const $fetch = inject("$fetch");
    const $foliaMessages = inject("$foliaMessages");
    const broadcastChannel = new BroadcastChannel("folia");
    const popupProjectShown = ref(false);
    const popupProjectContainer = ref(null);
    const titleProject = ref("");
    const documentsArray = ref({ data: [] });
    watch(
      () => route.params,
      async () => {
        await makeBreadCrumbs();
      }
    );
    async function makeBreadCrumbs() {
      const { projectId } = route.params;
      if (projectId && uuid.validate(projectId)) {
        const projectData = await $fetch.getProject(projectId);
        titleProject.value = projectData?.name || "";
        const documents = await $fetch.getDocuments(projectId);
        documentsArray.value = {
          data: documents,
        };
      } else {
        titleProject.value = "";
        documentsArray.value = {
          data: [],
        };
      }
    }
    onMounted(() => {
      $foliaMessages.on(BROADCAST_MESSAGE.PROJECT_UPDATED, projectUpdated);
      $foliaMessages.on(BROADCAST_MESSAGE.PROJECTS_UPDATED, projectsUpdated);
      window.addEventListener('click', popupProjectClickOutside)
      makeBreadCrumbs().catch(console.error);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('click', popupProjectClickOutside)
      $foliaMessages.off(BROADCAST_MESSAGE.PROJECT_UPDATED, projectUpdated);
      $foliaMessages.off(BROADCAST_MESSAGE.PROJECTS_UPDATED, projectsUpdated);
    });

    function popupProjectClickOutside(event) {
      if (event.target !== popupProjectContainer.value && !event.composedPath().includes(popupProjectContainer.value)) {
        popupProjectShown.value = false
      }
    }

    function projectUpdated() {
      makeBreadCrumbs().catch(console.error);
    }

    function projectsUpdated() {
      makeBreadCrumbs().catch(console.error);
    }

    function closedSidebar() {
      popupProjectShown.value = false;
      ctx.emit("CloseSidebar");
    }

    const goToPage = (nameRoute) => router.push({ name: `${nameRoute}` });
    return {
      popupProjectShown,
      goToPage,
      documentsArray,
      popupProjectContainer,
      titleProject,
      closedSidebar,
    };
  },
};
</script>

<style lang="scss" scoped>
.sidebar-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #f8fafc;
  overflow-x: hidden;
  transition: all 0.2s;

  .active-project-name {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #e2e8f0;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #334155;

    &:hover {
      background-color: #e3e2e2;
    }

    .popup-container {
      position: fixed;
      z-index: 150;
      left: 243px;
      top: 176px;
      width: 280px;
      height: 427px;
      background-color: white;
      box-shadow: 0 0 10px 2px #e6e6e6;
      border: solid 1px silver;
      border-radius: 5px;
    }
    span {
      color: #334155;
      width: 190px;
      max-width: 190px;
      overflow: hidden;
      cursor: pointer;
      text-overflow: ellipsis;
    }
    .arrow-bottom-btn {
      width: 20px;
      display: flex;
      align-items: center;
      padding-left: 2px;
      cursor: pointer;
      height: 20px;
    }
    .arrow-bottom-btn:hover {
      background-color: #e3e2e2;
      cursor: pointer;
      border-radius: 24px;
    }
  }
  .opened-active-project {
    background-color: #e3e2e2;
  }
  .active-project-actions {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;
    margin-top: 10px;
    width: 100%;

    .active-project-actions-item {
      display:flex;
      justify-content: flex-start;
      width: 200px;
      height: 35px;
      min-height: 35px;
      padding: 5px;
      margin-left: 10px;
      color: #64748B;
      cursor: pointer;
      .active-project-item-text {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .active-project-actions-item-active {
      background: #EAF4FF;
      color: #2990FF;
      border-radius: 6px;
    }
    .active-project-actions-item:hover {
      background: #EAF4FF;
      color: #2990FF;
      border-radius: 6px;
    }

  }
  .sidebar-header {
    width: 95%;
    height: 75px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .folia-logo {
      width: 138px;
      min-width: 138px;
      height: 70px;
      margin-left: -10px;
      margin-top: 15px;
      background-position: center center;
      background-size: 70px 50px;
      background-image: url("../../../assets/images/logo.svg");
      background-repeat: no-repeat;
    }
    .folia-info-button {
      width: 18px;
      min-width: 18px;
      height: 18px;
      background-position: center center;
      background-size: 18px 18px;
      background-repeat: no-repeat;
      cursor: pointer;
    }
    .folia-actions {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
    .close-sidebar {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }
  .sidebar-main-header-nav-menu {
    width: 100%;
    height: 80px;
    padding: 5px;
    margin-left: 5px;
    color: #64748b;
    border-bottom: 1px solid #e2e8f0;

    .element-menu {
      padding: 5px;
      margin-right: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      font-size: 15px;
      font-weight: 500;
      color: #64748b;

      .home-icon {
        width: 20px;
        min-width: 20px;
        height: 20px;
        background-position: center center;
        background-size: 20px 20px;
        background-repeat: no-repeat;
        margin-right: 5px;
      }
      .search-icon {
        width: 20px;
        min-width: 20px;
        height: 20px;
        background-position: center center;
        background-size: 20px 20px;
        background-repeat: no-repeat;
        margin-right: 5px;
      }
      &:hover {
        background: #eaf4ff;
        border-radius: 4px;
        color: #2990ff;
      }
    }
    .element-menu-active {
      background: #eaf4ff;
      border-radius: 4px;
      color: #2990ff;
    }
    .home-icon-active {
      color: #2990ff;
    }
  }
  .sidebar-body-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: space-between;

    .favorite-project-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 5px;
      font-weight: 700;
      line-height: 16px;
      font-size: 14px;
      span {
        font-weight: 700;
        line-height: 16px;
        font-size: 14px;
        margin-left: 8px;
      }
      .favorite-project-body {
        display: flex;
        flex-direction: column;
        height: auto;
        max-height:195px;
        margin-left: 5px;
        overflow: auto;
        width: 100%;
      }
    }
    .recently-accessed-project-container {
      display: flex;

      flex-direction: column;
      justify-content: flex-start;
      margin-left: 5px;
      margin-bottom: 10px;
      span {
        font-weight: 700;
        line-height: 16px;
        font-size: 14px;
        margin-left: 8px;
      }
      .recently-accessed-project-body {
        display: flex;
        flex-direction: column;
        height: auto;
        max-height:150px;
        margin-left: 5px;
        overflow: auto;
        width: 100%;
      }
    }
    .user-info {
      display: flex;
      flex-grow: 1;
      z-index: 12;
      width: 100%;
      position: absolute;
      background-color: #ffffff;
      bottom: 0;
      height: 55px;
      margin-top: 10px;
      padding: 10px;
      border-top: 1px solid #e2e8f0;
      &:hover {
        background-color: #e8e7e7;
      }
    }
  }
}
.sidebar-container-opened {
  width: 240px;
  min-width: 240px;
  border-right: 1px solid #e2e8f0;
  transition: all 0.5s;
}
</style>
