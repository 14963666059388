<template>
  <div class="dark-background">
    <div class="copy-to-project-modal">
      <div class="copy-to-project-modal-header">
        <div class="modal-title">
          <span>Copy Document to Project</span>
        </div>
        <div class="close-modal" @click="$emit('close')">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.625 3.375L3.375 8.625M3.375 3.375L8.625 8.625" stroke="#334155" stroke-width="1.5"
              stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
      </div>
      <div class="copy-to-project-modal-container">
        <div v-if="isLoading" role="status" class="text-center">
          <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-200 fill-orange-600"
            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor" />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill" />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
        <div v-if="!isLoading" class="personal-project-container">
          <div class="personal-project-title">
            <span>Personal Projects</span>
          </div>
          <div class="personal-project" v-for="project of personalProjects"
            :class="{'selected-personal-project':selectedProjectId === project.id}"
            @click="selectProjectToMove(project.id)">
            <span class="project-name">{{project.name}}</span>
            <div class="selected-checkmark" v-if="selectedProjectId === project.id">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.16602 8.49967L6.66602 10.1663L10.8327 5.83301" stroke="#F04E23" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </div>
        <div v-if="!isLoading" class="shared-project-container">
          <div class="shared-project-title">
            <span>Shared with Me</span>
          </div>
          <div class="shared-project" v-for="sharedProject of sharedProjects"
            :class="{'selected-shared-project':selectedProjectId === sharedProject.id}"
            @click="selectProjectToMove(sharedProject.id)">
            <span class="project-name">{{sharedProject.name}}</span>
            <div class="selected-checkmark" v-if="selectedProjectId === sharedProject.id">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.16602 8.49967L6.66602 10.1663L10.8327 5.83301" stroke="#F04E23" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="copy-to-project-modal-button-container">
        <button class="cancel-btn" @click="$emit('close')">
          <span>Cancel</span>
        </button>
        <button class="submit-btn" :class="{'inactive-submit-btn': !selectedProjectId}"
          @click="copyToProject(selectedProjectId)">
          <div class="flex p-2">
            <div v-if="isCopying" role="status" class="text-left">
              <svg aria-hidden="true"
                class="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-200 fill-orange-600"
                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor" />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill" />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
            <span class="ml-1">
              Copy
            </span>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, onMounted, ref } from "vue";
import {
  OWNER,
  COLLABORATOR_PERMISSIONS,
  SUCCESS_TOAST,
  ERROR_TOAST,
  BROADCAST_MESSAGE
} from "../../core/constants.js";
import ProjectService from "../../service/projectService";

export default {
  name: "CopyToProjectModal",
  props:['documentId', 'activeProjectId', 'documentName'],
  setup(props, context) {
    const projects = ref({ arr: [] });
    const $fetch = inject('$fetch');
    const selectedProjectId = ref('');
    const isLoading = ref(false);
    const isCopying = ref(false)
    const projectList = ref([]);
    const projectService = new ProjectService();
    onMounted(()=>{
      fetchProjects()
    })
    const personalProjects = computed(() => {
      const filteredList =  projects.value.arr.filter(project => project.userRole === OWNER && project.id !== props.activeProjectId )
      return filteredList.sort((a,b)=> a.name.localeCompare(b.name))
    })
    const sharedProjects = computed(() => {
      const filteredList =  projects.value.arr.filter(project => project.userRole !== OWNER && project.permissions.includes(COLLABORATOR_PERMISSIONS.UPLOAD_DOCUMENT) && project.id !== props.activeProjectId)
      return filteredList.sort((a,b)=> a.name.localeCompare(b.name))
    })
    function selectProjectToMove(projectId) {
      selectedProjectId.value = projectId
    }
    async function copyToProject(selectedProjectId) {
      try {
        let type = '';
        let title = '';
        let message = '';
        let toast0 = {}
        const selectedProjectName = projects.value.arr.find(project => project.id === selectedProjectId).name;
        isCopying.value=true;
        const copied = await projectService.copyDocumentToProject({
          documentId:props.documentId, 
          sourceProjectId:props.activeProjectId,
          targetProjectId:selectedProjectId,
          withAnnotations:true 
        });
        isCopying.value=false;
        if (copied) {
          type = SUCCESS_TOAST
          title = 'Successfully copied document'
          message = `<span><span class="message-toast-name">
                      ${props.documentName}
                      </span> has been copied to <span class="message-toast-name">
                      ${selectedProjectName }
                      </span></span>`
          toast0 = {
            type, title, message
          }
        } else {
          type = ERROR_TOAST
          title = 'Error copied document'
          message = 'Folia encountered an error when attempting to copied document. Please try again.'
          toast0 = {
            type, title, message
          }
        }
        context.emit('close');
        $fetch.dispatch(BROADCAST_MESSAGE.TOAST, toast0);
      } catch (e) {
        console.error(e)
      }
    }
    async function fetchProjects() {
      let continueLoop = false;
      let projectsCursor = null;
      isLoading.value = true;
      do {
        const tempProjectArray = projectList.value;
        const { hasNextPage, endCursor, projects = [] } = await projectService.getProjects({ projectType:'ALL', projectsCursor });
        projectList.value = [...tempProjectArray, ...projects];
        projectsCursor = endCursor;
        continueLoop = hasNextPage;
        isLoading.value = false
      } while (continueLoop);
      projects.value = {
        arr: projectList.value,
      };
    }
    return {
      projects,
      OWNER,
      COLLABORATOR_PERMISSIONS,
      personalProjects,
      isLoading,
      isCopying,

      copyToProject,
      sharedProjects,
      selectProjectToMove,
      selectedProjectId,
    }
  }
};
</script>

<style lang="scss" scoped>
 
  .dark-background {
    position: fixed;
    z-index: 200;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.25);
    .copy-to-project-modal {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      border-radius: 12px;
      background-color: white;
      width: 504px;
      height: 408px;
      .copy-to-project-modal-header {
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #E2E8F0;
        padding: 16px;
        .modal-title {
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: #334155;
        }
        .close-modal {
          display:flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #E2E8F0;
          border-radius: 6px;
          width: 24px;
          height: 24px;
          cursor: pointer;
          &:hover {
            background-color: #f1f1f1;
          }
        }

      }
      .copy-to-project-modal-container {
        display: flex;
        width: 100%;
        height: 280px;
        text-align: start;
        overflow-y: auto;
        flex-direction: column;
        padding: 14px 16px 16px 14px;
        .selected-checkmark {
          width: 24px;
          height: 24px;
          padding-top: 3px;
          margin-right: 10px;
        }

        .personal-project-container {
          display: flex;
          flex-direction: column;
          gap: 6px;
          .personal-project-title {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            padding-left: 16px;
            color: #334155;
          }
          .project-name {
            max-width: 350px;
            overflow: hidden;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #64748B;
            text-overflow: ellipsis;
          }
          .personal-project {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            height: 32px;
            min-height: 32px;
            white-space: nowrap;
            padding-top: 6px;
            padding-left: 16px;
            padding-bottom: 6px;
            width: 100%;
            overflow: hidden;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: space-between;
            border-radius: 6px;
            text-overflow: ellipsis;
            &:hover {
              cursor: pointer;
              background: #F8FAFC;
            }
          }
          .selected-personal-project {
            background: #F8FAFC;
            .project-name {
              color: #334155;
            }
          }
        }
        .shared-project-container {
          display: flex;
          flex-direction: column;
          margin-top: 28px;
          gap: 6px;
          .shared-project-title {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            padding-left: 16px;
            color: #334155;
          }
          .shared-project {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            height: 32px;
            min-height: 32px;
            white-space: nowrap;
            padding-top: 6px;
            padding-left: 16px;
            padding-bottom: 6px;
            width: 100%;
            overflow: hidden;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: space-between;
            border-radius: 6px;
            text-overflow: ellipsis;
            .project-name {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #64748B;
            }
            &:hover {
              cursor: pointer;
              background: #F8FAFC;
            }
          }
          .selected-shared-project {
            background: #F8FAFC;
            .project-name {
              color: #334155;
            }
          }
        }

                .loading-overlay {
                  display: flex;
                  flex-direction: column;
                  margin: auto auto;
                  justify-content: center;
                  align-items: center;
                  color: red;
                  background-color: #334155;
                }
      }
      .copy-to-project-modal-button-container {
        width: 100%;
        height: 68px;
        display:flex;
        flex-direction: row;
        background: #F8FAFC;
        padding:16px;
        align-items: center;
        justify-content: flex-end;
        border-top: 1px solid #E2E8F0;
        gap: 8px;
        border-radius: 0 0 12px 12px;

        .cancel-btn {
          width: 80px;
          height: 36px;
          background: #FFFFFF;
          border: 1px solid #E2E8F0;
          border-radius: 8px;
          cursor: pointer;
          color: #334155 !important;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          &:hover {
            box-shadow: 0 0 4px 4px #e6e6e6;
          }
        }
        .submit-btn {
          width: 70px;
          height: 36px;
          background: #475569;
          border-radius: 8px;
          border: none;
          color: #FFFFFF;
          cursor: pointer;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          &:hover {
            box-shadow: 0 0 4px 4px #e6e6e6;
          }
        }
        .inactive-submit-btn {
          background: #E2E8F0;
          color: #64748B;
          cursor: default;
          &:hover {
            box-shadow: none;
          }
        }
      }
    }
  }
</style>
