<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.9585 3.95834V16.0417M7.29183 10H16.0418M16.0418 10L13.1252 7.29168M16.0418 10L13.1252 12.7083" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "show-sidebar-icon"
}
</script>

<style scoped>

</style>
