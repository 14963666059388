<template>
    <div class="min-w-80 bg-gray-50 text-sm z-20" v-if="showAP">
        <div class="text-left p-4 bg-white font-semibold text-base text-gray-700 title-border mb-4">
            {{ panelName }}
        </div>
        <div class="dynamic-item-list">
            <div v-for="(items, pageNo) in itemList">
                <div class="text-left p-4 pb-0 pt-0 text-gray-500 text-xs" v-if="pageNo">
                    Page {{ pageNo }}
                </div>
                <template v-for="item in items" :key="item.id">
                    <div @click="onItemClick(item)" class="bg-white m-4 min-h-32 flex ap-border cursor-pointer">
                        <div class="p-4">
                            <Icon :type="item.title" :styles="item.iconStyles"></Icon>
                            <div v-if="item.hasComment">
                                +
                                <svg width="32" height="33" viewBox="0 0 32 33" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <rect y="0.5" width="32" height="32" rx="16" fill="white" />
                                    <rect y="0.5" width="32" height="32" rx="16" fill="#EAF4FF" />
                                    <path
                                        d="M12.4731 15.3264H18.7339M12.4731 18.4574H16.9731M16 25.5H7.75033C7.65176 25.5001 7.55413 25.4808 7.46304 25.4431C7.37195 25.4055 7.28918 25.3502 7.21948 25.2805C7.14978 25.2108 7.09452 25.1281 7.05685 25.037C7.01919 24.9459 6.99987 24.8482 7 24.7497V16.5C7 14.1131 7.94821 11.8239 9.63604 10.136C11.3239 8.44821 13.6131 7.5 16 7.5C17.1819 7.5 18.3522 7.73279 19.4442 8.18508C20.5361 8.63738 21.5282 9.30031 22.364 10.136C23.1997 10.9718 23.8626 11.9639 24.3149 13.0558C24.7672 14.1478 25 15.3181 25 16.5C25 17.6819 24.7672 18.8522 24.3149 19.9442C23.8626 21.0361 23.1997 22.0282 22.364 22.864C21.5282 23.6997 20.5361 24.3626 19.4442 24.8149C18.3522 25.2672 17.1819 25.5 16 25.5Z"
                                        stroke="#2990FF" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>
                        <div class="text-left pt-4 pr-4">
                            <div class="text-base font-semibold pb-1 text-gray-700">{{ item.title }}</div>
                            <div class="text-sm font-semibold text-gray-500">Page {{ item.pageNo }}</div>
                            <div class="text-sm font-normal text-gray-500 mt-1.5">{{ item.author }}</div>
                            <div class="text-xs font-normal text-gray-500 pt-1 pb-1">{{ item.date }}</div>
                        </div>
                        <div>

                        </div>
                    </div>
                </template>

            </div>
        </div>
    </div>
</template>

<script>
import { inject, onMounted, ref, watch, computed } from "vue";
import { state as APState, AP_CACHE_GET } from '../../../store/annotationPanel';
import moment from 'moment';
import Icon from './components/Icon.vue';
const AP_ITEM_TYPES = {
    ANNOTATIONS: 'ANNOTATIONS'
}
export default {
    name: "AnnotationPanel",
    components: {
        Icon
    },
    emits: ["scrollToObject"],
    setup(props, { emit }) {
        const showAP = ref(APState.isAPOpen ?? false);
        const itemList = ref([]);
        const panelName = ref('Annotations');
        const subPanelName = ref('Page 1')
        onMounted(async () => {
        });
        watch(APState, () => {
            showAP.value = APState.isAPOpen;
            if (APState?.currentPanel?.panel === AP_ITEM_TYPES.ANNOTATIONS) {
                const { objects = [] } = APState.currentPanel.data ?? {};
                formatAnnotationPayload(objects);
            }
        })
        const formatAnnotationPayload = (objects) => {
            const propertyFormat = objects.map(o => {
                const { id: annotationId, collaboratorName, collaboratorEmail, __typename = '', addedAt = '', page,
                    color, parentAnnotationId = "", boundingData } = o;
                let annotationName = '';
                switch (__typename) {
                    case 'InkAnnotation':
                        annotationName = 'Pencil';
                        break;
                    case 'CircleAnnotation':
                        annotationName = 'Circle';

                        break;
                    case 'SquareAnnotation':
                        annotationName = 'Square';

                        break;
                    case 'ArrowAnnotation':
                        annotationName = 'Arrow';

                        break;
                    case 'TextBoxAnnotation':
                        annotationName = 'Text';
                        break;

                    case 'ImageAnnotation':
                        annotationName = 'Image';
                        break;
                    case "CommentAnnotation":
                        annotationName = 'Comment';
                        break;
                    case "HighlightAnnotation":
                        annotationName = 'Highlight';
                        break;
                    default:
                        annotationName = '';
                        break;
                }
                const iconStyles = {
                    mainColor: color
                };
                const hasComment = objects.some(e => e.parentAnnotationId === annotationId);
                //const { points: [{ x: xPosition = 0, y: yPosition = 0 }] = [] } = boundingData || {}
                return {
                    type: AP_ITEM_TYPES.ANNOTATIONS,
                    title: annotationName,
                    author: collaboratorName === undefined ? collaboratorEmail : collaboratorName,
                    date: `Added ${moment(addedAt, 'YYYY-MM-DDTHH:mm:ssZ').format('MMM DD, YYYY')} at ${moment(addedAt, 'YYYY-MM-DDTHH:mm:ssZ').format('hh:mm A')} `,
                    addedAt,
                    pageNo: page + 1,
                    iconStyles,
                    hasComment,
                    parentAnnotationId,
                    xPosition: boundingData.left,
                    yPosition: boundingData.top,
                    rawObject: o
                }
            });
            const sortedByY = sortedAnnotations(propertyFormat)
            const annotationAttachedCommentRemovedArray = sortedByY.filter(a => a.parentAnnotationId === '');
            itemList.value = annotationAttachedCommentRemovedArray.reduce((acc, item) => {
                (acc[item.pageNo] = acc[item.pageNo] || []).push(item);
                return acc;
            }, {});
        }
        const sortedAnnotations = (annotations) => {
            return annotations.sort((a, b) => a.yPosition - b.yPosition);
        }
        const onItemClick = (item) => {
            const { rawObject } = item;
            emit('scrollToObject', { ...rawObject })
        }
        return {
            showAP,
            itemList,
            panelName,
            subPanelName,
            onItemClick
        }
    }
};
</script>

<style scoped lang="scss">
.title-border {
    border-top: 1px solid var(--gray-500, #E2E8F0);
    border-right: 0px solid var(--gray-500, #E2E8F0);
    border-bottom: 1.5px solid var(--gray-500, #E2E8F0);
    border-left: 0px solid var(--gray-500, #E2E8F0);
    background: var(--white-white, #FFF);

}

.ap-border {
    border: 1px solid var(--gray-200, #E2E8F0);
    background: var(--white-white, #FFF);
    border-radius: 5px;

    &:hover {
        border: 1.5px solid var(--gray-500, #E2E8F0);
    }
}

.dynamic-item-list {
    max-height: 80vh;
    overflow-y: scroll;
}
</style>