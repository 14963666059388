<script>
import StampsManagerTabs from "./StampsManagerTabs.vue";
import { computed, inject, nextTick, onMounted, reactive, ref, watch } from "vue";
import StampCard from "./StampCard.vue";
import BaseModalDialog from "../../common/modals/common/BaseModalDialog.vue";
import RenameStampDialog from "../../common/modals/stamps/RenameStampDialog.vue";
import DeleteStampDialog from "../../common/modals/stamps/DeleteStampDialog.vue";
import { ANNO_TYPES, STAMP_TYPES } from "../../../core/constants.js";
import AnnotationService from "../../../service/annotationService";
import { SET_CACHE } from "../../../store/cacheLayer";
import { GET_CACHE } from "../../../store/cacheLayer";

export default {
  name: "StampsManager",
  components: { DeleteStampDialog, RenameStampDialog, BaseModalDialog, StampCard, StampsManagerTabs },
  emits: ["close", "useStamp"],
  setup(props, { emit }) {
    const $fetch = inject("$fetch");
    const menuRef = ref(null);
    const activeStamp =ref(null)
    const state = reactive({
      tabIndex: 0,
      stamps: [],
      favorites: new Set(),
      recent: new Set(),
      loading: false,
      renamedStamp: null,
      deletedStamp: null,
      contextMenuData: { stampData: null, parentEl: null },
    });
    const showManagerView = ref(false);
    const isRenamingStamp = ref(false);
    const searchTerm = ref("")
    const displayedStamps = computed(() => {
      let stampsArray = state.stamps;
      if(searchTerm.value !==""){
        stampsArray = state.stamps.filter(stamp=>stamp.name.toLowerCase().includes(searchTerm.value.toLowerCase()))
      }
      switch (state.tabIndex) {
        case 0: 
        const now = Date.now();
        const twoDaysAgo = now - (2 * 24 * 60 * 60 * 1000);
        return stampsArray.filter(stamp => stamp.recent>= twoDaysAgo)
        case 1: return stampsArray
        case 2: return stampsArray
        // case 1: return state.stamps.filter(stamp => stamp?.favorite?.isFavorite === true);
        // case 2: return state.stamps.filter(stamp => state.recent.has(stamp.stampId));
        default: return state.stamps
      }
    });

    function sortByAddedTime(a, b) {
      const aTime = new Date(a.addedAt).getTime();
      const bTime = new Date(b.addedAt).getTime();
      return bTime - aTime;
    }
    const annotationService = new AnnotationService();
    onMounted(() => {
      state.loading = true;
      // $fetch.getStamps()
      //   .then(stamps => state.stamps = stamps.sort(sortByAddedTime))
      //   .catch(console.error)
      //   .finally(() => state.loading = false);
      loadStamps();

    })
    watch(() => showManagerView.value, () => {
      if (showManagerView) {
        state.tabIndex = 2
      }
    })
    async function loadStamps() {
      const cachedStamps = GET_CACHE.getCachedStamps() ?? [];
      if (cachedStamps.length > 0) {
        state.loading = false;
        state.stamps = cachedStamps.sort(sortByAddedTime);
      }
      const stampsArray = (await annotationService.getStamps({}) ?? []).map(stamp=>{
        const {annotation}  = stamp;
        stamp.annotation = annotationService.convertNewAnnotationObjectToOldVersion(annotation);
        return stamp;
      });
      SET_CACHE.cacheStamps(stampsArray);
      const updatedCachedStamps = GET_CACHE.getCachedStamps() ?? [];
      if (updatedCachedStamps.length > 0) { 
        state.stamps = updatedCachedStamps.sort(sortByAddedTime);
      }
      state.loading = false;
    }
    function closeStampsManager() {
      hideContextMenu();
      emit("close");
      window.foliaPdfViewer.resetObjectsSeletion();
    }

    function ask2renameStamp() {
      state.renamedStamp = state.contextMenuData.stampData;
      activeStamp.value=state.contextMenuData.stampData;
      hideContextMenu();
    }

    async function renameStamp(newName) {
      if (!newName || !state.renamedStamp || state.renamedStamp.name === newName) {
        state.renamedStamp = null;
        return;
      }
      isRenamingStamp.value=true;
      await annotationService.renameStamp({id:activeStamp.value.id,name:newName});
      isRenamingStamp.value=false;
      state.renamedStamp = null;
      SET_CACHE.updateStamp({...activeStamp.value,name:newName});
      activeStamp.value = null;
      loadStamps()
      // const addedAt = new Date().toISOString();
      // state.renamedStamp.name = newName;
      // state.renamedStamp.addedAt = addedAt;
      // const {
      //   __typename, pageSize,
      //   name, stampId, color, lineWidth, sourcePoint, targetPoint, paths, rect,
      //   content, filename, fontFamily, fontSize, fontWeight, textAlignment, text
      // } = state.renamedStamp;

      // $fetch.getDocumentStampsAPI()
      //   .then(stampsApi => {
      //     if (__typename === STAMP_TYPES.ARROW) {
      //       const payload = { stampId, addedAt, sourcePoint, targetPoint, color, lineWidth };
      //       return stampsApi.addArrowStamp(name, pageSize, payload);
      //     }
      //     else if (__typename === STAMP_TYPES.INK) {
      //       const payload = { stampId, addedAt, paths, color, lineWidth };
      //       return stampsApi.addInkStamp(name, pageSize, payload);
      //     }
      //     else if (__typename === STAMP_TYPES.IMAGE) {
      //       const payload = { stampId, addedAt, content, filename, rect };
      //       return stampsApi.addImageStamp(name, pageSize, payload);
      //     }
      //     else if (__typename === STAMP_TYPES.CIRCLE) {
      //       const payload = { stampId, addedAt, color, lineWidth, rect };
      //       return stampsApi.addCircleStamp(name, pageSize, payload);
      //     }
      //     else if (__typename === STAMP_TYPES.SQUARE) {
      //       const payload = { stampId, addedAt, color, lineWidth, rect };
      //       return stampsApi.addSquareStamp(name, pageSize, payload);
      //     }
      //     else if (__typename === STAMP_TYPES.TEXT_BOX) {
      //       const payload = { stampId, addedAt, color, fontFamily, fontSize, fontWeight, textAlignment, text, rect };
      //       return stampsApi.addSquareStamp(name, pageSize, payload);
      //     }
      //   })
      //   .catch(console.error)
      // state.renamedStamp = null;
    }

    function markAsFavoriteStamp(isFavorite) {
      const stamp = state.stamps.find(s => s.stampId === state.contextMenuData.stampData.stampId);
      if (!stamp) return;
      const addedAt = new Date().toISOString();
      stamp.favorite.isFavorite = isFavorite;
      stamp.favorite.addedAt = addedAt;
      $fetch.updateFavoriteStamp(addedAt, isFavorite, stamp.stampId).catch(console.error);
      hideContextMenu();
    }

    function ask2deleteStamp() {
      state.deletedStamp = state.contextMenuData.stampData;
      hideContextMenu();
    }

    async function deleteStamp(confirmation) {
      if (confirmation) {
        loadStamps()
      }
      state.deletedStamp = null;
      // if (!state.deletedStamp || !confirmation) {
      //   state.deletedStamp = null;
      //   return;
      // }
      // const deletedAt = new Date().toISOString();
      // const { stampId } = state.deletedStamp;
      // state.stamps = state.stamps.filter(stamp => stamp.stampId !== stampId);
      // $fetch.getDocumentStampsAPI()
      //   .then(api => api.deleteStamp(stampId, deletedAt))
      //   .catch(console.error)
      // state.deletedStamp = null;
    }

    function useStamp(stamp) {
      hideContextMenu();
      stamp.stamp['__typename']=annotationService.convertStampLegacyAnnoTypeNames(stamp?.stamp['__typename']);
      stamp.stamp["pageSize"] = [
          595.35,
          841.995
        ]
        if(stamp.stamp?.kind==='image'){
          stamp.stamp['content']=stamp?.preview?.replace('data:image/png;base64,','')
        }        
      emit("useStamp", stamp);
    }

    function showContextMenu(stampData, parentEl) {
      state.contextMenuData = {
        stampData: structuredClone(stampData),
        parentEl
      };
      nextTick(() => {
        const parentPosition = parentEl.getBoundingClientRect();
        menuRef.value.style.left = `${parentPosition.right - menuRef.value.clientWidth}px`;
        menuRef.value.style.top = `${parentPosition.top - 61}px`;
      });
    }

    function hideContextMenu() {
      state.contextMenuData = { stampData: null, parentEl: null };
    }

    return {
      state, displayedStamps, menuRef, showManagerView,isRenamingStamp,searchTerm,

      closeStampsManager, markAsFavoriteStamp,
      ask2deleteStamp, deleteStamp, ask2renameStamp, renameStamp, useStamp,
      showContextMenu, hideContextMenu,
    }
  }
}
</script>

<template>
  <div class="stamps-manager-overlay" @click.stop="closeStampsManager"></div>
  <section class="stamps-manager">
    <header class="stamps-manager-header">
      <!-- <span class="stamps-manager-title">Stamps</span> -->
      <div v-if="!showManagerView" v-on:click="showManagerView = true" class="stamp-manager-icon">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.75 8H7.25M12.75 8H19.25M4.75 16H12.25M17.75 16H19.25M12.25 8C12.25 9.24264 11.2426 10.25 10 10.25C8.75736 10.25 7.75 9.24264 7.75 8C7.75 6.75736 8.75736 5.75 10 5.75C11.2426 5.75 12.25 6.75736 12.25 8ZM17.25 16C17.25 17.2426 16.2426 18.25 15 18.25C13.7574 18.25 12.75 17.2426 12.75 16C12.75 14.7574 13.7574 13.75 15 13.75C16.2426 13.75 17.25 14.7574 17.25 16Z"
            stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

      </div>
      <div v-if="!showManagerView">
        <StampsManagerTabs :value="state.tabIndex" @input="state.tabIndex = $event" />
      </div>
      <div v-else class="stamp-manager-nav">
        <svg v-on:click="showManagerView = false; state.tabIndex=1" class="stampm-back" width="20" height="20" viewBox="0 0 20 20"
          fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_27916_225098)">
            <path d="M12.5 16.25L6.25 10L12.5 3.75" stroke="#64748B" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_27916_225098">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span>Mange Stamps</span>
      </div>

    </header>
    <section class="stamps-manager-search">
      <div class="stamps-manager-search-inner">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.0417 16.0416L12.9167 12.9166M3.95837 9.16665C3.95837 6.29016 6.29022 3.95831 9.16671 3.95831C12.0432 3.95831 14.375 6.29016 14.375 9.16665C14.375 12.0431 12.0432 14.375 9.16671 14.375C6.29022 14.375 3.95837 12.0431 3.95837 9.16665Z"
            stroke="#94A3B8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <input v-model="searchTerm" type="text" class="stamps-manager-search-input" placeholder="Content">
      </div>
    </section>
    <main v-if="!state.loading && displayedStamps.length === 0" class="stamps-manager-main no-stamps-found">
      <span v-if="state.tabIndex === 0">No Recent Stamps</span>
      <span v-else-if="state.tabIndex === 1">Stamp Library is Empty</span>
    </main>
    <main v-else class="stamps-manager-main" :class="{ loading: state.loading }">
      <StampCard :showManagerView="showManagerView" v-for="stamp of displayedStamps" :key="stamp.stampId" :stamp="stamp"
        @useStamp="useStamp" @showContextMenu="showContextMenu(stamp, $event)" />
    </main>
    <footer class="stamps-manager-footer">
      <!-- <button class="footer-button">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.00004 3.83337V12.1667M12.1667 8.00004H3.83337" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span>ADD IMAGE AS NEW STAMP</span>
      </button> -->
    </footer>
  </section>
  <RenameStampDialog v-if="state.renamedStamp" :name="state.renamedStamp?.name" :isRenamingStamp="isRenamingStamp" @close="renameStamp">
  </RenameStampDialog>
  <DeleteStampDialog v-if="state.deletedStamp" :name="state.deletedStamp?.name" :data="state.deletedStamp"
    @close="deleteStamp">
  </DeleteStampDialog>
  <div class="stamp-card-menu-overlay" v-if="state.contextMenuData.parentEl" @click.stop="hideContextMenu"></div>
  <div ref="menuRef" class="stamp-card-menu" :class="{ 'shown': state.contextMenuData.parentEl }">
    <div class="stamp-card-menu-item rename" @click.stop="ask2renameStamp">Rename</div>
    <div v-if="state.contextMenuData.stampData?.isFavorite === false" class="stamp-card-menu-item favorite-add"
      @click.stop="markAsFavoriteStamp(true)">Add to favorites</div>
    <div v-if="state.contextMenuData.stampData?.isFavorite === true" class="stamp-card-menu-item favorite-remove"
      @click.stop="markAsFavoriteStamp(false)">Remove from favorites</div>
    <div class="stamp-card-menu-item delete" @click.stop="ask2deleteStamp">Delete</div>
  </div>
</template>

<style scoped lang="scss">
.stamp-manager-icon {
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.stamp-manager-nav {
  display: flex;
  padding: 2px;
  margin-bottom: 10px;

  .stampm-back {
    cursor: pointer;
    margin-right: 10px;
  }

  span {
    font-size: 14px;
    font-weight: 500;
  }
}

.stamps-manager-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 2;
}

.stamps-manager {
  z-index: 2;
  position: absolute;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  width: 550px;
  border-radius: 12px;
  border: 1px solid #E2E8F0;
  background: #ffffff;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);

  .stamps-manager-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 16px 16px 0px 16px;
    border-bottom: solid 1px #E2E8F0;

    .stamps-manager-title {
      font-family: Inter, sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
      color: #334155;
    }
  }

  .stamps-manager-search {
    padding: 16px;
    width: 100%;

    .stamps-manager-search-inner {
      display: flex;
      align-items: center;
      height: 36px;
      padding: 8px;
      border-radius: 6px;
      gap: 8px;
      background: #F1F5F9;

      .stamps-manager-search-input {
        width: 100%;
        border: none;
        outline: none;
        background: #F1F5F9;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #334155;

        &::placeholder {
          color: #94A3B8;
        }
      }
    }
  }

  .stamps-manager-main {
    min-height: 160px;
    max-height: 330px;
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px;
    gap: 16px;
    overflow-y: auto;
    overflow-x: hidden;

    &.no-stamps-found {
      align-items: center;
      justify-content: center;
    }

    &.loading {
      align-items: center;
      justify-content: center;
      background: url("../../../assets/images/loading-icon.gif") no-repeat center;
      background-size: 20px;
    }
  }

  .stamps-manager-footer {
    width: 100%;
    border-top: solid 1px #E2E8F0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px;
    gap: 8px;

    .footer-button {
      display: flex;
      align-items: center;
      background-color: #334155;
      color: white;
      height: 36px;
      padding: 8px 16px 8px 16px;
      border: none;
      border-radius: 8px;
      gap: 8px;

      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;

      cursor: pointer;

      &:active {
        transform: translate(1px, 1px);
      }
    }
  }
}

.stamp-card-menu-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  //background: rgba(0, 0, 0, 0.05);
  z-index: 2;
}

.stamp-card-menu {
  display: none;
  position: absolute;
  //right: 0;
  //top: 0;
  background-color: white;
  border-radius: 8px;
  padding: 8px;
  border: 1px solid rgba(51, 65, 85, 0.15);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  z-index: 2;

  &.shown {
    display: flex;
    flex-direction: column;
    animation: fadeIn .3s;
    -webkit-animation: fadeIn .3s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .stamp-card-menu-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 10px 10px 40px;
    border-radius: 4px;
    user-select: none;
    white-space: nowrap;
    color: #334155;
    font-weight: 400;
    font-size: 14px;
    z-index: 3;

    &:hover {
      background-color: #F8FAFC;
      cursor: pointer;
    }

    &.rename {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.95825 16.0417L7.49992 15.2083L15.7909 6.91738C16.1163 6.59194 16.1163 6.0643 15.7909 5.73887L14.261 4.20904C13.9356 3.88361 13.408 3.88361 13.0825 4.20904L4.79159 12.5L3.95825 16.0417Z' stroke='%23334155' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.6863 5.86591L14.1863 8.36591' stroke='%23334155' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: 12px center;
      background-size: 20px;
    }

    &.favorite-add {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_23365_161672)'%3E%3Cpath d='M13.15 3C11.932 3 10.763 3.61798 10 4.59455C9.237 3.61798 8.068 3 6.85 3C4.694 3 3 4.84632 3 7.19619C3 10.0801 5.38 12.43 8.985 16.0005L10 17L11.015 15.9929C14.62 12.43 17 10.0801 17 7.19619C17 4.84632 15.306 3 13.15 3ZM10.07 14.8638L10 14.9401L9.93 14.8638C6.598 11.5755 4.4 9.40109 4.4 7.19619C4.4 5.6703 5.45 4.52589 6.85 4.52589C7.928 4.52589 8.978 5.2812 9.349 6.32643H10.658C11.022 5.2812 12.072 4.52589 13.15 4.52589C14.55 4.52589 15.6 5.6703 15.6 7.19619C15.6 9.40109 13.402 11.5755 10.07 14.8638Z' fill='%23334155'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_23365_161672'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: 12px center;
      background-size: 20px;
    }

    &.favorite-remove {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_23365_161672)'%3E%3Cpath d='M13.15 3C11.932 3 10.763 3.61798 10 4.59455C9.237 3.61798 8.068 3 6.85 3C4.694 3 3 4.84632 3 7.19619C3 10.0801 5.38 12.43 8.985 16.0005L10 17L11.015 15.9929C14.62 12.43 17 10.0801 17 7.19619C17 4.84632 15.306 3 13.15 3ZM10.07 14.8638L10 14.9401L9.93 14.8638C6.598 11.5755 4.4 9.40109 4.4 7.19619C4.4 5.6703 5.45 4.52589 6.85 4.52589C7.928 4.52589 8.978 5.2812 9.349 6.32643H10.658C11.022 5.2812 12.072 4.52589 13.15 4.52589C14.55 4.52589 15.6 5.6703 15.6 7.19619C15.6 9.40109 13.402 11.5755 10.07 14.8638Z' fill='%23334155'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_23365_161672'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: 12px center;
      background-size: 20px;
    }

    &.delete {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.12492 8.95831V13.5416M11.0416 8.95831V13.5416M7.29159 6.45831V5.62498C7.29159 4.70451 8.03778 3.95831 8.95825 3.95831H10.2083C11.1287 3.95831 11.8749 4.70451 11.8749 5.62498V6.45831M3.95825 6.45831H15.2083M5.49255 14.5194L4.79159 6.45831H14.3749L13.674 14.5194C13.5991 15.3806 12.8781 16.0416 12.0136 16.0416H7.15295C6.28843 16.0416 5.56744 15.3806 5.49255 14.5194Z' stroke='%23F43F5E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: 12px center;
      background-size: 20px;
      color: #F43F5E;
    }
  }
}
</style>