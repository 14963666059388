<script>
import { nextTick, onBeforeUnmount, onMounted, reactive, ref, watch } from "vue";

export default {
  name: "SmartScrollBar",
  props: {
    pdfViewer: Object,
    objects: Array,
    selectedObject: Object,
  },
  setup(props) {
    const state = reactive({
      labels: [],
    });
    const smartScrollBar = ref(null);
    const smartScrollBarThumb = ref(null);

    watch(() => props.selectedObject.info, () => {
      buildLabels().catch(console.error);
    })

    watch(() => props.objects.length, () => {
      buildLabels().catch(console.error);
    })

    onMounted(() => {
      // console.log("onMounted:objects", props.pdfViewer);
      nextTick()
        .then(buildLabels)
        .then(() => {
          window.addEventListener("resize", changeZoomHandler);
          props.pdfViewer.pdfViewer.container.addEventListener("scroll", scrollListener);
          calculateScrollBar(props.pdfViewer.pdfViewer.container);
        })
        .then(() => {
          smartScrollBarThumb.value.addEventListener("mousedown", onMouseDown, { passive: false });
        })
        .catch(console.error);
    });

    onBeforeUnmount(() => {
      smartScrollBarThumb.value.removeEventListener("mousedown", onMouseDown, { passive: false });
      props.pdfViewer.pdfViewer.container.removeEventListener("scroll", scrollListener)
      window.removeEventListener("resize", changeZoomHandler);
    });
    const scrollToAnnotation = (data) => {
      const page =1;
      const y = 915
      const scrollBarEl  = smartScrollBar.value;
      if (scrollBarEl) {
        const { height } = scrollBarEl.getBoundingClientRect();
        // console.log({height});
      }
    }
    function calculateScrollBar(targetEl) {
      const { clientHeight, scrollHeight, scrollTop } = targetEl;
      const thumbHeight = clientHeight / 100 * (100 * clientHeight / scrollHeight);
      const thumbScrollTop = clientHeight / 100 * (100 * scrollTop / scrollHeight);
      smartScrollBarThumb.value.style.height = `${thumbHeight}px`
      smartScrollBarThumb.value.style.top = `${thumbScrollTop}px`
    }

    function scrollListener(e) {
      calculateScrollBar(e.target);
    }

    let mouseIsDown = false;
    let thumbOffsetY = 0;

    function onMouseDown(e) {
      e.stopPropagation();
      e.preventDefault();
      mouseIsDown = true;
      thumbOffsetY = e.target.offsetTop - e.y;
      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("mouseup", onMouseUp);
    }

    function onMouseMove(e) {
      if (mouseIsDown) {
        const top = Math.min(
          Math.max(0, thumbOffsetY + e.y),
          props.pdfViewer.pdfViewer.container.clientHeight - smartScrollBarThumb.value.clientHeight
        );
        smartScrollBarThumb.value.style.top = `${top}px`;
        const topAsPercent = 100 / smartScrollBar.value.clientHeight * top;
        props.pdfViewer.pdfViewer.container.scrollTop = props.pdfViewer.pdfViewer.container.scrollHeight * topAsPercent / 100;
      }
    }

    function onMouseUp(e) {
      mouseIsDown = false;
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    }

    function changeZoomHandler(e) {
      buildLabels().catch(console.error);
    }

    async function buildLabels() {
      countLabels();
      const promises = [];
      for (let i = 1; i <= props.pdfViewer?.pagesCount; i++) {
        promises.push(props.pdfViewer.pdfDocument.getPage(i));
      }
      await Promise.allSettled(promises).then(promisedPages => {
        return promisedPages.reduce((summary, promise, arr) => {
          const { status, value: page } = promise;
          if (status !== "fulfilled" || !page) return summary;
          const pageObjects = props.objects
            .filter(obj => obj.page === page._pageIndex)
            .map(obj => {
              const { id, __typename } = obj;
              if (obj.rect) {
                const top = summary.height + obj.rect[1] * page.view[3];
                return { top, __typename, id, page: obj.page };
              }
              else if (obj.paths) {
                const top = summary.height + obj.paths.reduce((acc, path) => {
                  return Math.min(acc, ...path.filter((el, index) => index % 2));
                }, Infinity) * page.view[3];
                return { top, __typename, id, page: obj.page };
              }
              else if (obj.rects) {
                const top = summary.height + obj.rects.reduce((acc, rect) => {
                  return Math.min(acc, rect[1]);
                }, Infinity) * page.view[3];
                return { top, __typename, id, page: obj.page };
              } else if (obj.sourcePoint) {
                // TODO: need to find out data for arrow annotations.
                return null;
              }
              else {
                return null;
              }
            }).filter(obj => !!obj);
          summary.objects = summary.objects.concat(pageObjects);
          summary.height += page.view[3];
          return summary;
        }, { height: 0, objects: [] });

      }).then((pdfObjects) => {
        const height = pdfObjects.height;
        const objects = pdfObjects.objects.map(obj => {
          obj.top = 100 / height * obj.top;
          return obj;
        });
        state.labels.forEach(label => {
          const matchedObjects = objects.filter(obj => {
            return obj.top >= label.from && obj.top < label.to;
          });
          label.count = matchedObjects.length;
          label.position = matchedObjects[0]
            ? Math.round(height / 100 * matchedObjects[0].top)
            : 0;
          label.firstEl = matchedObjects[0]?.id;
          label.page = matchedObjects[0]?.page;
        });
      });
    }

    function countLabels() {
      let labelsCount = 0;
      const scrollBarEl = smartScrollBar.value;
      if (scrollBarEl) {
        const { height } = scrollBarEl.getBoundingClientRect();
        labelsCount = Math.round(height / 10);
      }
      state.labels = Array(labelsCount).fill(null).map((_, index, arr) => {
        return {
          position: index,
          from: index * (100 / arr.length),
          to: (index + 1) * (100 / arr.length),
          count: 0,
          hasSelectedObject: false,
        };
      });
    }

    function scrollToPosition(label) {
      const el = document.querySelector(`[data-id="${label.firstEl}"]`);
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "center" })
      } else {
        props.pdfViewer.page = label.page;
      }
    }
    return {
      smartScrollBar, smartScrollBarThumb,
      state,
      scrollToPosition,
      scrollToAnnotation
    };
  }
}
</script>

<template>
  <div class="smart-scroll-bar" ref="smartScrollBar">
    <div v-for="label of state.labels" :data-count="label.count" @click="scrollToPosition(label)"
      class="smart-scroll-bar-labels" :class="{
      'labels-count-1': label.count === 1,
      'labels-count-2': label.count > 1 && label.count < 4,
      'labels-count-4': label.count >= 4 && label.count < 8,
      'labels-count-8': label.count >= 8 && label.count < 16,
      'labels-count-16': label.count >= 16,
    }" />
    <div class="smart-scroll-bar-track" ref="smartScrollBarThumb"></div>
  </div>
</template>

<style scoped lang="scss">
.smart-scroll-bar {
  position: absolute;
  right: 0;
  height: 100%;
  width: 24px;
  border-left: 1px solid #E2E8F0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2px;
  background-color: white;

  .smart-scroll-bar-track {
    position: absolute;
    top: 100px;
    height: 30px;
    width: 20px;
    background-color: rgba(162, 167, 239, 0.25);
    border: solid 1px #64748B;
    border-radius: 10px;
  }

  .smart-scroll-bar-labels {
    position: relative;
    border-radius: 5px;
    //background-color: #e2e8fc;
    height: 5px;
    width: 16px;
    margin-top: 5px;

    &.labels-count-1 {
      cursor: pointer;
      background-color: #b2bee7 !important;
    }

    &.labels-count-2 {
      cursor: pointer;
      background-color: #909abb !important;
    }

    &.labels-count-4 {
      cursor: pointer;
      background-color: #717992 !important;
    }

    &.labels-count-8 {
      cursor: pointer;
      background-color: #54596b !important;
    }

    &.labels-count-16 {
      background-color: #333641 !important;
    }
  }

}
</style>