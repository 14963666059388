<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" stroke="#64748B" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.75 5.75L10.25 10.25M10.25 5.75L5.75 10.25M8 15.25C3.99594 15.25 0.75 12.0041 0.75 8C0.75 3.99594 3.99594 0.75 8 0.75C12.0041 0.75 15.25 3.99594 15.25 8C15.25 12.0041 12.0041 15.25 8 15.25Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "close-icon"
}
</script>

<style scoped>

</style>
