import { GraphqlAPI } from "../core/graphql-api";
const graphqlServer = import.meta.env.VITE_GRAPHQL_SERVER_V1;


export default class FeatureService {
    constructor() {
        this.accessToken = localStorage.getItem("accessToken");
        this.graphqlAPIService = new GraphqlAPI(graphqlServer, this.accessToken);
        this.getFeatures = this.getFeatures.bind(this);
    }

    async getFeatures() {
        try {
            // const data = await this.graphqlAPIService.getFeatures();
            return { }
        } catch (error) {
            return {}
        }
    }
}