<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.125 5H15.2083M8.125 10H15.2083M8.125 15H15.2083" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.83366 5.00008C5.83366 5.46032 5.46056 5.83342 5.00033 5.83342C4.54009 5.83342 4.16699 5.46032 4.16699 5.00008C4.16699 4.53984 4.54009 4.16675 5.00033 4.16675C5.46056 4.16675 5.83366 4.53984 5.83366 5.00008Z" fill="currentColor"/>
    <path d="M5.83366 10.0001C5.83366 10.4603 5.46056 10.8334 5.00033 10.8334C4.54009 10.8334 4.16699 10.4603 4.16699 10.0001C4.16699 9.53984 4.54009 9.16675 5.00033 9.16675C5.46056 9.16675 5.83366 9.53984 5.83366 10.0001Z" fill="currentColor"/>
    <path d="M5.83366 15.0001C5.83366 15.4603 5.46056 15.8334 5.00033 15.8334C4.54009 15.8334 4.16699 15.4603 4.16699 15.0001C4.16699 14.5398 4.54009 14.1667 5.00033 14.1667C5.46056 14.1667 5.83366 14.5398 5.83366 15.0001Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: "TableViewIcon"
}
</script>

<style scoped>

</style>
