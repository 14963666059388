import simpleModalHtml from "./folia-simple-modal.html?raw";

class FoliaSimpleModal extends HTMLElement {
  static SUCCESS = "success";
  static DANGEROUS = "dangerous";
  #shadowRoot;
  #type = "success";
  #onClickBind;

  constructor() {
    super();
    const template = document.createElement("template");
    template.innerHTML = simpleModalHtml;
    this.#shadowRoot = this.attachShadow({ mode: "closed" });
    this.#shadowRoot.appendChild(template.content.cloneNode(true));

    this.#onClickBind = this.onClick.bind(this);
  }

  static get observedAttributes() {
    return ["type", "header", "confirmation-button", "dismiss-button"];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    switch (name) {
      case "type": {
        this.#type = newValue;
        this.#shadowRoot
          .querySelectorAll(
            ".folia-simple-modal-header-icon, .folia-big-button:not(.neutral)"
          )
          .forEach((el) => {
            el.classList.toggle(
              "success",
              newValue === FoliaSimpleModal.SUCCESS
            );
            el.classList.toggle(
              "dangerous",
              newValue === FoliaSimpleModal.DANGEROUS
            );
          });
        break;
      }
      case "header": {
        this.#shadowRoot
          .querySelectorAll(".folia-simple-modal-title")
          .forEach((el) => {
            el.innerText = newValue;
          });
        break;
      }
      case "confirmation-button": {
        if (!newValue) return;
        this.#shadowRoot
          .querySelectorAll('.folia-big-button[data-role="confirm"]')
          .forEach((el) => {
            el.innerText = newValue;
          });
        break;
      }
      case "dismiss-button": {
        if (!newValue) return;
        this.#shadowRoot
          .querySelectorAll('.folia-big-button[data-role="dismiss"]')
          .forEach((el) => {
            el.innerText = newValue;
          });
        break;
      }
      default:
        break;
    }
  }

  connectedCallback() {
    this.#shadowRoot.addEventListener("click", this.#onClickBind);
  }

  disconnectedCallback() {
    this.#shadowRoot.removeEventListener("click", this.#onClickBind);
  }

  onClick(e) {
    e.stopPropagation();
    const { role } = e.target.dataset;
    switch (role) {
      case "dismiss":
      case "dismiss-on-top": {
        this.dispatchEvent(new CustomEvent("dismiss"));
        break;
      }
      case "confirm": {
        this.dispatchEvent(new CustomEvent("confirm"));
        break;
      }
      default:
        break;
    }
  }
}

if ("customElements" in window) {
  customElements.define("folia-simple-modal", FoliaSimpleModal);
} else {
  throw new Error("Custom html element <folia-simple-modal> is not supported.");
}

export { FoliaSimpleModal };
