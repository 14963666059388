<template>
  <div class="wait-list-container">
    <div class="wait-list-content">
      <div class="logo"/>
      <div class="text-container">
        <span class="title">Welcome to Folia</span>
        <span class="sub-text">
          <p>You’re on the waitlist!</p>
          <p></p>
          <p>Thanks for creating an account with us. You’ve now been added to the waitlist for early access to Folia. You will receive an email once we’ve activated your account. Thanks for your patience, and we look forward to you joining the Folia community very soon!</p>
        </span>
      </div>

      <section class="wait-list-section">
        <div class="back-to-login" @click="signOut">
          <span>Return to login screen</span>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import ActionButton from "../common/ActionButton.vue";
import { computed, inject, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "WaitList",
  components: {
    ActionButton,
  },
  setup() {
    const router = useRouter();
    const $auth0 = inject("$auth0");
    const $fetch = inject("$fetch");

    onMounted(() => {
      // $fetch.checkUserProfile()
      $fetch.getUserProfile()
        .then(() => router.replace("/"))
        .catch(() => {})
        .then(() => $auth0.getUser())
        .then(() => {
          document.querySelectorAll("#splash-screen").forEach(el => el.remove());
        })
        .catch(err => {
          console.error(err.message);
          router.replace("/login");
        });
    })

    function signOut() {
      $auth0.signOut().catch(console.error);
    }

    function joinWaitList() {
      localStorage.removeItem("wait-list");
      window.open("https://www.folia.com/waitlist", "_blank");
    }

    return {
      signOut, joinWaitList
    }
  }
};
</script>

<style lang="scss" scoped>
  .wait-list-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;

    .wait-list-content {
      width: 300px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .wait-list-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .confirmed-checkbox {
          text-align: center;
          margin-bottom: 20px;

          label {
            margin-left: 10px;
          }
        }

        .back-to-login {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          margin-top: 25px;
          color: #2990ff;
          cursor: pointer;
        }

        .input-container {
          width: 100%;
        }

        & > .input-container {
          margin-top: 15px;
        }

        & > .input-container ~ .input-container {
          margin-top: 20px;
        }

        .field-label {
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #64748B;
        }

        .input-field {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          background: #F1F5F9;
          width: 100% !important;
          height: 44px;
          margin-top: 8px;
          margin-bottom: 20px;
          box-sizing: border-box;
          padding: 0 6px;
          border: 1px solid #e2e8f0;
          border-radius: 6px;
        }
      }

      .logo {
        width: 99.87px;
        height: 56px;
        background-image: url('../../assets/images/logo-auth.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        margin-bottom: 35px;
      }

      .text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;
        margin-bottom: 24px;

        .title {
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
        }
        .sub-text {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
</style>
