<template>
  <div class="annotation-paint-properties" ref="rootEl">
    <ColorButton
      v-if="showColorProperties && canManage"
      :color="color"
      :lineWidth="lineWidth"
      :isSelected="true"
      @updateColor="updateColor"
      @updateWidth="updateLineWidth"
    />

    <FontBlock
      v-if="showTextProperties && canManage"
      :textProps="textProps"
      :color="color"
      @updateColor="updateColor"
      @updateTextProperties="updateTextProperties"
    />

<!--    <div class="space-expander" v-if="showColorProperties || showTextProperties"></div>-->
    <div class="vertical-divider" v-if="(showColorProperties || showTextProperties) && canManage"/>
    <ToolButton
      ref="infoButtonRef"
      :tool="INFO_BTN"
      @btnOnCLick="showInfo = true"
    />
    <ToolButton
      v-if="canManage && isNotHighlight"
      :tool="DUPLICATE_BTN"
      @btnOnCLick="duplicateAnnot"
    />
    <ToolButton
      v-if="canMakeAsStamp"
      :tool="MAKE_AS_STAMP_BTN"
      @btnOnCLick="createDialogIsShown = true"
    />
    <ToolButton
      v-if="canDelete"
      :tool="DELETE_BTN"
      @btnOnCLick="$emit('deleteSelectedAnnotations')"
    />
    <div class="info-panel-overlay" v-if="showInfo" @click="showInfo = false"></div>
    <div class="info-panel" v-if="showInfo" :style="{left: infoPanelLeft}">
      <span class="info-panel-added-date">{{addedAt}}</span>
      <span class="info-panel-author">Added by: <b>{{author}}</b></span>
    </div>
    <CreateStampDialog
      v-if="createDialogIsShown"
      :parentRects="parentRects"
      @close="createDialogIsShown = false"
    />
  </div>
</template>

<script>
import {ICON} from "../../common/constants.js";
import ToolButton from "../../common/ToolButton.vue";
import {computed, onMounted, reactive, ref, watch} from "vue";
import ColorButton from "./ColorButton.vue";
import FontBlock from "./FontBlock.vue";
import { formattedTime } from "../../common/utils.js";
import CreateStampDialog from "../../common/modals/stamps/CreateStampDialog.vue";
export default {
  name: "AnnotationPaintProperties",
  components: { CreateStampDialog, ColorButton, ToolButton, FontBlock},
  props: {
    drawingProps: Object,
    parentRects: Object,
    canDelete: Boolean,
    canManage: Boolean,
    annotationTypes: Set,
    info: Object,
    selectedAnnotations: Object,
  },
  emits: [
    "updateDrawing", "deleteSelectedAnnotations", "duplicateAnnot"
  ],
  setup(props, {emit}) {
    const REVERT_BTN = {title: "Delete", icon: ICON.REVERT};
    const DELETE_BTN = {title: "Delete", icon: ICON.DELETE};
    const INFO_BTN = {title: "Info", icon: ICON.INFO};
    const DUPLICATE_BTN = {title: "Duplicate", icon: ICON.DUPLICATE};
    const MAKE_AS_STAMP_BTN = {title: "Make as a stamp", icon: ICON.STAMP};
    const rootEl = ref(null);
    const infoButtonRef = ref(null);
    const showInfo = ref(false);
    const color = ref("#000000FF");
    const lineWidth = ref(3);
    const textProps = reactive({
      fontFamily: null,
      fontSize: null,
      fontWeight: null,
      textAlignment: null,
    });
    const infoPanelLeft = ref("0");
    const createDialogIsShown = ref(false);

    onMounted(() => {
      setValues(props.drawingProps);
      const parent = infoButtonRef.value.$el
      if (parent) {
        infoPanelLeft.value = (parent.offsetLeft + parent.clientWidth / 2) + "px";
      }
    });
    watch(() => props.drawingProps, drawingProps => setValues(drawingProps));

    function setValues(values) {
      color.value = values.color;
      lineWidth.value = values.lineWidth;
      textProps.fontFamily = values.fontFamily;
      textProps.fontSize = values.fontSize;
      textProps.fontWeight = values.fontWeight;
      textProps.textAlignment = values.textAlignment;

      adjustPosition();
    }
    function adjustPosition() {
      if (!rootEl.value) return console.warn("floating bar: root element has not been mounted yet");
      const safeBorder = 15;
      const barRect = {width: rootEl.value.clientWidth, height: rootEl.value.clientHeight};
      const {annoRect, pageRect} = props.parentRects;
      let left = 0, top = 0;

      if (annoRect.right + barRect.width + safeBorder < pageRect.width) { // check for right side
        left = annoRect.right + safeBorder + pageRect.left;
        top = annoRect.top + annoRect.height / 2 - barRect.height / 2 + pageRect.top;

      } else if (annoRect.left - safeBorder - barRect.width > 0) { // check for left side
        left = annoRect.left - barRect.width - safeBorder + pageRect.left;
        top = annoRect.top + annoRect.height / 2 - barRect.height / 2 + pageRect.top;

      } else if (annoRect.bottom + barRect.height + safeBorder < pageRect.height) { // check for bottom side
        left = annoRect.left + annoRect.width / 2 - barRect.width / 2 + pageRect.left;
        top = annoRect.bottom + safeBorder + pageRect.top;

      } else if (annoRect.top - barRect.height - safeBorder > 0) { // check for top side
        left = annoRect.left + annoRect.width / 2 - barRect.width / 2 + pageRect.left;
        top = annoRect.top  - barRect.height - safeBorder + pageRect.top;

      } else { // set on the center
        left = annoRect.left + annoRect.width / 2 - barRect.width / 2 + pageRect.left;
        top = annoRect.top + annoRect.height / 2 - barRect.height / 2 + pageRect.top;
      }

      console.log("pos", annoRect, pageRect, {left, top})
      rootEl.value.style.left = left  + "px";
      rootEl.value.style.top = top + "px";
    }
    function updateLineWidth(newLineWidth) {
      lineWidth.value = newLineWidth;
      emit("updateDrawing", {lineWidth: newLineWidth});
    }
    function updateColor(newColor) {
      color.value = newColor;
      emit("updateDrawing", {color: newColor});
    }
    function updateTextProperties(data) {
      Object.entries(data).forEach(([key, value]) => {
        textProps[key] = value;
      });
      emit("updateDrawing", {
        color: color.value,
        fontFamily: textProps.fontFamily,
        fontSize: textProps.fontSize,
        fontWeight: textProps.fontWeight,
        textAlignment: textProps.textAlignment,
      });
    }
    function duplicateAnnot() {
      emit("duplicateAnnot");
    }

    const isNotHighlight = computed(() => {
      return !props.annotationTypes.has("HighlightAnnotation");
    })
    const showTextProperties = computed(() => {
      return Object.keys(props.drawingProps).includes("fontFamily");
    });
    const showColorProperties = computed(() => {
      return Object.keys(props.drawingProps).includes("color") && !Object.keys(props.drawingProps).includes("fontFamily");
    });
    const author = computed(() => props.info?.author || "Unknown zebra");
    const addedAt = computed(() => {
      if (!props.info?.addedAt) return "Unknown date";
      return formattedTime(props.info?.addedAt)
    });
    const canMakeAsStamp = computed(() => {
      return props.canManage
        && props.selectedAnnotations.annotationIds.size === 1
        && !props.annotationTypes.has("HighlightAnnotation");
    });

    return {
      REVERT_BTN, DELETE_BTN, INFO_BTN, DUPLICATE_BTN, MAKE_AS_STAMP_BTN,
      rootEl, infoButtonRef, infoPanelLeft, showTextProperties,
      showColorProperties, showInfo, author, addedAt, color, lineWidth,
      textProps, isNotHighlight, createDialogIsShown, canMakeAsStamp,

      updateLineWidth, updateColor, updateTextProperties, duplicateAnnot,
    }
  }
}
</script>

<style scoped lang="scss">
.annotation-paint-properties {
  z-index: 1009 !important;
}

.space-expander {
  flex-grow: 1;
}

.vertical-divider {
  width: 0;
  height: 16px;
  border: 1px solid #e2e8f0;

  &.invisible {
    border: solid 1px transparent;
  }
}

.info-panel-overlay {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.05);
}

.info-panel {
  --infoBackgroundColor: #F8FAFC;
  position: absolute;
  top: 45px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--infoBackgroundColor);
  padding: 5px 7px;
  border-radius: 5px;
  border: solid 1px #e9e9e9;
  gap: 5px;

  & > span {
    white-space: nowrap;
    font-size: 13px;
    color: #7b899c;

    &.info-panel-added-date {
      color: #b2b2b2;
    }
    &.info-panel-author {
      color: gray;
      b {
        color: #636363;
      }
    }
    &.info-panel-error {
      color: orangered;
    }
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -100%);
    width: 0;
    height: 0;
    border: solid 10px transparent;
    border-bottom: solid 10px var(--infoBackgroundColor);
  }

  transform-origin: 50% -20%;
  transform: translate(-50%, 0);
  //animation: pendulum 2.5s ease-in-out infinite;;
}

@keyframes pendulum {
  0%, 100% {
    transform: translate(-50%, 0) rotate(15deg);
  }

  50% {
    transform: translate(-50%, 0) rotate(-15deg);
  }
}
</style>