<script>
import { computed, inject, onBeforeUnmount, onMounted, reactive, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { BROADCAST_MESSAGE } from "../../../core/constants.js";
import leftSidePanelState from "../../../store/left-side-panel-state.js";
import { GET_CACHE, state as cacheState } from '../../../store/cacheLayer';

export default {
  setup() {
    const $fetch = inject("$fetch");
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      projects: [],
      opened: false,
      currentProject: {}
    });
    const currentProjectId = computed(() => {
      const { projectId } = route.params;
      return projectId || leftSidePanelState.fromProject;
    });

    const currentProjectName = computed(() => {
      const { projectId } = route.params;
      const pid = projectId || leftSidePanelState.fromProject;
      return state.projects.find(p => p.id === pid)?.name || "No project selected";
    });

    const sortedProjects = computed(() => {
      return state.projects.slice().sort((a, b) => {
        const aDate = new Date(a.updatedAt).getTime();
        const bDate = new Date(b.updatedAt).getTime();
        return bDate - aDate;
      });
    })

    onMounted(async() => {

      getProjects();

      $fetch.on(BROADCAST_MESSAGE.PROJECTS_UPDATED, getProjects);
      $fetch.on(BROADCAST_MESSAGE.PROJECT_UPDATED, getProjects);
      window.addEventListener("click", onClick);
      // state.currentProject = GET_CACHE.getCachedCurrentOpenedProject();
      // console.log({currentProject:state.currentProject});
    });
    watch(()=> cacheState.isProjectLoading,(s)=>{
      if(!cacheState.isProjectLoading){
        state.currentProject = GET_CACHE.getCachedCurrentOpenedProject();
      }
    })
    onBeforeUnmount(() => {
      $fetch.off(BROADCAST_MESSAGE.PROJECTS_UPDATED, getProjects);
      $fetch.off(BROADCAST_MESSAGE.PROJECT_UPDATED, getProjects);
      window.removeEventListener("click", onClick);
    });

    function onClick(e) {
      state.opened = false;
    }

    function getProjects() {
      // return $fetch.getProjects().then(projects => {
      //   state.projects.length = 0;
      //   projects.forEach(project => state.projects.push(project));
      // }).catch(console.error);
      const cachedProjects = GET_CACHE.getCachedProjects();
      cachedProjects.some(p=>{
        const {id = ''} =p;
        if(id === currentProjectId.value){
          state.currentProject=p;
          return true;
        }
      })
      state.projects = cachedProjects;
    }

    function goHome() {
      state.opened = false;
      router.push("/");
    }

    function switch2project(projectId) {
      state.projects.some(p => {
        if (p.id.toString() === projectId.toString()) {
          state.currentProject = p;
          return true
        }
      });
      state.opened = false;
      router.push(`/p/${projectId}`);
    }

    function changeSwitcherVisibility() {
      state.projects = GET_CACHE.getCachedProjects();
      state.opened = !state.opened;
    }

    return {
      state, currentProjectId, currentProjectName, sortedProjects,
      goHome, switch2project, changeSwitcherVisibility,
    }
  }
}
</script>

<template>
  <div class="project-switcher" @click.stop>
    <div class="project-switcher-header">
      <div class="header-left-icon"></div>
      <span class="header-title" @click.stop="changeSwitcherVisibility">{{ state.currentProject?.name }}</span>
      <div class="header-right-icon">
        <folia-button size="20" icon="expand" :selected="state.opened"
          @click.stop="changeSwitcherVisibility"></folia-button>
      </div>
    </div>
    <div class="project-switcher-body" :class="{ 'opened': state.opened, 'closed': !state.opened }">
      <div class="body-header">
        <div class="header-icon"></div>
        <button class="header-text-button" @click.stop="goHome">All projects</button>
      </div>
      <div class="body-items">
        <div data-v-b9052455="" class="body-heading">RECENT</div>
        <button class="body-item" v-for="project of sortedProjects"
          :class="{ 'selected': currentProjectId === project.id }" @click.stop="switch2project(project.id)">
          <div class="item-icon"></div>
          <span class="item-text">{{ project.name }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.body-heading {
  padding: 8px;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: .6px;
  color: #334155;
}

::-webkit-scrollbar {
  appearance: none;
  width: 5px;
}

::-webkit-scrollbar-track {}

::-webkit-scrollbar-thumb {
  appearance: none;
  display: block;
  width: 5px;
  border-radius: 5px;
  background-color: silver;
}

.project-switcher {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  z-index: 1;
  background-color: white;

  .project-switcher-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    padding-left: 8px;

    .header-left-icon {
      width: 16px;
      height: 100%;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.24023 4.1565L7.99885 1L14.7575 4.1565M1.24023 4.1565L7.99885 7.27586M1.24023 4.1565V11.8806L7.99885 15M14.7575 4.1565L7.99885 7.27586M14.7575 4.1565V11.8806L7.99885 15M7.99885 7.27586V15' stroke='%23334155' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
    }

    .header-title {
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      color: #1E293B;

      width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }

    .header-right-icon {
      cursor: pointer;
    }
  }

  .project-switcher-body {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
    top: 50px;
    width: 224px;
    min-height: 100px;

    &.opened {
      display: flex;
    }

    &.closed {
      display: none;
    }

    border-radius: 8px;
    border: 1px solid #E2E8F0;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.10);

    .body-header {
      display: flex;
      padding: 0px 4px 8px 4px;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      border-bottom: 1px solid #E2E8F0;

      .header-icon {
        width: 16px;
        height: 16px;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.25 2.25H3.5C3.16848 2.25 2.85054 2.3817 2.61612 2.61612C2.3817 2.85054 2.25 3.16848 2.25 3.5V6.25C2.25 6.58152 2.3817 6.89946 2.61612 7.13388C2.85054 7.3683 3.16848 7.5 3.5 7.5H6.25C6.58152 7.5 6.89946 7.3683 7.13388 7.13388C7.3683 6.89946 7.5 6.58152 7.5 6.25V3.5C7.5 3.16848 7.3683 2.85054 7.13388 2.61612C6.89946 2.3817 6.58152 2.25 6.25 2.25ZM6 6H3.75V3.75H6V6ZM12.5 2.25H9.75C9.41848 2.25 9.10054 2.3817 8.86612 2.61612C8.6317 2.85054 8.5 3.16848 8.5 3.5V6.25C8.5 6.58152 8.6317 6.89946 8.86612 7.13388C9.10054 7.3683 9.41848 7.5 9.75 7.5H12.5C12.8315 7.5 13.1495 7.3683 13.3839 7.13388C13.6183 6.89946 13.75 6.58152 13.75 6.25V3.5C13.75 3.16848 13.6183 2.85054 13.3839 2.61612C13.1495 2.3817 12.8315 2.25 12.5 2.25ZM12.25 6H10V3.75H12.25V6ZM6.25 8.5H3.5C3.16848 8.5 2.85054 8.6317 2.61612 8.86612C2.3817 9.10054 2.25 9.41848 2.25 9.75V12.5C2.25 12.8315 2.3817 13.1495 2.61612 13.3839C2.85054 13.6183 3.16848 13.75 3.5 13.75H6.25C6.58152 13.75 6.89946 13.6183 7.13388 13.3839C7.3683 13.1495 7.5 12.8315 7.5 12.5V9.75C7.5 9.41848 7.3683 9.10054 7.13388 8.86612C6.89946 8.6317 6.58152 8.5 6.25 8.5ZM6 12.25H3.75V10H6V12.25ZM12.5 8.5H9.75C9.41848 8.5 9.10054 8.6317 8.86612 8.86612C8.6317 9.10054 8.5 9.41848 8.5 9.75V12.5C8.5 12.8315 8.6317 13.1495 8.86612 13.3839C9.10054 13.6183 9.41848 13.75 9.75 13.75H12.5C12.8315 13.75 13.1495 13.6183 13.3839 13.3839C13.6183 13.1495 13.75 12.8315 13.75 12.5V9.75C13.75 9.41848 13.6183 9.10054 13.3839 8.86612C13.1495 8.6317 12.8315 8.5 12.5 8.5ZM12.25 12.25H10V10H12.25V12.25Z' fill='%23334155'/%3E%3C/svg%3E%0A");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 16px;
      }

      .header-text-button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        color: #334155;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;

        &:active {
          transform: translate(1px, 1px);
        }
      }
    }

    .body-items {
      display: flex;
      flex-direction: column;
      gap: 8px;
      max-height: 500px;
      overflow: auto;

      .body-item {
        border: none;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 4px;
        gap: 4px;
        border-radius: 4px;

        &:hover {
          background-color: #F1F5F9;
          cursor: pointer;
        }

        &.selected {
          background: #EAF4FF;

          .item-icon {
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.24023 4.1565L7.99885 1L14.7575 4.1565M1.24023 4.1565L7.99885 7.27586M1.24023 4.1565V11.8806L7.99885 15M14.7575 4.1565L7.99885 7.27586M14.7575 4.1565V11.8806L7.99885 15M7.99885 7.27586V15' stroke='%232990FF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E%0A");
          }

          .item-text {
            color: #2990FF;
          }
        }

        .item-icon {
          width: 16px;
          height: 16px;
          background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.24023 4.1565L7.99885 1L14.7575 4.1565M1.24023 4.1565L7.99885 7.27586M1.24023 4.1565V11.8806L7.99885 15M14.7575 4.1565L7.99885 7.27586M14.7575 4.1565V11.8806L7.99885 15M7.99885 7.27586V15' stroke='%23334155' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E%0A");
          background-position: center;
          background-repeat: no-repeat;
        }

        .item-text {
          color: #334155;
          font-family: Inter, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          text-align: left;

          max-width: 165px;
          min-width: 165px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>