const {VITE_G_AUTH_ENDPOINT, VITE_G_AUTH_TOKEN_ENDPOINT} = import.meta.env;

class GoogleOAuth2Client {
    static titleName = "Google Drive";
    #authEndpoint = VITE_G_AUTH_ENDPOINT
    #tokenEndpoint = VITE_G_AUTH_TOKEN_ENDPOINT

    constructor() {
    }

    getAuthWithRedirect(transitedState) {
        const backendUrl = new URL(this.#authEndpoint);
        backendUrl.searchParams.set("state", window.btoa(JSON.stringify(transitedState)));

        fetch(backendUrl.href, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                window.location.href = data.auth_url;
            })
            .catch((error) => {
                throw new Error(`Error initiating Google auth: ${error}`);
            });
    }

    callbackHandler(query = {}) {
        const {state, code} = query;
        const params = new URLSearchParams({
            code: code,
            state: state
        });

        return fetch(this.#tokenEndpoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
            body: params.toString(),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.access_token) {
                    return {
                        access_token: data.access_token,
                        refresh_token: data.refresh_token
                    };
                } else if (data.error) {
                    throw new Error(`${data.error}:${data.error.description}`);
                }
            })
            .catch(console.error);
    }
}

export default GoogleOAuth2Client;
