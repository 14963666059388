<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    stroke="#64748B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.958008 7.20866H13.0413M12.208 3.87533V3.66699C12.208 2.28628 11.0887 1.16699 9.70801 1.16699H4.29134C2.91063 1.16699 1.79134 2.28628 1.79134 3.66699V4.70866C1.79134 6.08937 2.91063 7.20866 4.29134 7.20866H9.49967M1.79134 10.1253V10.542C1.79134 11.9227 2.91063 13.042 4.29134 13.042H9.70801C11.0887 13.042 12.208 11.9227 12.208 10.542V9.29199"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "strikeout-icon",
};
</script>
