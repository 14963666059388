<script>
import { computed, inject, nextTick, onMounted, reactive, ref, watch } from "vue";
import { useRoute } from "vue-router";
import CloudStorageFinder from "./CloudStorageFinder.vue";
import EditStorageNameModal from "./EditStorageNameModal.vue";
import GoogleOAuth2Client from "../../auth/auth-callbacks/GoogleOAuth2Client.js";
import { BROADCAST_MESSAGE, CLOUD_STORAGE, ERROR_TOAST, INFO_TOAST } from "../../../core/constants.js";
import uploadFiles from "../../../plugins/upload-files.js";
import MSOAuth2Client from "../../auth/auth-callbacks/MSOAuth2Client.js";
import BoxOAuth2Client from "../../auth/auth-callbacks/BoxOAuth2Client.js";
import TokenExpiredWarning from "./TokenExpiredWarning.vue";
import DropboxOAuth2Client from "../../auth/auth-callbacks/DropboxOAuth2Client.js";
import ProjectService from "../../../service/projectService.js";

export default {
  name: "UploadDocuments",
  computed: {
    uploadFiles() {
      return uploadFiles
    }
  },
  components: { TokenExpiredWarning, EditStorageNameModal, CloudStorageFinder },
  setup(props, { emit }) {
    const AUTHENTICATED = "AUTHENTICATED";
    const TOKEN_EXPIRED = "TOKEN_EXPIRED";
    const route = useRoute();
    const $fetch = inject("$fetch");
    const cloudStorageProviders = [
      { type: CLOUD_STORAGE.GOOGLE_DRIVE, className: "item-google-drive", title: "Connect to Google Drive", disabled: false },
      { type: CLOUD_STORAGE.ONE_DRIVE, className: "item-one-drive", title: "One Drive", disabled: false },
      { type: CLOUD_STORAGE.BOX_DRIVE, className: "item-boxcloud", title: "Box drive", disabled: false },
      { type: CLOUD_STORAGE.DROPBOX_DRIVE, className: "item-dropbox", title: "Dropbox", disabled: false },
    ]
    const state = reactive({
      uploadOptionsVisibleFlag: false,
      showAddStorageModal: false,
      retrievingStorages: false,
      storages: [],
      selectedStorage: null,
      renamedStorage: null,
      cloudFinderVisibleFlag: false,
    });
    const { projectId } = route.params;
    const projectService = new ProjectService();
    onMounted(async () => {
      const cloudStorage2Open = localStorage.getItem("cloudStorage2Open");
      localStorage.removeItem("cloudStorage2Open");
      if (cloudStorage2Open) {
        const storages = await projectService.getCloudStorages();
        state.storages = storages;
        state.selectedStorage = storages.find(st => st.provider === cloudStorage2Open);
        state.uploadOptionsVisibleFlag = false;
        // $fetch
        //   .getCloudStoragesList()
        //   .then(storages => {
        //     state.storages = storages;
        //     state.selectedStorage = storages.find(st => st.id === cloudStorage2Open);
        //     state.uploadOptionsVisibleFlag = false;
        //   })
        //   .catch((e) => {
        //     console.log(e)
        //   })
      }
    });

    watch(() => state.uploadOptionsVisibleFlag, async (val) => {
      if (state.retrievingStorages) return;
      if (state.storages.length === 0) state.retrievingStorages = true;
      const storages = await projectService.getCloudStorages();
      state.storages = storages.sort((stA, stB) => {
        return new Date(stA.addedAt).getTime() - new Date(stB.addedAt).getTime();
      });
      state.retrievingStorages = false
      // $fetch
      //   .getCloudStoragesList()
      //   .then(storages => )
      //   .then(storages =>  = storages)
      //   .catch(console.error)
      //   .finally(() => state.retrievingStorages = false);
    });

    const thereIsExpiredTokens = computed(() => {
      return state.storages.some(storage => storage.authenticationStatus === TOKEN_EXPIRED);
    });

    function showUploadOptions() {
      state.uploadOptionsVisibleFlag = true;
    }

    function hideUploadOptions() {
      state.uploadOptionsVisibleFlag = false;
    }

    function addLocalFiles() {
      state.uploadOptionsVisibleFlag = false;
      emit("addLocalFiles");
    }

    function addNewStorage() {
      state.uploadOptionsVisibleFlag = false;
      state.showAddStorageModal = true;
    }

    function clickOnStorage(storageId) {
      const storage = state.storages.find(storage => storage.id === storageId);
      if (storage.authenticationStatus === TOKEN_EXPIRED) {
        return authorizeCloudStorage(storage.provider, storage);
      }
      state.selectedStorage = storage;
      state.uploadOptionsVisibleFlag = false;
    }

    function removeStorage(storage) {
      $fetch.deleteCloudStorage(storage.id)
        .then(() => {
          state.selectedStorage = null;
          state.storages = state.storages.filter(s => s.id !== storage.id);
          $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
            type: INFO_TOAST,
            title: "The storage has been removed."
          });
        })
        .catch(error => {
          console.error(error.message);
          $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
            type: ERROR_TOAST,
            title: "Can not delete the storage."
          });
        })
    }

    function editStorageName(storage) {
      // console.log("editStorageName", storage);
      state.renamedStorage = storage;
    }

    function saveStorageName(newStorageName) {
      // console.log("saveStorageName", state.renamedStorage, newStorageName);
      $fetch.updateCloudStorage(state.renamedStorage.id, newStorageName)
        .then(() => {
          $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
            type: INFO_TOAST,
            title: "The storage has been renamed."
          });
          state.renamedStorage.name = newStorageName;
          state.renamedStorage = null;
        })
        .catch(error => {
          console.error(error.message);
          state.renamedStorage = null;
          $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
            type: ERROR_TOAST,
            title: "Can not update the storage."
          });
        });
    }

    function closeAddStorageModal() {
      state.showAddStorageModal = false;
    }

    function authorizeCloudStorage(providerType, existsStorage) {
      try {
        const transitedState = {
          path: location.pathname,
          provider: providerType,
          existsStorage: existsStorage ? {
            id: existsStorage.id,
            name: existsStorage.name,
          } : null
        };

        let oauth2client;
        switch (providerType) {
          case CLOUD_STORAGE.GOOGLE_DRIVE:
            oauth2client = new GoogleOAuth2Client();
            break;
          case CLOUD_STORAGE.ONE_DRIVE:
            oauth2client = new MSOAuth2Client();
            break;
          case CLOUD_STORAGE.BOX_DRIVE:
            oauth2client = new BoxOAuth2Client();
            break;
          case CLOUD_STORAGE.DROPBOX_DRIVE:
            oauth2client = new DropboxOAuth2Client();
            break;
          default: break;
        }
        if (oauth2client) oauth2client.getAuthWithRedirect(transitedState);
      } catch (err) {
        $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
          type: ERROR_TOAST,
          title: err.message,
        })
      }
    }

    return {
      CLOUD_STORAGE, AUTHENTICATED, TOKEN_EXPIRED, state, cloudStorageProviders,
      projectId, thereIsExpiredTokens,

      showUploadOptions, hideUploadOptions, addLocalFiles, addNewStorage,
      clickOnStorage, closeAddStorageModal, authorizeCloudStorage,
      removeStorage, editStorageName, saveStorageName,
    }
  }
};
</script>

<template>
  <div class="upload-documents">
    <div class="upload-documents-button" @click="showUploadOptions">
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.99967 0.833984V9.16732M9.16634 5.00065H0.833008" stroke="white" stroke-width="1.5"
          stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <span>Add Document</span>
    </div>

    <div class="upload-documents-overlay" v-if="state.uploadOptionsVisibleFlag" @click="hideUploadOptions"></div>
    <div class="upload-documents-menu" v-if="state.uploadOptionsVisibleFlag">
      <div class="storage-items-menu">
        <div class="storage-item item-local-files" @click="addLocalFiles">
          <div class="storage-item-title">
            <span class="storage-item-title-text">Local Files</span>
          </div>
        </div>
        <div class="remote-storages-container">
          <div class="storage-item" v-if="state.retrievingStorages">
            <div class="storage-item-title">
              <span class="storage-item-title-text">Retrieving storages ...</span>
            </div>
          </div>
          <button v-else v-for="storage of state.storages" class="storage-item" :class="{
      'item-google-drive': storage.provider === CLOUD_STORAGE.GOOGLE_DRIVE,
      'item-one-drive': storage.provider === CLOUD_STORAGE.ONE_DRIVE,
      'item-dropbox': storage.provider === CLOUD_STORAGE.DROPBOX_DRIVE,
      'item-box': storage.provider === CLOUD_STORAGE.BOX_DRIVE,
    }" @click="clickOnStorage(storage.id)">
            <div class="storage-item-title">
              <span class="storage-item-title-text">{{ storage.name }}</span>
              <TokenExpiredWarning v-if="storage.authenticationStatus === TOKEN_EXPIRED"
                class="storage-item-exclamation-mark" />
            </div>
            <div class="storage-item-buttons">
              <div class="item-button" @click.stop="editStorageName(storage)">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.958984 13.0417L4.50065 12.2083L12.2447 4.46425C12.5702 4.13881 12.5702 3.61118 12.2447 3.28574L10.7149 1.75592C10.3895 1.43048 9.86183 1.43048 9.5364 1.75592L1.79232 9.5L0.958984 13.0417Z"
                    stroke="#94A3B8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M13.0423 13.0417H8.45898" stroke="#94A3B8" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </div>
              <div class="item-button" @click.stop="removeStorage(storage)">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.375 1.625L1.625 10.375M1.625 1.625L10.375 10.375" stroke="#94A3B8" stroke-width="1.5"
                    stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
          </button>
        </div>
        <div class="storage-item-warning" v-if="thereIsExpiredTokens">
          <span class="storage-item-warning-message">Authentication token for one or more storage sources has expired.
            Please re-authenticate to restore your connection.</span>
        </div>
        <div class="storage-item item-add-cloud" @click="addNewStorage">
          <div class="storage-item-title">
            <span class="storage-item-title-text">Add Cloud Storage</span>
          </div>
        </div>
      </div>
    </div>

    <!-- file finder -->
    <CloudStorageFinder v-if="!!state.selectedStorage" :storage="state.selectedStorage" :projectId="projectId"
      @closeFinder="state.selectedStorage = null" @renameStorage="state.renamedStorage = state.selectedStorage"
      @removeStorage="removeStorage(state.selectedStorage)" />

    <!-- editing storage name -->
    <div class="upload-documents-overlay" v-if="!!state.renamedStorage" @click="state.renamedStorage = null">
      <EditStorageNameModal :initialValue="state.renamedStorage?.name" :storageType="state.renamedStorage?.provider"
        @dismissChanges="state.renamedStorage = null" @saveStorageName="saveStorageName" />
    </div>

    <!-- adding storage -->
    <div class="upload-documents-overlay" v-if="state.showAddStorageModal" @click.stop="closeAddStorageModal">
      <div class="add-cloud-storage-modal" @click.stop>
        <div class="add-modal-header">Add Cloud Storage</div>
        <div class="add-modal-body">
          <div v-for="provider of cloudStorageProviders" class="add-storage-item" :data-storage-type="provider.type"
            :class="{ inactive: provider.disabled }"
            @click.stop="!provider.disabled && authorizeCloudStorage(provider.type)">
            <div class="add-storage-item-icon" :class="{ [provider.className]: true }"></div>
            <div class="add-storage-item-title">{{ provider.title }}</div>
          </div>
        </div>
        <div class="add-modal-footer">
          <button @click.stop="closeAddStorageModal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.disabled {
  pointer-events: none;
  opacity: 0.5;
  background-color: #f2f2f2;
  color: #999;
}

.upload-documents {
  position: relative;

  .upload-documents-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;

    width: 157px;
    height: 36px;
    background: #334155;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
    }
  }

  .upload-documents-menu {
    position: absolute;
    display: flex;
    flex-direction: row;
    //overflow: hidden;

    width: 280px;
    right: 0;
    top: 40px;
    background: #FFFFFF;
    border: 1px solid rgba(51, 65, 85, 0.15);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 2;

    .storage-items-menu {
      position: relative;
      width: 100%;
      padding: 8px;
      margin-left: 0;
      transition: margin-left 0.25s ease-in-out;

      .remote-storages-container {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 330px;
      }

      .storage-item {
        width: 100%;
        height: 36px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 8px 8px 8px 44px;
        border-radius: 4px;
        border: none;
        gap: 6px;

        &:hover {
          background-color: #F8FAFC !important;
          cursor: pointer;
        }

        &:hover .storage-item-buttons {
          visibility: visible;
        }


        .storage-item-title {
          display: flex;
          align-items: center;
          gap: 5px;

          max-width: 160px;

          & .storage-item-title-text {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #334155;
            user-select: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .storage-item-buttons {
          display: flex;
          gap: 2px;
          visibility: hidden;

          .item-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            border-radius: 5px;

            &:hover {
              background-color: #E2E8F0;
            }

            &:active {
              transform: translate(1px, 1px);
            }
          }
        }

        &.item-local-files {
          background: url("../../../assets/images/clouds/local-files.png") no-repeat 8px center;
          background-size: 20px;
        }

        &.item-google-drive {
          background: url("../../../assets/images/clouds/google-drive.png") no-repeat 8px center;
          background-size: 20px;
        }

        &.item-one-drive {
          background: url("../../../assets/images/clouds/onedrive.png") no-repeat 8px center;
          background-size: 20px;
        }

        &.item-dropbox {
          background: url("../../../assets/images/clouds/dropbox.png") no-repeat 8px center;
          background-size: 20px;
        }

        &.item-box {
          background: url("../../../assets/images/clouds/box.png") no-repeat 8px center;
          background-size: 35px 20px;
        }

        &.item-add-cloud {
          background: url("../../../assets/images/clouds/plus.png") no-repeat 8px center;
          background-size: 20px;
        }
      }

      .storage-item-warning {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 15px 8px 15px 44px;
        border-radius: 4px;
        border: none;
        gap: 6px;

        background: #FFE4E6 url("../../../assets/images/clouds/warning.svg") no-repeat 12px center;
        background-size: 20px;

        .storage-item-warning-message {
          font-family: Inter, sans-serif;
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
          text-align: left;
          user-select: none;
          color: #F43F5E;
        }
      }
    }
  }

  .upload-documents-overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.12);
    z-index: 2;
  }

  .add-cloud-storage-modal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    position: absolute;
    width: 550px;
    //height: 368px;
    left: calc(50% - 550px/2);
    top: calc(35%);
    filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.15));
    border-radius: 12px;
    background: #FFFFFF;

    .add-modal-header {
      height: 60px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 24px;
      border-bottom: solid 1px #E2E8F0;
      border-radius: 12px 12px 0px 0px;

      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #334155;
    }

    .add-modal-body {
      width: 100%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;

      .add-storage-item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 8px;
        cursor: pointer;

        width: 100%;
        height: 60px;

        background: #FFFFFF;
        border-bottom: solid 1px #E2E8F0;

        &.inactive {
          filter: grayscale(100%);
          opacity: 0.4;
        }

        &:active {
          transform: translate(1px, 1px);
        }

        &:hover {
          background-color: #F8FAFC !important;
        }

        .add-storage-item-icon {
          width: 32px;
          height: 32px;
          background-position: center;
          background-size: contain;
        }

        .add-storage-item-title {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #334155;
          user-select: none;
        }
      }
    }

    .add-modal-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 16px;
      height: 68px;
      width: 100%;
      background: #F8FAFC;
      border-radius: 0px 0px 12px 12px;

      button {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        gap: 8px;

        width: 80px;
        height: 36px;
        background: #FFFFFF;
        border: 1px solid #E2E8F0;
        border-radius: 8px;
        cursor: pointer;

        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #334155;

        &:active {
          transform: translate(1px, 1px);
        }
      }
    }
  }

  .item-local-files {
    background: url("../../../assets/images/clouds/local-files.png") no-repeat;
  }

  .item-google-drive {
    background: url("../../../assets/images/clouds/google-drive.png") no-repeat;
  }

  .item-one-drive {
    background: url("../../../assets/images/clouds/onedrive.png") no-repeat;
  }

  .item-dropbox {
    background: url("../../../assets/images/clouds/dropbox.png") no-repeat;
  }

  .item-boxcloud {
    background: url("../../../assets/images/clouds/box.png") no-repeat;
  }

  .item-add-cloud {
    background: url("../../../assets/images/clouds/plus.png") no-repeat;
  }

}
</style>