<template>
  <div
    ref="cardContainer"
    class="project-card max-w-sm max-h-80 min-w-[325px] md:min-w-[330px]"
    :class="{ deleted: isDeleted }"
    @click="openProject"
  >
    <div class="card-preview">
      <svg width="94" height="93" viewBox="0 0 94 93" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40.86 68.383C38.1633 73.0758 35.7755 75.8628 34.9996 75.8628C34.4272 75.8628 33.9375 74.3488 33.9375 72.4732C33.9375 65.6185 38.216 55.3969 43.4737 45.4238C44.6262 47.4651 45.1761 50.4404 45.1761 54.493C45.1761 58.2517 43.5717 63.6525 40.86 68.383ZM60.4295 35.1569C56.2941 40.0756 54.1022 40.2338 52.3094 40.2338C51.1569 40.2338 48.852 39.6764 47.0894 38.8478C51.7897 30.6223 56.7988 23.1199 59.7667 18.5476C62.5838 14.2089 60.7685 11.7307 58.1773 11C57.3336 12.1901 49.3868 23.376 41.7714 36.8969C41.1688 36.6709 36.815 35.059 36.815 35.059C36.815 35.059 34.156 39.7141 37.636 41.3562C37.8017 41.439 38.0578 41.5671 38.4194 41.7328C38.5851 41.8081 38.7659 41.8985 38.9768 41.9889C33.1843 52.9187 28.3333 64.4661 28.3333 72.4732C28.3333 78.3636 31.4292 81 34.4949 81C36.4685 81 40.5285 79.7496 45.4473 71.3433C48.7917 65.6487 50.6975 59.3439 50.6975 54.493C50.6975 51.7662 49.9517 47.2843 48.8746 44.8588C49.8613 45.1451 51.3227 45.2053 51.5712 45.2129C55.2321 45.3786 58.3957 44.7232 61.9812 40.4221C63.6535 37.9364 61.9511 34.9309 60.4295 35.1494" fill="#CBD5E1"/>
      </svg>
    </div>
    <div class="card-info">
      <div class="card-actions-container">
        <div class="info-name-container">
          <span class="card-name" :title="name">{{ name }}</span>
          <div class="public-icon" v-if="IsPublic">
            <PublicIcon/>
          </div>
        </div>
        <FoliaContextMenu
          :userRole="userRole"
          :permissions="permissions"
          @menu-duplicate="$emit('duplicate',id)"
          @menu-reject="$emit('reject',id)"
          @menu-share="$emit('share', id)"
          @menu-rename="$emit('rename', id)"
          @menu-delete-project="$emit('remove',{id,name})"
        />
      </div>
      <div class="card-info-text">
        <span class="card-summary"> {{numFiles>0? numFiles>1?`${numFiles} Documents`:`${numFiles} Document`:'No Document'}}</span>
        <!-- <div class="dot"></div> -->
        <span class="card-summary ml-1">{{numFiles >= 1 || Date.parse(data.updatedAt) > Date.parse(data.createdAt) ? 'Last modified' : 'Created'}} {{ lastEdited }}</span>
      </div>
      <div class="card-shared-list">
        <AvatarStack :collaborators = "collaborators" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, onBeforeUnmount, onMounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import MoreActionsButton from "../../assets/vue-icons/more-actions-button.vue";
import {formattedTime} from "../common/utils.js";
import {SHARING_STATUS} from "../common/constants.js";
import AvatarStack from "../common/AvatarStack.vue";
import PublicIcon from "../../assets/vue-icons/public-icon.vue";
import FoliaContextMenu from "../common/FoliaContextMenu.vue";
import { BROADCAST_MESSAGE } from "../../core/constants.js";
import Folia_Drag_N_Drop from "../common/folia-drag-n-drop.js";
export default {
  name: "ProjectCard",
  components: {
    MoreActionsButton,
    PublicIcon,
    AvatarStack,
    FoliaContextMenu,
  },
  props: {
    data: Object,
  },
  setup(props) {
    const router = useRouter();
    const $fetch = inject('$fetch');
    const isActionMenuShown = ref(false);
    const cardContainer = ref(null);
    const folia_Drag_N_Drop = new Folia_Drag_N_Drop();

    const id = computed(() => {
      return props.data.id;
    });
    const userRole = computed(() => {
      return props.data.userRole
    })
    const IsPublic = computed(() => {
      return props.data.publicStatus === SHARING_STATUS.PUBLIC
    })
    const permissions = computed(() => {
      return props.data.permissions;
    })
    const name = computed(() => {
      return props.data.name;
    });
    const numFiles = computed(() => {
      return props.data.totalDocuments;
    });
    const collaborators = computed(() => {
      return props.data.collaborators || [];
    });
    const sharedLabel = computed(() => {
      return props.data.publicStatus.toLowerCase();
    });
    const lastEdited = computed(() => {
      if (Date.parse(props.data.updatedAt) > Date.parse(props.data.createdAt)) {
        return formattedTime(props.data.updatedAt)
      }
      return formattedTime(props.data.createdAt)
    });
    const isFavorite = computed(() => {
      return Math.round(Math.random() * 10000) % 3 === 0;
    });
    const isDeleted = computed(() => {
      return Boolean(props.data.deleted);
    });

    onMounted(() => {
      const {id, name, userRole } = props.data;
      folia_Drag_N_Drop.attach(cardContainer.value, id, name, userRole);
      $fetch.on(BROADCAST_MESSAGE.PROJECT_UPDATED, projectUpdated);
    })
    onBeforeUnmount(() => {
      folia_Drag_N_Drop.release();
      $fetch.off(BROADCAST_MESSAGE.PROJECT_UPDATED, projectUpdated);
    });
    watch(() => props.data, (newData, prevData) => {
      if (prevData.userRole !== newData.userRole) {
        folia_Drag_N_Drop.updateRole(newData.userRole);
      }
    });
    function projectUpdated(projectId) {
      if (projectId !== props.data.id) return;
      fetchCollaborators();
    }
    // TODO: check if it's using
    function fetchCollaborators() {
      $fetch.getProjectCollaborators(props.data.id)
        .then(({ collaborators, isOwner, publicStatus }) => {
        }).catch(console.error);
    }
    function openProject() {
      if (isDeleted.value) return;
      router.push("/p/" + props.data.id);
    }

    return {
      cardContainer, id, name, numFiles, sharedLabel, lastEdited, isFavorite, isDeleted,
      isActionMenuShown, collaborators, userRole, IsPublic, permissions,
      openProject,
    };
  },
};
</script>

<style lang="scss" scoped>
.project-card {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #FFFFFF;
  box-shadow: 0 1px 2px #e6e6e6;
  cursor: pointer;
  user-select: none;

  &:hover {
    box-shadow: 0 0 8px 4px #00000010;
  }

  .card-preview {
    border-radius: 12px 12px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #f1f5f9;
    height: 205px;
    width: 100%;
  }

  .card-info {
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px 16px;
    margin-top: 11px;

    .card-actions-container {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      .info-name-container {
        display: flex;
        flex-direction: row;
        justify-content:flex-start;
        .card-name {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          font-style: normal;
          max-width: 320px;
          height: 25px;
          white-space: nowrap;
          text-align:start;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #334155;
        }
        .public-icon {
          display: flex;
          align-items: center;
        }
      }
    }
    //.card-favorite-icon {
    //    position: absolute;
    //    right: 20px;
    //    color: silver;
    //}
    //.card-favorite-icon.selected {
    //    color: #f04e23;
    //}
    .card-info-text {
      display: flex;
      flex-direction: row;
      color: #334155;
      margin-top: -4px;
      align-items: center;
      text-align: center;
      .dot {
        height: 4px;
        width: 4px;
        margin-left: 8px;
        margin-right: 8px;
        background-color: #CBD5E1;
        border-radius: 50%;
        display: inline-block;
      }
      .action-menu-container {
        margin-left: 10px;
      }
    }
    .card-shared-list {
      width: 100%;
      height: 44px;
    }
    .card-summary {
      color: #64748B;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

/* Tablet (landscape) ----------- */
@media only screen  and (min-device-width : 768px)
and (max-device-width : 1024px)  {
  .project-card {
    // width: 335px;
    // max-width: 335px;
    // min-width: 335px;
    height: 309px;
    .card-info {
      width: 335px;
      .card-actions-container {
        .info-name-container {
          .card-name {
            max-width: 250px;
          }
        }
      }
    }
  }
}

.app-container {
  @media only screen and (max-width: 768px) {
    & .project-card{
    // max-width: 300px;
    // min-width: 300px;
    & .card-info {
        & .card-info-text{
          flex-direction: column;
          align-items: flex-start;
        }
      }
  }
}
}
</style>
