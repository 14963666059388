<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.4998 9.33341C11.8857 9.33341 12.4533 10.7653 12.6824 11.7974C12.8062 12.3548 12.3554 12.8334 11.7844 12.8334H11.1665M9.83314 6.83341C10.8457 6.83341 11.4998 6.0126 11.4998 5.00008C11.4998 3.98756 10.8457 3.16675 9.83314 3.16675M8.81202 12.8334H3.85427C3.47793 12.8334 3.18181 12.5212 3.2568 12.1524C3.46384 11.1342 4.14002 9.33341 6.33314 9.33341C8.52627 9.33341 9.20245 11.1342 9.40949 12.1524C9.48448 12.5212 9.18836 12.8334 8.81202 12.8334ZM8.16648 5.00008C8.16648 6.0126 7.34567 6.83341 6.33314 6.83341C5.32062 6.83341 4.49981 6.0126 4.49981 5.00008C4.49981 3.98756 5.32062 3.16675 6.33314 3.16675C7.34567 3.16675 8.16648 3.98756 8.16648 5.00008Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "shared-document-header-icon",
};
</script>

<style scoped>

</style>
