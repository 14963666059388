<template>
  <div class="dark-background">
    <div class="new-project-modal" ref="newProjectModalRef">
      <div class="modal-title">
        <span>New Project</span>
      </div>
      <div class="input-container">
        <div class="field-label" :class="{'active-field-label':nameProjectInputInFocus}">Project Name</div>
        <input ref="nameProjectInput" placeholder="Project Name" type="text" class="input-field" v-model="nameProject" />
      </div>
      <div class="input-container">
        <div class="description-field-label" :class="{'active-description-field-label':descriptionTextareaInFocus}">
          <span class="label">Project Description</span>
          <span class="info-label">Optional</span>
        </div>
        <textarea
          ref="descriptionTextarea"
          class="description-field"
          cols="20" rows="5"  maxlength="500"
          v-model="description"
        >
        </textarea>
      </div>
      <div class="button-container">
        <ActionButton
          class="cancel-btn"
          :width="'72px'"
          :height="'36px'"
          :text="'Cancel'"
          :fontColor="'#334155'"
          :background-color="'#FFFFFF'"
          @click="$emit('close')"
        />
        <ActionButton
          class="create-btn"
          :class="{'inactive-create-btn':!nameProject || isLoading }"
          :width="'130px'"
          :height="'36px'"
          :text="'Create Project'"
          :fontColor="'#FFFFFF'"
          :background-color="'#334155'"
          @click="createProject"
        />
        <Loader v-if="isLoading" class="mr-3"/>
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from "../common/ActionButton.vue";
import { inject, onBeforeUnmount, onMounted, ref } from "vue";
import ProjectService from '../../service/projectService';
import Loader from '../common/loaders/defaultLoader.vue';
export default {
  name: "NewProjectModal",
  components: {
    ActionButton,
    Loader
  },
  emits: ["fetchProject", "close"],
  setup(props,context) {
    const nameProject = ref('')
    const description = ref('')
    const descriptionTextarea = ref(null)
    const nameProjectInput = ref(null)
    const newProjectModalRef = ref(null)
    const descriptionTextareaInFocus = ref(false)
    const nameProjectInputInFocus = ref(false)
    const $fetch = inject('$fetch')
    const $auth0 = inject("$auth0");
    const projectService = new ProjectService();
    const isLoading = ref(false);
    onMounted(()=>{
      newProjectModalRef.value.addEventListener('focusin',focusIn);
      nameProjectInput.value.addEventListener('focusout',focusOutInput)
      descriptionTextarea.value.addEventListener('focusout',focusOutTextArea)
      nameProjectInput.value.focus()
    })
    onBeforeUnmount(()=>{
      newProjectModalRef.value.removeEventListener('focusin',focusIn);
      nameProjectInput.value.removeEventListener('focusout',focusOutInput)
      descriptionTextarea.value.removeEventListener('focusout',focusOutTextArea)
    })
    function  focusOutInput() {
      nameProjectInputInFocus.value = false
    }
    function focusOutTextArea() {
      descriptionTextareaInFocus.value = false
    }
    function  focusIn(e) {
      if (e.target === nameProjectInput.value) {
        descriptionTextareaInFocus.value = false
        nameProjectInputInFocus.value = true
      }
      if (e.target ===  descriptionTextarea.value) {
        nameProjectInputInFocus.value = false
        descriptionTextareaInFocus.value = true
      }
    }
    async function createProject() {
      if(nameProject.value) {
        // $fetch.createProject(nameProject.value, description.value).then(() => {
        //   context.emit("close");
        //   context.emit("fetchProject");
        // })
        isLoading.value = true;
        await projectService.createProject({name:nameProject.value,description:description.value});
        isLoading.value = false;
        context.emit("close");
        context.emit("fetchProject");        

      }
      return false
    }
    return {
      nameProject,
      createProject,
      description,
      descriptionTextareaInFocus,
      nameProjectInputInFocus,
      newProjectModalRef,
      descriptionTextarea,
      nameProjectInput,
      isLoading
    }
  }
};
</script>

<style lang="scss" scoped>
  .dark-background {
    position: fixed;
    z-index: 200;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.25);

    .new-project-modal {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      border-radius: 12px;
      background-color: white;
      width: 550px;
      padding: 24px 24px 16px 24px;
      height: 400px;

      .modal-title {
        color: #334155;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
      }
      .input-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .field-label {
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #64748B;
        }
        .active-field-label {
          color: #2990FF;
        }
        .description-field-label {
          display: flex;
          width: 100%;
          flex-direction: row;
          justify-content: space-between;

          .label {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #64748B;
          }

          .info-label {
            font-weight: 600;
            font-size: 10px;
            line-height: 12px;
            color: #94A3B8;
          }
        }
        .active-description-field-label {
          .label {
            color: #2990FF;
          }
        }
        .input-field {
          background: #F1F5F9;
          width: 100% !important;
          height: 44px;
          margin-top: 8px;
          margin-bottom: 20px;
          box-sizing: border-box;
          padding: 0 6px;
          border-radius: 6px;
          border: 0;

          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #334155;
          font-family: inherit;

          &:focus {
            outline: none;
            border: 2px solid #2990FF;
          }
        }
        .description-field {
          width: 100%;
          max-width: 500px;
          min-width: 460px;
          height: 100%;
          margin-top: 8px;
          margin-bottom: 20px;
          padding: 8px;
          background: #F1F5F9;
          box-sizing: border-box;
          border-radius: 6px;
          border: 0;
          resize: none;

          font-family: inherit;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #334155;

          &:focus {
            outline: none;
            border: 2px solid #2990FF;
          }
        }
      }
      & > .input-container {
        margin-top: 16px;
      }
      & > .input-container ~ .input-container {
        margin-top: 16px;
      }
      .button-container {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: #F8FAFC;
        display: flex;
        flex-direction: row;
        align-items:center;
        justify-content: flex-end;
        height: 80px;
        border-radius: 0 0 12px 12px;
        .cancel-btn {
          border: 1px solid #E2E8F0;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          border-radius: 8px;
        }
        .create-btn {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          border-radius: 8px;
        }
        .inactive-create-btn {
          background: #E2E8F0 !important;
          color: #64748B !important;
          cursor: default;
        }
      }
    }
  }

  .app-container {
  @media only screen and (max-width: 768px) {
    & .new-project-modal{
    max-width: 300px;
    & .description-field{
      max-width: 300px;
      min-width: 200px !important;
    }
    }

  }
}
</style>
