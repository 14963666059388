<template>
  <div class="context-menu-container" ref="contextMenuContainer" @blur="showContextMenu = false">
    <div class="context-menu-overlay" v-if="showContextMenu" @scroll="showContextMenu = false"
      @click.stop="showContextMenu = false" />
    <div class="context-menu-button" ref="contextMenuButton" @click.stop="showContextMenu = !showContextMenu">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.5 7C12.5 7.27614 12.2761 7.5 12 7.5C11.7239 7.5 11.5 7.27614 11.5 7C11.5 6.72386 11.7239 6.5 12 6.5C12.2761 6.5 12.5 6.72386 12.5 7Z"
          stroke="#334155" />
        <path
          d="M12.5 12C12.5 12.2761 12.2761 12.5 12 12.5C11.7239 12.5 11.5 12.2761 11.5 12C11.5 11.7239 11.7239 11.5 12 11.5C12.2761 11.5 12.5 11.7239 12.5 12Z"
          stroke="#334155" />
        <path
          d="M12.5 17C12.5 17.2761 12.2761 17.5 12 17.5C11.7239 17.5 11.5 17.2761 11.5 17C11.5 16.7239 11.7239 16.5 12 16.5C12.2761 16.5 12.5 16.7239 12.5 17Z"
          stroke="#334155" />
      </svg>
    </div>
    <div class="context-menu-actions-items" ref="contextMenu"
      :class="{ 'context-menu-actions-items-table': isTableView }" v-if="showContextMenu">
      <div v-if="canShare" class="context-menu-actions-item" @click.stop="$emit('menu-share'); showContextMenu = false">
        <share-icon />
        <span class="context-menu-actions-item-text">Share Project</span>
      </div>
      <div v-if="canCopyDocument" class="context-menu-actions-item" @click.stop="$emit('menu-copy-document');
  showContextMenu = false">
        <duplicate-icon />
        <span class="context-menu-actions-item-text">Copy to Project</span>
      </div>
      <!-- <div v-if="canDownloadDocument" class="context-menu-actions-item" @click.stop="downloadFile">
        <download-icon />
        <span v-if="downloadFileStatus()" class="context-menu-actions-item-text">Downloading ...</span>
        <span v-else class="context-menu-actions-item-text">Download Document</span>

      </div> -->
      <div @mouseover="adjustSubmenuPosition" v-if="canDownloadDocument"
        class="context-menu-actions-item relative group">
        <download-icon />
        <span v-if="downloadFileStatus()" class="context-menu-actions-item-text">Downloading ...</span>
        <span v-else class="context-menu-actions-item-text cursor-pointer">Download Document</span>
        <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="chevron">
            <path id="icon" d="M6.00012 12L10.0001 8L6.00012 4" stroke="#64748B" stroke-width="1.5"
              stroke-linecap="round" stroke-linejoin="round" />
          </g>
        </svg>
        <div
          :class="['absolute top-0 w-40 bg-white hidden group-hover:block transition-all duration-300 ease-in-out transform p-1 pl-2 shadow-lg rounded-md', submenuClass]">
          <ul>
            <li @click.stop="downloadFile({ flatten: true, original: false })" class="py-1 hover:bg-gray-100">Download
              printable PDF</li>
            <li @click.stop="downloadFile({ flatten: false, original: false })" class="py-1 hover:bg-gray-100">Download
              editable PDF</li>
            <li @click.stop="downloadFile({ original: true })" class="py-1 hover:bg-gray-100">Download original file {{
    fileType ? `(${fileType})` : '' }}</li>
          </ul>
        </div>
      </div>




      <div v-if="canProjectDetailsShown" class="context-menu-actions-item"
        @click.stop="$emit('menu-document-details'); showContextMenu = false">
        <detailsIcon />
        <span class="context-menu-actions-item-text">View Details</span>
      </div>
      <div v-if="canRename" class="context-menu-actions-item"
        @click.stop="$emit('menu-rename'); showContextMenu = false">
        <edit-icon />
        <span class="context-menu-actions-item-text">Edit Project Details</span>
      </div>
      <div v-if="canRenameDocument" class="context-menu-actions-item"
        @click.stop="$emit('menu-rename'); showContextMenu = false">
        <edit-icon />
        <span class="context-menu-actions-item-text">Rename Document</span>
      </div>
      <div v-if="canDuplicate" class="context-menu-actions-item"
        @click.stop="$emit('menu-duplicate'); showContextMenu = false">
        <duplicate-icon />
        <span class="context-menu-actions-item-text">Duplicate Project</span>
      </div>
      <div v-if="canDeleteDocument" class="context-menu-actions-item" style="color: red"
        @click.stop="$emit('menu-delete-document'); showContextMenu = false">
        <delete-icon />
        <span class="context-menu-actions-item-text" style="color: red">Delete Document</span>
      </div>
      <div v-if="canDeleteProject" class="context-menu-actions-item" style="color: red"
        @click.stop="$emit('menu-delete-project'); showContextMenu = false">
        <delete-icon />
        <span class="context-menu-actions-item-text" style="color: red">Delete Project</span>
      </div>
      <div v-if="canReject" class="context-menu-actions-item" style="color: red"
        @click.stop="$emit('menu-reject'); showContextMenu = false">
        Reject share
      </div>
    </div>
  </div>
</template>

<script>
import deleteIcon from "../../assets/vue-icons/context-menu-icon/delete-icon.vue";
import InfoIcon from "../../assets/vue-icons/info-icon.vue";
import shareIcon from "../../assets/vue-icons/context-menu-icon/share-icon.vue";
import detailsIcon from "../../assets/vue-icons/details-icon.vue";
import downloadIcon from "../../assets/vue-icons/context-menu-icon/download-icon.vue";
import editIcon from "../../assets/vue-icons/context-menu-icon/edit-icon.vue";
import duplicateIcon from "../../assets/vue-icons/context-menu-icon/duplicate-icon.vue";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { getters } from "../../store/index.js";

export default {
  name: "FoliaContextMenu",
  props: [
    'isTableView', 'permissions', 'userRole',
    'permissionsDocuments', 'notListView', "documentId", "fileType"
  ],
  components: {
    deleteIcon, shareIcon, InfoIcon, downloadIcon, editIcon,
    detailsIcon, duplicateIcon,
  },
  setup(props, { emit }) {
    const showContextMenu = ref(false);
    const contextMenu = ref(null);
    const contextMenuButton = ref(null)
    const route = useRoute();
    const contextMenuContainer = ref(null);
    const submenuClass = ref('left-full translate-x-0 origin-top-left')
    function downloadFile(options) {
      emit("download", options);
      showContextMenu.value = false;
    }

    function downloadFileStatus() {
      return getters.downloadFileStatus(props.documentId);
    }

    watch(contextMenu, () => {
      const gridView = document.querySelector('.project-view-grid')
        ?? document.querySelector('.document-container-grid');
      const tableView = document.querySelector('.document-container-table')
        ?? document.querySelector('.project-view-table');

      if (!gridView && tableView && !props.notListView) {
        //calculates and set position for table view data(documents,projects)
        const contextMenuContainerRect = contextMenuContainer.value.getBoundingClientRect()
        const contextMenuStyle = contextMenu.value?.style;
        const contextMenuRect = contextMenu.value?.getBoundingClientRect();
        const tableViewRect = tableView?.getBoundingClientRect();
        if (contextMenuStyle) {
          contextMenuStyle.left = `${contextMenuContainerRect?.left}px`;
          contextMenuStyle.top = `${(contextMenuContainerRect?.top - 45) + contextMenuContainerRect?.height}px`;
          const isOffBottom = contextMenuRect?.bottom > tableViewRect?.bottom;
          if (isOffBottom) {
            let top = contextMenuRect?.top;
            top = ((contextMenu.value.clientHeight + contextMenu.value.offsetTop) - contextMenu.value.clientHeight);
            top -= contextMenu.value.clientHeight - contextMenuButton.value.clientHeight;
            contextMenuStyle.top = `${top}px`;
          }
        }
      }
      if (props.notListView) {
        //calculates and set position for inside project context menu
        const contextMenuStyle = contextMenu.value?.style;
        if (contextMenuStyle) {
          const parentElement = contextMenu.value?.parentNode;
          contextMenuStyle.top = `${(contextMenuButton.value.clientHeight + 9)}px`
          contextMenuStyle.left = `${-(contextMenu.value.clientWidth - parentElement.offsetWidth)}px`
        }
      }

      const gridViewRect = gridView?.getBoundingClientRect();
      const contextMenuRect = contextMenu.value?.getBoundingClientRect();

      const isOffRight = contextMenuRect?.right > gridViewRect?.right;
      const isOffBottom = contextMenuRect?.bottom > gridViewRect?.bottom;

      let left = contextMenuRect?.left;
      let top = contextMenuRect?.top;

      if (isOffRight) {
        // if contextMenu get off over right  main grid side
        left = ((contextMenu.value.clientWidth + contextMenu.value.clientLeft) - contextMenu.value.clientWidth);
        left -= contextMenu.value.clientWidth - contextMenuButton.value.clientWidth;
        contextMenu.value.style.left = `${left}px`;
      }
      if (isOffBottom) {
        // if contextMenu get off over bottom main grid side
        top = ((contextMenu.value.clientHeight + contextMenu.value.offsetTop) - contextMenu.value.clientHeight);
        top -= contextMenu.value.clientHeight - contextMenuButton.value.clientHeight;
        contextMenu.value.style.top = `${top}px`;
      }
    });

    const canShare = computed(() => {
      return props.permissions?.includes('SHARE_PROJECT') && !props.notListView;
    });

    const canRename = computed(() => {
      return props.permissions?.includes('RENAME_PROJECT') && route.name !== 'global-search'
    });

    const canRenameDocument = computed(() => {
      return props.permissionsDocuments?.includes('RENAME_DOCUMENT') && route.name !== 'global-search'
    });

    const canArchive = computed(() => {
      return props.permissions?.includes('ARCHIVE_PROJECT')
    });

    const canProjectDetailsShown = computed(() => {
      return (route.name === 'project' || route.name === 'global-search') && !props.notListView && props.permissionsDocuments
    });

    const canDuplicate = computed(() => {
      return props.permissions?.includes('DUPLICATE_PROJECT') && route.name !== 'global-search'
    });

    const canDeleteProject = computed(() => {
      return props.permissions?.includes('DELETE_PROJECT')
    });

    const canDeleteDocument = computed(() => {
      return props.permissionsDocuments?.includes('DELETE_DOCUMENT')
    });

    const canCopyDocument = computed(() => {
      return props.permissionsDocuments?.includes('COPY_DOCUMENT') && route.name !== 'global-search'
    });

    const canDownloadDocument = computed(() => {
      return props.permissionsDocuments?.includes("DOWNLOAD_ANNOTATED_DOCUMENT" || "DOWNLOAD_SOURCE_DOCUMENT" || "DOWNLOAD_ORIGINAL_DOCUMENT")
    });

    const canReject = computed(() => {
      return props.userRole !== 'OWNER' && !props.permissionsDocuments
    });
    const adjustSubmenuPosition = (event) => {
      const boundingRect = event.currentTarget.getBoundingClientRect();
      const windowWidth = window.innerWidth;

      if (boundingRect.right + 160 > windowWidth) { // 160px is the approx width of the submenu
        submenuClass.value = 'right-full translate-x-[8%] origin-top-right z-20'; // switch to left-side display
      } else {
        submenuClass.value = 'left-full translate-x-0 origin-top-left'; // default to right-side display
      }
    }
    return {
      showContextMenu,
      canShare,
      canRename,
      canDeleteProject,
      canDeleteDocument,
      canCopyDocument,
      canRenameDocument,
      canDownloadDocument,
      canReject,
      canProjectDetailsShown,
      contextMenuButton,
      contextMenuContainer,
      contextMenu,
      canDuplicate,
      canArchive,
      submenuClass,
      downloadFileStatus,
      downloadFile,
      adjustSubmenuPosition
    }
  }
}
</script>

<style scoped lang="scss">
.context-menu-container {
  display: flex;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border-radius: 6px;

  .context-menu-overlay {
    position: fixed;
    cursor: auto;
    left: 0;
    top: 0;
    right: 0;
    z-index: 950;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .context-menu-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    width: 34px;
    height: 34px;
  }

  .context-menu-button:hover {
    background-color: #f1f1f1;
  }

  .context-menu-actions-items {
    display: flex;
    position: absolute;
    width: 216px !important;
    flex-direction: column;
    z-index: 1000 !important;
    background-color: white;
    color: black;
    border-radius: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 1px solid rgba(51, 65, 85, 0.15);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    animation: fadeIn .3s;
    -webkit-animation: fadeIn .3s;

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    @-moz-keyframes fadeIn {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    .context-menu-actions-item {
      display: flex;
      width: calc(100% - 14px);
      margin-left: 8px;
      padding: 10px 10px 10px 10px;
      height: 36px;
      z-index: 100;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      align-items: center;
      justify-content: flex-start;
      text-align: start;
      border-radius: 2px;
      gap: 5px;

      .context-menu-actions-item-text {
        color: #334155;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }

      &:hover {
        background-color: #F8FAFC;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }

  .context-menu-actions-items-table {
    position: fixed;
    margin-left: -165px;
  }
}
</style>
