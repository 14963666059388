<script>
import { computed, inject, onMounted, ref } from "vue";
import ArrowBottomBtn from "../../assets/vue-icons/arrow-bottom-btn.vue";
import moment from "moment/moment.js";
import { useRoute } from "vue-router";
import { ICON_BY_TYPE } from "../common/constants.js";
import DownloadButton from "./DownloadButton.vue";
import ProjectService from "../../service/projectService";
import { GET_CACHE } from "../../store/cacheLayer";
export default {
  name: "DocumentDetails",
  props: [
    "documentId",
    "userRole",
    "project"
  ],
  emits: ["close", "downloadDocument"],
  components:{ DownloadButton, ArrowBottomBtn },
  setup(props) {
    const $fetch = inject('$fetch');
    const route = useRoute();
    const documentInfo = ref({});
    const ownerName = ref('');
    const previewImage = ref('');
    const isPreviewLoading = ref(true);
    const showDocumentDetails = ref(false);

    const sourceSize = computed(() => {
      const size = documentInfo.value.sourceSize
      if (!size) {
        return null;
      } else if (size > 0 && size <= 1_000_000) {
        return `${(size / 1000).toFixed(2)} Kb`;
      } else if (size > 1_000_000) {
        return `${(size / 1000 / 1000).toFixed(2)} Mb`;
      } else {
        return size;
      }
    });
    const isThereSource = computed(() => {
      return Boolean(documentInfo.value?.webViewLink);
    });
    const canViewSource = computed(()=>{
      return props.userRole !== 'VIEWER'
    })
    onMounted(async () => {
      isPreviewLoading.value= true;
      const foundADoc = GET_CACHE.getCachedDocuments().some(d=>{
        if(d.id === props.documentId){
          const {name = '', id = '', createdAt, webViewLink= '', originMimeType = '', updatedAt, uploadedBy='', sourceSize:sz}=d;
          const sourceSize = formatFileSize(sz);
          documentInfo.value ={name, id, createdAt, webViewLink, originMimeType, updatedAt, uploadedBy, sourceSize} 

          previewImage.value = `url("${d.preview}")`;
          return true
        }
        return false;
      });
      await fetchDocumentInfo();
      // await fetchPreview();
      // getOwnerName(documentInfo.value?.collaboratorEmail,route.params.projectId)
      //   .then(name => {
      //     ownerName.value = name
      //   })
      // const {project:{ownerProfile:{email, name}}={}} = props;
      // ownerName.value = name;
    })

    async function getOwnerName(ownerEmail, projectId) {
      const { collaborators } = await $fetch.getProjectCollaborators(projectId);
      const collaborator = collaborators.find(person => person.email === ownerEmail);
      return collaborator?.profile?.username || ownerEmail;
    }
    function formattedDate(date) {
      // return moment(date).format('LLL');
      return `${moment(date).format("MMM DD")}, at ${moment(date).format("hh:mm A")}`;
    }
    function getDocumentType(docType) {
      return ICON_BY_TYPE[docType]?.title || "PDF"
    }
    function formatFileSize (fileSizeInBytes){
      const size = fileSizeInBytes
      if (!size) {
        return null;
      } else if (size > 0 && size <= 1_000_000) {
        return `${(size / 1000).toFixed(2)} Kb`;
      } else if (size > 1_000_000) {
        return `${(size / 1000 / 1000).toFixed(2)} Mb`;
      } else {
        return size;
      }
    }
    async function fetchDocumentInfo() {
      const {preview,id, name, createdAt, webViewLink , originMimeType,updatedAt, uploadedBy, source =null, sourceSize:sz} = await new ProjectService().getDocument({documentId:props.documentId});
      previewImage.value =  `url("${preview}")`;
      const sourceSize = formatFileSize(sz);
      const payload = {name, id, createdAt, webViewLink, originMimeType, updatedAt, uploadedBy, sourceSize} 
      if(source){
        const SourceList = {ONE_DRIVE:'One drive', GOOGLE_DRIVE:'Google drive', BOX:'Box',DROPBOX:'Dropbox',LOCAL:'Local file'}
        payload['source'] = SourceList[source];
      }
      documentInfo.value = payload;
    }
    async function  fetchPreview() {
      // await $fetch
      //   .getDocumentPreview(props.documentId || route.params.documentId)
      //   .then((base64preview) => {
      //     previewImage.value = `url("${base64preview}")`;
      //   })
      //   .catch(console.error);
    }
    function gotoSource() {
      window.open(documentInfo.value?.webViewLink, "_new");
    }

    return {
      showDocumentDetails, previewImage, ownerName, documentInfo, sourceSize,
      isThereSource,isPreviewLoading,
      canViewSource,
      formattedDate, getDocumentType, gotoSource,
    }
  }
};
</script>

<template>
  <div class="right-side-bar-document-details-overlay" @click="$emit('close')"/>
  <div class="right-side-bar-document-details">
    <div class="header-document-details">
      <span class="header-title">Details</span>
      <div class="close-icon" @click="$emit('close')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.25 6.75L6.75 17.25M6.75 6.75L17.25 17.25" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    <div class="doc-info-content">
      <div class="preview-container">
        <div class="doc-info-previewImage">
          <div class="doc-info-image" :style="{ backgroundImage: previewImage }" />
        </div>
      </div>
      <div class="doc-filename">
        <span>{{documentInfo.name}}</span>
      </div>
      <div class="doc-info-values">
        <div class="doc-info-label">Uploaded By</div>
        <div class="doc-info-value">{{documentInfo.uploadedBy}}</div>
        <div class="doc-info-label">Date uploaded</div>
        <div class="doc-info-value">{{formattedDate(documentInfo.createdAt) ? formattedDate(documentInfo.createdAt) : 'No data' }}</div>
        <div class="doc-info-label">Last modified</div>
        <div class="doc-info-value">{{documentInfo.updatedAt ? formattedDate(documentInfo.updatedAt): 'No Updates'}}</div>
        <div class="doc-info-label">File type</div>
        <div class="doc-info-value">{{getDocumentType(documentInfo.originMimeType)}}</div>
        <div class="doc-info-label">Size</div>
        <div class="doc-info-value">{{documentInfo.sourceSize || 'No data'}}</div>
        <div v-if="canViewSource">
          <div class="doc-info-label">Source</div>
          <div class="doc-info-value">{{ documentInfo.source }}</div>
          <div class="doc-info-value">{{ documentInfo.webViewLink }}</div>
        </div>

<!--        <div class="doc-info-label">Source</div>-->
<!--        <div class="doc-info-value">{{'No data'}}</div>-->
      </div>
      <div class="doc-info-buttons">
        <DownloadButton
          :userRole="userRole"
          :documentId="documentId"
          :fileName ="documentInfo.name"
          @downloadFlattenedPDF="$emit('downloadDocument', documentId, true)"
          @downloadEditedPDF="$emit('downloadDocument', documentId, false)"
        />
        <div class="doc-info-button"
             v-if="isThereSource"
             @click.stop="gotoSource"
        >Go to Source file</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.right-side-bar-document-details-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 300px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 100;
}
.right-side-bar-document-details {
  position: fixed;
  display: flex;
  flex-direction: column;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 320px;
  z-index: 1000;
  background: #FFFFFF;
  box-shadow: -4px 0px 8px rgba(51, 65, 85, 0.15);

  .header-document-details {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    background: #F8FAFC;

    .header-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #334155;
    }
    .close-icon {
      cursor: pointer;
    }
  }
  .doc-info-content {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    align-self: center;
    text-align: start;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    //padding: 0 20px;
    overflow-y: auto;
    overflow-x: hidden;

    .preview-container {
      margin: 16px;
      background-color: white;
      border: 1px solid #E2E8F0;
      width: 288px;
      height: 198px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      .doc-info-previewImage {
        border: solid 2px transparent;
        position: relative;
        width: 272px;
        height: 182px;
        min-width: 272px;
        min-height: 182px;
        background: rgba(197, 200, 202, 0.15);
        transition: background 0.25s;
        border-radius: 6px;
        mix-blend-mode: normal;
        margin: 16px;
        display: flex;
        padding: 8px;
        justify-content: center;
        align-items: center;
        .doc-info-image {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #f57849;
          width: 256px;
          height: 166px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
    .doc-filename {
      padding: 0 20px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #334155;
      text-align: start;
      white-space: pre-line;
      word-break: break-word;
      word-wrap: break-word;
      width: 100%;
    }
    .doc-info-values {
      padding: 0 20px;
      width: 100%;
      margin-top: 16px;
      .doc-info-label {
        width: 100%;
        margin-bottom: 2px;
        font-family: Inter, serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #64748B;
      }
      .doc-info-value {
        width: 100%;
        margin-bottom: 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #334155;
      }
    }
    .doc-info-buttons {
      position: sticky;
      left: 0;
      bottom: 0;
      width: 100%;
      background: #F8FAFC;
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: auto;
      justify-content: center;
      align-items: center;
      padding: 20px;

      .doc-info-button {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 8px;
        cursor: pointer;

        width: 100%;
        height: 36px;

        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #334155;
        user-select: none;

        background: #FFFFFF;
        border: 1px solid #E2E8F0;
        border-radius: 8px;

        &:active {
          transform: translate(1px, 1px);
        }
      }
    }
  }
}
</style>
