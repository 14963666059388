<script>
import { nextTick, onMounted, ref } from "vue";

export default {
  name: "SingleLineEditor",
  props: ["modelValue", "label", "focus", "placeholderText", "maxLength"],
  emits: ["update:modelValue", "on-enter", "on-escape"],
  setup(props, { emit }) {
    const inputEl = ref(null);
    const focused = ref(false);

    onMounted(() => {
      if (props.focus) {
        nextTick(() => {
          inputEl.value.focus();
        });
      }
    });

    return { inputEl, focused };
  }
}
</script>

<template>
  <label class="single-line-editor-label" :class="{ focused: focused }">
    {{label || 'empty label'}}
    <input ref="inputEl"
           type="text"
           class="single-line-editor"
           :placeholder="placeholderText"
           :value="modelValue"
           :maxlength="maxLength"
           @input="$emit('update:modelValue', $event.target.value)"
           @keydown.stop.enter="$emit('on-enter')"
           @keydown.stop.esc="$emit('on-escape')"
           @focusin="focused = true"
           @focusout="focused = false"
    >
  </label>
</template>

<style scoped lang="scss">
.single-line-editor-label {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #64748B;

  &.focused {
    color: #2990FF;
  }

  .single-line-editor {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #334155;
    background: #F1F5F9;
    width: 100%;
    height: 44px;
    margin-top: 8px;
    margin-bottom: 20px;
    box-sizing: border-box;
    padding: 0 8px;
    outline: none;
    border: 2px solid transparent;
    border-radius: 6px;

    &:focus {
      border: 2px solid #2990FF;
    }
  }
}
</style>