import { reactive } from "vue";

function loadState() {
  try {
    const stored = JSON.parse(localStorage.getItem("left-panel"));
    if (!stored) return;
    for (const [key, value] of Object.entries(stored)) {
      leftSidePanelState[key] = value;
    }
  } catch (e) {}
}

function saveState() {
  localStorage.setItem("left-panel", JSON.stringify(leftSidePanelState));
}

const leftSidePanelState = reactive({
  pinned: false,
  favoriteDocumentsCollapsed: false,
  documentsCollapsed: false,
  fromProject: null,
});

loadState();

export default {
  // ------------------------ getters ------------------------
  get isPanelPinned() {
    return leftSidePanelState.pinned;
  },
  get favoriteDocumentsCollapsed() {
    return leftSidePanelState.favoriteDocumentsCollapsed;
  },
  get documentsCollapsed() {
    return leftSidePanelState.documentsCollapsed;
  },

  get fromProject() {
    return leftSidePanelState.fromProject;
  },
  // ------------------------ setters ------------------------
  pinLeftSidePanel() {
    leftSidePanelState.pinned = true;
    saveState();
  },
  unpinLeftSidePanel() {
    leftSidePanelState.pinned = false;
    saveState();
  },
  expandDocumentsList() {
    leftSidePanelState.documentsCollapsed = false;
    saveState();
  },
  collapseDocumentsList() {
    leftSidePanelState.documentsCollapsed = true;
    saveState();
  },
  expandFavoriteDocumentsList() {
    leftSidePanelState.favoriteDocumentsCollapsed = false;
    saveState();
  },
  collapseFavoriteDocumentsList() {
    leftSidePanelState.favoriteDocumentsCollapsed = true;
    saveState();
  },

  setFromProject(projectId) {
    leftSidePanelState.fromProject = projectId;
  },
};
