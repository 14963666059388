<script>
export default {
  props: ["displaySyncStatus"],
  emits: ["sync"],
  setup(props, { emit }) {

    function onClick() {
      if (props.displaySyncStatus !== "DONE") return;
      emit("sync");
    }

    return {
      onClick
    }
  }
}
</script>

<template>
  <div class="sync-status"
       :class="{
          'status-done': displaySyncStatus === 'DONE',
          'status-offline': displaySyncStatus === 'OFFLINE',
          'status-failed': displaySyncStatus === 'FAILED',
          'status-syncing': displaySyncStatus === 'SYNCING',
        }"
       @click="onClick"
  >
    <span class="status-title" v-if="displaySyncStatus === 'OFFLINE'">Offline</span>
    <span class="status-title" v-if="displaySyncStatus === 'FAILED'">Sync failed</span>
    <span class="status-title" v-if="displaySyncStatus === 'SYNCING'">Syncing...</span>
  </div>
</template>

<style scoped lang="scss">
.sync-status {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 5px;
  border-radius: 12px;
  height: 24px;
  width: 100px;
  gap: 5px;
  border: solid 1px #E2E8F0;
  //background-color: rgba(182, 182, 215, 0.1);

  &.status-done {
    cursor: pointer;
    border: 1px solid transparent;
    background-image: url("data:image/svg+xml, %3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='16' rx='8' fill='%2394A3B8'/%3E%3Cpath d='M5.16602 8.50065L6.66602 10.1673L10.8327 5.83398' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-size: 16px;
    background-position: 4px center;
    background-repeat: no-repeat;
  }
  &.status-offline {
    background-image: url("data:image/svg+xml, %3Csvg width='12' height='11' viewBox='0 0 12 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.33268 8.99935C6.33268 9.18344 6.18344 9.33268 5.99935 9.33268C5.81525 9.33268 5.66602 9.18344 5.66602 8.99935C5.66602 8.81525 5.81525 8.66602 5.99935 8.66602C6.18344 8.66602 6.33268 8.81525 6.33268 8.99935Z' fill='%23141414' stroke='%2364748B' stroke-width='1.5'/%3E%3Cpath d='M4.33268 6.70912C4.80038 6.36837 5.37639 6.16732 5.99935 6.16732C6.62231 6.16732 7.19831 6.36837 7.66602 6.70912M2.85775 4.48577C3.46039 4.06564 4.1511 3.76308 4.89663 3.61133M7.4523 3.69464C8.06444 3.86186 8.63402 4.13218 9.14122 4.48577M1.16602 2.50065C2.48882 1.57796 3.99935 0.833984 5.99935 0.833984C6.45766 0.833984 6.89026 0.873052 7.30079 0.944384M9.5563 1.71968C10.0051 1.95244 10.4277 2.21817 10.8327 2.50065M10.166 0.833984L2.49935 8.50065' stroke='%2364748B' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");    background-size: 16px;
    background-position: 4px center;
    background-repeat: no-repeat;
  }
  &.status-syncing {
    background-image: url("data:image/svg+xml, %3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM1.90413 8C1.90413 11.3667 4.63335 14.0959 8 14.0959C11.3667 14.0959 14.0959 11.3667 14.0959 8C14.0959 4.63335 11.3667 1.90413 8 1.90413C4.63335 1.90413 1.90413 4.63335 1.90413 8Z' fill='url(%23paint0_angular_19408_110976)'/%3E%3Cdefs%3E%3CradialGradient id='paint0_angular_19408_110976' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(8 8) rotate(90) scale(8)'%3E%3Cstop stop-color='%232563EB' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%232563EB'/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    background-size: 16px;
    background-position: 4px center;
    background-repeat: no-repeat;
  }
  &.status-failed {
    border: 1px solid #F04E23;
    background-image: url("data:image/svg+xml, %3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.30135 8.90171L4.81003 1.9037C5.30197 0.922524 6.70249 0.92278 7.19407 1.90414L10.6995 8.90215C11.1436 9.78874 10.499 10.8326 9.50739 10.8326H2.49326C1.50144 10.8326 0.856817 9.78833 1.30135 8.90171Z' fill='%23FCDCD3'/%3E%3Cpath d='M6.33322 8.66598C6.33322 8.85008 6.18398 8.99932 5.99988 8.99932C5.81579 8.99932 5.66655 8.85008 5.66655 8.66598C5.66655 8.48189 5.81579 8.33265 5.99988 8.33265C6.18398 8.33265 6.33322 8.48189 6.33322 8.66598Z' fill='%23FCDCD3'/%3E%3Cpath d='M5.99988 4.66598V6.66598M4.81003 1.9037L1.30135 8.90171C0.856817 9.78833 1.50144 10.8326 2.49326 10.8326H9.50739C10.499 10.8326 11.1436 9.78874 10.6995 8.90215L7.19407 1.90414C6.70248 0.92278 5.30197 0.922524 4.81003 1.9037ZM6.33322 8.66598C6.33322 8.85008 6.18398 8.99932 5.99988 8.99932C5.81579 8.99932 5.66655 8.85008 5.66655 8.66598C5.66655 8.48189 5.81579 8.33265 5.99988 8.33265C6.18398 8.33265 6.33322 8.48189 6.33322 8.66598Z' stroke='%23F04E23' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    background-size: 16px;
    background-position: 4px center;
    background-repeat: no-repeat;
  }

  .status-title {
    position: absolute;
    left: 30px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #334155;

    &.status-failed {
      color: #F04E23;
    }
  }
}

.refresh-button {
  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    filter: drop-shadow(0 0 2px #74aff1);
  }
  &.disabled-animated-icon {
    cursor: not-allowed !important;
    animation: rotating 2s linear infinite;
    width: 28px;
    height: 28px;
    color: #2990ff;
  }
  @keyframes rotating {
    0% {
      transform: rotate(0);
    }
    50% {
      transform: rotate(-180deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
}
.refresh-button:hover {
  filter: drop-shadow(0 0 2px #74aff1);
}
</style>