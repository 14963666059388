import { GraphqlAPI } from "../core/graphql-api";
import { setApplicationState } from "../store/applicationState";
const graphqlServer = import.meta.env.VITE_GRAPHQL_SERVER_V1;

export default class ProfileService {
  constructor() {
    this.accessToken = localStorage.getItem("accessToken");
    this.graphqlAPIService = new GraphqlAPI(graphqlServer, this.accessToken);
    this.getMyProfile = this.getMyProfile.bind(this);
  }

  async getMyProfile() {
    try {
      const {
        userProfile: { name, email, profilePictureUrl: profilePicture, profileStatus },
      } = await this.graphqlAPIService.getMyProfile();
      setApplicationState.setProfileDataLoadingStatus(true);
      localStorage.setItem("name", name);
      localStorage.setItem("email", email);
      setApplicationState.setProfileDataLoadingStatus(false);
      if (email === name) {
        console.log({from:'p service',email,name});
        setApplicationState.setProfileUpdateStatus(true);
      }
      localStorage.setItem("profilePicture", profilePicture);
      return { email, name, profilePicture, profileStatus};
    } catch (error) {
      console.log({error});
      throw error;
      // return {
      //   email: "guest@gmail.com",
      //   userName: "guest",
      //   profilePicture: "GU",
      // };
    }
  }

  async updateUserProfile({email, name}) {
    const input = {
      email,
      name
    };

    try {
      const { updateUserProfile } =
        await this.graphqlAPIService.updateUserProfile_v4(input);
      const updatedName = updateUserProfile.profile.name;
      localStorage.setItem("name", updatedName);
    } catch (error) {
      console.error("Error updating profile:", error);
      throw error;
    }
  }

  async getSubscriptionData() {
    // call the API
  }
}
