<template>
  <svg v-if="type === 'Pencil'" width="32" height="32" viewBox="0 0 32 32" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" :fill="backgroundColor" fill-opacity="0.25" />
    <rect width="32" height="32" rx="16" :fill="secondaryColor" fill-opacity="0.25" />
    <path fill-rule="evenodd" clip-rule="evenodd" :fill="accentColor"
      d="M15.0041 7.66107C15.3251 6.7077 16.6735 6.7077 16.9944 7.66107L18.5537 12.2933H13.4449L15.0041 7.66107ZM12.94 13.7933L10.5828 20.796C10.5465 20.9039 10.528 21.017 10.528 21.1309V25.7948C10.528 26.209 10.1922 26.5448 9.77795 26.5448C9.36374 26.5448 9.02795 26.209 9.02795 25.7948V21.1309C9.02795 20.8543 9.07296 20.5796 9.1612 20.3174L13.5825 7.18254C14.3619 4.86721 17.6367 4.86721 18.416 7.18254L22.8374 20.3174C22.9256 20.5796 22.9706 20.8543 22.9706 21.1309V25.7948C22.9706 26.209 22.6348 26.5448 22.2206 26.5448C21.8064 26.5448 21.4706 26.209 21.4706 25.7948V21.1309C21.4706 21.017 21.4521 20.9039 21.4157 20.796L19.0586 13.7933H12.94Z" />
  </svg>
  <svg v-if="type === 'Arrow'" width="32" height="32" viewBox="0 0 32 32" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" :fill="backgroundColor" fill-opacity="0.25" />
    <rect width="32" height="32" rx="16" :fill="secondaryColor" fill-opacity="0.25" />
    <g clip-path="url(#clip0_1_4559)">
      <path d="M23.6368 20.1999L23.6368 8.36323L11.8001 8.36311M8.36328 23.6367L22.5834 9.41658" :stroke="accentColor"
        stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1_4559">
        <rect width="24" height="24" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </svg>
  <svg v-if="type === 'Circle'" width="32" height="32" viewBox="0 0 32 32" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" :fill="backgroundColor" fill-opacity="0.25" />
    <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" :fill="secondaryColor" :stroke="secondaryColor" />
    <circle cx="16" cy="16" r="9" :stroke="accentColor" stroke-width="1.5" stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
  <svg v-if="type === 'Highlight'" width="32" height="32" viewBox="0 0 32 32" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="white" />
    <rect width="32" height="32" rx="16" :fill="accentColor" fill-opacity="0.5" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M17.8288 6.91821C17.9255 6.88786 18.0238 6.96003 18.0238 7.06132V11.2058H13.9746V8.23875C13.9746 8.17321 14.0172 8.11527 14.0797 8.09564L17.8288 6.91821ZM12.4746 11.2058V8.23875C12.4746 7.51792 12.9426 6.88053 13.6303 6.66455L17.3794 5.48712C18.4424 5.1533 19.5238 5.94718 19.5238 7.06132V11.2058H19.5609C20.4722 11.2058 21.2109 11.9445 21.2109 12.8558V14.6748H21.2483C22.1595 14.6748 22.8983 15.4135 22.8983 16.3248V25.7773C22.8983 26.1915 22.5625 26.5273 22.1483 26.5273C21.734 26.5273 21.3983 26.1915 21.3983 25.7773V16.3248C21.3983 16.2419 21.3311 16.1748 21.2483 16.1748H10.75C10.6671 16.1748 10.6 16.2419 10.6 16.3248V25.784C10.6 26.1982 10.2642 26.534 9.84998 26.534C9.43576 26.534 9.09998 26.1982 9.09998 25.784V16.3248C9.09998 15.4135 9.83871 14.6748 10.75 14.6748H10.7872V12.8558C10.7872 11.9445 11.5259 11.2058 12.4372 11.2058H12.4746ZM12.4372 12.7058C12.3543 12.7058 12.2872 12.7729 12.2872 12.8558V14.6748H19.7109V12.8558C19.7109 12.7729 19.6437 12.7058 19.5609 12.7058H12.4372Z"
      fill="#F59E0B" />
  </svg>
  <svg v-if="type === 'Square'" width="32" height="33" viewBox="0 0 32 33" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="32" height="32" rx="16" :fill="backgroundColor" fill-opacity="0.25" />
    <rect y="0.5" width="32" height="32" rx="16" :fill="secondaryColor" fill-opacity="0.25" />
    <path
      d="M22.0828 24.9001H9.91718C8.63742 24.9001 7.59998 23.8626 7.59998 22.5829V10.4173C7.59998 9.13756 8.63742 8.1001 9.91718 8.1001H22.0828C23.3625 8.1001 24.4 9.13756 24.4 10.4173V22.5829C24.4 23.8626 23.3625 24.9001 22.0828 24.9001Z"
      :stroke="accentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>

  <svg v-if="type === 'Comment'" width="32" height="33" viewBox="0 0 32 33" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="32" height="32" rx="16" fill="white" />
    <rect y="0.5" width="32" height="32" rx="16" fill="#EAF4FF" />
    <path
      d="M12.4731 15.3264H18.7339M12.4731 18.4574H16.9731M16 25.5H7.75033C7.65176 25.5001 7.55413 25.4808 7.46304 25.4431C7.37195 25.4055 7.28918 25.3502 7.21948 25.2805C7.14978 25.2108 7.09452 25.1281 7.05685 25.037C7.01919 24.9459 6.99987 24.8482 7 24.7497V16.5C7 14.1131 7.94821 11.8239 9.63604 10.136C11.3239 8.44821 13.6131 7.5 16 7.5C17.1819 7.5 18.3522 7.73279 19.4442 8.18508C20.5361 8.63738 21.5282 9.30031 22.364 10.136C23.1997 10.9718 23.8626 11.9639 24.3149 13.0558C24.7672 14.1478 25 15.3181 25 16.5C25 17.6819 24.7672 18.8522 24.3149 19.9442C23.8626 21.0361 23.1997 22.0282 22.364 22.864C21.5282 23.6997 20.5361 24.3626 19.4442 24.8149C18.3522 25.2672 17.1819 25.5 16 25.5Z"
      stroke="#2990FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
  <svg v-if="type === 'Text'" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="32" height="32" rx="16" fill="white" />
    <rect y="0.5" width="32" height="32" rx="16" :fill="accentColor" />
    <path d="M15.9999 9.1001V24.5001" stroke="#CBD5E1" stroke-width="1.5" stroke-linecap="round"
      stroke-linejoin="round" />
    <path d="M8.09399 12.2081V9.1001H23.9058V12.2081" stroke="#CBD5E1" stroke-width="1.5" stroke-linecap="round"
      stroke-linejoin="round" />
    <path d="M12.6117 24.9119H19.6391" stroke="#CBD5E1" stroke-width="1.5" stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
  <svg v-if="type === 'Image'" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="white" />
    <rect width="32" height="32" rx="16" fill="#EAF4FF" />
    <path
      d="M23.2727 8H8.72727C8.32561 8 8 8.32561 8 8.72727V23.2727C8 23.6744 8.32561 24 8.72727 24H23.2727C23.6744 24 24 23.6744 24 23.2727V8.72727C24 8.32561 23.6744 8 23.2727 8Z"
      stroke="#2990FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M13.0885 14.5452C13.8918 14.5452 14.543 13.894 14.543 13.0907C14.543 12.2873 13.8918 11.6361 13.0885 11.6361C12.2851 11.6361 11.6339 12.2873 11.6339 13.0907C11.6339 13.894 12.2851 14.5452 13.0885 14.5452Z"
      stroke="#2990FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M9.51794 23.9994L19.4861 14.0303C19.5537 13.9627 19.6339 13.9091 19.7222 13.8725C19.8105 13.8359 19.9051 13.817 20.0007 13.817C20.0962 13.817 20.1909 13.8359 20.2792 13.8725C20.3675 13.9091 20.4477 13.9627 20.5152 14.0303L24.0007 17.5167"
      stroke="#2990FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
import { onMounted, computed } from "vue";

export default {
  name: 'Icon',
  props: {
    styles: {
      type: Object,
      required: false,
      default: () => ({
        width: '32',
        height: '32',
        mainColor: '#F59E0B',
        secondaryColor: '#0D9488',
        accentColor: '#F59E0B'
      })
    },
    type: { type: String, required: true, default: () => 'Pencil' }
  },
  setup(props) {
    const adjustBrightness = (color, percent) => {
      const factor = percent / 100;
      const [r, g, b] = color.match(/\w\w/g).map(x => parseInt(x, 16));
      const adjusted = [r * factor, g * factor, b * factor].map(Math.round);
      return '#' + adjusted.map(x => x.toString(16).padStart(2, '0')).join('');
    };
    const secondaryColor = computed(() => {
      return adjustBrightness(props.styles.mainColor, -30);
    });
    const accentColor = computed(() => {
      return props.styles.mainColor
    });
    const backgroundColor = computed(() => {
      return adjustBrightness(props.styles.mainColor, 101);
    });
    onMounted(() => {
      // console.log(props);
    });
    return {
      secondaryColor,
      accentColor,
      backgroundColor
    };
  }
};
</script>

<style scoped>
/* Add scoped styles if needed */
</style>
