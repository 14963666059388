<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-10" @close="open = false">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                            class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                            <div>
                                <!-- <div
                                    class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                    <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                                </div> -->
                                <div class="mt-3 text-center sm:mt-5">
                                    <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Enter your name.</DialogTitle>
                                    <div class="mt-2">
                                        <p class="text-sm text-gray-500">
                                            <input v-model="userName"  type="text" name="name" id="user-name"
                                                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                        </p>
                                        <span class="m-2 text-red-600">{{error}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5 sm:mt-6">
                                <button type="button"
                                    class="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    @click="updateUserName">Confirm</button>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import { inject, ref, watch } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import ProfileService from '../../service/profileService';
import { CheckIcon } from '@heroicons/vue/24/outline'
import { getApplicationState, state } from '../../store/applicationState';
import {BROADCAST_MESSAGE, ERROR_TOAST, SUCCESS_TOAST} from "../../core/constants.js";

const $fetch = inject('$fetch');
const open = ref(false)
const userName = ref("");
const error = ref("");
const profileService = new ProfileService()
watch(()=>state.isProfileDataLoading,()=>{
    open.value = getApplicationState.getProfileUpdateStatus()
})

async function updateUserName(){
    error.value='';
    if(userName.value === null || userName.value === ''){
        error.value = 'user name cannot be blank.'
        return;
    }
    const email = localStorage.getItem('email');
    if(email && email !== null && email !== ''){
        try {
            await profileService.updateUserProfile({email,name:userName.value});
            $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
          type: SUCCESS_TOAST,
          message: "user name updated successfully"
        });
        } catch (e) {
        $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
          type: ERROR_TOAST,
          message: 'Could not update your name. try again later'
        });
        console.log(e);
        }
        open.value= false;
    }
}

</script>