<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6038 6.49714H18.1721C18.8725 6.49714 19.3395 6.96409 19.3395 7.66453V18.1711C19.3395 18.8715 18.8725 19.3385 18.1721 19.3385H7.66551C6.96507 19.3385 6.49811 18.8715 6.49811 18.1711V15.6028C3.11266 14.7856 0.661133 11.8672 0.661133 8.24823C0.661133 4.0456 4.04658 0.660156 8.24921 0.660156C11.7514 0.660156 14.7866 3.11169 15.6038 6.49714ZM8.24921 2.99495C5.33072 2.99495 2.99592 5.32974 2.99592 8.24823C2.99592 10.583 4.51354 12.4509 6.49811 13.1513V7.66453C6.49811 6.96409 6.96507 6.49714 7.66551 6.49714H13.1523C12.4518 4.51256 10.584 2.99495 8.24921 2.99495ZM13.3857 8.83193C13.1523 11.2835 11.1677 13.1513 8.8329 13.3848V8.83193H13.3857ZM8.8329 17.0037H17.0047V8.83193H15.8373C15.4871 12.5676 12.5686 15.6028 8.8329 15.8363V17.0037Z" fill="#64748B"/>
  </svg>
</template>

<script>
export default {
  name: "shape-icon",
};
</script>
