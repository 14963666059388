<script>

import { computed, onMounted, ref } from "vue";
import { CLOUD_STORAGE } from "../../../core/constants.js";

export default {
  name: "EditStorageNameModal",
  props: {
    action: String,
    storageType: String,
    initialValue: String,
  },
  emits: ["dismissChanges", "saveStorageName"],
  setup(props, {emit}) {
    const storageName = ref("");
    const storageTypeName = computed(() => {
      return props.storageType || "🤷";
    });

    onMounted(() => {
      storageName.value = props.initialValue;
    });

    function cancel() {
      emit("dismissChanges");
    }

    function save() {
      if (storageName.value.length === 0) return;
      if (storageName.value === props.initialValue) return;
      emit("saveStorageName", storageName.value);
    }

    return {
      CLOUD_STORAGE,
      storageTypeName, storageName,
      save, cancel,
    }
  }
}
</script>

<template>
  <div class="storage-name-modal-overlay" @click.stop>
    <div class="storage-name-modal">
      <div class="storage-name-modal-body">
        <span class="modal-body-header">
          <span v-if="storageType === CLOUD_STORAGE.GOOGLE_DRIVE">Rename Google Storage</span>
          <span v-else-if="storageType === CLOUD_STORAGE.ONE_DRIVE">Rename One Drive Storage</span>
          <span v-else-if="storageType === CLOUD_STORAGE.BOX_DRIVE">Rename Box Storage</span>
          <span v-else-if="storageType === CLOUD_STORAGE.DROPBOX_DRIVE">Rename Dropbox Storage</span>
        </span>
        <label for="storage-name-input" class="storage-name-modal-body-input-label">
          Storage Name
          <input id="storage-name-input"
                 v-model="storageName"
                 class="storage-name-modal-body-input"
                 @keydown.stop.enter="save"
                 @keydown.stop.esc="cancel"
          >
        </label>
      </div>
      <div class="storage-name-modal-footer">
        <div class="storage-name-modal-btn white" @click="cancel">Cancel</div>
        <div class="storage-name-modal-btn gray" @click="save">Save</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.storage-name-modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.20);

  .storage-name-modal {
    position: absolute;
    width: 550px;
    height: 220px;
    left: calc(50% - 550px/2);
    top: calc(50% - 220px/2);
    filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.15));
    border-radius: 12px;
    background-color: white;
    display: flex;
    flex-direction: column;

    .storage-name-modal-body {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
      gap: 16px;
      background: #FFFFFF;
      border-radius: 12px 12px 0px 0px;

      .modal-body-header {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #334155;
      }

      .storage-name-modal-body-input-label {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #64748B;
        text-align: left;

        &:focus-within {
          color: #2990FF;
        }
      }

      .storage-name-modal-body-input {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        padding: 8px;
        gap: 8px;

        width: 502px;
        height: 36px;
        background: #F1F5F9;
        border-radius: 6px;
        border: solid 2px transparent;
        outline: none;

        &:focus {
          border: solid 2px #2990FF;
        }
      }
    }

    .storage-name-modal-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      padding: 16px;
      gap: 8px;
      width: 550px;
      height: 68px;
      background: #F8FAFC;
      border-radius: 0 0 12px 12px;

      .storage-name-modal-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        gap: 8px;
        cursor: pointer;
        user-select: none;

        height: 36px;
        border: 1px solid #E2E8F0;
        border-radius: 8px;

        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #334155;

        &.white {
          background: #FFFFFF;
        }

        &.gray {
          background: #334155;
          color: white;
        }

        &:active {
          transform: translate(1px, 1px);
        }
      }
    }
  }
}
</style>