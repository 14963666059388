<script>
import { onMounted, ref, watch } from "vue";
import { CLOUD_STORAGE } from "../../../core/constants.js";

export default {
  name: "AuthNameModal",
  props: {
    provider: String,
    initialName: String,
  },
  emits: ["dismissChanges", "saveStorageName"],
  setup(props, {emit}) {
    const storageName = ref(props.initialName || "");
    const confirmBtn = ref(null);

    onMounted(() => {
      confirmBtn.value.toggleAttribute("disabled", false);
    });

    watch(() => props.initialName, value => {
      storageName.value = value || "";
    });

    function back() {
      emit("dismissChanges");
    }

    function confirm() {
      if (storageName.value.length === 0) return;
      confirmBtn.value.toggleAttribute("disabled", true);
      emit("saveStorageName", storageName.value);
    }

    return {
      CLOUD_STORAGE, storageName, confirmBtn,
      confirm, back,
    }
  }
}
</script>

<template>
  <div class="auth-name-modal-overlay">
    <div class="auth-name-modal">
      <div class="auth-name-modal-body">
        <span class="modal-body-header">
          <span v-if="provider === CLOUD_STORAGE.GOOGLE_DRIVE">Add Google Storage</span>
          <span v-else-if="provider === CLOUD_STORAGE.ONE_DRIVE">Add One Drive Storage</span>
          <span v-else-if="provider === CLOUD_STORAGE.BOX_DRIVE">Add Box Storage</span>
          <span v-else-if="provider === CLOUD_STORAGE.DROPBOX_DRIVE">Add Dropbox Storage</span>
        </span>
        <label for="" class="modal-body-input-label">
          Storage Name
          <input id="storage-name-input"
                 v-model="storageName"
                 class="modal-body-input"
                 @keydown.enter="confirm"
                 autofocus
          >
        </label>
      </div>
      <div class="auth-name-modal-footer">
        <button class="auth-name-modal-btn white" @click="back">Back</button>
        <button class="auth-name-modal-btn dark" ref="confirmBtn" @click="confirm" :disabled="storageName.length === 0">Save Storage</button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.auth-name-modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.20);

  .auth-name-modal {
    position: absolute;
    width: 550px;
    height: 220px;
    left: calc(50% - 550px/2);
    top: calc(50% - 220px/2);
    filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.15));
    border-radius: 12px;
    background-color: white;
    display: flex;
    flex-direction: column;

    .auth-name-modal-body {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
      gap: 16px;
      background: #FFFFFF;
      border-radius: 12px 12px 0px 0px;

      .modal-body-header {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #334155;
      }

      .modal-body-input-label {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #64748B;

        &:focus-within {
          color: #2990FF;
        }
      }

      .modal-body-input {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        padding: 8px;
        gap: 8px;

        width: 502px;
        height: 36px;
        background: #F1F5F9;
        border-radius: 6px;
        border: solid 2px transparent;
        outline: none;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #334155;

        &:focus {
          border: solid 2px #2990FF;
        }
      }
    }

    .auth-name-modal-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      padding: 16px;
      gap: 8px;
      width: 550px;
      height: 68px;
      background: #F8FAFC;
      border-radius: 0 0 12px 12px;

      .auth-name-modal-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        gap: 8px;
        cursor: pointer;
        user-select: none;

        height: 36px;
        border: 1px solid #E2E8F0;
        border-radius: 8px;

        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #334155;

        &.white {
          background: #FFFFFF;
        }

        &:enabled.dark {
          background: #334155;
          color: white;
        }

        &:disabled.dark {
          background: #E2E8F0;
          color: #64748B;
          cursor: not-allowed;
        }

        &:enabled:active {
          transform: translate(1px, 1px);
        }
      }
    }
  }
}
</style>