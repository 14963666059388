<script>
import {onBeforeUnmount, onMounted, reactive, ref} from "vue";

export default {
  name: "StrokeBlock",
  props: ["preset"],
  emits: ["updateWidth"],
  setup(props, { emit }) {
    const inputRef = ref(null);
    const labelRef = ref(null);
    const state = reactive({
      strokePanelShown: false,
    });

    onMounted(() => {
      inputRef.value.addEventListener("input", onInput);
      inputRef.value.value = props.preset.lineWidth;
      labelRef.value.innerText = props.preset.lineWidth;
      paintSlider(inputRef.value);
    });

    onBeforeUnmount(() => {
      inputRef.value.removeEventListener("input", onInput);
    });

    function onInput(e) {
      paintSlider(e.target);
    }

    function paintSlider(target) {
      const { min, max, value } = target;
      const strokePercentValue = (value * 100) / (max - min);
      const stokeBackground = `linear-gradient(90deg, #64748B 0%, #64748B ${strokePercentValue - 5}%, #E2E8F0 ${strokePercentValue - 5}% 100%)`;
      target.style.setProperty("--color", "#FFFFFF");
      target.style.setProperty("background", stokeBackground);
      labelRef.value.innerText = value;
      emit("updateWidth", parseInt(value, 10))
    }

    return {
      state, inputRef, labelRef,
      onInput
    }
  }
}
</script>

<template>
  <div class="stroke-block">
    <folia-button
        icon="stroke"
        tooltip="Pencil"
        shortcut="P"
        size="28"
        @click="state.strokePanelShown = !state.strokePanelShown"
    ></folia-button>

    <div class="stroke-block-panel" :class="{'shown': state.strokePanelShown}">
      <input ref="inputRef" type="range" min="1" max="20" step="1" class="stroke-block-slider">
      <div class="stroke-block-label-container">
        <span class="stroke-block-label">Thickness</span>
        <span class="stroke-block-label" ref="labelRef">0</span>
      </div>
    </div>

  </div>
</template>

<style scoped lang="scss">
.stroke-block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stroke-block-panel {
  position: absolute;
  top: 55px;
  display: none;
  padding: 16px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;

  border-radius: 12px;
  border: 1px solid var(--gray-200, #e2e8f0);
  background: var(--white-white, #fff);
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
}
.stroke-block-panel.shown {
  display: flex;
}
.stroke-block-slider {
  width: 100%;
  min-width: 216px;
}
.stroke-block-slider:focus {
  outline: none;
}
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  height: 10px;
  border-radius: 10px;
  margin: 0;
  border: solid 1px #f1f5f9;
}
input[type="range"].stroke {
  background: var(--color);
}
input[type="range"].opacity {
  background: var(--color);
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 18px;
  border: solid 1px #e2e8f0;
  align-self: center;
  background-color: var(--color);
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1));
}
.stroke-block-label-container {
  width: 100%;
  min-width: 216px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.stroke-block-label {
  font-family: Inter, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  color: #64748b;
}
</style>