<template>
  <div
    class="color-button"
    :class="{ 'selected': isSelected }"
    @click.stop="selectColor"
    :style="{
      '--color-btn': color || gradient,
      '--line-width': safeLineWidth,
      '--adjust-panel-width': isLineWidthShown ? '500px' : '250px'
    }"
  >
    <div class="color-button-filled"></div>
    <div
      v-if="isAdjustPanelShown"
      class="adjust-color-panel"
      @click.stop
    >
      <div class="adjust-block" v-if="isLineWidthShown">
        <div class="adjust-block-line">
          <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.25 10.0714C11.25 12.9315 8.89949 15.25 6 15.25C3.10051 15.25 0.75 12.9315 0.75 10.0714C0.75 7.21138 6 0.75 6 0.75C6 0.75 11.25 7.21138 11.25 10.0714Z" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11 9H1V12L2.5 13.5L5 15L8 14.5L10.5 13L11 9Z" fill="#334155"/>
          </svg>
          <input type="range" min="10" max="100" v-model="opacity" @change="setOpacity">
          <span class="adjust-block-value">{{opacity}}%</span>
        </div>
        <div class="adjust-block-line">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.25 8C15.25 12.0041 12.0041 15.25 8 15.25C3.99594 15.25 0.75 12.0041 0.75 8C0.75 3.99594 3.99594 0.75 8 0.75C12.0041 0.75 15.25 3.99594 15.25 8Z" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.25 8C12.25 10.3472 10.3472 12.25 8 12.25C5.65279 12.25 3.75 10.3472 3.75 8C3.75 5.65279 5.65279 3.75 8 3.75C10.3472 3.75 12.25 5.65279 12.25 8Z" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.25 8C9.25 8.69036 8.69036 9.25 8 9.25C7.30964 9.25 6.75 8.69036 6.75 8C6.75 7.30964 7.30964 6.75 8 6.75C8.69036 6.75 9.25 7.30964 9.25 8Z" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <input type="range" min="1" max="20" v-model="width" @change="setWidth">
          <span class="adjust-block-value">{{width}}</span>
        </div>
      </div>
      <div class="adjust-divider" v-if="isLineWidthShown"></div>
      <div class="adjust-block">
        <div v-for="line of colors" class="adjust-block-line">
          <div
            v-for="color of line"
            class="adjust-block-item"
            :style="{backgroundColor: color}"
            @click.stop="setColor(color)"
          ></div>
        </div>
      </div>
      <div class="adjust-divider" v-if="canDeletePreset"></div>
      <button class="adjust-delete-preset-btn" v-if="canDeletePreset" @click.stop="deletePreset">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.75 10.75V16.25M13.25 10.75V16.25M8.75 7.75V6.75C8.75 5.64543 9.64543 4.75 10.75 4.75H12.25C13.3546 4.75 14.25 5.64543 14.25 6.75V7.75M4.75 7.75H18.25M6.59115 17.4233L5.75 7.75H17.25L16.4088 17.4233C16.319 18.4568 15.4538 19.25 14.4164 19.25H8.58363C7.54622 19.25 6.68102 18.4568 6.59115 17.4233Z" stroke="#F43F5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, onUpdated, ref, watch} from "vue";
import Color from "color";
import ToolButton from "../../common/ToolButton.vue";

export default {
  name: "ColorButton",
  components: { ToolButton },
  props: {
    color: String,
    lineWidth: Number,
    isSelected: Boolean,
    canDeletePreset: Boolean,
  },
  emits: ["btnOnCLick", "updateWidth", "updateColor", "deletePreset"],
  setup(props, {emit}) {
    const gradient = "conic-gradient(from 180deg at 50% 50%, #BB29FF 0deg, #2990FF 80.47deg, #33FF00 142.34deg, #FFF500 226.72deg, #FF7A00 290.47deg, #FF0000 360deg)"
    const colors = [
      //#FFFFFF    #FFED69    #F59E0B    #EF4444    #EC4899    #8B5CF6    #5B21B6
      ["#FFFFFF", "#FFED69", "#F59E0B", "#EF4444", "#EC4899", "#8B5CF6", "#5B21B6"],
      //#000000    #D3E660    #9DCF62    #0D9488    #22D3EE    #0EA5E9    #94A3B8
      ["#000000", "#D3E660", "#9DCF62", "#0D9488", "#22D3EE", "#0EA5E9", "#94A3B8"],
    ];
    const opacity = ref(100);
    const width = ref(1);
    const isAdjustPanelShown = ref(false);
    const isLineWidthShown = computed(() => {
      return Boolean(props.lineWidth);
    })
    onMounted(() => {
      width.value = props.lineWidth;
      const color = Color(props.color);
      opacity.value = parseInt(color.alpha() * 100, 10);
    })

    const safeLineWidth = computed(() => {
      const lineWidth = props.lineWidth || 20;
      return Math.min(20, Math.max(3, lineWidth)) + "px";
    });

    watch(() => props.lineWidth, value => {
      width.value = value;
    });
    watch(() => props.color, value => {
      const color = Color(value);
      opacity.value = parseInt(color.alpha() * 100, 10);
    })
    watch(() => props.isSelected, () => {
      isAdjustPanelShown.value = false;
    })
    function selectColor() {
      if (props.isSelected) {
        isAdjustPanelShown.value = !isAdjustPanelShown.value;
        return;
      }
      emit("btnOnCLick", props.color)
    }
    function setColor(color) {
      const alpha = Color(props.color).alpha();
      emit("updateColor", Color(color).alpha(alpha).hexa());
    }
    function setOpacity() {
      const color = Color(props.color).alpha(opacity.value / 100);
      emit("updateColor", color.hexa());
    }
    function setWidth() {
      emit("updateWidth", parseInt(width.value, 10));
    }
    function deletePreset() {
      isAdjustPanelShown.value = false;
      emit('deletePreset');
    }

    return {
      gradient, colors, isLineWidthShown, isAdjustPanelShown, safeLineWidth,
      opacity, width,
      selectColor, setOpacity, setWidth, setColor, deletePreset,
    };
  },
};
</script>

<style scoped lang="scss">
.color-button {
  position: relative;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  background-color: transparent;
  margin: 0 2px;
  cursor: pointer;
  border: solid 1px #E2E8F0;
  //overflow: hidden;

  &.selected:after {
    content: "";
    display: block;
    position: absolute;
    border: solid 4px transparent;
    border-top-color: #94A3B8;
    bottom: -12px;
    width: 0;
    height: 0;
  }

  &.selected {
    border: solid 1px #000000;

    svg {
      stroke: #2990ff;
    }
  }

  &:hover {
    border: solid 1px #afb3b9;
  }
}

.color-button-filled {
  font-size: 11px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: var(--line-width);
  min-height: var(--line-width);
  border-radius: 10px;
  background: var(--color-btn);
}

.adjust-color-panel {
  position: absolute;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px;
  gap: 16px;
  cursor: default;

  width: var(--adjust-panel-width);
  height: 88px;
  left: calc(var(--adjust-panel-width) / 2 * -1);
  top: 45px;
  background: #FFFFFF;
  border: 1px solid #E2E8F0;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  .adjust-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    .adjust-block-line {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;

      .adjust-block-value {
        width: 50px;
      }
      .adjust-block-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border: 1px solid #E2E8F0;
        border-radius: 100px;
        cursor: pointer;

        &:hover:after {
          content: "";
          position: absolute;
          display: inline-block;
          width: 22px;
          height: 22px;
          border: 2px solid #E2E8F0;
          border-radius: 100px;

        }
      }
    }
  }
  .adjust-divider {
    width: 0;
    height: 70px;
    border: 1px solid #e2e8f0;
  }

  .adjust-delete-preset-btn {
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:active {
      transform: translate(1px, 1px);
    }
  }
}
</style>
