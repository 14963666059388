<script>
import { computed, watch } from "vue";

const TABS = {
  ProjectSearchResult: "Projects",
  DocumentSearchResult: "Documents",
  DocumentTextSearchResult: "Document text",
  DocumentObjectSearchResult: "Annotations",
};

export default {
  name: "GlobalSearchTabs",
  emits: ["setSearchTab"],
  props: {
    currentTab: String,
    projects: Array,
    documents: Array,
    texts: Object,
    objects: Array,
  },
  setup(props, {emit}) {
    const countProjects = computed(() => {
      return props.projects.length || 0;
    });

    const countDocuments = computed(() => {
      return props.documents.length || 0;
    });

    const countDocumentText = computed(() => {
      return props.texts.total || 0;
    });

    const countDocumentObjects = computed(() => {
      return props.objects.length || 0;
    });

    watch(() => props.currentTab, tab => {
      localStorage.setItem("global-search-tab", tab);
    });

    function onClickTab(tabId) {
      emit("setSearchTab", tabId);
    }

    return {
      TABS,
      countProjects, countDocuments, countDocumentText, countDocumentObjects,

      onClickTab,
    }
  }
}
</script>

<template>
  <div class="search-category-tab-container p-8">
    <div class="category-nav-tab-item left-item"
         :class="{'selected': currentTab === TABS.ProjectSearchResult }"
         @click="onClickTab(TABS.ProjectSearchResult)"
    >
      <span>Projects</span>
      <div class="count-search-category">{{ countProjects }}</div>
    </div>
    <div class="category-nav-tab-item middle-item"
         :class="{'selected': currentTab === TABS.DocumentSearchResult }"
         @click="onClickTab(TABS.DocumentSearchResult)"
    >
      <span>Documents</span>
      <div class="count-search-category">{{ countDocuments }}</div>
    </div>
    <div class="category-nav-tab-item middle-item"
         :class="{'selected': currentTab === TABS.DocumentTextSearchResult }"
         @click="onClickTab(TABS.DocumentTextSearchResult)"
    >
      <span>Document Text</span>
      <div class="count-search-category">{{ countDocumentText }}</div>
    </div>
    <div class="category-nav-tab-item middle-item"
         :class="{'selected': currentTab === TABS.DocumentObjectSearchResult }"
         @click="onClickTab(TABS.DocumentObjectSearchResult)"
    >
      <span>Annotations</span>
      <div class="count-search-category">{{ countDocumentObjects }}</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.search-category-tab-container {
  position: relative;
  height: 45px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  // padding-left: 8%;

  .category-nav-tab-item {
    height: 44px;
    max-height: 44px;
    min-height: 44px;
    overflow: hidden;
    width: auto;
    display: flex;
    text-overflow: ellipsis;
    flex-direction: row;
    color: #334155;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;

    &.left-item {
      padding: 8px 10px 16px 0;
    }
    &.middle-item {
      padding: 8px 10px 16px 10px;
    }
    &.right-item {
      padding: 8px 0 16px 10px;
    }

    &.selected {
      border-bottom: 3px solid #f04e23 !important;

      .count-search-category {
        background: #F04E23;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #FFFFFF;
      }
      &:hover {
        background-color: inherit;
      }
    }

    .count-search-category {
      display: flex;
      min-width: 25px;
      margin-left: 6px;
      height: 16px;
      color: #334155;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      padding: 0 4px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: #E2E8F0;
      border-radius: 4px;
    }

    &:hover {
      border-bottom: 3px solid #f1f1f1;
    }
  }
}
</style>