import foliaDropDownWithTooltipHTML from "./folia-drop-down-with-tooltip.html?raw";

const areArraysSimilar = (arr1, arr2) => {
  if (!Array.isArray(arr1)) return false;
  if (!Array.isArray(arr2)) return false;
  if (arr1.length !== arr2.length) return false;
  return arr1.every((arr1item, arr1index) => {
    return arr2[arr1index] === arr1item;
  });
};

class FoliaDropDownWithTooltip extends HTMLElement {
  canBeShown = false;
  #dropDownOpened = false;
  #value = "";
  #items = [];
  onMouseOver = this.showToolTip.bind(this);
  onMouseOut = this.hideToolTip.bind(this);
  onClickBind = this.onClick.bind(this);

  constructor() {
    super();
    const template = document.createElement("template");
    template.innerHTML = foliaDropDownWithTooltipHTML;
    this.attachShadow({ mode: "open" });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
  }

  static get observedAttributes() {
    return ["name", "tooltip", "shortcut", "width", "top", "disabled", "value"];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    // console.log("set attr", name, newValue);
    switch (name) {
      case "name": {
        this.name = newValue;
        break;
      }
      case "tooltip": {
        this.canBeShown = Boolean(newValue);
        this.shadowRoot
          .querySelectorAll(".folia-drop-down-tooltip-text")
          .forEach((el) => {
            el.innerText = newValue;
          });
        break;
      }
      case "top": {
        const isTop = newValue === "" || newValue === "true";
        const tooltip = this.shadowRoot.querySelector(
          ".folia-drop-down-tooltip"
        );
        if (!tooltip) return;
        tooltip.classList.toggle("top", isTop);
        tooltip.classList.toggle("bottom", !isTop);
        break;
      }
      case "disabled": {
        const isDisabled = newValue === "true" || newValue === "";
        const dropDownCaret = this.shadowRoot.querySelector(
          ".folia-drop-down-caret"
        );
        const dropDownValue = this.shadowRoot.querySelector(
          ".folia-drop-down-value"
        );
        const toolTip = this.shadowRoot.querySelector(
          ".folia-drop-down-tooltip"
        );
        if (dropDownCaret)
          dropDownCaret.toggleAttribute("disabled", isDisabled);
        if (dropDownValue)
          dropDownValue.classList.toggle("disabled", isDisabled);
        if (toolTip) {
          toolTip.classList.toggle("disabled", isDisabled);
          toolTip.classList.toggle("shown", false);
        }
        break;
      }
      case "value": {
        this.value = newValue;
        break;
      }
      case "width": {
        this.shadowRoot
          .querySelector(".folia-drop-down")
          .style.setProperty("--minimal-width", `${newValue}px`);
      }
      default:
        break;
    }
  }

  connectedCallback() {
    this.shadowRoot.addEventListener("mouseover", this.onMouseOver, {
      passive: false,
    });
    this.shadowRoot.addEventListener("mouseout", this.onMouseOut, {
      passive: false,
    });
    this.shadowRoot.addEventListener("click", this.onClickBind, {
      passive: false,
    });

    const items = [];
    this.childNodes.forEach((node) => {
      if (node.nodeName !== "OPTION") return;
      items.push({ value: node.value, text: node.text });
      // console.log("add option", { value: node.value, text: node.text });
    });
    this.items = items;

    this.mutationObserver = new MutationObserver(
      this.mutationObserverFn.bind(this)
    );
    this.mutationObserver.observe(this, { childList: true, subtree: true });
  }

  disconnectedCallback() {
    this.mutationObserver.disconnect();
    this.shadowRoot.removeEventListener("mouseover", this.onMouseOver, {
      passive: false,
    });
    this.shadowRoot.removeEventListener("mouseout", this.onMouseOut, {
      passive: false,
    });
    this.shadowRoot.removeEventListener("click", this.onClickBind, {
      passive: false,
    });
  }

  mutationObserverFn(mutationsList, observer) {
    console.log("mutation observe", mutationsList);
  }

  set value(_newValue) {
    const newValue = `${_newValue}`;
    // console.log("set property value", newValue);
    this.#value = newValue;
    this.drawValueText();
  }
  get value() {
    return this.#value;
  }

  set items(_newItems) {
    const newItems = Array.from(_newItems).map((item) => {
      return { value: `${item.value}`, text: `${item.text}` };
    });
    // console.log("set items", newItems);
    this.#items = newItems;
    const itemsBody = this.shadowRoot.querySelector(
      ".folia-drop-down-items-body"
    );
    for (const item of this.#items) {
      const selector = `.folia-drop-down-item[data-value="${item.value}"]`;
      let itemEl = itemsBody.querySelector(selector);
      if (!itemEl) {
        itemEl = document.createElement("div");
        itemEl.classList.add("folia-drop-down-item");
        itemEl.setAttribute("data-role", "drop-down-item");
        itemEl.setAttribute("data-value", item.value);
        itemEl.innerText = item.text;
        itemsBody.appendChild(itemEl);
      }
      itemEl.classList.toggle("selected", this.value === item.value);
      // TODO: need fix this -----\/-----
      // if (itemEl.classList.contains("selected")) {
      //   itemEl.scrollIntoView({ block: "nearest", inline: "start" });
      //   console.log("scrolled", itemEl);
      // }
    }
    this.drawValueText();
  }
  get items() {
    return this.#items;
  }

  drawValueText() {
    const item = this.#items.find((item) => item.value === this.#value);
    this.shadowRoot.querySelectorAll(".folia-drop-down-value").forEach((el) => {
      el.innerText = item?.text || "--";
    });
  }

  showToolTip() {
    if (this.#dropDownOpened) return;
    this.delayTimer = setTimeout(() => {
      const toolTip = this.shadowRoot.querySelector(".folia-drop-down-tooltip");
      toolTip.classList.toggle("shown", this.canBeShown);
    }, 400);
  }

  hideToolTip() {
    clearTimeout(this.delayTimer);
    const toolTip = this.shadowRoot.querySelector(".folia-drop-down-tooltip");
    toolTip.classList.toggle("shown", false);
  }

  openDropDown() {
    this.#dropDownOpened = true;
    this.hideToolTip();
    const dropDownOverlay = this.shadowRoot.querySelector(
      ".folia-drop-down-overlay"
    );
    const dropDownCaret = this.shadowRoot.querySelector(
      ".folia-drop-down-caret"
    );
    const items = this.shadowRoot.querySelector(".folia-drop-down-items");

    dropDownOverlay.classList.add("shown");
    dropDownCaret.classList.add("opened");
    items.classList.toggle("shown", this.#dropDownOpened);
  }

  closeDropDown() {
    this.#dropDownOpened = false;
    const dropDownOverlay = this.shadowRoot.querySelector(
      ".folia-drop-down-overlay"
    );
    const dropDownCaret = this.shadowRoot.querySelector(
      ".folia-drop-down-caret"
    );
    const items = this.shadowRoot.querySelector(".folia-drop-down-items");

    dropDownOverlay.classList.remove("shown");
    dropDownCaret.classList.remove("opened");
    items.classList.toggle("shown", this.#dropDownOpened);
  }

  onClick(e) {
    e.preventDefault();
    const { role, value } = e.target.dataset;
    switch (role) {
      case "drop-down-button": {
        if (this.#dropDownOpened) {
          this.closeDropDown();
        } else {
          this.openDropDown();
        }
        break;
      }
      case "drop-down-item": {
        this.shadowRoot
          .querySelectorAll(".folia-drop-down-item")
          .forEach((el) => {
            el.classList.remove("selected");
          });
        e.target.classList.add("selected");
        this.value = value;
        this.dispatchEvent(new InputEvent("input", { data: value }));
        this.closeDropDown();
        break;
      }
      default: {
        this.closeDropDown();
        break;
      }
    }
  }
}

if ("customElements" in window) {
  customElements.define("folia-drop-down", FoliaDropDownWithTooltip);
} else {
  throw new Error("Custom html element <folia-drop-down> is not supported.");
}

export { FoliaDropDownWithTooltip };
