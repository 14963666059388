<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    stroke="#64748B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.375 8.70833V1.625H3.29167M10.1667 1.83333L1.625 10.375"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "arrow-icon",
};
</script>
