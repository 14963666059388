export const DEFAULT_PRESETS = {
  activePresets: [
    { index: 0, kind: "INK", addedAt: new Date().toISOString() },
    { index: 0, kind: "MARKER", addedAt: new Date().toISOString() },
    { index: 0, kind: "UNDERLINE", addedAt: new Date().toISOString() },
    { index: 0, kind: "CROSSLINE", addedAt: new Date().toISOString() },
    { index: 0, kind: "SQUARE", addedAt: new Date().toISOString() },
    { index: 0, kind: "CIRCLE", addedAt: new Date().toISOString() },
    { index: 0, kind: "ARROW", addedAt: new Date().toISOString() },
    { index: 0, kind: "TEXT_BOX", addedAt: new Date().toISOString() },
    { index: 0, kind: "PIXEL_ERASER", addedAt: new Date().toISOString() },
  ],
  inkPresets: [
    {
      index: 0,
      color: "#EF4444ff",
      lineWidth: 2,
      addedAt: new Date().toISOString(),
    },
    {
      index: 1,
      color: "#0EA5E9FF",
      lineWidth: 6,
      addedAt: new Date().toISOString(),
    },
    {
      index: 2,
      color: "#000000ff",
      lineWidth: 10,
      addedAt: new Date().toISOString(),
    },
  ],

  markerPresets: [
    { index: 0, color: "#FFED69FF", addedAt: new Date().toISOString() },
    { index: 1, color: "#D3E660FF", addedAt: new Date().toISOString() },
    { index: 2, color: "#22D3EEFF", addedAt: new Date().toISOString() },
  ],
  underlinePresets: [
    { index: 0, color: "#EF4444FF", addedAt: new Date().toISOString() },
  ],
  crosslinePresets: [
    { index: 0, color: "#000000FF", addedAt: new Date().toISOString() },
  ],

  arrowPresets: [
    {
      index: 0,
      color: "#EF4444FF",
      lineWidth: 2,
      addedAt: new Date().toISOString(),
    },
    {
      index: 1,
      color: "#0EA5E9FF",
      lineWidth: 6,
      addedAt: new Date().toISOString(),
    },
    {
      index: 2,
      color: "#000000FF",
      lineWidth: 10,
      addedAt: new Date().toISOString(),
    },
  ],
  circlePresets: [
    {
      index: 0,
      color: "#EF4444FF",
      lineWidth: 2,
      addedAt: new Date().toISOString(),
    },
    {
      index: 1,
      color: "#0EA5E9FF",
      lineWidth: 6,
      addedAt: new Date().toISOString(),
    },
    {
      index: 2,
      color: "#000000FF",
      lineWidth: 10,
      addedAt: new Date().toISOString(),
    },
  ],
  squarePresets: [
    {
      index: 0,
      color: "#EF4444FF",
      lineWidth: 2,
      addedAt: new Date().toISOString(),
    },
    {
      index: 1,
      color: "#0EA5E9FF",
      lineWidth: 6,
      addedAt: new Date().toISOString(),
    },
    {
      index: 2,
      color: "#000000FF",
      lineWidth: 10,
      addedAt: new Date().toISOString(),
    },
  ],

  textBoxPresets: [
    {
      index: 0,
      color: "#000000FF",
      fontFamily: "SANS_SERIF",
      fontSize: 14,
      fontWeight: "W400",
      textAlignment: "START",
      addedAt: new Date().toISOString(),
    },
  ],
  eraserPresets: [
    { index: 0, lineWidth: 9, addedAt: new Date().toISOString() },
  ],
};

export const BUTTON_ROLES = {
  UNDO: "UNDO",
  REDO: "REDO",
  POINTER: "POINTER",
  INK: "INK",
  HIGHLIGHTER: "HIGHLIGHTER",
  MARKER: "MARKER",
  UNDERLINE: "UNDERLINE",
  CROSSLINE: "CROSSLINE",
  COMMENT: "COMMENT",
  STAMPS: "STAMPS",
  SHAPES: "SHAPES",
  SQUARE: "SQUARE",
  CIRCLE: "CIRCLE",
  ARROW: "ARROW",
  TEXT: "TEXT_BOX",
  IMAGE: "IMAGE",
  ERASERS: "ERASERS",
  OBJECT_ERASER: "ERASER",
  PIXEL_ERASER: "PIXEL_ERASER",
  CLOSE: "CLOSE",
};

export const TOOL_PRESETS_MAP = {
  [BUTTON_ROLES.INK]: "inkPresets",
  [BUTTON_ROLES.MARKER]: "markerPresets",
  [BUTTON_ROLES.UNDERLINE]: "underlinePresets",
  [BUTTON_ROLES.CROSSLINE]: "crosslinePresets",
  [BUTTON_ROLES.SQUARE]: "squarePresets",
  [BUTTON_ROLES.CIRCLE]: "circlePresets",
  [BUTTON_ROLES.ARROW]: "arrowPresets",
  [BUTTON_ROLES.TEXT]: "textBoxPresets",
  [BUTTON_ROLES.PIXEL_ERASER]: "eraserPresets",
};
