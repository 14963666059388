<template>
  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.1666 7.16699L7.99992 9.50033L5.83325 7.16699" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "arrow-bottom-btn",
};
</script>

<style scoped></style>
