<template>
  <div class="sort-select">
    <div class="sort-select-overlay" v-if="selectOpened" @click="selectOpened = false"/>
    <div class="sort-select-text" @click="selectOpened = !selectOpened">
      <div class="sort-select-value">
        <span class="sort-sub-text">Sort by</span>
        <span v-if="representation.sort === REPRESENTATION.SORT.DATE_ADDED">Date Added</span>
        <span v-if="representation.sort === REPRESENTATION.SORT.LAST_MODIFIED">Last Modified</span>
        <span v-if="representation.sort === REPRESENTATION.SORT.NAME">Name</span>
      </div>
      <div class="sort-select-icon" :class="{ 'opened': selectOpened, 'closed': !selectOpened }"/>
    </div>
    <div class="sort-select-switch" @click="toggleDirection">
      <div class="sort-select-switch-icon" :class="{
        'ascent': representation.dir === REPRESENTATION.DIRECTIONS.ASCENT,
        'descent': representation.dir === REPRESENTATION.DIRECTIONS.DESCENT
      }"/>
    </div>
    <div class="sort-select-items" :class="{ 'opened': selectOpened, 'closed': !selectOpened }">
      <div v-for="sortType of Object.keys(REPRESENTATION.SORT)"
           :key="sortType"
           class="sort-select-item"
           @click="changeSorting(sortType)">
        <span v-if="sortType === REPRESENTATION.SORT.DATE_ADDED">Date Added</span>
        <span v-if="sortType === REPRESENTATION.SORT.LAST_MODIFIED">Last Modified</span>
        <span v-if="sortType === REPRESENTATION.SORT.NAME">Name</span>
        <div class="active-item-check" v-if="sortType === representation.sort">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.45801 10.6248L8.33301 12.7082L13.5413 7.2915" stroke="#F04E23" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { REPRESENTATION } from "./constants.js";

export default {
  name: "SortSelect",
  props: {
    representation: Object,
  },
  setup(props, { emit }) {
    const selectOpened = ref(false)

    function toggleDirection() {
      emit("change", {
        dir: props.representation.dir === REPRESENTATION.DIRECTIONS.DESCENT
          ? REPRESENTATION.DIRECTIONS.ASCENT
          : REPRESENTATION.DIRECTIONS.DESCENT
      });
    }

    function changeSorting(value) {
      if (value === props.representation.sort) return;
      console.log("changeSorting", value)
      emit("change", { sort: value });
      selectOpened.value = false;
    }

    return {
      REPRESENTATION, selectOpened,
      toggleDirection, changeSorting
    }
  }
};
</script>

<style scoped lang="scss">
.sort-select {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 250px;
  min-width: 240px;

  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #334155;

  .sort-select-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .sort-select-text {
    height: 36px;
    max-width: 200px;
    min-width: 200px;
    background-color: white;
    border: 1px solid #E2E8F0;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
    font-family: Inter, serif;
    font-size: 12px;
    line-height: 15px;
    padding-left: 15px;
    padding-right: 5px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .sort-select-value {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      white-space: nowrap;
      & :first-child {
        color: #64748b;
        margin-right: 5px;
      }
      & :last-child {
        color: #334155;
      }
    }

    .sort-select-icon {
      height: 16px;
      width: 16px;

      &.closed {
        background: url('../../assets/images/sort-select/caret-down.svg');
        background-size: 16px;
        transform: rotateX(0deg);
        //transition: transform 0.3s ease-in-out;
      }
      &.opened {
        background: url('../../assets/images/sort-select/caret-down.svg');
        background-size: 16px;
        transform: rotateX(180deg);
        //transition: transform 0.3s ease-in-out;
      }
    }
  }
  .sort-select-switch {
    width: 48px;
    height: 36px;
    margin-left: 8px;
    background-color: white;
    border: 1px solid #E2E8F0;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .sort-select-switch-icon {
      width: 30px;
      height: 20px;
    }
    .sort-select-switch-icon.ascent {
      background: url('../../assets/images/sort-select/sort-switch.svg') center no-repeat;
      background-size: 30px;
      transform: rotate(0deg);
      //transition: transform 0.2s ease-in-out;
    }
    .sort-select-switch-icon.descent {
      background: url('../../assets/images/sort-select/sort-switch.svg') center no-repeat;
      background-size: 30px;
      transform: rotate(180deg);
      //transition: transform 0.2s ease-in-out;
    }
  }
  .sort-select-items {
    position: absolute;
    top: 38px;
    max-width: 200px;
    min-width: 200px;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 8px 0;
    overflow: hidden;
    z-index: 1;
    &:hover {
      cursor: pointer;
    }

    &.opened {
      display: block;
    }
    &.closed {
      display: none;
    }
    .sort-select-item:hover {
      background-color: #E2E2E2;
    }
    .sort-select-item {
      height: 36px;
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #334155;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 12px;
      text-transform: capitalize;
      .active-item-check {
        position: absolute;
        right:0;
        margin-top: 2px;
        margin-right: 12px;
      }
    }
  }
}
</style>
