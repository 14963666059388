<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.62516 16.0423H14.3752C15.2956 16.0423 16.0418 15.2961 16.0418 14.3757V8.12565L10.0002 3.95898L3.9585 8.12565V14.3757C3.9585 15.2961 4.70469 16.0423 5.62516 16.0423Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.12465 13.1248C8.12465 12.2044 8.87085 11.4582 9.79132 11.4582H10.208C11.1285 11.4582 11.8747 12.2044 11.8747 13.1248V16.0415H8.12465V13.1248Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "home-icon"
}
</script>

<style scoped>

</style>
