<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0002 6.66634V9.99967L11.6668 11.6663M16.0418 9.99967C16.0418 13.3364 13.3369 16.0413 10.0002 16.0413C6.66344 16.0413 3.9585 13.3364 3.9585 9.99967C3.9585 6.66295 6.66344 3.95801 10.0002 3.95801C13.3369 3.95801 16.0418 6.66295 16.0418 9.99967Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: "history-icon"
}
</script>

<style scoped>

</style>
