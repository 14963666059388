<template>
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    stroke="#64748B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.625 2.62467C1.625 1.7042 2.37119 0.958008 3.29167 0.958008H8.70833C9.62881 0.958008 10.375 1.7042 10.375 2.62467V13.0413L6 9.29134L1.625 13.0413V2.62467Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "bookmark-icon",
};
</script>
