<script>

import { computed, inject, onBeforeUnmount, onMounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { PUBLIC_VIEWER } from "../../../core/constants.js";

export default {
  props: {
    favorites: Array,
    userRole: String,
  },
  emits: ["toggleFavoriteStatus"],
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const $fetch = inject("$fetch");
    const state = reactive({
      visible: false,
      projects: [],
      projectsListVisible: false,
    });

    const sortedFavorites = computed(() => {
      return props.favorites.sort((favA, favB) => {
        return new Date(favB.favorite.addedAt).getTime() - new Date(favA.favorite.addedAt).getTime();
      })
    });

    const sortedProjects = computed(() => {
      return state.projects.sort((projectA, projectB) => {
        return projectA.name.localeCompare(projectB.name);
      });
    });

    const currentProjectName = computed(() => {
      return state.projects.find(project => {
        return project.id === route.params.projectId;
      })?.name || "-"
    });

    const canFavorite = computed(() => {
      return props.userRole && props.userRole !== PUBLIC_VIEWER;
    });

    onMounted(() => {
      document.addEventListener("left-side-bar-visibility", visibilityEventHandler);
    });

    onBeforeUnmount(() => {
      document.removeEventListener("left-side-bar-visibility", visibilityEventHandler);
    })

    function visibilityEventHandler(e) {
      const visible = Boolean(e.detail.visibility);
      if (visible) {
        $fetch.getProjects()
          .then(projects => state.projects = projects)
          .then(() => state.visible = visible)
          .catch(console.error)
      } else {
        state.visible = visible;
      }
    }

    function projectItemClick(project) {
      const { id } = project
      router.push(`/p/${id}`);
    }

    function favoriteItemClick(item) {
      setTimeout(() => {
        state.visible = false;
        state.projectsListVisible = false;
        const { id, projectId } = item
        router.push(`/p/${projectId}/d/${id}`);
      }, 100);
    }

    function closeSidebar() {
      setTimeout(() => {
        state.visible = false;
        state.projectsListVisible = false;
      }, 100);
    }

    function toggleProjectsList() {
      state.projectsListVisible = !state.projectsListVisible;
    }

    return {
      state, currentProjectName, sortedProjects, sortedFavorites, canFavorite,
      projectItemClick, favoriteItemClick, closeSidebar, toggleProjectsList
    }
  }
}
</script>

<template>
  <div class="left-sidebar-overlay"
       :class="{'shown': state.visible}"
       @click.stop="closeSidebar"
  ></div>
  <div class="left-sidebar" :class="{'shown': state.visible}">
    <header class="left-sidebar-header">
      <span class="sidebar-project-title">{{currentProjectName}}</span>
      <button class="sidebar-caret-down-icon"
              :class="{ 'caret-up': state.projectsListVisible }"
              @click.stop="toggleProjectsList"
      ></button>
    </header>
    <div class="left-sidebar-body">
      <section class="sidebar-body-section animated"
               :class="{
                'shown': state.projectsListVisible,
                'hidden': !state.projectsListVisible,
               }"
      >
        <div class="sidebar-body-section-header">My Projects</div>
        <div v-for="project of sortedProjects"
             class="sidebar-body-section-item section-item-folder"
             @click.stop="projectItemClick(project)"
        >
          <span class="section-item-name">{{project.name}}</span>
        </div>
      </section>
      <section class="sidebar-body-section" v-if="canFavorite">
        <div class="sidebar-body-section-header">Favorite Documents</div>
        <div v-if="!sortedFavorites.length" class="sidebar-body-section-item">
          <span class="section-item-name">No favorites added yet</span>
        </div>
        <div v-else v-for="document of sortedFavorites"
             class="sidebar-body-section-item section-item-file"
             @click.stop="favoriteItemClick(document)"
        >
          <span class="section-item-name">{{document.name}}</span>
          <button class="section-item-button"
                  @click.stop="$emit('toggleFavoriteStatus', document.id, !document?.isFavorite)"
          ></button>
        </div>
      </section>
    </div>
    <footer class="left-sidebar-footer">
      <button class="close-sidebar-button" @click.stop="closeSidebar">Close</button>
    </footer>
  </div>
</template>

<style scoped lang="scss">
.left-sidebar-overlay {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);

  &.shown {
    display: block;
  }
}

.left-sidebar {
  position: absolute;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100%;
  background: #FFFFFF;
  border-right: 1px solid #E2E8F0;
  box-shadow: 0px 4px 8px rgba(51, 65, 85, 0.15);
  display: flex;
  flex-direction: column;
  transition: 0.25s;

  &.shown {
    left: 0;
    transition: 0.25s;
  }

  .left-sidebar-header {
    position: relative;
    padding: 16px;
    border-bottom: 1px solid #E2E8F0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span.sidebar-project-title {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #334155;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    button.sidebar-caret-down-icon {
      width: 26px;
      height: 26px;
      border-radius: 13px;
      border: none;
      background: url("../../../assets/images/sort-select/caret-down.svg") center no-repeat;
      background-size: 16px;

      &.caret-up {
        transform: rotate(180deg);
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.07);
        cursor: pointer;
      }

      &:active {
        transform: translate(1px, 1px);
      }
    }
  }

  .left-sidebar-body {
    position: relative;
    flex-grow: 1;
    overflow: hidden auto;

    .sidebar-body-section {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background-color: white;
      overflow: hidden;

      &.animated.shown {
        max-height: 100%;
        transition: 0.25s;
      }
      &.animated.hidden {
        max-height: 0%;
        transition: 0.25s;
      }

      .sidebar-body-section-header {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #334155;
        text-align: left;

        padding: 14px 16px 6px;
        width: 100%;
      }

      .sidebar-body-section-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        padding-top: 6px;
        padding-right: 16px;
        padding-left: 44px;
        padding-bottom: 5px;

        &.section-item-folder {
          background: url("../../../assets/images/folder.svg") 20px center no-repeat;
        }
        &.section-item-file {
          background: url("../../../assets/images/document.svg") 20px center no-repeat;
        }

        .section-item-name {
          flex-grow: 1;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #64748B;

          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          user-select: none;
        }

        .section-item-button {
          min-width: 16px;
          max-width: 16px;
          height: 16px;
          background: url("../../../assets/images/filled-star.svg") center no-repeat;
          background-size: 12px;
          border: none;
          border-radius: 10px;
          &:hover {
            background-color: rgba(0, 0, 0, 0.1);
            cursor: pointer;
          }
          &:active {
            transform: translate(1px, 1px);
          }
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
          cursor: pointer;
        }
        &:active {
          transform: translate(1px, 1px);
        }
      }
    }
  }

  .left-sidebar-footer {
    border-top: 1px solid #E2E8F0;
    padding: 16px 16px 16px 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    button.close-sidebar-button {
      padding-left: 26px;
      width: 100%;
      border: none;
      background: url("../../../assets/images/sidebar-icon.svg") left center no-repeat;

      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: left;
      color: #334155;

      cursor: pointer;

      &:active {
        transform: translate(1px, 1px);
      }
    }
  }
}
</style>