<template>
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1721 0.828125H1.82853C1.12809 0.828125 0.661133 1.29508 0.661133 1.99552V16.0043C0.661133 16.7047 1.12809 17.1717 1.82853 17.1717H18.1721C18.8725 17.1717 19.3395 16.7047 19.3395 16.0043V1.99552C19.3395 1.29508 18.8725 0.828125 18.1721 0.828125ZM7.66551 4.33031C8.36595 4.33031 8.8329 4.79727 8.8329 5.49771C8.8329 6.19815 8.36595 6.6651 7.66551 6.6651C6.96507 6.6651 6.49811 6.19815 6.49811 5.49771C6.49811 4.79727 6.96507 4.33031 7.66551 4.33031ZM5.33072 8.9999L2.99592 13.6695H17.0047L12.3351 6.6651L8.8329 11.3347L5.33072 8.9999Z" fill="#64748B"/>
  </svg>
</template>

<script>
export default {
  name: "image-icon",
};
</script>
