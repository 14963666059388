export const BROADCAST_MESSAGE = {
  TOAST: "TOAST",
  SYNC_STATUS: "SYNC_STATUS",
  OWNER_UPDATED: "OWNER_UPDATED",
  PROJECTS_UPDATED: "PROJECTS_UPDATED",
  PROJECT_UPDATED: "PROJECT_UPDATED",
  COLLABORATORS_UPDATED: "COLLABORATORS_UPDATED",
  DOCUMENT_UPDATED: "DOCUMENT_UPDATED",
  STAMPS_UPDATED: "STAMPS_UPDATED",
  SEARCH_CHUNK: "SEARCH_CHUNK",
  DATADOG_INFO: "DATADOG_INFO",
  DATADOG_ERROR: "DATADOG_ERROR",
};

export const COLLABORATOR_PERMISSIONS = {
  MANAGE_ANNOTATION: "MANAGE_ANNOTATION",
  DELETE_FOREIGN_ANNOTATION: "DELETE_FOREIGN_ANNOTATION",
  MANAGE_OWN_COMMENT: "MANAGE_OWN_COMMENT",
  DELETE_FOREIGN_COMMENT: "DELETE_FOREIGN_COMMENT",
  SHARE_PROJECT: "SHARE_PROJECT",
  PUBLISH_PROJECT: "PUBLISH_PROJECT",
  RENAME_PROJECT: "RENAME_PROJECT",
  DELETE_PROJECT: "DELETE_PROJECT",
  ARCHIVE_PROJECT: "ARCHIVE_PROJECT",
  DUPLICATE_PROJECT: "DUPLICATE_PROJECT",
  UPLOAD_DOCUMENT: "UPLOAD_DOCUMENT",
  RENAME_DOCUMENT: "RENAME_DOCUMENT",
  DELETE_DOCUMENT: "DELETE_DOCUMENT",
  COPY_DOCUMENT: "COPY_DOCUMENT",
  DOWNLOAD_SOURCE_DOCUMENT: "DOWNLOAD_SOURCE_DOCUMENT",
  DOWNLOAD_ORIGINAL_DOCUMENT: "DOWNLOAD_ORIGINAL_DOCUMENT",
  DOWNLOAD_ANNOTATED_DOCUMENT: "DOWNLOAD_ANNOTATED_DOCUMENT",
};

export const PUBLIC_PROJECT = "PUBLIC";
export const PRIVATE_PROJECT = "PRIVATE";

export const REVOKED = "REVOKED";
export const REJECTED = "REJECTED";
export const OWNER = "OWNER";
export const EDITOR = "EDITOR";
export const CONTRIBUTOR = "CONTRIBUTOR";
export const VIEWER = "VIEWER";
export const PUBLIC_VIEWER = "PUBLIC_VIEWER";

export const SYNCING_STATUS = {
  WORKING: "WORKING",
  FAILED: "FAILED",
  IDLE: "IDLE",
};

export const SYNCING_TYPE = {
  CREATE: "CREATE",
  DELETE: "DELETE",
  UPDATE_NAME: "UPDATE_NAME",
  UPDATE_FAVORITE: "UPDATE_FAVORITE",
  UPDATE_PUBLIC: "UPDATE_PUBLIC",
  UPDATE_ROLE: "UPDATE_ROLE",
  INSERT_CHANGE: "INSERT_CHANGE",
  DELETE_CHANGE: "DELETE_CHANGE",
};

export const ACCESS_ERRORS = {
  UNAUTHORIZED: "UNAUTHORIZED",
  PROFILE_ACCESS_DENIED: "PROFILE_ACCESS_DENIED",
  PROJECT_ACCESS_DENIED: "PROJECT_ACCESS_DENIED",
  DOCUMENT_ACCESS_DENIED: "DOCUMENT_ACCESS_DENIED",
};

export const role = {
  [OWNER]: 0,
  [EDITOR]: 1,
  [CONTRIBUTOR]: 2,
  [VIEWER]: 3,
};

export const MAP_CONSTANTS = {
  SANS_SERIF: "Inter",
  SERIF: "Lora",
  MONOSPACE: "Courier Prime",
  SCRIPT: "Cookie",
  FANTASY: "Eagle Lake",

  W400: "normal",
  W600: "bold",

  START: "left",
  CENTER: "center",
  END: "right",
};
export const FONT_FAMILY = {
  SANS_SERIF: "SANS_SERIF",
  SERIF: "SERIF",
  MONOSPACE: "MONOSPACE",
  SCRIPT: "SCRIPT",
  FANTASY: "FANTASY",
};
export const FONT_WEIGHT = {
  W400: "W400",
  W600: "W600",
};
export const TEXT_ALIGNMENT = {
  START: "START",
  CENTER: "CENTER",
  END: "END",
};

export const ANNO_TYPES = {
  INK: "InkAnnotation",
  CIRCLE: "CircleAnnotation",
  SQUARE: "SquareAnnotation",
  ARROW: "ArrowAnnotation",
  HIGHLIGHT: "HighlightAnnotation",
  TEXT_BOX: "TextBoxAnnotation",
  IMAGE: "ImageAnnotation",
  COMMENT: "CommentAnnotation",
  REPLY: "ReplyAnnotation",
  DOCUMENT_DELETE: "DocumentDelete",
};

export const STAMP_TYPES = {
  INK: "InkDocumentStamp",
  CIRCLE: "CircleDocumentStamp",
  SQUARE: "SquareDocumentStamp",
  ARROW: "ArrowDocumentStamp",
  TEXT_BOX: "TextBoxDocumentStamp",
  IMAGE: "ImageDocumentStamp",
};

export const ANNO_WEIGHT = {
  [ANNO_TYPES.DOCUMENT_DELETE]: 0,
  [ANNO_TYPES.INK]: 1,
  [ANNO_TYPES.CIRCLE]: 1,
  [ANNO_TYPES.SQUARE]: 1,
  [ANNO_TYPES.ARROW]: 1,
  [ANNO_TYPES.HIGHLIGHT]: 1,
  [ANNO_TYPES.TEXT_BOX]: 1,
  [ANNO_TYPES.IMAGE]: 1,
  [ANNO_TYPES.COMMENT]: 1,
  [ANNO_TYPES.REPLY]: 2,
};

export const SUCCESS_TOAST = "SUCCESS_TOAST";
export const INFO_TOAST = "INFO_TOAST";
export const WARN_TOAST = "WARN_TOAST";
export const ERROR_TOAST = "ERROR_TOAST";
export const OFFLINE_TOAST = "OFFLINE_TOAST";

export const CLOUD_STORAGE = {
  GOOGLE_DRIVE: "GOOGLE_DRIVE",
  ONE_DRIVE: "ONE_DRIVE",
  BOX_DRIVE: "BOX",
  DROPBOX_DRIVE: "DROPBOX",
};

export const ROLE_PERMISSIONS = [
  {
    role: "PUBLIC_VIEWER",
    // permissions: ["DOWNLOAD_ANNOTATED_DOCUMENT"],
    permissions:[]
  },
  {
    role: "VIEWER",
    // permissions: ["DOWNLOAD_ANNOTATED_DOCUMENT"],
    permissions: [],
  },
  {
    role: "CONTRIBUTOR",
    permissions: [
      "MANAGE_ANNOTATION",
      "MANAGE_OWN_COMMENT",
      "DUPLICATE_PROJECT",
      "UPLOAD_DOCUMENT",
      "RENAME_DOCUMENT",
      "COPY_DOCUMENT"
      // "DOWNLOAD_SOURCE_DOCUMENT",
      // "DOWNLOAD_ORIGINAL_DOCUMENT",
      // "DOWNLOAD_ANNOTATED_DOCUMENT",
    ],
  },
  {
    role: "EDITOR",
    permissions: [
      "MANAGE_ANNOTATION",
      "DELETE_FOREIGN_ANNOTATION",
      "MANAGE_OWN_COMMENT",
      "DELETE_FOREIGN_COMMENT",
      "PUBLISH_PROJECT",
      "SHARE_PROJECT",
      "RENAME_PROJECT",
      "DUPLICATE_PROJECT",
      "UPLOAD_DOCUMENT",
      "RENAME_DOCUMENT",
      "DELETE_DOCUMENT",
      "COPY_DOCUMENT",
      "DOWNLOAD_SOURCE_DOCUMENT",
      "DOWNLOAD_ORIGINAL_DOCUMENT",
      "DOWNLOAD_ANNOTATED_DOCUMENT",
    ],
  },
  {
    role: "OWNER",
    permissions: [
      "MANAGE_ANNOTATION",
      "DELETE_FOREIGN_ANNOTATION",
      "MANAGE_OWN_COMMENT",
      "DELETE_FOREIGN_COMMENT",
      "PUBLISH_PROJECT",
      "SHARE_PROJECT",
      "RENAME_PROJECT",
      "DELETE_PROJECT",
      "ARCHIVE_PROJECT",
      "DUPLICATE_PROJECT",
      "UPLOAD_DOCUMENT",
      "RENAME_DOCUMENT",
      "DELETE_DOCUMENT",
      "COPY_DOCUMENT",
      "DOWNLOAD_SOURCE_DOCUMENT",
      "DOWNLOAD_ORIGINAL_DOCUMENT",
      "DOWNLOAD_ANNOTATED_DOCUMENT",
    ],
  },
]

export const BUTTON_ROLES = {
  UNDO: "UNDO",
  REDO: "REDO",
  POINTER: "POINTER",
  INK: "INK",
  HIGHLIGHTER: "HIGHLIGHTER",
  MARKER: "MARKER",
  UNDERLINE: "UNDERLINE",
  CROSSLINE: "CROSSLINE",
  COMMENT: "COMMENT",
  STAMPS: "STAMPS",
  SHAPES: "SHAPES",
  SQUARE: "SQUARE",
  CIRCLE: "CIRCLE",
  ARROW: "ARROW",
  TEXT: "TEXT_BOX",
  IMAGE: "IMAGE",
  ERASERS: "ERASERS",
  OBJECT_ERASER: "ERASER",
  PIXEL_ERASER: "PIXEL_ERASER",
  CLOSE: "CLOSE",
};

export const TOOL_PRESETS_MAP = {
  [BUTTON_ROLES.INK]: "inkPresets",
  [BUTTON_ROLES.MARKER]: "markerPresets",
  [BUTTON_ROLES.UNDERLINE]: "underlinePresets",
  [BUTTON_ROLES.CROSSLINE]: "crosslinePresets",
  [BUTTON_ROLES.SQUARE]: "squarePresets",
  [BUTTON_ROLES.CIRCLE]: "circlePresets",
  [BUTTON_ROLES.ARROW]: "arrowPresets",
  [BUTTON_ROLES.TEXT]: "textBoxPresets",
  [BUTTON_ROLES.PIXEL_ERASER]: "eraserPresets",
};
