<template>
  <svg width="19" height="8" viewBox="0 0 19 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.91462 0.498047C11.4374 0.498047 13.9087 1.34908 15.919 2.85268V0.498047H18.2538V6.33503C18.2538 6.9806 17.7308 7.50242 17.0864 7.50242H11.2494V5.16763H15.0761C13.3822 3.6827 11.1735 2.83284 8.91462 2.83284C6.45725 2.83284 4.05008 3.82979 2.31183 5.56805L0.661133 3.91735C2.83366 1.74366 5.8432 0.498047 8.91462 0.498047Z" fill="#64748B"/>
  </svg>
</template>

<script>
export default {
  name: "redo",
};
</script>
