<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.0001 7.70801H11.4584V4.16634M8.12508 12.708H11.8751M8.12508 10.208H11.8751M13.5417 16.0413H6.45841C5.53794 16.0413 4.79175 15.2951 4.79175 14.3747V5.62467C4.79175 4.7042 5.53794 3.95801 6.45841 3.95801H11.6667L15.2084 7.49967V14.3747C15.2084 15.2951 14.4622 16.0413 13.5417 16.0413Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  name: "document-icon"
}
</script>

<style scoped>

</style>
