import { reactive } from "vue";
export const state = reactive({
  showProfileUpdateView: false,
  isProfileDataLoading:true,
  favoriteDocumentChange:{docId: null, isFavorite:false}
});

export const getApplicationState = {
  getProfileUpdateStatus() {
    return state.showProfileUpdateView;
  },
  getProfileDataLoadingStatus(){
    return state.isProfileDataLoading
  },
  setFavoriteDocumentChange(){
    return state.favoriteDocumentChange
  }
};

export const setApplicationState = {
  setProfileUpdateStatus(status) {
    console.log({status});
    state.showProfileUpdateView = status;
    return;
  },
  setProfileDataLoadingStatus(status){
    state.isProfileDataLoading = status
    return;
  },
  setFavoriteDocumentChange({docId,isFavorite}){
    state.favoriteDocumentChange = {docId,isFavorite}
    return;
  }
};

const store ={ getApplicationState,setApplicationState};
export default store;
