<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9997 6.16602H9.16634V3.33268M6.49967 10.166H9.49967M6.49967 8.16602H9.49967M10.833 12.8327H5.16634C4.42996 12.8327 3.83301 12.2357 3.83301 11.4993V4.49935C3.83301 3.76297 4.42996 3.16602 5.16634 3.16602H9.33301L12.1663 5.99935V11.4993C12.1663 12.2357 11.5694 12.8327 10.833 12.8327Z" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "file-category-icon",
};
</script>

<style scoped>

</style>
