<template>
  <div class="wrapper">
    <DynamicSubscriptionView/>
  </div>
  

</template>

<script>
import { inject, onMounted, ref, watch, computed } from "vue";
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import DynamicSubscriptionView from "./stripe/DynamicSubscriptionView.vue";




export default {
  name: "SubscriptionSettings",
  components: {
    StripeCheckout,
    DynamicSubscriptionView
  },
  setup() {
    return {

    };
  },

};
</script>

<style scoped lang="scss">
.wrapper{
  min-width: 80svw
}
.subscription-wrapper {
  width: 100svw;
  margin: 90px;

}

</style>
