class OpeningDocument {
  #state = {
    clarifications: null,
  };

  constructor() {}

  get clarifications() {
    return Object.assign({}, this.#state.clarifications);
  }

  setOpenedPage(pageNumber) {
    this.#state.clarifications["page"] = pageNumber;
    return this;
  }

  openLiveSearchWithQuery(query) {
    this.#state.clarifications["liveSearchQuery"] = query;
    return this;
  }

  resetClarification() {
    this.#state.clarifications = {};
    return this;
  }
}
export const openingDocument = new OpeningDocument();
