<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.1663 4.83398V3.83398H3.83301V4.83398M7.99967 4.00065V12.1673M7.99967 12.1673H7.16634M7.99967 12.1673H8.83301" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "text-anno-category-icon",
};
</script>

<style scoped>

</style>
