<template>
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.9892 5.2659L14.3197 0.596312C13.8527 0.129354 13.1523 0.129354 12.6853 0.596312L3.34614 9.93548C2.87918 10.4024 2.87918 11.1029 3.34614 11.5698L4.51354 12.7372L0.661133 16.5896L4.16332 17.757L6.84833 15.072L8.01573 16.2394C8.36594 16.5896 8.94964 16.9399 9.65008 16.2394L18.9892 6.90025C19.4562 6.43329 19.4562 5.73285 18.9892 5.2659Z" fill="#64748B"/>
  </svg>
</template>

<script>
export default {
  name: "highlight-icon",
};
</script>
