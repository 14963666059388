<script>
export default {
  name: "DialogButton",
  props: ["title"],
  emits: ["click"],
  setup(props) {
    return {}
  }
}
</script>

<template>
<button class="dialog-button" @click.stop="$emit('click')">
  {{title}}
</button>
</template>

<style scoped lang="scss">
.dialog-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  border: 1px solid #E2E8F0;
  gap: 8px;
  background-color: white;
  margin: 0;

  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #334155;
  cursor: pointer;

  &.save {
    background: #E2E8F0;
  }

  &:disabled {
    color: silver;
  }

  &:enabled:active {
    transform: translate(1px, 1px);
  }
}
</style>