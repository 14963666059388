<template>
  <div class="mt-5 overflow-y-scroll max-h-80 min-h-80" :class="{ 'with-overflow': hasOverflow }"
    ref="participantsList">
    <div class="participants-list-item" v-for="person of filteredParticipantsList">
      <!-- <Avatar class="item-avatar" :owner="person.profile?.username || person.email" /> -->
      <div class="-mt-5">
        <img src="/src/assets/images/avatar.svg" alt="" srcset="">
      </div>
      <div class="item-body">
        <div class="item-name">
          <span :class="{ 'item-name-text': true }">
            {{ person?.name || person.email }}
          </span>
        </div>
        <div class="item-email">{{ person.email }}</div>
        <div class="flex flex-col items-start text-left">
          <span class="text-xs text-gray-500 text-left rounded" v-if="person.approved && person.lastAccessedAtValue !='N/A'">Last accessed {{
    person?.lastAccessedAtValue }}</span>
          <span class="bg-gray-200 text-xs text-gray-500 p-1 text-left rounded" v-else-if="person.approved && person.lastAccessedAtValue =='N/A'"> Not yet accessed</span>
          <span class="bg-red-100 text-xs text-red-400 p-1 text-left rounded" v-if="!person.approved">Folia
            account not yet created</span>
        </div>
      </div>
      <div class="text-right mr-2">
        <span v-if="person.role === 'OWNER'" class="item-is-author">Owner</span>
        <PermissionSelector v-if="isPermissionSelectorShown(person.email, person.role)" class="item-permission"
          :isOwner="participantsList.isOwner" :isRejectShown="isRejectShown(person.email, person.role)"
          :value="person.role" @input="changePersonPermission(person, $event)" text-align="left" :withReject="true" />
        <span v-else-if="person.role !== 'OWNER'" class="item-is-role">{{ person.role }}</span>
        <span v-if="person.role !== 'OWNER' && person.showResendInvitation"
          class="flex text-xs text-gray-800 border border-gray-500 rounded-lg p-2 cursor-pointer hover:bg-gray-200"
          @click="resendInvitation(person)">
          <p class="font-semibold">Resend Invitation</p>
          <svg class="ml-1" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.25022 7.99774H3.25" stroke="#334155" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
            <path
              d="M1.28048 13.8281C1.24533 13.9241 1.24045 14.0286 1.26652 14.1274C1.29259 14.2263 1.34834 14.3148 1.42626 14.381C1.50419 14.4472 1.60054 14.488 1.70232 14.4977C1.80411 14.5075 1.90644 14.4858 1.99552 14.4356L12.4961 8.42963C12.5743 8.38638 12.6394 8.323 12.6848 8.24605C12.7301 8.16911 12.7541 8.08142 12.7541 7.9921C12.7541 7.90278 12.7301 7.81509 12.6848 7.73815C12.6394 7.66121 12.5743 7.59782 12.4961 7.55458L1.99552 1.56362C1.90671 1.51395 1.80486 1.49255 1.70357 1.5023C1.60227 1.51204 1.50637 1.55246 1.42865 1.61815C1.35094 1.68384 1.29511 1.77168 1.26864 1.86993C1.24216 1.96819 1.2463 2.07218 1.28048 2.16803L3.24997 7.99773L1.28048 13.8281Z"
              stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <Loader v-if="isResending && resendingInfo?.email === person.email" class="max-w-4 -mt-2 ml-1"></Loader>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "../Avatar.vue";
import PermissionSelector from "./PermissionSelector.vue";
import { VIEWER, EDITOR, CONTRIBUTOR, OWNER, REVOKED } from "../../../core/constants";
import { computed, inject, onMounted, ref } from "vue";
import Loader from '../../common/loaders/defaultLoader.vue';
import moment from "moment";
import ProjectService from "../../../service/projectService";
export default {
  name: "ParticipantsList",
  components: { PermissionSelector, Avatar, Loader },
  props: ['projectId', 'participantsList', 'currentUserRole'],
  setup(props, context) {
    const $auth0 = inject("$auth0");
    const hasOverflow = ref(false);
    const currentUserEmail = ref("");
    const isResending = ref(false);
    const resendingInfo = ref(null);
    const projectService = new ProjectService();
    onMounted(async () => {
      const user = await $auth0.getUser();
      currentUserEmail.value = `${user.profile.email}`.toLowerCase();

    });

    function changePersonPermission(person, newPermission) {
      context.emit('changePermission', [{ email: person.email, role: newPermission }])
    }
    function isRejectShown(personEmail, personRole) {
      return personEmail === currentUserEmail.value && personRole !== OWNER;
    }
    const filteredParticipantsList = computed(() => {
      const collaborators = props.participantsList.collaborators.map(c => {
        const { lastAccessedAt, profileStatus } = c;
        c.approved = (profileStatus === 'APPROVED');
        c.showResendInvitation = lastAccessedAt === '0';
        if (lastAccessedAt === '0') {
          c.lastAccessedAtValue = 'N/A'
        } else {
          const now = moment();
           const lastAccessedAtModified = parseInt(lastAccessedAt, 10);
          const lastAccessedAtMoment = moment(lastAccessedAtModified);
          if (now.diff(lastAccessedAtMoment, 'days') === 1 && now.isAfter(lastAccessedAtMoment)) {
            c.lastAccessedAtValue = `Yesterday at ${lastAccessedAtMoment.format('hh:mm A')}`
          } else {
            c.lastAccessedAtValue = `${lastAccessedAtMoment.format("MMM DD")} at ${lastAccessedAtMoment.format("hh:mm A")}`;
          }
        }
        return c;
      });
      return collaborators;
    })
    function isPermissionSelectorShown(personEmail, personRole) {
      if (personRole !== OWNER && (props.currentUserRole === EDITOR || props.currentUserRole === OWNER)) {
        return true;
      }
      if (personRole !== OWNER && (props.currentUserRole === VIEWER || props.currentUserRole === CONTRIBUTOR)) {
        return personEmail === currentUserEmail.value;

      }
      return false
    }
    const resendInvitation = async(person) => {
      resendingInfo.value = person;
      isResending.value = true;
      await projectService.shareProject({
          projectId: props.projectId, collaboratorEmail: person.email,
          collaboratorRole: person.role
        });
        isResending.value = false;
    }
    return {
      hasOverflow,
      isResending,
      resendingInfo,
      changePersonPermission,
      isRejectShown,
      filteredParticipantsList,
      isPermissionSelectorShown,
      resendInvitation
    }
  },
}
</script>

<style scoped lang="scss">
.participants-list {
  border-top: solid 1px #C6C8CA;
  border-bottom: solid 1px #EFEFEF;
}

.with-overflow {
  border-bottom: solid 1px #C6C8CA;
}

.participants-list-item {
  display: flex;
  align-items: center;
  margin: 8px 0;
  border-bottom: 1px solid var(--gray-100, #F1F5F9);
  min-height: 100px;

  .item-loading {
    display: inline-block;
    background: url("../../../assets/images/loading-icon.gif") center no-repeat;
    background-size: 20px;
    width: 28px;
    height: 28px;
  }

  .item-avatar {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    width: 30px;
    height: 30px;
    padding: 5px;
    margin: 0 5px;
    border-radius: 50%;
    border: 2px solid #e2e8f0;
    background-color: #8cb3d5;
    color: #FFFFFF;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }

  .item-body {
    flex-grow: 1;
    margin-left: 10px;

    .item-name {
      display: flex;
      align-items: center;

      .item-name-text {
        margin-right: 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #343541;

        max-width: 270px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .authors-name {
        max-width: 400px;
      }

      .item-invited {
        padding: 1px 8px 1px 8px;
        background: #FFD6CB;
        border-radius: 2px;
        font-weight: 400;
        font-size: 12px;
        text-align: center;
        color: #F04E23;
      }

      .item-opened {
        padding: 1px 8px 1px 8px;
        background: #CBECFF;
        border-radius: 2px;
        font-weight: 400;
        font-size: 12px;
        text-align: center;
        color: #239AF0;
      }
    }

    .item-email {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #979797;
      text-align: start;
      max-width: 270px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .item-is-author {
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #979797;
    margin-right: 30px;
  }

  .item-is-role {
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #979797;
    margin-right: 30px;
  }

  .item-permission {
    border: none;
    box-shadow: none;
  }
}
</style>
