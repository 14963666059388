<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.25 4.75V19.25M4.75 12H15.25M4.75 12L8.25 8.75M4.75 12L8.25 15.25" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "arrow-back-left"
}
</script>

<style scoped>

</style>
