import { EventBus } from "../core/event_utils.js";
import { v4 as uuid } from "uuid";
import {
  BROADCAST_MESSAGE,
  ERROR_TOAST,
  INFO_TOAST,
  PUBLIC_VIEWER,
  SUCCESS_TOAST,
} from "../core/constants.js";
import {
  DocumentAccessError,
  GraphqlAPI,
  ProjectAccessError,
} from "../core/graphql-api.js";
import { mutations } from "../store/index.js";

class ServiceWorkerProxy extends EventBus {
  constructor(vueApp, graphqlServer) {
    super();
    this.vueApp = vueApp;
    this.graphqlServer = graphqlServer;
    this.api = null;
    this.initConnection2sw();
  }

  initConnection2sw() {
    navigator.serviceWorker.addEventListener("message", (e) => {
      const { eventName, resData, error } = e.data;
      switch (eventName) {
        case BROADCAST_MESSAGE.TOAST:
        case BROADCAST_MESSAGE.SYNC_STATUS:
        case BROADCAST_MESSAGE.OWNER_UPDATED:
        case BROADCAST_MESSAGE.PROJECTS_UPDATED:
        case BROADCAST_MESSAGE.PROJECT_UPDATED:
        case BROADCAST_MESSAGE.COLLABORATORS_UPDATED:
        case BROADCAST_MESSAGE.DOCUMENT_UPDATED:
        case BROADCAST_MESSAGE.SEARCH_CHUNK:
          this.dispatch(eventName, resData);
          break;
        case BROADCAST_MESSAGE.DATADOG_INFO:
        case BROADCAST_MESSAGE.DATADOG_ERROR:
          break;
        default:
          // console.log("tab listener", eventName, data, error);
          this.dispatch(eventName, { resData, error });
          break;
      }
    });
  }

  async prepareCredentials() {
    const user = await this.vueApp.config.globalProperties.$auth0.getUser();

    // try {
    //   const [headerBASE64, bodyBASE64, signatureBASE64] =
    //     user.accessToken.split(".");
    //   const body = JSON.parse(window.atob(bodyBASE64));
    //   console.log(new Date(body.exp * 1000).toTimeString());
    // } catch (e) {
    //   console.error(e);
    // }

    return {
      graphqlServer: this.graphqlServer,
      graphqlAccessToken: user.accessToken,
      userEmail: `${user.profile.email}`.toLowerCase(),
    };
  }

  buildGraphqlAPI() {
    return this.prepareCredentials().then((credentials) => {
      const { graphqlServer, graphqlAccessToken, userEmail } = credentials;
      return new GraphqlAPI(graphqlServer, graphqlAccessToken);

      // if (!this.api || this.api.isTokenValid(graphqlAccessToken)) {
      //   this.api = new GraphqlAPI(graphqlServer, graphqlAccessToken);
      // }
      //
      // return this.api;
    });
  }

  postMessage(eventName, reqData) {
    const responseName = uuid();
    return this.prepareCredentials().then((credentials) => {
      // create promised job
      return new Promise((resolve, reject) => {
        try {
          //prepare message body
          const message = {
            eventName,
            responseName,
            credentials,
            reqData,
          };

          // prepare response listener
          const responseListener = ({ resData, error }) => {
            // console.log("response from worker", { resData, error });
            this.off(responseName, responseListener);
            if (error) {
              error.initialEvent = eventName;
              return reject(error);
            }
            resolve(resData);
          };

          // create timeout promise
          setTimeout(() => {
            this.off(responseName, responseListener);
            reject(new Error("tab.timeout"));
          }, 30_000);

          // wait for local response
          this.on(responseName, responseListener);

          // send locally
          super.dispatch(eventName, message);

          // send to sw message and wait for him response
          navigator.serviceWorker.ready
            .then((registration) => {
              registration.active.postMessage(message);
              // console.log("post to worker", structuredClone(message));
            })
            .catch((error) => {
              this.off(responseName, responseListener);
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    });
  }

  // prettier-ignore
  serviceWorkerProxyErrorHandler(error) {
    console.log(`Error during %c"${error.initialEvent}"`, "color: orangered; font-weight: bold;", ` with message: ${error.message}`);
    const { globalProperties: { $router } } = this.vueApp.config;
    const { projectId, documentId } = $router.currentRoute.value.params;

    if (error.initialEvent === "incomingSync" && error.message === DocumentAccessError.name) {
      this.dispatch(BROADCAST_MESSAGE.TOAST, {
        type: ERROR_TOAST,
        message: "Document access denied",
      });

      return $router.push(`/p/${projectId}`);
    } else if (error.initialEvent === "incomingSync" && error.message === ProjectAccessError.name) {
      this.dispatch(BROADCAST_MESSAGE.TOAST, {
        type: ERROR_TOAST,
        message: "Project access denied",
      });

      return $router.push("/");
    }

    throw error;
  }
  // version
  async version() {
    return await this.postMessage("version", null)
      .then((versions) => {
        return { environment: import.meta.env.MODE, ...versions };
      })
      .catch(this.serviceWorkerProxyErrorHandler.bind(this));
  }

  // // deprecated
  // async outputSyncProjects() {
  //   await this.postMessage("outgoingSync", {}).catch(
  //     this.serviceWorkerProxyErrorHandler.bind(this)
  //   );
  // }
  // async outputSyncProject(projectId) {
  //   await this.postMessage("outgoingSync", { projectId }).catch(
  //     this.serviceWorkerProxyErrorHandler.bind(this)
  //   );
  // }
  // async outputSyncDocument(projectId, documentId) {
  //   await this.postMessage("outgoingSync", { projectId, documentId }).catch(
  //     this.serviceWorkerProxyErrorHandler.bind(this)
  //   );
  // }
  // async outputSyncCollaborators(projectId) {
  //   await this.postMessage("outgoingCollaboratorsSync", projectId).catch(
  //     this.serviceWorkerProxyErrorHandler.bind(this)
  //   );
  // }

  // sync
  async inputSyncUser() {
    await this.postMessage("getProfile", null).catch(
      this.serviceWorkerProxyErrorHandler.bind(this)
    );
  }
  async inputSyncProjects(force = false) {
    await this.postMessage("incomingSync", { force }).catch(
      this.serviceWorkerProxyErrorHandler.bind(this)
    );
  }
  async inputSyncProject(force = false, projectId) {
    await this.postMessage("incomingSync", { force, projectId }).catch(
      this.serviceWorkerProxyErrorHandler.bind(this)
    );
  }
  async inputSyncDocument(force = false, projectId, documentId) {
    await this.postMessage("incomingSync", {
      force,
      projectId,
      documentId,
    }).catch(this.serviceWorkerProxyErrorHandler.bind(this));
  }
  async documentChangesSubscription(documentId) {
    await this.postMessage("subscribe", { documentId }).catch(
      this.serviceWorkerProxyErrorHandler.bind(this)
    );
    return async () => {
      await this.postMessage("unsubscribe", { documentId }).catch(
        this.serviceWorkerProxyErrorHandler.bind(this)
      );
    };
  }
  async outputSync() {
    await this.postMessage("outputSync", {}).catch(
      this.serviceWorkerProxyErrorHandler.bind(this)
    );
  }

  // sw api
  async getUserProfile() {
    return await this.postMessage("getUserProfile");
  }
  async getUserPermissions() {
    return await this.postMessage("getUserPermissions").catch(
      this.serviceWorkerProxyErrorHandler.bind(this)
    );
  }
  async updateUserName(name) {
    return await this.postMessage("updateProfile", { name }).catch(
      this.serviceWorkerProxyErrorHandler.bind(this)
    );
  }
  async deleteUserData() {
    return await this.postMessage("deleteUserData").catch(
      this.serviceWorkerProxyErrorHandler.bind(this)
    );
  }
  async deleteAccount() {
    try {
      const api = await this.buildGraphqlAPI();
      const {
        deleteUserProfile: { success },
      } = await api.deleteAccount();
      return success;
    } catch (e) {
      this.serviceWorkerProxyErrorHandler(e);
    }
  }

  async getProjects() {
    return await this.postMessage("getProjects").catch(
      this.serviceWorkerProxyErrorHandler.bind(this)
    );
  }
  async getProject(projectId) {
    return await this.postMessage("getProject", {
      projectId,
    }).catch(this.serviceWorkerProxyErrorHandler.bind(this));
  }
  async deleteProject(projectId) {
    await this.postMessage("deleteProject", { projectId }).catch(
      this.serviceWorkerProxyErrorHandler.bind(this)
    );
  }
  async createProject(name, description) {
    return await this.postMessage("createProject", {
      name,
      description,
    }).catch(this.serviceWorkerProxyErrorHandler.bind(this));
  }
  async updateProjectName(projectId, name, description) {
    return await this.postMessage("updateProjectName", {
      projectId,
      name,
      description,
    }).catch(this.serviceWorkerProxyErrorHandler.bind(this));
  }
  async updateProjectPublicStatus(projectId, publicStatus) {
    return await this.postMessage("updateProjectPublicStatus", {
      projectId,
      publicStatus,
    }).catch(this.serviceWorkerProxyErrorHandler.bind(this));
  }
  async getProjectCollaborators(projectId) {
    return await this.postMessage("getProjectCollaborators", {
      projectId,
    }).catch(this.serviceWorkerProxyErrorHandler.bind(this));
  }
  async shareProject(projectId, email, role) {
    const data = { projectId, email, role };
    await this.postMessage("shareProject", data).catch(
      this.serviceWorkerProxyErrorHandler.bind(this)
    );
  }
  async getDocuments(projectId) {
    return await this.postMessage("getDocuments", { projectId }).catch(
      this.serviceWorkerProxyErrorHandler.bind(this)
    );
  }
  async getDocument(documentId) {
    return await this.postMessage("getDocument", { documentId }).catch(
      this.serviceWorkerProxyErrorHandler.bind(this)
    );
  }
  async getDocumentPreview(documentId) {
    return await this.postMessage("getDocumentPreview", { documentId }).catch(
      this.serviceWorkerProxyErrorHandler.bind(this)
    );
  }
  async getDocumentPDF(documentId) {
    return await this.postMessage("getDocumentPDF", { documentId }).catch(
      this.serviceWorkerProxyErrorHandler.bind(this)
    );
  }
  async updateDocumentName(documentId, addedAt, name) {
    await this.postMessage("updateDocumentName", {
      documentId,
      addedAt,
      name,
    }).catch(this.serviceWorkerProxyErrorHandler.bind(this));
  }
  async deleteDocument(documentId) {
    await this.postMessage("deleteDocument", {
      documentId,
    }).catch(this.serviceWorkerProxyErrorHandler.bind(this));
  }
  async uploadFile(projectId, id, name, mimeType, base64file, createdAt) {
    const reqData = { projectId, id, name, mimeType, base64file, createdAt };
    return await this.postMessage("uploadDocument", reqData).catch(
      this.serviceWorkerProxyErrorHandler.bind(this)
    );
  }

  async setDocumentFavoriteStatus(documentId, addedAt, isFavorite) {
    await this.postMessage("setDocumentFavoriteStatus", {
      documentId,
      addedAt,
      isFavorite,
    }).catch(this.serviceWorkerProxyErrorHandler.bind(this));
  }
  async setRepliesReadStatus(statuses) {
    await this.postMessage("setRepliesReadStatus", { statuses }).catch(
      this.serviceWorkerProxyErrorHandler.bind(this)
    );
  }
  async getObjects(documentId) {
    return await this.postMessage("getObjects", { documentId }).catch(
      this.serviceWorkerProxyErrorHandler.bind(this)
    );
  }
  async updateObject(projectId, documentId, objectData) {
    await this.postMessage("updateObject", {
      projectId,
      documentId,
      objectData,
    }).catch(this.serviceWorkerProxyErrorHandler.bind(this));
  }

  async getStamps() {
    return await this.postMessage("getStamps", {}).catch(
      this.serviceWorkerProxyErrorHandler.bind(this)
    );
  }
  async addStamp(stampData) {
    await this.postMessage("addStamp", { stampData }).catch(
      this.serviceWorkerProxyErrorHandler.bind(this)
    );
  }
  async deleteStamp(stampId) {
    await this.postMessage("deleteStamp", { stampId }).catch(
      this.serviceWorkerProxyErrorHandler.bind(this)
    );
  }
  async favoriteStamp(stampId, isFavorite) {
    await this.postMessage("favoriteStamp", { stampId, isFavorite }).catch(
      this.serviceWorkerProxyErrorHandler.bind(this)
    );
  }

  // old $graphQL
  async checkUserProfile() {
    const api = await this.buildGraphqlAPI();
    return await api.getOwnerProfile();
  }
  async subscribeOnSearch(query, onDataCb, onErrorCb, onCompleteCb) {
    try {
      const api = await this.buildGraphqlAPI();
      const permissions = await this.getUserPermissions();
      let subscription = api.subscribeOnSearch(query).subscribe(
        (data) => {
          const {
            data: { search },
          } = data;
          // prettier-ignore
          //if (!search.project.sessionUserRole && search.project.publicStatus === "PRIVATE") return;
          search.project.userRole = search.project.ownRole || PUBLIC_VIEWER;
          search.project.permissions = permissions[search.project.userRole];
          onDataCb(search);
        },
        (err) => {
          subscription.unsubscribe();
          subscription = null;
          onErrorCb(err);
        },
        () => {
          subscription.unsubscribe();
          subscription = null;
          onCompleteCb();
        }
      );
      return () => subscription && subscription.unsubscribe();
    } catch (e) {
      this.serviceWorkerProxyErrorHandler(e);
    }
  }
  async copyDocument2AnotherProject(...args) {
    // documentId, sourceProjectId, targetProjectId, withAnnotations
    try {
      const api = await this.buildGraphqlAPI();
      const {
        copyDocument: { success },
      } = await api.copyDocument2AnotherProject(...args);
      return success;
    } catch (e) {
      this.serviceWorkerProxyErrorHandler(e);
    }
  }
  async duplicateActiveProject(projectId) {
    try {
      const api = await this.buildGraphqlAPI();
      return await api.duplicateProject(projectId);
    } catch (e) {
      this.serviceWorkerProxyErrorHandler(e);
    }
  }
  async uploadCloudFiles(...args) {
    try {
      const api = await this.buildGraphqlAPI();
      return await api.cloudStorages.uploadDocument(...args);
    } catch (e) {
      this.serviceWorkerProxyErrorHandler(e);
    }
  }
  async getUploadStatus() {
    try {
      const api = await this.buildGraphqlAPI();
      return await api.getUploadStatus();
    } catch (e) {
      this.serviceWorkerProxyErrorHandler(e);
    }
  }
  async getCloudStoragesList() {
    try {
      const api = await this.buildGraphqlAPI();
      return await api.cloudStorages.getAll();
    } catch (e) {
      this.serviceWorkerProxyErrorHandler(e);
    }
  }
  async authorizeCloudStorage(data = {}) {
    try {
      const { provider, id, name, access_token, refresh_token } = data;
      const addedAt = new Date().toISOString();
      const api = await this.buildGraphqlAPI();
      return await api.cloudStorages.authorizeStorage(
        id,
        addedAt,
        name,
        provider,
        access_token,
        refresh_token
      );
    } catch (e) {
      this.serviceWorkerProxyErrorHandler(e);
    }
  }
  async deleteCloudStorage(storageId) {
    try {
      const api = await this.buildGraphqlAPI();
      return await api.cloudStorages.deleteStorage(storageId);
    } catch (e) {
      this.serviceWorkerProxyErrorHandler(e);
    }
  }
  async updateCloudStorage(storageId, name) {
    try {
      const api = await this.buildGraphqlAPI();
      return await api.cloudStorages.updateStorage(storageId, name);
    } catch (e) {
      this.serviceWorkerProxyErrorHandler(e);
    }
  }
  getCloudStorageItems(id, parentFolderId) {
    const that = this;
    return async function* () {
      let pageSize = 11,
        pageCursor = null,
        hasNextPage = false;
      const api = await that.buildGraphqlAPI();

        do {
          const {
          items: { edges, pageInfo },
        } = await api.cloudStorages.getStorageItems(
            id,
            pageSize,
            pageCursor,
            parentFolderId
          );
          hasNextPage = pageInfo.hasNextPage;
          pageCursor = pageInfo.endCursor;
          yield (parentFolderId, edges.map((edge) => edge.node));
        } while (hasNextPage);
    };
  }
  async downloadDocument(projectId, documentId, flatten = false) {
    try {
      this.dispatch(BROADCAST_MESSAGE.TOAST, {
        type: INFO_TOAST,
        title: "Document download has been started",
      });

      mutations.setDownloadFileStatus(documentId);
      let timeoutTimer = null;
      const options = { projectId, documentId, flatten };
      const api = await this.buildGraphqlAPI();
      const downloadPromise = api.downloadDocument(options);
      const timeoutPromise = new Promise((resolve, reject) => {
        timeoutTimer = setTimeout(
          () => reject(new Error("file download timeout")),
          20_000
        );
      });

      const { downloadAnnotatedDocument: r } = await Promise.race([
        timeoutPromise,
        downloadPromise,
      ]);
      clearTimeout(timeoutTimer);

      if (r.error) {
        mutations.unsetDownloadFileStatus(r.documentId);
        throw new Error(r.error);
      } else {
        const urlChunks = new URL(r.url).pathname.split("/");
        const filename = decodeURIComponent(urlChunks[urlChunks.length - 1]);
        const data = await fetch(r.url);
        const blob = await data.blob();
        const objectUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.style.display = "none";
        link.target = "_blank";
        link.download = filename || "download.pdf";
        link.href = objectUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.dispatch(BROADCAST_MESSAGE.TOAST, {
          type: SUCCESS_TOAST,
          title: "Successfully downloaded document",
          message: "Document has been successfully downloaded",
        });
        mutations.unsetDownloadFileStatus(r.documentId);
      }
    } catch (e) {
      console.error(e.message);
      mutations.unsetDownloadFileStatus(documentId);
      this.dispatch(BROADCAST_MESSAGE.TOAST, {
        type: ERROR_TOAST,
        title: "Error downloading document",
        message:
          "Folia encountered an error when attempting to download the document. Please try again.",
      });
    }
  }
  async requestProjectAccessRequest(projectId) {
    try {
      const api = await this.buildGraphqlAPI();
      const {
        sendProjectAccessRequest: { success },
      } = await api.sendProjectAccessRequest(projectId);
      return success;
    } catch (e) {
      this.serviceWorkerProxyErrorHandler(e);
    }
  }
  async resendVerificationEmail(email) {
    try {
      const api = await this.buildGraphqlAPI();
      return await api.resendVerificationEmail(email);
    } catch (e) {
      this.serviceWorkerProxyErrorHandler(e);
    }
  }
  async approveProfileApplication(email) {
    try {
      const api = await this.buildGraphqlAPI();
      return await api.approveProfileApplication(email);
    } catch (e) {
      this.serviceWorkerProxyErrorHandler(e);
    }
  }
  async retrieveToolPresets() {
    try {
      const api = await this.buildGraphqlAPI();
      const toolPresets = await api.getToolPresets();
      return Object.entries(toolPresets).reduce((acc, entry) => {
        const [presetKey, presetData] = entry;
        acc[presetKey] = presetData.sort((a, b) => a.index - b.index);
        return acc;
      }, {});
    } catch (e) {
      this.serviceWorkerProxyErrorHandler(e);
    }
  }
  async updateToolPresets(presets) {
    try {
      delete presets.__eraserPresets;
      presets.activePresets = presets.activePresets.filter(
        (item) => item.kind !== "ERASER"
      );
      const api = await this.buildGraphqlAPI();
      return await api.updateToolPresets(presets);
      return presets;
    } catch (e) {
      this.serviceWorkerProxyErrorHandler(e);
    }
  }
  // async getDocumentStampsAPI() {
  //   try {
  //     const api = await this.buildGraphqlAPI();
  //     return api.stamps;
  //   } catch (e) {
  //     this.serviceWorkerProxyErrorHandler(e);
  //   }
  // }

  // FMC
  async getWaitList() {
    const api = await this.buildGraphqlAPI();
    return await api.getWaitList();
  }
  async getFoliaUsers() {
    const api = await this.buildGraphqlAPI();
    return await api.getFoliaUsers();
  }

  // api call template ---^^^---
  async template() {
    try {
      const api = await this.buildGraphqlAPI();
      const someData = await api.someApiCall();
    } catch (e) {
      this.serviceWorkerProxyErrorHandler(e);
    }
  }
}

export default ServiceWorkerProxy;
