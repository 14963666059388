<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.4372 13.3348L12.8389 15.3611C12.9276 15.8087 13.4075 16.1076 13.9108 16.0287L15.2776 15.8143C15.7808 15.7354 16.1168 15.3085 16.0281 14.8609L15.6264 12.8346M12.4372 13.3348L11.4732 8.47178C11.3845 8.02415 11.7205 7.59728 12.2237 7.51835L13.5905 7.30398C14.0938 7.22505 14.5737 7.52394 14.6624 7.97158L15.6264 12.8346M12.4372 13.3348L15.6264 12.8346M7.29232 13.1247V15.208C7.29232 15.6682 7.66541 16.0413 8.12565 16.0413H9.37565C9.83589 16.0413 10.209 15.6682 10.209 15.208V13.1247M7.29232 13.1247V6.45801C7.29232 5.99777 7.66541 5.62467 8.12565 5.62467H9.37565C9.83589 5.62467 10.209 5.99777 10.209 6.45801V13.1247M7.29232 13.1247H10.209M3.95898 13.1247V15.208C3.95898 15.6682 4.33208 16.0413 4.79232 16.0413H6.04232C6.50255 16.0413 6.87565 15.6682 6.87565 15.208V13.1247M3.95898 13.1247V4.79134C3.95898 4.3311 4.33208 3.95801 4.79232 3.95801H6.04232C6.50255 3.95801 6.87565 4.3311 6.87565 4.79134V13.1247M3.95898 13.1247H6.87565" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "library-icon"
}
</script>

<style scoped>

</style>
