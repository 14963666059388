<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.167 8.83339L12.0003 8.00006C13.1049 6.89549 13.1049 5.10463 12.0003 4.00006C10.8957 2.89549 9.10487 2.89549 8.0003 4.00006L7.16697 4.83339M4.83364 7.16672L4.0003 8.00006C2.89573 9.10463 2.89573 10.8955 4.0003 12.0001C5.10487 13.1046 6.89573 13.1046 8.0003 12.0001L8.83363 11.1667M9.5003 6.50006L6.5003 9.50006" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "link-icon",
};
</script>

<style scoped>

</style>
