<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 13.958C7.5 13.958 7.5 16.0413 10 16.0413C12.5 16.0413 12.5 13.958 12.5 13.958M14.375 8.33301V9.99967L16.0417 13.5413H3.95833L5.625 9.99967V8.33301C5.625 5.91676 7.58375 3.95801 10 3.95801C12.4162 3.95801 14.375 5.91676 14.375 8.33301Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "bell-icon"
}
</script>

<style scoped>

</style>
