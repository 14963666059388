<script>
export default {
  name: "ViewStyleSwitcher",
  props: {
    displayStyle: String,
  },
  setup(props) {
  }
}
</script>

<template>
  <div class="view-style-switcher">
    <div
      class="view-as-cards"
      :class="{'selected': displayStyle === 'GRID'}"
      @click.stop="$emit('update:displayStyle', 'GRID')"
    >
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.955078 1.79168C0.955078 1.33145 1.32817 0.958349 1.78841 0.958349H4.70508C5.16532 0.958349 5.53841 1.33145 5.53841 1.79168V4.70835C5.53841 5.16859 5.16532 5.54168 4.70508 5.54168H1.78841C1.32817 5.54168 0.955078 5.16859 0.955078 4.70835V1.79168Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M0.955078 9.29168C0.955078 8.83145 1.32817 8.45835 1.78841 8.45835H4.70508C5.16532 8.45835 5.53841 8.83145 5.53841 9.29168V12.2083C5.53841 12.6686 5.16532 13.0417 4.70508 13.0417H1.78841C1.32817 13.0417 0.955078 12.6686 0.955078 12.2083V9.29168Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.45508 1.79168C8.45508 1.33145 8.82817 0.958349 9.28841 0.958349H12.2051C12.6653 0.958349 13.0384 1.33145 13.0384 1.79168V4.70835C13.0384 5.16859 12.6653 5.54168 12.2051 5.54168H9.28841C8.82817 5.54168 8.45508 5.16859 8.45508 4.70835V1.79168Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.45508 9.29168C8.45508 8.83145 8.82817 8.45835 9.28841 8.45835H12.2051C12.6653 8.45835 13.0384 8.83145 13.0384 9.29168V12.2083C13.0384 12.6686 12.6653 13.0417 12.2051 13.0417H9.28841C8.82817 13.0417 8.45508 12.6686 8.45508 12.2083V9.29168Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <div
      class="view-as-table"
      :class="{'selected': displayStyle === 'TABLE'}"
      @click.stop="$emit('update:displayStyle', 'TABLE')"
    >
      <svg width="21" height="20" viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.12109 5.00002H16.2044M9.12109 10H16.2044M9.12109 15H16.2044" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.82878 5.00001C6.82878 5.46025 6.45568 5.83335 5.99544 5.83335C5.53521 5.83335 5.16211 5.46025 5.16211 5.00001C5.16211 4.53978 5.53521 4.16668 5.99544 4.16668C6.45568 4.16668 6.82878 4.53978 6.82878 5.00001Z" />
        <path d="M6.82878 10C6.82878 10.4603 6.45568 10.8333 5.99544 10.8333C5.53521 10.8333 5.16211 10.4603 5.16211 10C5.16211 9.53978 5.53521 9.16668 5.99544 9.16668C6.45568 9.16668 6.82878 9.53978 6.82878 10Z" />
        <path d="M6.82878 15C6.82878 15.4603 6.45568 15.8333 5.99544 15.8333C5.53521 15.8333 5.16211 15.4603 5.16211 15C5.16211 14.5398 5.53521 14.1667 5.99544 14.1667C6.45568 14.1667 6.82878 14.5398 6.82878 15Z" />
      </svg>
    </div>
  </div>
</template>

<style scoped lang="scss">
.view-style-switcher {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 4px;
  gap: 4px;
  width: 68px;
  height: 36px;
  background: #E2E8F0;
  border-radius: 8px;

  .view-as-cards {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 4px;
    cursor: pointer;
    stroke: #64748B;

    &.selected {
      background-color: white;
      stroke: #334155;
    }
  }

  .view-as-table {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 4px;
    cursor: pointer;
    stroke: #64748B;

    &.selected {
      background-color: white;
      stroke: #334155;
      fill: #334155;
    }
  }
}
</style>