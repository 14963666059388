<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.7049 8.79878L15.7024 4.79628C15.3022 4.39603 14.7018 4.39603 14.3016 4.79628L6.29655 12.8013C5.8963 13.2015 5.8963 13.8019 6.29655 14.2022L7.29718 15.2028L3.99512 18.5048L6.99699 19.5055L9.29843 17.204L10.2991 18.2047C10.5992 18.5048 11.0996 18.805 11.6999 18.2047L19.7049 10.1997C20.1052 9.79941 20.1052 9.19903 19.7049 8.79878Z" fill="#334155"/>
  </svg>
</template>

<script>
export default {
  name: "highlight-search-icon",
};
</script>

<style scoped>

</style>
