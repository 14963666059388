<script>
import { computed, inject, onBeforeUnmount, onMounted, reactive, watch, ref } from "vue";
import ToolDetails from "./ToolDetails.vue";
import { BUTTON_ROLES, DEFAULT_PRESETS, TOOL_PRESETS_MAP } from "./constants.js";
import { state as AP_State, AP_CACHE_GET } from '../../../store/annotationPanel';
export default {
  components: { ToolDetails },
  props: [],
  emits: ["usePreset", "useTool"],
  setup(props, { emit }) {
    const $fetch = inject("$fetch");
    const state = reactive({
      activeGroup: BUTTON_ROLES.POINTER,
      activeTool: BUTTON_ROLES.POINTER,
      toolPresets: [],
      activePreset: 0,

      canUndo: false,
      canRedo: false,
    });
    const isAPOpen = ref(AP_State.isAPOpen ?? false);
    const undoShortcut = computed(() => {
      return navigator.platform.indexOf("Mac") === 0 ? "⌘Z" : "Ctrl + Z"
    });
    const redoShortcut = computed(() => {
      return navigator.platform.indexOf("Mac") === 0 ? "⇧⌘Z" : "Ctrl + Shift + Z"
    });
    const showMoreTools = ref(false);
    const recentTool = ref(null);
    onMounted(() => {
      window.foliaPdfViewer.eventBus.on("undo-redo-changed", undoRedoChanged);
      window.foliaPdfViewer.eventBus.on("stop-drawing", stopDrawing);
      window.foliaPdfViewer.eventBus.on("select-tool", useTool);

    });

    onBeforeUnmount(() => {
      window.foliaPdfViewer.eventBus.off("undo-redo-changed", undoRedoChanged);
      window.foliaPdfViewer.eventBus.off("stop-drawing", stopDrawing);
      window.foliaPdfViewer.eventBus.off("select-tool", useTool);
    });

    watch(() => state.activeTool, () => {
      loadPresets();
    });

    function undoRedoChanged({ canUndo, canRedo }) {
      // console.log("undoRedoChanged", { canUndo, canRedo });
      state.canUndo = canUndo;
      state.canRedo = canRedo;
    }

    function stopDrawing() {
      // console.log("stopDrawing");
      window.foliaPdfViewer.stopDrawing();
      state.activeGroup = BUTTON_ROLES.POINTER;
      state.activeTool = BUTTON_ROLES.POINTER;
      state.toolPresets = [];
      state.activePreset = 0;
    }

    function loadPresets() {
      if (state.activeTool === BUTTON_ROLES.POINTER) return;

      let presets;
      const toolPresetsKey = TOOL_PRESETS_MAP[state.activeTool];
      try {
        presets = JSON.parse(localStorage.getItem("tool-presets"));
        const toolPresets = presets[toolPresetsKey];
        const activePreset = Math.min(
          presets.activePresets.find(ap => ap.kind === state.activeTool)?.index || 0,
          toolPresets.length - 1
        );

        state.toolPresets = toolPresets;
        state.activePreset = activePreset;
      } catch (e) {
        presets = structuredClone(DEFAULT_PRESETS);
        state.toolPresets = presets[toolPresetsKey];
        state.activePreset = 0;
      }
    }

    function savePresets() {
      let presets;
      try {
        presets = JSON.parse(localStorage.getItem("tool-presets"));
      } catch (e) {
        presets = structuredClone(DEFAULT_PRESETS);
      }

      const activePreset = presets.activePresets.find(ap => ap.kind === state.activeTool);
      if (activePreset) {
        activePreset.index = state.activePreset;
        activePreset.addedAt = new Date().toISOString();
      }

      for (const [key, value] of Object.entries(state.toolPresets[state.activePreset])) {
        presets[TOOL_PRESETS_MAP[state.activeTool]][state.activePreset][key] = value;
      }
      presets[TOOL_PRESETS_MAP[state.activeTool]][state.activePreset].addedAt = new Date().toISOString();

      localStorage.setItem("tool-presets", JSON.stringify(presets));
      $fetch.updateToolPresets(presets).catch(console.error);
    }

    function onClickHandler(e) {
      const { role } = e.target.dataset;

      switch (role) {
        case BUTTON_ROLES.UNDO: {
          return window.foliaPdfViewer.undo();
        }
        case BUTTON_ROLES.REDO: {
          return window.foliaPdfViewer.redo();
        }

        case BUTTON_ROLES.POINTER:
        case BUTTON_ROLES.INK:
        case BUTTON_ROLES.COMMENT:
        case BUTTON_ROLES.STAMPS:
        case BUTTON_ROLES.TEXT:
        case BUTTON_ROLES.IMAGE: {
          state.activeGroup = role;
          state.activeTool = role;
          break;
        }

        case BUTTON_ROLES.HIGHLIGHTER: {
          state.activeGroup = role;
          state.activeTool = BUTTON_ROLES.MARKER;
          break;
        }
        case BUTTON_ROLES.SHAPES: {
          state.activeGroup = role;
          state.activeTool = BUTTON_ROLES.SQUARE;
          break;
        }
        case BUTTON_ROLES.ERASERS: {
          state.activeGroup = role;
          state.activeTool = BUTTON_ROLES.OBJECT_ERASER;
          break;
        }

        case BUTTON_ROLES.MARKER:
        case BUTTON_ROLES.UNDERLINE:
        case BUTTON_ROLES.CROSSLINE:
        case BUTTON_ROLES.SQUARE:
        case BUTTON_ROLES.CIRCLE:
        case BUTTON_ROLES.ARROW:
        case BUTTON_ROLES.OBJECT_ERASER:
        case BUTTON_ROLES.PIXEL_ERASER: {
          state.activeTool = role;
          break;
        }
      }

      useTool(state.activeTool);
    }

    function useTool(tool) {
      state.activeTool = tool;

      let preset = {};
      try {
        const presets = JSON.parse(localStorage.getItem("tool-presets"));
        state.activePreset = presets.activePresets.find(ap => ap.kind === tool)?.index;
        state.toolPresets = presets[TOOL_PRESETS_MAP[tool]];
        if (state.toolPresets) {
          preset = state.toolPresets[state.activePreset];
        }
      } catch (e) {
        console.error(e)
      }

      emit("useTool", tool, preset);
    }

    function usePreset(index, preset) {
      state.activePreset = index;
      if (preset) {
        for (let [key, value] of Object.entries(preset)) {
          state.toolPresets[state.activePreset][key] = value;
        }
      } else {
        preset = state.toolPresets[state.activePreset];
      }
      savePresets();
      window.foliaPdfViewer.updateToolDrawingProperties(preset);
    }

    function closePresets() {
      state.activeGroup = BUTTON_ROLES.POINTER;
      state.activeTool = BUTTON_ROLES.POINTER;
      useTool(BUTTON_ROLES.POINTER);
    }

    function isSelectedTool(toolRole) {
      return state.activeTool === toolRole;
    }

    function isSelectedGroup(groupRole) {
      return state.activeGroup === groupRole;
    }
    const changeAPVisibility = () => {
      isAPOpen.value = !isAPOpen.value;
      localStorage.setItem('isAPOpen', isAPOpen.value);
      AP_CACHE_GET.changedPanelVisibility()
    }
    return {
      BUTTON_ROLES, state, undoShortcut, redoShortcut, changeAPVisibility,
      onClickHandler, isSelectedTool, isSelectedGroup, useTool, usePreset, closePresets,
      showMoreTools, recentTool
    }
  }
}

</script>

<template>
  <div class="toolbar-container  hidden sm:hidden md:flex" @click.stop="onClickHandler">
    <div class="tool-block undo-redo">
      <folia-button :data-role="BUTTON_ROLES.UNDO" icon="undo" tooltip="Undo" :shortcut="undoShortcut"
        :disabled="!state.canUndo"></folia-button>
      <folia-button :data-role="BUTTON_ROLES.REDO" icon="redo" tooltip="Redo" :shortcut="redoShortcut"
        :disabled="!state.canRedo"></folia-button>
    </div>

    <folia-button :data-role="BUTTON_ROLES.POINTER" icon="pointer" tooltip="Select" shortcut="A"
      :selected="isSelectedGroup(BUTTON_ROLES.POINTER)">
    </folia-button>

    <div class="toolbar-divider"></div>

    <div class="tool-block">
      <div class="tool-box ink">
        <folia-button :data-role="BUTTON_ROLES.INK" icon="ink" tooltip="Pencil" shortcut="P"
          :selected="isSelectedGroup(BUTTON_ROLES.INK)"></folia-button>
        <ToolDetails v-if="isSelectedGroup(BUTTON_ROLES.INK)" :presets="state.toolPresets"
          :activePreset="state.activePreset" @close="closePresets" @usePreset="usePreset"></ToolDetails>
      </div>
      <div class="tool-box highlight">
        <folia-button :data-role="BUTTON_ROLES.HIGHLIGHTER" icon="marker" tooltip="Highlighter" shortcut="H"
          :selected="isSelectedGroup(BUTTON_ROLES.HIGHLIGHTER)"></folia-button>
        <ToolDetails v-if="isSelectedGroup(BUTTON_ROLES.HIGHLIGHTER)" :presets="state.toolPresets"
          :activePreset="state.activePreset" @close="closePresets" @usePreset="usePreset">
          <div class="tool-block">
            <folia-button :data-role="BUTTON_ROLES.MARKER" size="28" icon="Marker" tooltip="Marker"
              :selected="isSelectedTool(BUTTON_ROLES.MARKER)"></folia-button>
            <folia-button :data-role="BUTTON_ROLES.UNDERLINE" size="28" icon="underline" tooltip="Underline"
              :selected="isSelectedTool(BUTTON_ROLES.UNDERLINE)"></folia-button>
            <folia-button :data-role="BUTTON_ROLES.CROSSLINE" size="28" icon="strikeout" tooltip="Crossline"
              :selected="isSelectedTool(BUTTON_ROLES.CROSSLINE)"></folia-button>
          </div>
          <div class="toolbar-divider"></div>
        </ToolDetails>
      </div>
      <div class="tool-box comment">
        <folia-button :data-role="BUTTON_ROLES.COMMENT" icon="comment" tooltip="Comment" shortcut="C"
          :selected="isSelectedGroup(BUTTON_ROLES.COMMENT)"></folia-button>
      </div>
      <div class="tool-box stamps">
        <folia-button :data-role="BUTTON_ROLES.STAMPS" icon="stamp" tooltip="Stamp" shortcut="S"
          :selected="isSelectedGroup(BUTTON_ROLES.STAMPS)"></folia-button>
      </div>
    </div>

    <div class="toolbar-divider"></div>

    <div class="tool-block">
      <div class="tool-box shapes">
        <folia-button :data-role="BUTTON_ROLES.SHAPES" icon="shape" tooltip="Shape" shortcut="U"
          :selected="isSelectedGroup(BUTTON_ROLES.SHAPES)"></folia-button>
        <ToolDetails v-if="isSelectedGroup(BUTTON_ROLES.SHAPES)" :presets="state.toolPresets"
          :activePreset="state.activePreset" @close="closePresets" @usePreset="usePreset">
          <div class="tool-block">
            <folia-button :data-role="BUTTON_ROLES.SQUARE" size="28" icon="square" tooltip="square"
              :selected="isSelectedTool(BUTTON_ROLES.SQUARE)"></folia-button>
            <folia-button :data-role="BUTTON_ROLES.CIRCLE" size="28" icon="circle" tooltip="Circle"
              :selected="isSelectedTool(BUTTON_ROLES.CIRCLE)"></folia-button>
            <folia-button :data-role="BUTTON_ROLES.ARROW" size="28" icon="arrow" tooltip="Arrow"
              :selected="isSelectedTool(BUTTON_ROLES.ARROW)"></folia-button>
          </div>
          <div class="toolbar-divider"></div>
        </ToolDetails>
      </div>
      <div class="tool-box text-box">
        <folia-button :data-role="BUTTON_ROLES.TEXT" icon="text_box" tooltip="Text" shortcut="T"
          :selected="isSelectedGroup(BUTTON_ROLES.TEXT)"></folia-button>
        <ToolDetails v-if="isSelectedGroup(BUTTON_ROLES.TEXT)" :presets="state.toolPresets"
          :activePreset="state.activePreset" @close="closePresets" @usePreset="usePreset"></ToolDetails>
      </div>
      <div class="tool-box image">
        <folia-button :data-role="BUTTON_ROLES.IMAGE" icon="image" tooltip="Image" shortcut="I"
          :selected="isSelectedGroup(BUTTON_ROLES.IMAGE)"></folia-button>
      </div>
      <div class="tool-box erasers">
        <folia-button :data-role="BUTTON_ROLES.ERASERS" icon="erasers" tooltip="Erasers" shortcut="E"
          :selected="isSelectedGroup(BUTTON_ROLES.ERASERS)"></folia-button>
        <ToolDetails v-if="isSelectedGroup(BUTTON_ROLES.ERASERS)" :presets="state.toolPresets"
          :activePreset="state.activePreset" @close="closePresets" @usePreset="usePreset">
          <div class="tool-block">
            <folia-button :data-role="BUTTON_ROLES.OBJECT_ERASER" size="28" icon="object_eraser" tooltip="Object eraser"
              :selected="isSelectedTool(BUTTON_ROLES.OBJECT_ERASER)"></folia-button>
            <folia-button :data-role="BUTTON_ROLES.PIXEL_ERASER" size="28" icon="pixel_eraser" tooltip="Pixel eraser"
              :selected="isSelectedTool(BUTTON_ROLES.PIXEL_ERASER)"></folia-button>
          </div>
          <div class="toolbar-divider"></div>
        </ToolDetails>
      </div>
    </div>
    <div class="absolute right-2.5 cursor-pointer">
      <svg @click="changeAPVisibility" width="32" height="32" viewBox="0 0 32 32" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_4268)">
          <path d="M13 10H24.25" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M13 16H24.25" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M13 22H24.25" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M7.75 10H9.25" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M7.75 16H9.25" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M7.75 22H9.25" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_1_4268">
            <rect width="24" height="24" fill="white" transform="translate(4 4)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>

  <!-- width<768 -->
  <!-- Top undo redo tools -->
  <div @click.stop="onClickHandler"
    class="fixed sm:flex md:hidden z-10 left-4 bg-white p-2 rounded-full border border-gray-200 top-[114px] ">
    <div class="tool-block undo-redo flex">
      <folia-button :data-role="BUTTON_ROLES.UNDO" icon="undo" tooltip="Undo" :shortcut="undoShortcut"
        :disabled="!state.canUndo"></folia-button>
      <folia-button :data-role="BUTTON_ROLES.REDO" icon="redo" tooltip="Redo" :shortcut="redoShortcut"
        :disabled="!state.canRedo"></folia-button>
    </div>
  </div>

  <div class="fixed bottom-2 left-1/2 transform -translate-x-1/2 sm:flex md:hidden 
  min-w-72 max-w-72 min-h-14 max-h-14 bg-white rounded-full z-30 p-2
  border border-gray-200
  ">
    <div class="flex">
      <div class="flex" @click.stop="onClickHandler">
        <folia-button :data-role="BUTTON_ROLES.POINTER" icon="pointer" tooltip="Select" shortcut="A"
          :selected="isSelectedGroup(BUTTON_ROLES.POINTER)">
        </folia-button>
        <div class="tool-box ink">
          <folia-button :data-role="BUTTON_ROLES.INK" icon="ink" tooltip="Pencil" shortcut="P"
            :selected="isSelectedGroup(BUTTON_ROLES.INK)"></folia-button>
          <ToolDetails class="!-top-14 left-0 sm:top-12" v-if="isSelectedGroup(BUTTON_ROLES.INK)"
            :presets="state.toolPresets" :activePreset="state.activePreset" @close="closePresets"
            @usePreset="usePreset">
          </ToolDetails>
        </div>
        <div class="tool-box highlight">
          <folia-button :data-role="BUTTON_ROLES.HIGHLIGHTER" icon="marker" tooltip="Highlighter" shortcut="H"
            :selected="isSelectedGroup(BUTTON_ROLES.HIGHLIGHTER)"></folia-button>
          <ToolDetails class="!-top-14 left-0 sm:top-12" v-if="isSelectedGroup(BUTTON_ROLES.HIGHLIGHTER)"
            :presets="state.toolPresets" :activePreset="state.activePreset" @close="closePresets"
            @usePreset="usePreset">
            <div class="flex">
              <folia-button :data-role="BUTTON_ROLES.MARKER" size="28" icon="Marker" tooltip="Marker"
                :selected="isSelectedTool(BUTTON_ROLES.MARKER)"></folia-button>
              <folia-button :data-role="BUTTON_ROLES.UNDERLINE" size="28" icon="underline" tooltip="Underline"
                :selected="isSelectedTool(BUTTON_ROLES.UNDERLINE)"></folia-button>
              <folia-button :data-role="BUTTON_ROLES.CROSSLINE" size="28" icon="strikeout" tooltip="Crossline"
                :selected="isSelectedTool(BUTTON_ROLES.CROSSLINE)"></folia-button>
            </div>
          </ToolDetails>
        </div>
        <div class="tool-box comment">
          <folia-button :data-role="BUTTON_ROLES.COMMENT" icon="comment" tooltip="Comment" shortcut="C"
            :selected="isSelectedGroup(BUTTON_ROLES.COMMENT)"></folia-button>
        </div>
        <div class="flex mt-3 ml-2 mr-2">
          <svg width="1" height="16" viewBox="0 0 1 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.5" y1="2.18557e-08" x2="0.499999" y2="16" stroke="#E2E8F0" />
          </svg>
        </div>
      </div>

      <div @click.stop="onClickHandler">
        <div v-if="recentTool === null" class="flex min-w-[42px]"></div>
        <div v-if="recentTool === 'shapes'" class="tool-box shapes">
          <folia-button :data-role="BUTTON_ROLES.SHAPES" icon="shape" tooltip="Shape" shortcut="U"
            :selected="isSelectedGroup(BUTTON_ROLES.SHAPES)"></folia-button>
          <ToolDetails class="!-top-14 left-0 sm:top-12" v-if="isSelectedGroup(BUTTON_ROLES.SHAPES)"
            :presets="state.toolPresets" :activePreset="state.activePreset" @close="closePresets"
            @usePreset="usePreset">
            <div class="flex">
              <folia-button :data-role="BUTTON_ROLES.SQUARE" size="28" icon="square" tooltip="square"
                :selected="isSelectedTool(BUTTON_ROLES.SQUARE)"></folia-button>
              <folia-button :data-role="BUTTON_ROLES.CIRCLE" size="28" icon="circle" tooltip="Circle"
                :selected="isSelectedTool(BUTTON_ROLES.CIRCLE)"></folia-button>
              <folia-button :data-role="BUTTON_ROLES.ARROW" size="28" icon="arrow" tooltip="Arrow"
                :selected="isSelectedTool(BUTTON_ROLES.ARROW)"></folia-button>
            </div>
          </ToolDetails>
        </div>
        <div v-if="recentTool === 'stamp'" class="tool-box stamps">
          <folia-button :data-role="BUTTON_ROLES.STAMPS" icon="stamp" tooltip="Stamp" shortcut="S"
            :selected="isSelectedGroup(BUTTON_ROLES.STAMPS)"></folia-button>
        </div>
        <div v-if="recentTool === 'text_box'" class="tool-box text-box">
          <folia-button :data-role="BUTTON_ROLES.TEXT" icon="text_box" tooltip="Text" shortcut="T"
            :selected="isSelectedGroup(BUTTON_ROLES.TEXT)"></folia-button>
          <ToolDetails v-if="isSelectedGroup(BUTTON_ROLES.TEXT)" :presets="state.toolPresets"
            :activePreset="state.activePreset" @close="closePresets" @usePreset="usePreset"></ToolDetails>
        </div>
        <div v-if="recentTool === 'image'" class="tool-box image">
          <folia-button :data-role="BUTTON_ROLES.IMAGE" icon="image" tooltip="Image" shortcut="I"
            :selected="isSelectedGroup(BUTTON_ROLES.IMAGE)"></folia-button>
        </div>
        <div v-if="recentTool === 'erasers'" class="tool-box erasers">
          <folia-button :data-role="BUTTON_ROLES.ERASERS" icon="erasers" tooltip="Erasers" shortcut="E"
            :selected="isSelectedGroup(BUTTON_ROLES.ERASERS)"></folia-button>
          <ToolDetails v-if="isSelectedGroup(BUTTON_ROLES.ERASERS)" :presets="state.toolPresets"
            :activePreset="state.activePreset" @close="closePresets" @usePreset="usePreset">
            <div class="tool-block">
              <folia-button :data-role="BUTTON_ROLES.OBJECT_ERASER" size="28" icon="object_eraser"
                tooltip="Object eraser" :selected="isSelectedTool(BUTTON_ROLES.OBJECT_ERASER)"></folia-button>
              <folia-button :data-role="BUTTON_ROLES.PIXEL_ERASER" size="28" icon="pixel_eraser" tooltip="Pixel eraser"
                :selected="isSelectedTool(BUTTON_ROLES.PIXEL_ERASER)"></folia-button>
            </div>
            <div class="toolbar-divider"></div>
          </ToolDetails>
        </div>
      </div>

      <div class="flex rounded-full hover:bg-gray-200 p-1 cursor-pointer" @click="showMoreTools = !showMoreTools">
        <div v-if="showMoreTools"
          class="absolute p-2 flex-raw bottom-[50px] right-0 bg-white rounded-full  min-h-[100px] min-w-[56px]">

          <div @click.stop="onClickHandler">
            <folia-button @click="recentTool = 'shapes'; showMoreTools = false;" :data-role="BUTTON_ROLES.SHAPES"
              icon="shape" tooltip="Shape" shortcut="U" :selected="isSelectedGroup(BUTTON_ROLES.SHAPES)"></folia-button>

            <folia-button @click="recentTool = 'stamp'; showMoreTools = false;" :data-role="BUTTON_ROLES.STAMPS"
              icon="stamp" tooltip="Stamp" shortcut="S" :selected="isSelectedGroup(BUTTON_ROLES.STAMPS)"></folia-button>

            <folia-button @click="recentTool = 'text_box'; showMoreTools = false;" :data-role="BUTTON_ROLES.TEXT"
              icon="text_box" tooltip="Text" shortcut="T" :selected="isSelectedGroup(BUTTON_ROLES.TEXT)"></folia-button>

            <folia-button @click="recentTool = 'erasers'; showMoreTools = false;" :data-role="BUTTON_ROLES.ERASERS"
              icon="erasers" tooltip="Erasers" shortcut="E"
              :selected="isSelectedGroup(BUTTON_ROLES.ERASERS)"></folia-button>

            <folia-button @click="recentTool = 'image'; showMoreTools = false;" :data-role="BUTTON_ROLES.IMAGE"
              icon="image" tooltip="Image" shortcut="I" :selected="isSelectedGroup(BUTTON_ROLES.IMAGE)"></folia-button>
          </div>

        </div>
        <svg class="" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M25 19.5L16 10.5L7 19.5" stroke="#64748B" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </div>

    </div>
  </div>

</template>

<style scoped lang="scss">
.toolbar-container {
  height: 50px;
  // display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  //z-index: 2;

  // border-top: 1px solid #e2e8f0;
  // border-bottom: 1px solid #e2e8f0;

  .tool-block {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .toolbar-divider {
    width: 2px;
    height: 16px;
    background: #e2e8f0;
  }

  .tool-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>