<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.0417 9.99967C16.0417 13.3364 13.3367 16.0413 10 16.0413C6.66328 16.0413 3.95833 13.3364 3.95833 9.99967C3.95833 6.66295 6.66328 3.95801 10 3.95801C13.3367 3.95801 16.0417 6.66295 16.0417 9.99967Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.125 8.33301C8.125 8.33301 8.33333 6.45801 10 6.45801C11.6667 6.45801 11.875 7.49967 11.875 8.33301C11.875 8.95913 11.5222 9.58525 10.8165 9.85792C10.3872 10.0238 10 10.3728 10 10.833V11.0413" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.4167 13.3337C10.4167 13.5638 10.2301 13.7503 10 13.7503C9.76988 13.7503 9.58333 13.5638 9.58333 13.3337C9.58333 13.1035 9.76988 12.917 10 12.917C10.2301 12.917 10.4167 13.1035 10.4167 13.3337Z" stroke="#64748B" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "info-icon"
}
</script>

<style scoped>

</style>
