import Dexie from "dexie";

import { reactive } from "vue";

export const state = reactive({
  isAPOpen: JSON.parse(localStorage.getItem("isAPOpen")) ?? false,
  currentPanel:null
});

export const AP_CACHE_GET = {
  changedPanelVisibility() {
    try {
      const isOpen = localStorage.getItem("isAPOpen");
      state.isAPOpen = JSON.parse(isOpen);
    } catch (error) {
      console.log(error);
      state.isAPOpen = false;
    }
    return state.isAPOpen;
  }
};

export const AP_CACHE_SET = {
setCurrentPanel(data){
state.currentPanel = data;
}
};

const store = { AP_CACHE_GET, AP_CACHE_SET };
export default store;
