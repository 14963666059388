<template>
  <svg viewBox="0 0 120 86" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_20306_241544)">
      <ellipse cx="60" cy="67.75" rx="60" ry="18" fill="#0F172A"/>
    </g>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M54.667 47.2493C54.667 50.7014 57.4649 53.4993 60.917 53.4993C64.3691 53.4993 67.167 50.7014 67.167 47.2493V44.4723C68.8274 44.1389 70.6816 43.6473 72.5566 42.916C81.1732 39.5681 85.917 32.9681 85.917 24.3327C85.917 13.8785 79.667 1.41602 63.0003 1.41602C51.7087 1.41602 42.167 10.0035 42.167 20.166C42.167 23.6181 44.9649 26.416 48.417 26.416C51.8691 26.416 54.667 23.6181 54.667 20.166C54.667 18.0827 57.9149 13.916 63.0003 13.916C69.2503 13.916 73.417 18.0827 73.417 22.2493C73.417 30.5827 62.1732 32.6535 60.917 32.666C57.4649 32.666 54.667 35.4639 54.667 38.916V47.2493ZM67.167 63.916C67.167 67.3678 64.3688 70.166 60.917 70.166C57.4652 70.166 54.667 67.3678 54.667 63.916C54.667 60.4642 57.4652 57.666 60.917 57.666C64.3688 57.666 67.167 60.4642 67.167 63.916Z" fill="#CBD5E1"/>
    <defs>
      <filter id="filter0_i_20306_241544" x="0" y="49.75" width="120" height="36" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.278431 0 0 0 0 0.333333 0 0 0 0 0.411765 0 0 0 1 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_20306_241544"/>
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "empty-search-result-icon",
};
</script>

<style scoped>

</style>
