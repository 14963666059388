<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.8285 33.3395H22.8329V31.0047H16.9959V16.9959H31.0047V22.8329H33.3395V15.8285C33.3395 15.1841 32.8165 14.6611 32.1721 14.6611H15.8285C15.1841 14.6611 14.6611 15.1841 14.6611 15.8285V32.1721C14.6611 32.8165 15.1841 33.3395 15.8285 33.3395ZM27.2608 28.9115L31.6888 33.3395L33.3395 31.6888L28.9115 27.2608L32.1721 25.1677L22.8329 22.8329L25.1677 32.1721L27.2608 28.9115Z" fill="#64748B"/>
  </svg>
</template>

<script>
export default {
  name: "stamp-icon",
};
</script>
