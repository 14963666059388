<template>
    <div class="folia-table-view">
      <!-- header -->
      <div class="folia-table-view-pre-header"></div>
      <div class="folia-table-view-header">
        <div class="as-favorite header">&nbsp;</div>
        <div class="project-name header">Project</div>
        <div class="documents-count header">Documents</div>
        <div v-if="sort === REPRESENTATION.SORT.LAST_MODIFIED" class="last-edited header">Last Modified</div>
        <div v-if="sort === REPRESENTATION.SORT.DATE_ADDED || sort === REPRESENTATION.SORT.NAME" class="added-at header">Date added</div>
        <!-- <div class="project-owner header">Owner</div> -->
        <div class="shared-with header">Shared With</div>
        <div class="project-menu header">&nbsp;</div>
      </div>
      <!-- data -->
      <div class="folia-table-view-data-row" v-for="project of projectData" :key="project.id" @click="openProject(project.id)">
        <div class="as-favorite">&nbsp;</div>
        <div class="project-name" :title="project.name">
          <div class="project-name-div">
            <span class="project-name-text">{{project.name}}</span>
            <div class="project-is-public" v-if="project.publicStatus === SHARING_STATUS.PUBLIC"></div>
          </div>
        </div>
        <div class="documents-count">{{project.totalDocuments >= 1 ? project.totalDocuments : 'No'}} {{ project.totalDocuments > 1 || project.totalDocuments === 0 ? "documents" : "document"}}</div>
        <div v-if="sort === REPRESENTATION.SORT.LAST_MODIFIED" :title="lastEdited(project.updatedAt)" class="last-edited">{{lastEdited(project.updatedAt)}}</div>
        <div v-if="sort === REPRESENTATION.SORT.DATE_ADDED || sort === REPRESENTATION.SORT.NAME" :title="lastEdited(project.createdAt)" class="added-at">{{lastEdited(project.createdAt)}}</div>
        <!-- <div :title="ownerNames[project.id] || project.ownerEmail" class="project-owner">{{ownerNames[project.id] || project.ownerEmail}}</div> -->
        <div class="shared-with">{{ project.totalCollaboratorCount < 2 ? "No people" : project.totalCollaboratorCount === 2 ? project.totalCollaboratorCount - 1 + " person" : project.totalCollaboratorCount - 1 + " people"}}</div>
        <div class="project-menu"><FoliaContextMenu
            v-if="project.permissions"
            :isTableView = 'true'
            :permissions="project.permissions"
            :userRole="project.userRole"
            @menu-duplicate="$emit('duplicate', project.id)"
            @menu-reject="$emit('reject', project.id)"
            @menu-share="$emit('share', project.id)"
            @menu-rename="$emit('rename', project)"
            @menu-delete-project="$emit('remove', project.id)"
        /></div>
      </div>
    </div>
</template>

<script>
import { useRouter } from "vue-router";
import MoreActionsButton from "../../assets/vue-icons/more-actions-button.vue";
import FoliaContextMenu from "../common/FoliaContextMenu.vue";
import StarIcon from "../../assets/vue-icons/star-icon.vue";
import publicIcon from "../../assets/vue-icons/public-icon.vue";
import TableColumsIcon from "../../assets/vue-icons/table-colums-icon.vue";
import { SHARING_STATUS } from "../common/constants.js";
import { REPRESENTATION } from "../common/constants.js";
import { formattedTime } from "../common/utils.js";
import { inject, onMounted, reactive, ref, watchEffect } from "vue";

export default {
    name: 'ProjectTable',
    components: {
      MoreActionsButton,
      publicIcon,
      StarIcon,
      FoliaContextMenu,
      TableColumsIcon,
    },
    props:['data', 'sort'],
    setup(props) {
      const router = useRouter();
      const $fetch = inject('$fetch');
      const ownerNames = ref({})
      const projectData = ref(props.data);
      const dataCollaborators = reactive({
        collaborators: [],
        isOwner: false,
        publicStatus: '',
        projectId: "",
      });
      onMounted(()=> {
        props.data.forEach(async (project) => {
          await getOwnerName(project.ownerEmail, project.id)
            .then(name => {
              ownerNames.value[project.id] = name
            })
        })
      })
      watchEffect(() => {
        projectData.value =  props.data;
      });

      async function getOwnerName(ownerEmail, projectId) {
        const { collaborators } = await $fetch.getProjectCollaborators(projectId);
        const collaborator = collaborators.find(person => person.email === ownerEmail);
        return collaborator?.profile?.username || ownerEmail;
      }
      function lastEdited(updatedAtTime) {
        return  formattedTime(updatedAtTime)
      }
      function openProject(indexProject) {
        router.push("/p/" + indexProject);
      }

      return {
        REPRESENTATION, SHARING_STATUS,
        projectData, ownerNames, dataCollaborators,
        lastEdited, openProject,
      }
    }
}
</script>

<style scoped lang="scss">
.folia-table-view {
  border-radius: 8px;
  border: 0.5px solid var(--stroke-gray-outside, rgba(51, 65, 85, 0.15));
  background: #FFFFFF;
  margin: 20px 0;

  .folia-table-view-header {
    //position: sticky;
    //top: 0px;
    z-index: 2;
  }
  .folia-table-view-data-row:hover {
    background-color: #F1F1F1;
    cursor: pointer;
  }
  .folia-table-view-header, .folia-table-view-data-row {
    display: flex;
  }

  .as-favorite, .project-name, .project-owner, .shared-with, .documents-count,
  .last-edited, .added-at, .project-menu {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 16px;
    white-space: nowrap;
    height: 56px;

    color: #334155;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    &.header {
      color: #64748B;
      font-family: Inter, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 133.333% */
      background-color: #F8FAFC;
    }
  }
  .as-favorite.header {
    border-radius: 8px 0 0 0;
  }
  .project-menu.header {
    border-radius: 0 8px 0 0;
  }
  .as-favorite {
    width: 50px;
  }
  .project-name {
    width: 350px;

    .project-name-div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;

      .project-name-text {
        max-width: 280px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .project-is-public {
        width: 16px;
        height: 16px;
        background-image: url("data:image/svg+xml, %3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.99967 16.0418C13.3364 16.0418 16.0413 13.3369 16.0413 10.0002C16.0413 6.66344 13.3364 3.9585 9.99967 3.9585M9.99967 16.0418C6.66295 16.0418 3.95801 13.3369 3.95801 10.0002C3.95801 6.66344 6.66295 3.9585 9.99967 3.9585M9.99967 16.0418C11.0352 16.0418 12.708 13.7502 12.708 10.0002C12.708 6.25016 11.0352 3.9585 9.99967 3.9585M9.99967 16.0418C8.96414 16.0418 7.29134 13.7502 7.29134 10.0002C7.29134 6.25016 8.96414 3.9585 9.99967 3.9585M4.16634 10.0002H15.833' stroke='%233B82F6' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-size: 16px;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
  .project-owner {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .shared-with {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
  }
  .documents-count {
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
  }
  .last-edited, .added-at {
    width: 170px;
    white-space: nowrap;
    overflow: hidden;
  }
  .project-menu {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}



</style>
