<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.7507 5.62467H13.837C15.055 5.62467 16.0423 6.61203 16.0423 7.83C16.0423 8.75736 15.4622 9.58564 14.5906 9.90256L13.7507 10.208M6.25065 5.62467H6.16431C4.94634 5.62467 3.95898 6.61203 3.95898 7.83C3.95898 8.75736 4.53914 9.58564 5.41066 9.90256L6.25065 10.208M10.0007 12.9163V15.833M7.29232 16.0413H12.709M6.45898 3.95801H13.5423V9.16634C13.5423 11.1223 11.9567 12.708 10.0007 12.708C8.04464 12.708 6.45898 11.1223 6.45898 9.16634V3.95801Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "executive-summary-icon"
}
</script>

<style scoped>

</style>
