<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.70801 3.95831H5.62467C4.7042 3.95831 3.95801 4.70451 3.95801 5.62498V14.375C3.95801 15.2955 4.7042 16.0416 5.62467 16.0416H14.3747C15.2951 16.0416 16.0413 15.2955 16.0413 14.375V12.2916M16.0413 7.70831V3.95831H12.2913M15.833 4.16665L9.79134 10.2083" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "share-icon",
};
</script>

<style scoped>

</style>
