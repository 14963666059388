<script>
import BaseModalDialog from "../common/BaseModalDialog.vue";
import DialogButton from "../common/DialogButton.vue";
import DarkDialogButton from "../common/DarkDialogButton.vue";
import SingleLineEditor from "../common/SingleLineEditor.vue";
import { inject, onMounted, ref, nextTick } from "vue";
import { v4 as uuid } from "uuid";
import AnnotationPreviewRenderer from "../../../document/stamps-manager/AnnotationPreviewRenderer.js";
import { ANNO_TYPES, BROADCAST_MESSAGE,STAMP_TYPES,SUCCESS_TOAST,ERROR_TOAST } from "../../../../core/constants.js";
import AnnotationService from "../../../../service/annotationService";
import Loader from '../../../common/loaders/defaultLoader.vue';
export default {
  name: "CreateStampDialog",
  components: { DarkDialogButton, DialogButton, SingleLineEditor, BaseModalDialog, Loader },
  props: {
    annotationData: Object 
  },
  emits: ["close"],
  setup(props, { emit }) {
    const $fetch = inject("$fetch");
    const previewEl = ref(null);
    const stampName = ref("");
    const pageSize = ref([0, 0]);
    const renderer = new AnnotationPreviewRenderer();
    const annotationService = new AnnotationService();
    const isLoading = ref(true)
    const stampPreview= ref();
    const isCreatingStamp = ref(false)
    onMounted(() => {
      
      // nextTick(() => {
      //   const {
      //     pageRect: { width, height }, annotData
      //   } = props.parentRects;
      //   const sourceViewport = { width, height, scale: props.parentRects?.annoRect?.scale || 1 };
      //   pageSize.value = [width, height];
      //   annotationData.value = annotData[0]
      //   renderer.render(sourceViewport, previewEl.value, annotData[0]);
      // });
      loadStampPreview();
    });
     async function loadStampPreview(){
    isLoading.value=true;
     const {preview} = await annotationService.getStampPreview(props.annotationData);
     isLoading.value=false;
     const image = `data:image/png;base64,${preview}`;
     stampPreview.value= image;
    }

   async  function submit() {
      if (!stampName.value) return;
      const payload = {  ...props.annotationData,name:stampName.value,}
      isCreatingStamp.value =true;
      const createdStamp = await annotationService.createStamp(payload);
      isCreatingStamp.value=false
      if(createdStamp){
        $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
            type: SUCCESS_TOAST, title: 'Successfully', message:'Stamp Created Successfully'
          })
      }else {
        $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
            type: SUCCESS_TOAST, title: 'Error', message:'Stamp Creation failed.'
          })
      }
      emit('close')

      // const name = stampName.value;
      // const stampId = uuid();
      // const addedAt = new Date().toISOString();
      // const sourcePoint = annotationData.value.sourcePoint;
      // const targetPoint = annotationData.value.targetPoint;
      // const paths = annotationData.value.paths;
      // const rect = annotationData.value.rect;
      // const color = annotationData.value.color;
      // const lineWidth = annotationData.value.lineWidth;
      // const content = annotationData.value.content;
      // const filename = annotationData.value.filename;
      // const fontFamily = annotationData.value.fontFamily;
      // const fontSize = annotationData.value.fontSize;
      // const fontWeight = annotationData.value.fontWeight;
      // const textAlignment = annotationData.value.textAlignment;
      // const text = annotationData.value.text;

      // $fetch.addStamp(name, pageSize.value, )

      // $fetch.getDocumentStampsAPI().then(stampsApi => {
      //   if (annotationData.value.__typename === ANNO_TYPES.ARROW) {
      //     const payload = { stampId, addedAt, sourcePoint, targetPoint, color, lineWidth };
      //     return stampsApi.addArrowStamp(name, pageSize.value, payload);
      //   }
      //   else if (annotationData.value.__typename === ANNO_TYPES.INK) {
      //     const payload = { stampId, addedAt, paths, color, lineWidth };
      //     return stampsApi.addInkStamp(name, pageSize.value, payload);
      //   }
      //   else if (annotationData.value.__typename === ANNO_TYPES.IMAGE) {
      //     const payload = { stampId, addedAt, content, filename, rect };
      //     return stampsApi.addImageStamp(name, pageSize.value, payload);
      //   }
      //   else if (annotationData.value.__typename === ANNO_TYPES.CIRCLE) {
      //     const payload = { stampId, addedAt, color, lineWidth, rect };
      //     return stampsApi.addCircleStamp(name, pageSize.value, payload);
      //   }
      //   else if (annotationData.value.__typename === ANNO_TYPES.SQUARE) {
      //     const payload = { stampId, addedAt, color, lineWidth, rect };
      //     return stampsApi.addSquareStamp(name, pageSize.value, payload);
      //   }
      //   else if (annotationData.value.__typename === ANNO_TYPES.TEXT_BOX) {
      //     const payload = { stampId, addedAt, color, fontFamily, fontSize, fontWeight, textAlignment, text, rect };
      //     return stampsApi.addTextBoxStamp(name, pageSize.value, payload);
      //   }
      // }).then(stamp => {
      //   console.log("stamp has been created", stamp);
      // }).catch(console.error)
      // emit("close");
    }

    function cancel() {
      emit('close');
    }

    return {
      stampName, previewEl,stampPreview,isLoading,isCreatingStamp,
      submit, cancel
    }
  }
}

</script>

<template>
  <BaseModalDialog headerTitle="Create Stamp" @close="cancel">
    <template v-slot:main>
      <div class="create-stamp-preview" ref="previewEl">
        <img v-if="!isLoading" style="padding: 10px;" :src="stampPreview" alt="Image" />
       <Loader v-if="isLoading" />
      </div>
      <SingleLineEditor label="Stamp name"
                        :focus="true"
                        placeholder-text="Type new stamp name here"
                        max-length="50"
                        @on-enter="submit"
                        @on-escape="cancel"
                        v-model="stampName"
      />
    </template>
    <template v-slot:buttons>
      <DialogButton title="Cancel" @click="cancel"></DialogButton>

      <DarkDialogButton title="Create" @click="submit" :disabled="!stampName || isCreatingStamp"></DarkDialogButton>
      <Loader v-if="isCreatingStamp"/>
    </template>
  </BaseModalDialog>
</template>

<style scoped lang="scss">
.create-stamp-preview {
  position: relative;
  width: 250px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 4px;
  border: solid 16px #F1F5F9;
  background-color: #F1F5F9;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
</style>