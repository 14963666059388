<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.00033 4.99935L8.37934 3.86088C8.1457 3.43253 7.69674 3.16602 7.20881 3.16602H4.50033C3.76395 3.16602 3.16699 3.76297 3.16699 4.49935V7.33268M12.8337 6.49935V11.4993C12.8337 12.2357 12.2367 12.8327 11.5003 12.8327H4.50033C3.76395 12.8327 3.16699 12.2357 3.16699 11.4993V5.16602H11.5003C12.2367 5.16602 12.8337 5.76297 12.8337 6.49935Z" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "project-category-icon",
};
</script>

<style scoped>

</style>
