<template>
  <table class='table-view'>
    <thead>
    <tr>
      <th class="table-view-cell empty-cell"></th>
      <th class="table-view-cell cell-name">Document</th>
      <th class="table-view-cell cell-filetype">Type</th>
      <th class="table-view-cell cell-added-by">Owner</th>
      <th class="table-view-cell cell-added">Last Modified</th>
      <th class="table-view-cell cell-menu">
      </th>
    </tr>
    </thead>
    <tbody>
    <tr
      v-for="document of documents"
      :key="`doc_${document.index}`"
      class="table-view-row"
      @click="openDocument(document.id)"
    >
      <td class="table-view-cell cell-favorite fw-400">
        <button v-if="canFavorite"
                class="empty-cell-button"
                :class="{
                  'is-favorite': document.favorite?.isFavorite,
                  'is-not-favorite': !document.favorite?.isFavorite,
                }"
                @click.stop="$emit('toggleFavoriteStatus', document.id, !document.favorite.isFavorite)"
        ></button>
      </td>
      <td class="table-view-cell cell-name fw-400" :title="document.name">
        <span v-html="document.name"/>
      </td>
      <td class="table-view-cell cell-filetype fw-300">
        <span class="cell-filetype-text" :class="{ [document.mimeTypeClassName]: true }">
          {{ document.mimeTypeTitle }}
        </span>
      </td>
      <td class="table-view-cell cell-added-by" :title="document.collaboratorEmail">
        <div class="table-person"><span>{{ownerNames[document.id] || document.collaboratorEmail}}</span></div>
      </td>
      <td class="table-view-cell fw-400" :title="lastEdited(document.updatedAt)">{{lastEdited(document.updatedAt)}}</td>
      <td class="table-view-cell cell-menu">
        <FoliaContextMenu
          :isTableView="true"
          :permissionsDocuments="permissions"
          :documentId="document.id"
          :userRole="'VIEWER'"
          @menu-copy-document="$emit('copy-to-project', document.id)"
          @menu-document-details="$emit('show-document-details', document.id)"
          @menu-rename="$emit('rename', document.id)"
          @menu-delete-document="$emit('remove', document.id)"
          @download="$emit('download', document.id, isFlatten)"
        />
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import MoreActionsButton from "../../assets/vue-icons/more-actions-button.vue";
import TableColumsIcon from "../../assets/vue-icons/table-colums-icon.vue";
import FoliaContextMenu from "../common/FoliaContextMenu.vue";
import StarIcon from "../../assets/vue-icons/star-icon.vue";
import { ICON_BY_TYPE } from "../common/constants.js";
import {formattedTime} from "../common/utils.js";
import { computed, inject, onMounted, ref, watchEffect } from "vue";
import { PUBLIC_VIEWER, VIEWER } from "../../core/constants.js";

export default {
  name: 'DocumentTable',
  components: {
    MoreActionsButton,
    TableColumsIcon,
    FoliaContextMenu,
    StarIcon,
  },
  props:["data", "permissions", "userRole"],
  setup(props) {
    const router = useRouter();
    const $fetch = inject('$fetch');
    const ownerNames = ref({})
    const route = useRoute();

    const isFlatten = computed(() => {
      return props.userRole === VIEWER || props.userRole === PUBLIC_VIEWER;
    });

    const canFavorite = computed(() => {
      return props.userRole && props.userRole !== PUBLIC_VIEWER;
    });

    const documents = computed(() => {
      return props.data.map(document => {
        const mimeType = document.origin && ICON_BY_TYPE[document.origin.mimeType]
          ? ICON_BY_TYPE[document.origin.mimeType]
          : ICON_BY_TYPE.default;

        document.mimeTypeTitle = mimeType.title;
        document.mimeTypeClassName = mimeType.className;
        return document;
      })
    });

    onMounted(async ()=> {
      await props.data.forEach((document) => {
        getOwnerName(document.collaboratorEmail, document.projectId)
          .then(name => {
            ownerNames.value[document.id] = name
          })
      })
    });

    async function getOwnerName(ownerEmail, projectId) {
      const { collaborators } = await $fetch.getProjectCollaborators(projectId);
      const collaborator = collaborators.find(person => person.email === ownerEmail);
      return collaborator?.profile?.username || ownerEmail;
    }

    const lastEdited = (updatedAtTime) => {
      return formattedTime(updatedAtTime)
    }
    function openDocument(indexDocument) {
      const path = route.path + "/d/" + indexDocument;
      router.push(path);
    }

    return {
      isFlatten, canFavorite, ownerNames, documents,
      lastEdited, openDocument,
    }
  }
}
</script>

<style scoped lang="scss">
.table-view {
  table-layout: fixed;
  border-spacing: 0;
  background: #FFFFFF;
  height: fit-content;
  margin-top: 35px;
  width: 100% !important;
  border: 0.5px solid rgba(51, 65, 85, 0.15);
  border-radius: 8px;

  th, td {
    text-align: left;
  }

  th {
    //border-bottom: solid 1px #B6B6B6 !important;
    background: #F8FAFC;
    font-weight: 600;
    font-size: 12px !important;
    line-height: 16px;
    &.cell-menu {
      border-radius: 0 8px 0 0;
    }
  }

  .table-view-row {
    cursor: pointer;
    color: #334155;
    border-radius: 8px;
    .table-context-menu {
      background-color: #FFFFFF;
    }
  }
  .table-view-row:hover {
    background-color: #F8FAFC;
  }

  .fw-300 { font-weight: 300; }
  .fw-500 { font-weight: 500; }
  .fw-400 {
    overflow: hidden;
    color: #334155;
    text-overflow: ellipsis;
    font-size: 14px !important;
    line-height: 20px;
    font-weight: 400;
  }
  .table-view-cell {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 7px 5px;
    height: 56px;
    color: #64748B;
    font-size: 14px;

    &.cell-favorite {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.empty-cell {
      width: 5%;
      border-radius: 8px 0 0 0;
    }

    &.cell-name {
      width: 30%;
      padding-left: 15px;
      border-radius: 8px 0 0 0;
      .unseen {
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: #0a84ff;
        left: 0;
        top: 14px;
      }
    }

    &.cell-filetype {
      width: 10%;
      .cell-filetype-text {
        background: #EF4444;
        border-radius: 4px;
        padding: 4px;
        display: inline-block;
        width: 50px;
        text-align: center;
        height: 20px;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        color: #FFFFFF;

        &.pdf {
          background: #EF4444;
        }
        &.png, &.jpg {
          background: #9d06f5;
        }
        &.doc, &.docx, &.gdoc {
          background: #3B82F6;
        }
        &.xls, &.xlsx, &.gsheet {
          background: #10B981;
        }
        &.ppt, &.pptx, &.gslide {
          background: #F97316;
        }
      }
    }

    &.cell-viewed {width: 15%;}
    &.cell-modified {width: 15%;}
    &.cell-added {width: 15%;}
    &.cell-added-by {
      width: 10%;

      .table-person {
        display: inline-flex;
        align-items: center;
        border-radius: 2px;
        background-position: 10px center;
        height: 20px;
        min-width: 20px;
        max-width: 200px;

        & span {
          margin-right: 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          font-size: 14px !important;
          line-height: 20px;
          font-weight: 400;
          color: #64748B;
        }
      }
    }
    &.cell-menu {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .menu-row-icon {
        margin-left: 10px;
      }
    }

    & .empty-cell-button {
      width: 25px;
      height: 25px;
      border-radius: 11px;
      border: none;
      outline: none;
      cursor: pointer;

      &:hover {
        background-color: rgba(238, 239, 241, 0.8) !important;
      }

      &:active {
        transform: translate(1px, 1px);
      }

      &.is-favorite {
        background: url("../../assets/images/filled-star.svg") center no-repeat;
        background-size: 18px;
      }
      &.is-not-favorite {
        background: url("../../assets/images/outline-star.svg") center no-repeat;
        background-size: 18px;
      }
    }
  }
}
</style>
