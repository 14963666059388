<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    stroke="#64748B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3749 13.0413H2.62467C1.7042 13.0413 0.958008 12.2951 0.958008 11.3747V2.62467C0.958008 1.7042 1.7042 0.958008 2.62467 0.958008H11.3749C12.2953 0.958008 13.0415 1.7042 13.0415 2.62467V11.3747C13.0415 12.2951 12.2953 13.0413 11.3749 13.0413Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "square-icon",
};
</script>
