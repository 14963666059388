<template>
  <div class="account-settings">
    <div class="account-settings-header">
      <div class="account-settings-header-title">
        <span>{{ "Settings" }}</span>
      </div>
      <div class="account-settings-header-description">
        <span>{{ "Manage your personal and organization settings." }}</span>
      </div>
    </div>
    <div class="account-settings-body">
      <DeleteAccountModal v-if="deleteAccountModalShown" @close="deleteAccountModalShown = false" />

      <!-- MANAGE PROFILE BLOCK -->
      <div class="account-profile-block">
        <div class="account-profile-text-container">
          <span class="title-profile">
            {{ "Profile" }}
          </span>
          <span class="description-profile">
            {{ "Manage details of your personal profile." }}
          </span>
        </div>

        <div class="account-profile-input-container">
          <div class="input-container">
            <div class="field-label">Name</div>
            <input ref="nameProjectInput" placeholder="User Name" type="text" class="input-field" v-model="userName" />
          </div>
          <div class="input-container">
            <div class="field-label">Email</div>
            <input ref="nameProjectInput" placeholder="User Email" type="text" class="input-field-not-editable"
              v-model="userEmail" readonly />
          </div>
        </div>

        <div class="account-profile-button-container">
          <ActionButton class="cancel-btn" text="Cancel" @click="cancelChanges" />
          <ActionButton class="rename-profile-btn" text="Save" @click="changeUserName" />
        </div>
      </div>

      <!-- DELETE ACCOUNT BLOCK -->
      <div class="account-delete-block">
        <div class="account-delete-text-container">
          <span class="delete-profile">
            {{ "Delete Account" }}
          </span>
          <span class="description-delete">
            {{ "Close and delete your account" }}
          </span>
        </div>
        <div class="action-button-container">
          <ActionButton class="delete-user-btn" :text="'Delete Account'" :disabled="deleteButtonIsDisabled"
            @click="deleteAccountModalShown = true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from "./ActionButton.vue";
import { inject, onMounted, ref, watch, computed } from "vue";
import DeleteAccountModal from "../app-bar/DeleteAccountModal.vue";
import ProfileService from "../../service/profileService";
import {BROADCAST_MESSAGE, ERROR_TOAST, SUCCESS_TOAST} from "../../core/constants.js";
import {useRouter} from "vue-router";
export default {
  name: "AccountSettings",
  components: {
    DeleteAccountModal,
    ActionButton
  },
  setup() {
    const router = useRouter();
    const $auth0 = inject('$auth0');
    const $fetch = inject('$fetch');
    const deleteAccountModalShown = ref(false);
    const currentUserName = ref('')
    const userName = ref('');
    const userEmail = ref('');
    const busy = ref(false);
    const deleteButtonIsDisabled = ref(false);

    const hasActiveChanges = computed(() => {
      return userName.value !== currentUserName.value;
    });

    const saveButtonIsDisabled = computed(() => {
      return busy.value || !hasActiveChanges.value;
    });
    const profileService = new ProfileService()
    onMounted(async () => {
      await fetchOwner()
    });

    async function fetchOwner() {
      try {
        busy.value = true;
        const { name = null, email = '', profilePicture = '' } = await profileService.getMyProfile();
        currentUserName.value = name
        userName.value = name;
        userEmail.value = email;
      } catch (e) {
        console.warn(e.message);
      } finally {
        busy.value = false;
      }
    }

    async function changeUserName() {
      if (!userName.value || !hasActiveChanges) return
      try {
        busy.value = true;
        await profileService.updateUserProfile({email:userEmail.value, name:userName.value});
        $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
          type: SUCCESS_TOAST,
          message: "user name updated successfully"
        });
        await fetchOwner();
      } catch (e) {
        $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
          type: ERROR_TOAST,
          message: e.message
        });
      } finally {
        busy.value = false;
      }
    }

    async function cancelChanges() {
      if (!hasActiveChanges) return
      await router.push({ name: 'home' });
    }

    async function deleteAccount() {
      try {
        deleteButtonIsDisabled.value = true;
        await $fetch.deleteAccount()
        await $auth0.signOut();
      } catch (e) {
        $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
          type: ERROR_TOAST,
          message: e.message
        });
      } finally {
        deleteButtonIsDisabled.value = false;
      }
    }
    return {
      saveButtonIsDisabled, deleteButtonIsDisabled,
      userName, hasActiveChanges, deleteAccountModalShown, userEmail,

      deleteAccount, cancelChanges, changeUserName,
    }
  }
};
</script>

<style scoped lang="scss">
.account-settings {
  display: flex;
  flex-direction: column;
  width: 100%;

  .account-settings-header {
    background-color: white;
    border-bottom: 1px solid #E2E8F0;
    display: flex;
    align-self: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 92px;

    .account-settings-header-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      text-align: center;
      color: #334155;
    }

    .account-settings-header-description {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #64748B
    }
  }

  .account-settings-body {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-top: 24px;
    flex-direction: column;

    .account-profile-block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
      width: 688px;
      height: 228px;
      gap: 16px;
      background: #FFFFFF;
      border: 1px solid #E2E8F0;
      border-radius: 6px;

      .account-profile-text-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: start;

        .title-profile {
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
          color: #334155;
        }

        .description-profile {
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
          color: #64748B;
        }
      }

      .account-profile-input-container {
        display: flex;
        align-items: flex-start;
        text-align: start;
        flex-direction: row;
        gap: 16px;

        .input-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .field-label {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #64748B;
          }

          .input-field {
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            color: #334155;
            background: #F1F5F9;
            width: 312px;
            height: 36px;
            margin-top: 8px;
            box-sizing: border-box;
            padding: 0 8px;
            border: 0;
            border-radius: 6px;

            &:focus {
              outline: none;
              border: 2px solid #2990FF;
            }
          }

          .input-field-not-editable {
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            color: #64748B;
            background: #E2E8F0;
            width: 312px;
            height: 36px;
            margin-top: 8px;
            box-sizing: border-box;
            padding: 0 8px;
            border: 0;
            border-radius: 6px;

            &:focus {
              outline: none;
            }
          }
        }
      }

      .account-profile-button-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 8px;

        .cancel-btn {
          border: 1px solid #E2E8F0;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          width: 80px;
          height: 36px;
          border-radius: 8px;
          margin: 0;
        }

        .rename-profile-btn {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          border-radius: 8px;
          width: 65px;
          height: 36px;
          color: #FFFFFF;
          background-color: #334155;
          margin: 0;
        }
      }
    }

    .account-delete-block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
      gap: 16px;
      width: 688px;
      height: 152px;
      background: #FFFFFF;
      border: 1px solid #E2E8F0;
      border-radius: 6px;

      .account-delete-text-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: start;

        .delete-profile {
          font-weight: 500;
          font-size: 20px;
          line-height: 32px;
          color: #334155;
        }

        .description-delete {
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
          color: #64748B;
        }
      }

      .action-button-container {
        width: 100%;
        display: flex;
        margin-left: -10px;
        flex-direction: row;
        align-items: flex-start;

        .delete-user-btn {
          border-radius: 8px;
          width: 137px;
          height: 36px;
          color: #FFFFFF;
          background-color: #F43F5E;
        }
      }
    }
  }
}
</style>
