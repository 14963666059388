<template>
  <div class="project-filter">
    <div class="project-filter-text">
      <div class="project-filter-value">
        <div class="folder-icon">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.75 3.75L6.28426 2.89615C6.10903 2.57489 5.77231 2.375 5.40637 2.375H3.375C2.82272 2.375 2.375 2.82272 2.375 3.375V5.5M9.625 4.875V8.625C9.625 9.17728 9.17728 9.625 8.625 9.625H3.375C2.82272 9.625 2.375 9.17728 2.375 8.625V3.875H8.625C9.17728 3.875 9.625 4.32272 9.625 4.875Z" stroke="#334155" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <span>{{"Project"}}</span>
      </div>
      <div class="filter-select-icon" :class="{'opened':selectOpened,'closed':!selectOpened}"/>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "ProjectSearchFilter",
  setup() {
    const selectOpened = ref(false);
    return {
      selectOpened,
    }
  }
};
</script>

<style lang="scss" scoped>
.project-filter {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 200px;
  min-width: 200px;

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #334155;
  .project-filter-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .project-filter-text {
    height: 24px;
    width: 90px;
    background: #FFFFFF;
    border: 1px solid #E2E8F0;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    .project-filter-value {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      white-space: nowrap;
      display:flex;
      flex-direction: row;
      align-items: center;
      text-align:center;
      justify-content: space-between;
      .folder-icon {
        font-size: 12px;
        line-height: 12px;
      }
    }
    .filter-select-icon {
      height: 12px;
      width: 12px;
      cursor: pointer;
      &.closed {
        background: url('../../../assets/images/search-filter-select/filter-caret-down.svg');
        background-size: 12px;
        transform: rotateX(0deg);
        //transition: transform 0.3s ease-in-out;
      }
      &.opened {
        background: url('../../../assets/images/search-filter-select/filter-caret-down.svg');
        background-size: 12px;
        transform: rotateX(180deg);
        //transition: transform 0.3s ease-in-out;
      }
    }
  }
}
</style>
