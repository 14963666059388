<script>
export default {
  name: "StampsManagerTabs",
  props: {
    value: Number
  },
  emits: ["input"],
  setup(props) {

    return {}
  }
}

</script>

<template>
  <div class="stamp-manager-tabs">
    <!-- <div class="stamp-manager-tab"
       :class="{ selected: value === 0 }"
       @click.stop="$emit('input', 0)"
  >
    <svg v-if="value === 0" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 16.1111H3V6.36845C3 5.59977 3.6268 4.97664 4.4 4.97664H7.2V4.28073C7.2 3.51205 7.8268 2.88892 8.6 2.88892H11.4C12.1732 2.88892 12.8 3.51205 12.8 4.28073V6.36845H15.6C16.3732 6.36845 17 6.99158 17 7.76026V16.1111ZM12.8 7.41231V15.0673H15.95V7.41231H12.8ZM8.25 3.93278V15.0673H11.75V3.93278H8.25ZM4.05 6.0205V15.0673H7.2V6.0205H4.05Z" fill="#F04E23"/>
      <rect x="3.77783" y="7.5556" width="3.88889" height="0.777777" fill="#F04E23"/>
      <rect x="3.77783" y="13" width="12.4444" height="0.777778" fill="#F04E23"/>
      <rect x="12.3334" y="9.11115" width="3.88889" height="0.777778" fill="#F04E23"/>
      <rect x="7.66663" y="5.22223" width="4.66667" height="0.777778" fill="#F04E23"/>
    </svg>
    <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 16.1111H3V6.36845C3 5.59977 3.6268 4.97664 4.4 4.97664H7.2V4.28073C7.2 3.51205 7.8268 2.88892 8.6 2.88892H11.4C12.1732 2.88892 12.8 3.51205 12.8 4.28073V6.36845H15.6C16.3732 6.36845 17 6.99158 17 7.76026V16.1111ZM12.8 7.41231V15.0673H15.95V7.41231H12.8ZM8.25 3.93278V15.0673H11.75V3.93278H8.25ZM4.05 6.0205V15.0673H7.2V6.0205H4.05Z" fill="#64748B"/>
      <rect x="3.77783" y="7.5556" width="3.88889" height="0.777777" fill="#64748B"/>
      <rect x="3.77783" y="13" width="12.4444" height="0.777778" fill="#64748B"/>
      <rect x="12.3334" y="9.11115" width="3.88889" height="0.777778" fill="#64748B"/>
      <rect x="7.66663" y="5.22223" width="4.66667" height="0.777778" fill="#64748B"/>
    </svg>
    <span class="stamp-manager-tab-title">Default</span>
  </div> -->

    <!-- <div class="stamp-manager-tab"
       :class="{ selected: value === 1 }"
       @click.stop="$emit('input', 1)"
  >
    <svg v-if="value === 1" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_23365_160802)">
        <path d="M13.15 3C11.932 3 10.763 3.61798 10 4.59455C9.237 3.61798 8.068 3 6.85 3C4.694 3 3 4.84632 3 7.19619C3 10.0801 5.38 12.43 8.985 16.0005L10 17L11.015 15.9929C14.62 12.43 17 10.0801 17 7.19619C17 4.84632 15.306 3 13.15 3ZM10.07 14.8638L10 14.9401L9.93 14.8638C6.598 11.5755 4.4 9.40109 4.4 7.19619C4.4 5.6703 5.45 4.52589 6.85 4.52589C7.928 4.52589 8.978 5.2812 9.349 6.32643H10.658C11.022 5.2812 12.072 4.52589 13.15 4.52589C14.55 4.52589 15.6 5.6703 15.6 7.19619C15.6 9.40109 13.402 11.5755 10.07 14.8638Z" fill="#F04E23"/>
      </g>
      <defs>
        <clipPath id="clip0_23365_160802">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
    <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_23365_160802)">
        <path d="M13.15 3C11.932 3 10.763 3.61798 10 4.59455C9.237 3.61798 8.068 3 6.85 3C4.694 3 3 4.84632 3 7.19619C3 10.0801 5.38 12.43 8.985 16.0005L10 17L11.015 15.9929C14.62 12.43 17 10.0801 17 7.19619C17 4.84632 15.306 3 13.15 3ZM10.07 14.8638L10 14.9401L9.93 14.8638C6.598 11.5755 4.4 9.40109 4.4 7.19619C4.4 5.6703 5.45 4.52589 6.85 4.52589C7.928 4.52589 8.978 5.2812 9.349 6.32643H10.658C11.022 5.2812 12.072 4.52589 13.15 4.52589C14.55 4.52589 15.6 5.6703 15.6 7.19619C15.6 9.40109 13.402 11.5755 10.07 14.8638Z" fill="#64748B"/>
      </g>
      <defs>
        <clipPath id="clip0_23365_160802">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
    <span class="stamp-manager-tab-title">Favorites</span>
  </div> -->

    <div class="stamp-manager-tab" :class="{ selected: value === 0 }" @click.stop="$emit('input', 0)">
      <svg v-if="value === 0" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_23365_160822)">
          <path
            d="M9.992 2C5.576 2 2 5.584 2 10C2 14.416 5.576 18 9.992 18C14.416 18 18 14.416 18 10C18 5.584 14.416 2 9.992 2ZM10 16.4C6.464 16.4 3.6 13.536 3.6 10C3.6 6.464 6.464 3.6 10 3.6C13.536 3.6 16.4 6.464 16.4 10C16.4 13.536 13.536 16.4 10 16.4ZM10.4 6H9.2V10.8L13.4 13.32L14 12.336L10.4 10.2V6Z"
            fill="#F04E23" />
        </g>
        <defs>
          <clipPath id="clip0_23365_160822">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_23365_160822)">
          <path
            d="M9.992 2C5.576 2 2 5.584 2 10C2 14.416 5.576 18 9.992 18C14.416 18 18 14.416 18 10C18 5.584 14.416 2 9.992 2ZM10 16.4C6.464 16.4 3.6 13.536 3.6 10C3.6 6.464 6.464 3.6 10 3.6C13.536 3.6 16.4 6.464 16.4 10C16.4 13.536 13.536 16.4 10 16.4ZM10.4 6H9.2V10.8L13.4 13.32L14 12.336L10.4 10.2V6Z"
            fill="#64748B" />
        </g>
        <defs>
          <clipPath id="clip0_23365_160822">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <span class="stamp-manager-tab-title">Recent</span>
    </div>

    <div class="stamp-manager-tab" :class="{ selected: value === 1 }" @click.stop="$emit('input', 1)">

      <svg v-if="value === 1" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_27916_224489)">
          <path
            d="M8.125 3.125H4.375C4.02982 3.125 3.75 3.40482 3.75 3.75V16.25C3.75 16.5952 4.02982 16.875 4.375 16.875H8.125C8.47018 16.875 8.75 16.5952 8.75 16.25V3.75C8.75 3.40482 8.47018 3.125 8.125 3.125Z"
            stroke="#F04E23" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M17.0054 16.0754L13.3484 16.8566C13.2678 16.8738 13.1845 16.8748 13.1035 16.8597C13.0225 16.8446 12.9453 16.8136 12.8763 16.7685C12.8074 16.7234 12.748 16.6651 12.7016 16.597C12.6552 16.5288 12.6228 16.4522 12.6062 16.3715L10.014 4.04649C9.97911 3.88368 10.0101 3.71369 10.1001 3.57361C10.1901 3.43354 10.3319 3.33476 10.4945 3.29884L14.1515 2.51759C14.2321 2.50044 14.3153 2.4994 14.3963 2.51453C14.4773 2.52967 14.5545 2.56067 14.6235 2.60576C14.6925 2.65085 14.7519 2.70914 14.7982 2.77727C14.8446 2.8454 14.877 2.92203 14.8937 3.00274L17.4859 15.3277C17.5207 15.4906 17.4898 15.6606 17.3998 15.8006C17.3097 15.9407 17.168 16.0395 17.0054 16.0754Z"
            stroke="#F04E23" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M3.75 5.625H8.75" stroke="#F04E23" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M3.75 14.375H8.75" stroke="#F04E23" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M10.4062 5.89609L15.2852 4.84766" stroke="#F04E23" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M10.9219 8.36094L15.8016 7.3125" stroke="#F04E23" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M12.2188 14.525L17.0977 13.4766" stroke="#F04E23" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_27916_224489">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_27916_225712)">
          <path
            d="M8.125 3.125H4.375C4.02982 3.125 3.75 3.40482 3.75 3.75V16.25C3.75 16.5952 4.02982 16.875 4.375 16.875H8.125C8.47018 16.875 8.75 16.5952 8.75 16.25V3.75C8.75 3.40482 8.47018 3.125 8.125 3.125Z"
            stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M17.0054 16.0754L13.3484 16.8566C13.2678 16.8738 13.1845 16.8748 13.1035 16.8597C13.0225 16.8446 12.9453 16.8136 12.8763 16.7685C12.8074 16.7234 12.748 16.6651 12.7016 16.597C12.6552 16.5288 12.6228 16.4522 12.6062 16.3715L10.014 4.04649C9.97911 3.88368 10.0101 3.71369 10.1001 3.57361C10.1901 3.43354 10.3319 3.33476 10.4945 3.29884L14.1515 2.51759C14.2321 2.50044 14.3153 2.4994 14.3963 2.51453C14.4773 2.52967 14.5545 2.56067 14.6235 2.60576C14.6925 2.65085 14.7519 2.70914 14.7982 2.77727C14.8446 2.8454 14.877 2.92203 14.8937 3.00274L17.4859 15.3277C17.5207 15.4906 17.4898 15.6606 17.3998 15.8006C17.3097 15.9407 17.168 16.0395 17.0054 16.0754Z"
            stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M3.75 5.625H8.75" stroke="#64748B" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M3.75 14.375H8.75" stroke="#64748B" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M10.4062 5.89609L15.2852 4.84766" stroke="#64748B" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M10.9219 8.36094L15.8016 7.3125" stroke="#64748B" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M12.2188 14.525L17.0977 13.4766" stroke="#64748B" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_27916_225712">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>


      <span class="stamp-manager-tab-title">Library</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.stamp-manager-tabs {
  display: flex;
  gap: 16px;
  height: 44px;

  .stamp-manager-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    cursor: pointer;
    border-bottom: 3px solid transparent;

    &.selected {
      border-bottom: 3px solid #F04E23;
    }

    & span {
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;

      user-select: none;
    }
  }
}
</style>