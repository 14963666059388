import { datadogLogs } from "@datadog/browser-logs";

let service = "folia-web";

if (location.href.includes("preview.folia.com")) {
  service += "-preview";
} else if (location.href.includes("dev.folia.com")) {
  service += "-dev";
} else if (location.href.includes("localhost")) {
  service += "-localhost";
}

const options = {
  clientToken: import.meta.env.VITE_DATADOG_TOKEN,
  service,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
};

if (!datadogLogs.getInitConfiguration()) {
  datadogLogs.init(options);
}

export const dataDog = {
  log(message, context = {}) {
    try {
      if (location.href.includes("localhost")) return;
      datadogLogs.logger.info(message, { context });
    } catch (e) {
      console.error("datadogLogs", e.message);
    }
  },
  error(message, error = {}) {
    try {
      if (location.href.includes("localhost")) return;
      datadogLogs.logger.error(message, {}, error);
    } catch (e) {
      console.error("datadogLogs", e.message);
    }
  },
};
