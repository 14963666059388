<template>
  <div class="popup-project-container">
    <FoliaSearchSidebar/>
    <div class="folia-favorite-project" v-if="false">
      <div class="title-favorite-project">
        <span>Favorite Projects</span>
      </div>
      <div class="favorite-project-body">

      </div>
    </div>
    <div class="home-project-container">
      <div class="home-project-title">
        <span>Home</span>
      </div>
      <div class="home-project-body" v-for="project in projects.arr">
        <ProjectInfoComponent
          :project="project"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FoliaSearchSidebar from "../FoliaSearchSidebar.vue";
import ProjectInfoComponent from "./ProjectInfoComponent.vue";
import {inject, onMounted, ref} from 'vue';
export default {
  name: "PopupProjectProject",
  components: {
    FoliaSearchSidebar,
    ProjectInfoComponent,
  },
  setup() {
    const projects = ref({ arr: [] })
    const $fetch = inject("$fetch");
    const popupProjectContainer = ref(null);
    onMounted(()=>{
      fetchProjects().catch(console.error)
    })
    async function fetchProjects() {
      const data = await $fetch.getProjects();
      projects.value = {
        arr: data,
        // .filter((project) => project.syncStatus !== SYNC_DELETE),
      };
    }
    return {
      projects,
      popupProjectContainer,
      fetchProjects,
    }
  }
}
</script>

<style lang="scss">
  .popup-project-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .folia-favorite-project {
      display:flex;
      justify-content: flex-start;
      .title-favorite-project {
        display: flex;
        justify-content: flex-start;
        margin-left: 15px;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        font-style: normal;
        color: #334155;
      }
      .favorite-project-body {
        margin-left: 20px;
      }
    }
    .home-project-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      overflow-x:hidden;
      overflow-y: auto;
      .home-project-title {
        display: flex;
        justify-content: flex-start;
        margin-left: 15px;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        font-style: normal;
        color: #334155;
      }
      .home-project-body {
        display: flex;
        flex-direction: column;
        width: 100%;
        .name {
          height: 44px;
          width: 100%;
        }
      }
    }
  }
</style>
