<script>
import {
  computed,
  inject, nextTick,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import DocumentCard from "./DocumentCard.vue";
import DocumentTable from "./DocumentTable.vue";
import Spinner from "../common/Spinner.vue";
import ActiveProjectContent from "../common/ActiveProjectContent.vue";
import ArrowBottomBtn from "../../assets/vue-icons/arrow-bottom-btn.vue";
import PopupProjectProject from "../common/SideBar/PopupProjectProject/PopupProjectProject.vue";
import OwnerIcon from "../../assets/vue-icons/owner-icon.vue";
import GridViewIcon from "../../assets/vue-icons/grid-view-icon.vue";
import TableViewIcon from "../../assets/vue-icons/table-view-icon.vue";
import ActionButton from "../common/ActionButton.vue";
import DeleteDocumentModal from "./DeleteDocumentModal.vue";
import CopyToProjectModal from "./CopyToProjectModal.vue";
import EditDocumentModal from "./EditDocumentModal.vue";
import SortSelect from "../common/SortSelect.vue";
import FoliaContextMenu from "../common/FoliaContextMenu.vue";
import EditProjectModal from "../home/EditProjectModal.vue";
import DeleteProjectModal from "../home/DeleteProjectModal.vue";
import PlusIcon from "../../assets/vue-icons/plus-icon.vue";
import DocumentDetails from "../document/DocumentDetails.vue";
import emptyDocumentCardImage from "../../assets/vue-icons/empty-document-card-image.vue";
import AllProjectNavIcon from "../../assets/vue-icons/all-project-nav-icon.vue";
import bookmarkIcon from "../../assets/vue-icons/bookmark-icon.vue";
import SharingDialog from "../common/Sharing/SharingDialog.vue";
import ProjectDetails from "./ProjectDetails.vue";
import sharedDocumentHeaderIcon from "../../assets/vue-icons/shared-document-header-icon.vue";
import { ALLOWED_CONTENT_TYPES, SORT, REPRESENTATION } from "../common/constants";
import store from "../../store/index.js";
import { BROADCAST_MESSAGE, ERROR_TOAST, SUCCESS_TOAST } from "../../core/constants.js";
import UploadDocuments from "./upload/UploadDocuments.vue";
import LeftSideBar from "../common/SideBar/LeftSideBar.vue";

import ProjectService from '../../service/projectService';
import FeatureService from '../../service/featureService';

import { useFlag } from '@unleash/proxy-client-vue'
import { GET_CACHE, SET_CACHE } from '../../store/cacheLayer';
import { state as applicationState, getApplicationState } from '../../store/applicationState';

export default {
  name: "Project",
  components: {
    LeftSideBar, UploadDocuments, ActionButton, Spinner, DocumentCard,
    emptyDocumentCardImage, DocumentTable, GridViewIcon, TableViewIcon,
    SharingDialog, CopyToProjectModal, EditDocumentModal, FoliaContextMenu,
    ArrowBottomBtn, PlusIcon, EditProjectModal, DeleteProjectModal,
    PopupProjectProject, DeleteDocumentModal, OwnerIcon, ActiveProjectContent,
    AllProjectNavIcon, DocumentDetails, bookmarkIcon, sharedDocumentHeaderIcon,
    ProjectDetails, SortSelect,
  },
  setup() {
    const $fetch = inject("$fetch");
    const $auth0 = inject("$auth0");
    const $uploadFiles = inject("$uploadFiles");
    const $router = useRouter();
    const showSharedDialog = ref(false);
    const copyToProjectDocumentId = ref('');
    const copyToProjectDocumentName = ref('');
    const route = useRoute();
    const currentUserEmail = ref('');
    const isLoading = ref(false);
    const popupProjectShown = ref(false);
    const dragNDropArea = ref(null);
    const popupProjectContainer = ref(null);
    const renamedProject = ref(null);
    const showDeleteProjectModal = ref(false);
    const editDocumentId = ref('');
    const detailsProjectId = ref('');
    const deletedDocumentName = ref('');
    const deleteDocumentId = ref('');
    const detailsDocumentId = ref('');
    const editDocumentName = ref('');
    const dragNDropStart = ref(false);
    const sortType = ref(SORT.TYPES.MODIFIED);
    const sortDirection = ref(SORT.DIRECTIONS.ASCENT);
    const renameDocDialogRef = ref(null);
    const docNameRef = ref(null);
    const flags = reactive({
      summary: '',
      isLoading: false,
      processPending: false
    })
    const currentDocument = reactive({ document: null });
    const data = reactive({
      project: {},
      documents: [],
      representation: {
        view: null,
        sort: null,
        dir: null,
      },
      getDocumentsInProgress: false
    });
    const pagination = reactive({
      documents: { endCursor: null, hasNextPage: false }
    });
    const isSideBarShown = computed(() => {
      return store.getters.getStatusSideBar();
    });
    const collaboratorsCount = computed(() => {
      return data.project?.totalCollaboratorCount || 1;
    });
    const ownerEmail = computed(() => {
      return data.project?.ownerProfile?.email || '';
    });
    const currentUserRole = computed(() => {
      return data.project?.userRole || '';
    });
    const projectName = computed(() => {
      return data.project?.name || route.params.projectId;
    });
    const projectPermissions = computed(() => {
      return data.project?.permissions || []
    });
    const projectId = computed(() => {
      return route.params.projectId || data.project?.id;
    });
    const canDeleteProject = computed(() => {
      return Boolean(data.project?.permissions.find(p => p === "DELETE_PROJECT"));
    });
    const canRenameProject = computed(() => {
      return Boolean(data.project?.permissions?.find(p => p === "RENAME_PROJECT"));
    });
    const canShareProject = computed(() => {
      return Boolean(data.project?.permissions?.find(p => p === "SHARE_PROJECT"));
    });
    const canUploadDocument = computed(() => {
      return Boolean(data.project?.permissions?.find(p => p === "UPLOAD_DOCUMENT"));
    });
    const favoriteDocuments = computed(() => {
      const documents = Object.values(data?.documents || []);
      return documents.filter(doc => {
        return doc?.favorite?.isFavorite;
      })
    });
    const sortedDocuments = computed(() => {
      const documents = Object.values(data?.documents || []);
      const { sort, dir } = data.representation;
      const sortFn = REPRESENTATION.SORT_FUNC[sort]?.[dir];
      return documents.sort(sortFn);
    });
    const projectService = new ProjectService();
    const featureService = new FeatureService();


    const showExecutiveSummary = useFlag('showExecutiveSummary')
    const executiveSummary = ref(null);
    onMounted(async () => {
      const store = JSON.parse(localStorage.getItem("representation") || "{}");
      data.representation.view = store[route.params.projectId]?.view || REPRESENTATION.DEFAULT.VIEW;
      data.representation.sort = store[route.params.projectId]?.sort || REPRESENTATION.DEFAULT.SORT;
      data.representation.dir = store[route.params.projectId]?.dir || REPRESENTATION.DEFAULT.DIRECTION;
      isLoading.value = true;
      const user = await $auth0.getUser();
      currentUserEmail.value = `${user.profile.email}`.toLowerCase();
      $fetch.on(BROADCAST_MESSAGE.PROJECT_UPDATED, fetchDocuments);
      await fetchProjectData();
      await fetchDocuments();
      await nextTick();
      if (dragNDropArea.value) {
        dragNDropArea.value.addEventListener('dragenter', onDragenter);
      }
      getExecutiveSummaryData(false);
      isLoading.value = false;

    });
    onBeforeUnmount(() => {
      $fetch.off(BROADCAST_MESSAGE.PROJECT_UPDATED, fetchDocuments)
      dragNDropArea.value.removeEventListener('dragenter', onDragenter)
    });

    watch(() => route.params.projectId, fetchDocuments);
    watch(() => data.representation.view, saveRepresentation);
    watch(() => data.representation.sort, saveRepresentation);
    watch(() => data.representation.dir, saveRepresentation);
    watch(() => store.getters.reloadDocumentView, reloadDocuments);
    watch(() => showExecutiveSummary, getExecutiveSummaryData);
    watch(() => applicationState.favoriteDocumentChange, () => {
      const { docId = null, isFavorite } = getApplicationState.setFavoriteDocumentChange();
      if (docId !== null) {
        const documents = Object.values(data?.documents || []);
        const document = documents.find(doc => doc.id === docId);
        if (!document) return;
        document.isFavorite = isFavorite;
      }
    })
    async function getExecutiveSummaryData(invoke = false) {
      // flags.isLoading = true;
      // const { summary, isPending } = await projectService.getExecutiveSummaryData({ projectId: projectId.value, invoke });
      // console.log({ summary });
      // executiveSummary.value = summary;
      // flags.isLoading = false;
      // flags.processPending = isPending;
    }

    async function reloadDocuments() {
      if (!data.getDocumentsInProgress) {
        await fetchDocuments()
      }
    }
    function saveRepresentation() {
      const store = JSON.parse(localStorage.getItem("representation") || "{}");
      store[route.params.projectId] = data.representation;
      localStorage.setItem("representation", JSON.stringify(store));
    }
    function changeRepresentation(payload) {
      for (const entry of Object.entries(payload)) {
        data.representation[entry[0]] = entry[1];
      }
    }
    async function fetchProjectData() {
      SET_CACHE.setCurrentProjectLoadingState();
      const project = await projectService.getProject({ projectId: projectId.value });
      data.project = project;
      SET_CACHE.cacheProjects({ user: { email: currentUserEmail.value }, project });
      SET_CACHE.cacheCurrentProject({ user: { email: currentUserEmail.value }, project });
    }
    async function getAllCollaborators(endCursor = null) {
      const collaborators = await projectService.getProjectCollaborators({
        projectId: projectId.value,
        pageSize: 100,
        endCursor
      })
    }
    async function fetchDocuments(params = {}) {
      if (params.reloadDocuments) {
        pagination.documents.endCursor = null;
      }
      data.getDocumentsInProgress = true;
      SET_CACHE.setDocumentsLoadingState();
      const { sort, dir } = data.representation;
      const sortDirectionValues = {
        ASCENT: 'ASC',
        DESCENT: 'DESC'
      }
      const { documents, hasNextPage, endCursor } = await projectService.getDocuments({
        projectId: projectId.value, ...params, ...pagination.documents,
        sortBy: sort, sortDirection: sortDirectionValues[dir]
      });
      if (params.reloadDocuments) {
        data.documents = []
      }
      const currentDocList = data.documents;

      documents.forEach(doc => {
        const { id: docId } = doc;
        if (!currentDocList.some(e => e.id === docId)) {
          data.documents.push(doc)
        }
      })
      pagination.documents.endCursor = endCursor;
      pagination.documents.hasNextPage = hasNextPage;
      isLoading.value = false;
      SET_CACHE.cacheDocuments({
        documents: data.documents.map(d => {
          d.projectId = projectId.value;
          return d;
        })
      });
      if (hasNextPage) {
        await fetchDocuments()
      }
      store.mutations.reloadDocumentView(false)
      data.getDocumentsInProgress = false;
    }

    const onDragenter = (e) => {
      e.preventDefault();
      if (e.target.id === 'dragAndDropOverlay') {
        e.target.addEventListener('dragleave', onDragLeave);
        e.target.addEventListener('dragover', onDragOver);
        e.target.addEventListener('drop', onDrop);
      }
      dragNDropStart.value = true;
    }

    const onDragOver = (e) => {
      e.preventDefault();
    }

    const onDrop = async (e) => {
      e.preventDefault();
      e.target.removeEventListener('dragleave', onDragLeave);
      e.target.removeEventListener('dragover', onDragOver);
      e.target.removeEventListener('drop', onDrop);
      dragNDropStart.value = false;
      if (e.dataTransfer.files.length === 0 || !canUploadDocument.value) return;
      uploadDocuments(e.dataTransfer.files);
    }

    const onDragLeave = (e) => {
      e.preventDefault();
      e.target.removeEventListener('dragleave', onDragLeave);
      e.target.removeEventListener('dragover', onDragOver);
      e.target.removeEventListener('drop', onDrop);
      dragNDropStart.value = false;
    }

    async function rejectShare() {
      await $fetch.shareProject(projectId.value, currentUserEmail.value, "REJECTED").then(() => {
        $router.push({ name: 'home' })
      });
    }

    function addDoc() {
      if (!canUploadDocument) return;
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      // input.setAttribute("accept", ALLOWED_CONTENT_TYPES.join(", "));
      input.setAttribute("multiple", "");
      input.onchange = (e) => uploadDocuments(e.target.files);
      input.click();
    }

    function showProjectDetails() {
      detailsProjectId.value = projectId.value;
    }

    async function duplicateProject() {

      const id = await projectService.duplicateProject(projectId.value);
      if (id) {
        const type = SUCCESS_TOAST;
        const title = 'Successfully duplicated project';
        const message = `<span><span class="message-toast-name">
                      ${projectName.value}
                      </span> has been created and added to your projects.</span>`;
        const toast = {
          type, title, message,
        }
        $fetch.dispatch(BROADCAST_MESSAGE.TOAST, toast);
      } else {
        message = 'Folia encountered an error when attempting to duplicate this project. Please try again.';
        const toast = {
          type: ERROR_TOAST, title: 'Error duplicating project', message
        }
        $fetch.dispatch(BROADCAST_MESSAGE.TOAST, toast);
      }
    }

    async function showDocumentDetails(documentId) {
      detailsDocumentId.value = documentId
    }

    function uploadDocuments(files) {
      const fileNames = []
      for (const file of files) {
        fileNames.push(file.name)
      }
      $uploadFiles.uploadDocuments(projectId.value, files);
    }

    function fetchDeletedProject() {
      $router.push({ name: 'home' });
    }

    function renameDoc(documentId) {
      editDocumentId.value = documentId;
      const document = data.documents.find((doc) => doc.id === documentId);
      editDocumentName.value = document.name;
    }

    function copyToProjectDoc(documentId) {
      const document = data.documents.find((doc) => doc.id === documentId);
      copyToProjectDocumentId.value = documentId;
      copyToProjectDocumentName.value = document.name;
    }

    function removeDeleteDocument(docId) {
      data.documents = data.documents.filter(d => d.id !== docId);
    }
    function removeDocFromList() {
      data.documents = data.documents.filter(d => d.id !== deleteDocumentId.value);
      deleteDocumentId.value = '';
      deletedDocumentName.value = ''
    }
    function removeDoc(documentId) {
      deleteDocumentId.value = documentId
      deletedDocumentName.value = data.documents.find(d => d.id === documentId).name
    }

    async function downloadDocument(options) {
      const { documentId, flatten = true, original = false, fileName } = options;
      detailsDocumentId.value = '';

        const type = SUCCESS_TOAST;
        const title = 'Your file is being downloaded';
        const message = `<span><span class="message-toast-name">
                      ${fileName}
                      </span>.</span>`;
        const toast = {
          type, title, message,
        }
        $fetch.dispatch(BROADCAST_MESSAGE.TOAST, toast);
      const { content, originMimeType = 'application/pdf' } = await projectService.downloadDocument({ documentId, flatten, original, fileName });
      if(content === null) {
        $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
          title:'File download failed',
          type:'ERROR_TOAST',
          message:`Please try agin later.`
        });
        return;
      }
      function downloadPDF() {
        const base64PDF = content.replace(/^data:application\/pdf;base64,/, '');
        const binaryPDF = atob(base64PDF);
        const arrayBuffer = new ArrayBuffer(binaryPDF.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryPDF.length; i++) {
          uint8Array[i] = binaryPDF.charCodeAt(i);
        }

        const blob = new Blob([uint8Array], { type: original ? originMimeType : 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = original?`${fileName}`:`${fileName}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      downloadPDF()
    }

    async function toggleDocumentFavoriteStatus(documentId, isFavorite) {
      const { tenantId } = data.project;
      const email = localStorage.getItem("email");
      const documents = Object.values(data?.documents || []);
      const document = documents.find(doc => doc.id === documentId);
      if (!document) return;
      document.isFavorite = isFavorite;
      await projectService.MarkDocumentFavorite({ documentId, isFavorite, tenantId, email });
    }

    function afterProjectDeleting() {
      showDeleteProjectModal.value = false;
      $router.replace("/");
    }

    function renameProject() {
      renamedProject.value = data.project;
    }

    async function completeRenamingProject(payload = {}) {
      const { name, description } = payload;
      if (name) {
        await projectService.editProject({ projectId: projectId.value, name, description });
        await fetchProjectData();
      }
      renamedProject.value = null;

    }

    return {
      SORT, REPRESENTATION,
      data, sortedDocuments, canDeleteProject, canRenameProject,
      renameDocDialogRef, docNameRef, showSharedDialog, popupProjectContainer,
      dragNDropArea, ownerEmail, editDocumentId, editDocumentName, isLoading,
      dragNDropStart, currentDocument, copyToProjectDocumentName,
      showDeleteProjectModal, detailsDocumentId, renamedProject,
      favoriteDocuments, deletedDocumentName, detailsProjectId, copyToProjectDocumentId,
      deleteDocumentId, collaboratorsCount, projectName, projectPermissions,
      projectId, canShareProject, popupProjectShown, currentUserRole,
      isSideBarShown, canUploadDocument, sortType, sortDirection,
      flags, showExecutiveSummary, executiveSummary,

      changeRepresentation, downloadDocument, renameDoc, removeDoc, addDoc,
      afterProjectDeleting, copyToProjectDoc, duplicateProject, rejectShare,
      showProjectDetails, removeDeleteDocument, showDocumentDetails,
      fetchDocuments, fetchDeletedProject, toggleDocumentFavoriteStatus,
      renameProject, completeRenamingProject, getExecutiveSummaryData, removeDocFromList
    };
  },
};
</script>

<template>
  <div class="project-container min-h-svh max-w-[100vw] md:max-w-full">
    <DocumentDetails v-if="detailsDocumentId" :project="data.project" :document-id="detailsDocumentId"
      :userRole="currentUserRole" @close="detailsDocumentId = ''" @downloadDocument="downloadDocument" />
    <ProjectDetails v-if="detailsProjectId" :projectId="detailsProjectId" :projectPermissions="projectPermissions"
      :projectData="data.project" @close="detailsProjectId = ''" @editProject="renameProject" />
    <div class="project-container-drag-n-drop-area" ref="dragNDropArea">
      <div class="drag-and-drop-overlay" id="dragAndDropOverlay" v-if="dragNDropStart"
        :class="{ 'enabled': canUploadDocument, 'disabled': !canUploadDocument }">
        <div v-if="canUploadDocument" class="drag-and-drop-allow">
          <div>Drop files to instantly upload them to:</div>
          <div>{{ projectName }}</div>
        </div>
        <div v-else class="drag-and-drop-deny">
          <div>This file could not be uploaded.</div>
          <div>You don’t have an access to upload files to this project.</div>
        </div>
      </div>
      <SharingDialog :projectId="projectId" :currentUserRole="currentUserRole" :isOpen="showSharedDialog"
        @close="showSharedDialog = false" />
      <div class="project-header w-full p-8 pb-14 flex flex-col">
        <div class="project-header-row flex flex-col md:flex-row justify-between">
          <div class="project-info-container">
            <span class="project-label">PROJECT</span>
            <div class="project-info-title-container">
              <span v-if="!isLoading" class="project-name" :title="projectName">{{ projectName }}</span>
              <div class="info-icon" @click="showProjectDetails()">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 13V15" stroke="#64748B" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path
                    d="M12.5 9C12.5 9.27614 12.2761 9.5 12 9.5C11.7239 9.5 11.5 9.27614 11.5 9C11.5 8.72386 11.7239 8.5 12 8.5C12.2761 8.5 12.5 8.72386 12.5 9Z"
                    stroke="#64748B" />
                  <path
                    d="M19.25 12C19.25 16.0041 16.0041 19.25 12 19.25C7.99594 19.25 4.75 16.0041 4.75 12C4.75 7.99594 7.99594 4.75 12 4.75C16.0041 4.75 19.25 7.99594 19.25 12Z"
                    stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
            <div class="collaborators-container">
              <div class="owned-container">
                <OwnerIcon />
                <span class="owner-name">
                  {{ `Owned by ${ownerEmail}` }}
                </span>
              </div>
              <div class="dot" />
              <div class="shared-container">
                <sharedDocumentHeaderIcon />
                <span class="shared-name">
                  {{ collaboratorsCount <= 1 ? "Shared with no people" : collaboratorsCount === 2 ? `Shared with 1 person`
      : `Shared with ${collaboratorsCount - 1} others` }} </span>
              </div>
            </div>
          </div>
          <div class="action-document-container mt-4 md:mt-0 md:ml-auto">
            <FoliaContextMenu class="document-context-menu" v-if="projectPermissions" :userRole="currentUserRole"
              :notListView="true" @menu-share="showSharedDialog = true" @menu-duplicate="duplicateProject"
              @menu-document-details="" @menu-reject="rejectShare" @menu-rename="renameProject"
              @menu-delete-project="showDeleteProjectModal = true" :permissions="projectPermissions" />
            <ActionButton class="share-action-btn" v-if="canShareProject" :width="'72px'" :height="'36px'"
              :text="'Share'" :fontColor="'#334155'" :background-color="'#FFFFFF'" @click="showSharedDialog = true" />
            <UploadDocuments @addLocalFiles="addDoc" v-if="canUploadDocument" />
          </div>
        </div>
      </div>

      <div v-if="sortedDocuments.length === 0 && isLoading" class="documents-container">
        <div class="loading-overlay">
          <Spinner width="80px" height="80px" />
        </div>
      </div>
      <div v-if="sortedDocuments.length === 0 && !isLoading" class="documents-container-empty">
        <div class="empty-documents-container">
          <emptyDocumentCardImage />
          <div class="empty-documents-container-text">
            <span class="title">This project is empty!</span>
            <span class="sub-text" v-if="canUploadDocument">Click below or drag and drop to add documents to this
              project.</span>
          </div>
          <button class="new-document-btn" v-if="canUploadDocument" @click="addDoc">
            <PlusIcon />
            <span>Add Document</span>
          </button>
        </div>
      </div>
      <div v-if="sortedDocuments.length > 0 && !isLoading"
        class="documents-container flex w-full  max-h-[60vh] min-h-[70vh] md:max-h-[74vh] overflow-y-scroll">
        <div v-if="data.representation.view === REPRESENTATION.VIEWS.GRID">
          <div class="pl-8 pt-2 mt-2">
            <SortSelect :representation="data.representation" @change="changeRepresentation" class="sort-select" />
          </div>
          <div
            class="document-container-grid pl-8 pt-2 pr-2 grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 2xl:gap-10">
            <template v-for="document in sortedDocuments.filter(d => d.isAnnotatable)" :key="document.id">
              <DocumentCard :doc="document" :permissions="data.project.permissions" :userRole="currentUserRole"
                @copy-to-project="copyToProjectDoc" @show-document-details="showDocumentDetails" @rename="renameDoc"
                @remove="removeDoc" @download="downloadDocument" @toggleFavoriteStatus="toggleDocumentFavoriteStatus" />
            </template>
          </div>
          <div v-if="sortedDocuments.filter(d => !d.isAnnotatable).length>0" class="text-left pl-8 pt-8">
            <h1 class="font-bold text-gray-700">Download-only Files</h1>
            <p class=" text-gray-400">These files have been included as part of this project, but are available only to
              download.
            </p>
          </div>
          <div
            class="document-container-grid pl-8 pt-2 pr-2 grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 2xl:gap-10">
            <template v-for="document in sortedDocuments.filter(d => !d.isAnnotatable)" :key="document.id">
              <DocumentCard :doc="document" :permissions="data.project.permissions" :userRole="currentUserRole"
                @copy-to-project="copyToProjectDoc" @show-document-details="showDocumentDetails" @rename="renameDoc"
                @remove="removeDoc" @download="downloadDocument" @toggleFavoriteStatus="toggleDocumentFavoriteStatus" />
            </template>
          </div>

        </div>
        <div v-if="data.representation.view === REPRESENTATION.VIEWS.TABLE" class="document-container-table">
          <DocumentTable :data="sortedDocuments" :permissions="data.project.permissions" :userRole="currentUserRole"
            @copy-to-project="copyToProjectDoc" @show-document-details="showDocumentDetails" @rename="renameDoc"
            @remove="removeDoc" @download="downloadDocument" @toggleFavoriteStatus="toggleDocumentFavoriteStatus" />
        </div>
      </div>

      <EditDocumentModal v-if="editDocumentId" :documentName="editDocumentName" :documentId="editDocumentId"
        :projectId="projectId" @fetchDocuments="fetchDocuments" @close="editDocumentId = ''" />
      <DeleteDocumentModal v-if="deleteDocumentId" :documentName="deletedDocumentName" :documentId="deleteDocumentId"
        :projectId="projectId" @fetchDocuments="fetchDocuments" @removeDeleteDocument="removeDocFromList"
        @close="deleteDocumentId = ''" />
      <EditProjectModal v-if="renamedProject" :project="renamedProject" @close="completeRenamingProject" />
      <DeleteProjectModal v-if="showDeleteProjectModal" :projectId="projectId" @fetchProject="fetchDeletedProject"
        @close="afterProjectDeleting" />
      <CopyToProjectModal v-if="copyToProjectDocumentId" :activeProjectId="projectId"
        :documentId="copyToProjectDocumentId" :documentName="copyToProjectDocumentName"
        @close="copyToProjectDocumentId = ''" />
    </div>
    <LeftSideBar :favorites="favoriteDocuments" :userRole="currentUserRole"
      @toggleFavoriteStatus="toggleDocumentFavoriteStatus" />
  </div>
</template>

<style lang="scss" scoped>
//TODO:Remove these temp css later
.execute-btn {
  display: flex;
  align-items: center;
  background-color: #334155;
  color: white;
  padding: 10px;
  border: 0;
  border-radius: 10px;
  margin: 3px;
  font-weight: 600;
  cursor: pointer;

}

.reload-btn {
  display: flex;
  align-items: center;
  background-color: #334155;
  color: white;
  padding: 10px;
  border: 0;
  border-radius: 10px;
  margin: 3px;
  font-weight: 600;
  cursor: pointer;
}

// END

.summary-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  padding: 5px;
  border: 1px solid #E2E8F0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 700px;
  margin: 3px auto;
}

.project-container {
  display: flex;
  width: 100%;
  height: 100%;

  .new-document-btn {
    width: 157px;
    height: 36px;
    display: flex;
    padding: 8px 16px;
    background: #334155;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    color: white;
    cursor: pointer;
    border: 1px solid #334155;

    .title-new-do-btn-btn {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .new-document-btn:hover {
    box-shadow: 0 0 4px 4px #e6e6e6;
  }
}

.left-side-bar {
  width: 240px;
  min-width: 240px;
  background: #FFFFFF;
  border-right: 1px solid #E2E8F0;
  transition: all 1.5s;
}

.right-side-details {
  position: absolute;
  right: 0;
  display: flex;
  width: 320px;
  min-width: 320px;
  height: calc(100% - 48px);
  background-color: white;
  z-index: 1000;
  box-shadow: -4px 0px 8px rgba(51, 65, 85, 0.15);

  .header-details {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    background: #F8FAFC;

    .header-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #334155;
    }

    .close-icon {
      cursor: pointer;
    }
  }
}

.hide-left-side-bar {
  display: none;
  transition: all 0.5s;
}

.project-container-drag-n-drop-area {
  //border: solid 4px greenyellow;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;

  .drag-and-drop-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    border-radius: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 1200;

    &.enabled {
      border: dashed 3px #0a84ff;
      background-color: #0a84ff80;
    }

    &.disabled {
      border: dashed 3px #F04E23;
      background-color: #F04E2380;
    }

    .drag-and-drop-allow {
      background: #0a84ff;
      background-position: 10px;
      color: white;
      font-weight: 400;
      border-radius: 5px;
    }

    .drag-and-drop-deny {
      background: #f35f5f;
      background-position: 10px;
      color: white;
      font-weight: 400;
      padding: 10px;
      padding-left: 40px;
      border-radius: 5px;
    }
  }

  .project-header {
    // width: 100%;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: space-between;
    background: #FFFFFF;
    border-bottom: 1px solid #E2E8F0;

    .project-header-row {
      // width: 90%;
      height: 85px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .project-info-container {
        display: flex;
        // margin-top: 75px;
        gap: 8px;
        flex-direction: column;
        text-align: start;
        justify-content: flex-start;

        .project-label {
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #94A3B8;
        }

        .project-info-title-container {
          display: flex;
          flex-direction: row;
          align-items: center;

          .project-name {
            font-weight: 600;
            font-size: 20px;
            color: #334155;
            max-width: 600px;
            max-height: 48px;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .info-icon {
            cursor: pointer;
            width: 24px;
            height: 24px;
          }
        }

        .collaborators-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-weight: 400;
          font-size: 12px;
          white-space: nowrap;
          line-height: 16px;
          color: #64748B;

          .owned-container {
            display: flex;
            align-items: center;
            text-align: center;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
          }

          .dot {
            width: 4px;
            height: 4px;
            margin: 0 10px;
            border-radius: 50%;
            background: #CBD5E1;
          }

          .shared-container {
            display: flex;
            align-items: center;
            text-align: center;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }

      .action-document-container {
        display: flex;
        flex-direction: row;

        .document-context-menu {
          border: 1px solid #E2E8F0;
          margin-right: 10px;
          border-radius: 8px;
        }

        .share-action-btn {
          font-weight: 600;
          font-size: 14px;
          margin-left: 0px;
          line-height: 20px;
          border: 1px solid #E2E8F0;
          border-radius: 8px;
        }
      }
    }

    .project-header-row-nav {
      width: 90%;
      display: flex;
      margin-right: 80px;
      margin-left: 80px;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;

      .sort-container {
        display: flex;
        margin-bottom: 16px;
        flex-direction: row;

        .list-header-view-mode {
          display: flex;
          -webkit-box-align: center;
          align-items: center;

          .sort-select {
            margin-right: 10px;
          }

          .view-mode-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            background: #E2E8F0;
            border-radius: 8px;
            width: 68px;
            height: 36px;
            padding: 4px;
            gap: 4px;

            .view-mode-grid {
              width: 28px;
              height: 28px;
              padding: 4px;
              color: #64748B;
              border-radius: 2px;
              cursor: pointer;
            }

            .view-mode-grid.selected {
              background: #FFFFFF;
              cursor: unset;
              padding: 4px;
              color: #334155;
              width: 28px;
              height: 28px;
              border-radius: 4px;
            }

            //.view-mode-grid:hover {
            //  filter: drop-shadow(0 0 2px #74aff1);
            //}
            .view-mode-table {
              width: 28px;
              height: 28px;
              color: #64748B;
              padding: 4px;
              border-radius: 2px;
              cursor: pointer;
            }

            .view-mode-table.selected {
              background: #FFFFFF;
              cursor: unset;
              padding: 4px;
              color: #334155;
              width: 28px;
              height: 28px;
              border-radius: 4px;
            }
          }

          .button-actions-container {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            justify-content: center;
            flex-direction: row;
          }
        }
      }
    }
  }

  .documents-container-empty {
    display: flex;
    width: 100%;
    height: calc(100% - 186px);

    .empty-documents-container {
      display: flex;
      flex-direction: column;
      margin: auto auto;
      justify-content: center;
      align-items: center;
      gap: 20px;

      .empty-documents-container-text {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .title {
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
          color: #334155;
        }

        .sub-text {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #64748B;
        }
      }
    }
  }

  .documents-container {
    display: grid;
    grid-template-rows: auto 1fr;
    width: 100%;
    height: 100%;

    .document-container-grid {
      background: #F8FAFC !important;
      // display: grid;
      // grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
      // grid-auto-rows: minmax(286px, 286px);
      // gap: 24px;
      // overflow-y: auto;
      // margin-bottom: 170px;
      // padding: 36px 80px 80px 80px;
    }

    .document-container-table {
      height: auto;
      width: 100%;
      display: flex;
      overflow: auto;
      justify-content: center;
      padding-left: 80px;
      margin-bottom: 190px;
      padding-right: 80px;
    }

    .loading-overlay {
      display: flex;
      flex-direction: column;
      margin: auto auto;
      justify-content: center;
      align-items: center;
    }
  }

  .rename_dialog {

    input,
    textarea {
      width: 100%;
      margin-bottom: 10px;
    }

    textarea {
      resize: none;
    }
  }

  .rename-doc-dialog {
    min-width: 300px;

    input {
      width: 100%;
      margin-bottom: 30px;
    }
  }
}

/* Tablet (landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  .left-side-bar {
    position: absolute;
    height: 100%;
    z-index: 1000;
    //background-color: red;
  }

  .document-container-table {
    padding: 15px !important;
  }

  .project-name {
    max-width: 350px !important;
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .project-header-row {
    width: 100% !important;
  }
}

.app-container {
  @media only screen and (max-width: 768px) {
    & .project-header-row-nav {
      & .view-mode-container {
        display: none !important;
      }

      min-height: 100px;
      flex-direction: column !important;
    }

    & .documents-container {
      padding-bottom: 30px;

      & .document-container-grid {
        padding: 25px;
        margin-bottom: 220px;
      }
    }
  }
}
</style>
