<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.41634 12.708C4.61093 12.708 3.95801 12.0551 3.95801 11.2497V5.62467C3.95801 4.7042 4.7042 3.95801 5.62467 3.95801H11.2497C12.0551 3.95801 12.708 4.61093 12.708 5.41634M8.95801 16.0413H14.3747C15.2951 16.0413 16.0413 15.2951 16.0413 14.3747V8.95801C16.0413 8.03753 15.2951 7.29134 14.3747 7.29134H8.95801C8.03753 7.29134 7.29134 8.03753 7.29134 8.95801V14.3747C7.29134 15.2951 8.03753 16.0413 8.95801 16.0413Z" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "duplicate-icon",
};
</script>

<style scoped>

</style>
