<template>
  <div class="alert-toasts" ref="rootEl" :style="{
    display: toastsList.size > 0 ? 'flex' : 'none'
  }">
    <div
      v-for="[id, toast] of toastsList"
      :id="id"
      class="alert-toast"
      :class="{
        'offline': toast.type === OFFLINE_TOAST,
        'error': toast.type === ERROR_TOAST,
        'warning': toast.type === WARN_TOAST,
        'info': toast.type === INFO_TOAST,
        'success': toast.type === SUCCESS_TOAST,
        'created': toast.created,
        'deleted': toast.deleted
      }"
      :style="{'max-height': 55 + (toast.message ? 55 : 0) + (toast.action ? 25 : 0) + 'px'}"
    >
      <div v-if="toast.type === OFFLINE_TOAST" class="alert-toast-icon">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.5 18C12.5 18.2761 12.2761 18.5 12 18.5C11.7239 18.5 11.5 18.2761 11.5 18C11.5 17.7239 11.7239 17.5 12 17.5C12.2761 17.5 12.5 17.7239 12.5 18Z" fill="#2990FF" stroke="#2990FF" stroke-width="1.67"/>
          <path d="M9.5 14.5627C10.2016 14.0516 11.0656 13.75 12 13.75C12.9344 13.75 13.7984 14.0516 14.5 14.5627M7.2876 11.2277C8.19156 10.5975 9.22762 10.1436 10.3459 9.91602M14.1794 10.041C15.0976 10.2918 15.952 10.6973 16.7128 11.2277M4.75 8.25C6.73421 6.86597 9 5.75 12 5.75C12.6875 5.75 13.3364 5.8086 13.9522 5.9156M17.3354 7.07854C18.0087 7.42768 18.6425 7.82628 19.25 8.25M18.25 5.75L6.75 17.25" stroke="#2990FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div v-if="toast.type === ERROR_TOAST" class="alert-toast-icon">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.74997 5.75L10.25 10.25M10.25 5.75L5.74997 10.25M7.99997 15.25C3.99591 15.25 0.749969 12.0041 0.749969 8C0.749969 3.99594 3.99591 0.75 7.99997 0.75C12.004 0.75 15.25 3.99594 15.25 8C15.25 12.0041 12.004 15.25 7.99997 15.25Z" stroke="#F43F5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div v-if="toast.type === WARN_TOAST" class="alert-toast-icon">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.00001 6V8M6.21523 1.85658L0.952211 12.3536C0.285408 13.6835 1.25235 15.25 2.74008 15.25H13.2613C14.7487 15.25 15.7156 13.6841 15.0495 12.3543L9.79128 1.85723C9.05391 0.385196 6.95314 0.384812 6.21523 1.85658ZM8.50001 12C8.50001 12.2761 8.27615 12.5 8.00001 12.5C7.72386 12.5 7.50001 12.2761 7.50001 12C7.50001 11.7239 7.72386 11.5 8.00001 11.5C8.27615 11.5 8.50001 11.7239 8.50001 12Z" stroke="#F59E0B"/>
        </svg>
      </div>
      <div v-if="toast.type === INFO_TOAST" class="alert-toast-icon">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 9V11" stroke="#3B82F6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8.5 5C8.5 5.27614 8.27614 5.5 8 5.5C7.72386 5.5 7.5 5.27614 7.5 5C7.5 4.72386 7.72386 4.5 8 4.5C8.27614 4.5 8.5 4.72386 8.5 5Z" stroke="#3B82F6"/>
          <path d="M15.25 8C15.25 12.0041 12.0041 15.25 8 15.25C3.99594 15.25 0.75 12.0041 0.75 8C0.75 3.99594 3.99594 0.75 8 0.75C12.0041 0.75 15.25 3.99594 15.25 8Z" stroke="#3B82F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div v-if="toast.type === SUCCESS_TOAST" class="alert-toast-icon">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.75 8C0.75 3.99594 3.99594 0.75 8 0.75C12.0041 0.75 15.25 3.99594 15.25 8C15.25 12.0041 12.0041 15.25 8 15.25C3.99594 15.25 0.75 12.0041 0.75 8Z" stroke="#10B981" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M5.75 8.75L6.18373 9.67438C6.52746 10.407 7.55365 10.4492 7.95638 9.74729L10.25 5.75" stroke="#10B981" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>

      <div class="alert-toasts-body">
        <span class="alert-toast-title">{{toast.title}}</span>
        <div class="alert-toast-message" v-if="toast.message" v-html="toast.message"></div>
      </div>

      <button class="alert-toast-button" @click.stop="hideToast(id)">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" stroke="#334155" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.25 0.75L0.749969 11.25M0.749969 0.75L11.25 11.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";
import { inject, onBeforeUnmount, onMounted, ref } from "vue";
import {
  SUCCESS_TOAST,
  INFO_TOAST,
  WARN_TOAST,
  ERROR_TOAST,
  OFFLINE_TOAST,
  BROADCAST_MESSAGE
} from "../../core/constants.js";

const CLOSE_TIMEOUT = 5_000;
const ANIMATION_TIME_MS = 250;

export default {
  name: "AlertToasts",
  setup() {
    const $fetch = inject("$fetch");
    const toastsList = ref(new Map());
    const rootEl = ref(null);

    onMounted(() => {
      $fetch.on(BROADCAST_MESSAGE.TOAST, onReceivedToast);
      rootEl.value.style.setProperty("--animation--time-ms", `${ANIMATION_TIME_MS}ms`)
    });
    onBeforeUnmount(() => {
      $fetch.off(BROADCAST_MESSAGE.TOAST, onReceivedToast);
      toastsList.value.forEach(toast => {
        clearTimeout(toast.closeTimer);
      });
    });

    function onReceivedToast(toast) {
      if (!toast) return;
      const id = uuid();
      toastsList.value.set(id, {...toast, created: true, deleted: false})
      setTimeout(() => {
        const toast = toastsList.value.get(id);
        toast.created = false;
        toast.closeTimer = setTimeout(
          () => hideToast(id),
          toast.close_timeout || CLOSE_TIMEOUT
        );
      }, ANIMATION_TIME_MS);
    }

    function hideToast(id) {
      const toast = toastsList.value.get(id);
      clearTimeout(toast.closeTimer);
      toast.deleted = true;
      setTimeout(() => {
        toastsList.value.delete(id);
      }, ANIMATION_TIME_MS - 10);
    }

    return {
      SUCCESS_TOAST, INFO_TOAST, WARN_TOAST, ERROR_TOAST, OFFLINE_TOAST,
      rootEl, toastsList,

      hideToast
    }
  }
};
</script>
<style lang="scss">
  .message-toast-name {
    color:#64748b;
    max-width: 310px !important;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis !important;
    font-weight: 600;
  }
</style>
<style scoped lang="scss">
.alert-toasts {
  z-index: 1000 !important;
  --animation--time-ms: 100ms;
  position: absolute;
  right: 25px;
  bottom: 15px;
  width: 450px;
  overflow: hidden;
  gap: 16px;

  display: flex;
  flex-direction: column;

  .alert-toast {
    display: flex;
    justify-content: center;
    gap: 16px;
    padding: 8px 0;
    overflow: hidden;

    width: 429px;
    background: #FFFFFF;
    border-left: 4px solid transparent;
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.15);
    border-radius: 0 8px 8px 0;

    &.created {
      animation: created-toast var(--animation--time-ms) ease-in-out;
    }
    &.deleted {
      animation: deleted-toast var(--animation--time-ms) ease-in-out;
    }
    @keyframes created-toast {
      from {
        height: 0;
      }
      to {
        height: 40px;
      }
    }
    @keyframes deleted-toast {
      from {
        height: 40px;
      }
      to {
        height: 0;
      }
    }

    &.success {
      border-left: solid 4px #10B981;
    }
    &.info {
      border-left: solid 4px #3B82F6;
    }
    &.warning {
      border-left: solid 4px #F59E0B;
    }
    &.error {
      border-left: solid 4px #F43F5E;
    }
    &.offline {
      border-left: solid 4px #2990FF;
    }

    .alert-toast-icon {
      display: flex;
      width: 24px;
      height: 24px;
      align-items: center;
    }

    .alert-toasts-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 310px;
      gap: 5px;
      width: 100%;

      .alert-toast-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #334155;
      }
      .alert-toast-message {
        font-weight: 400;
        font-size: 14px;
        color: #64748B;
        line-height: 1.2rem;
        max-height: 3.6rem;
        //min-height: 3.6rem;
        overflow: hidden;
      }
      .alert-toast-action {
        color: #2990FF;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
      }
    }

    .alert-toast-button {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      outline: none;
      border: none;
      background-color: transparent;
      cursor: pointer;

      &:hover svg {
        stroke: #64748B;
      }
    }
  }
}
</style>
