// prettier-ignore
import html2canvas from "html2canvas";
import {
  ANNO_TYPES,
  FONT_WEIGHT,
  MAP_CONSTANTS,
  STAMP_TYPES,
  TEXT_ALIGNMENT,
} from "../../../core/constants.js";
// import text from "../../../assets/vue-icons/tools/text.vue";

class AnnotationPreviewRenderer {
  #viewport;
  #preview;
  #stampData;

  constructor() {}

  fromPdfRect(pdfRect) {
    const [left, top, right, bottom] = pdfRect;
    return [
      Math.round(left * this.#viewport.width),
      Math.round(top * this.#viewport.height),
      Math.round(right * this.#viewport.width - left * this.#viewport.width),
      Math.round(bottom * this.#viewport.height - top * this.#viewport.height),
    ];
  }

  fromPdfPoint(point) {
    const [left, top] = point;
    const x = Math.round(left * this.#viewport.width);
    const y = Math.round(top * this.#viewport.height);
    return { x, y };
  }

  fromPdfPaths(paths) {
    const canvasPaths = [];

    // convert from pdf coordinates to absolute points
    for (const path of paths) {
      const canvasPath = path.reduce((acc, num, index, arr) => {
        if (index % 2 === 0) return acc;
        const point = this.fromPdfPoint([arr[index - 1], num]);
        acc.push(point);
        return acc;
      }, []);
      canvasPaths.push(canvasPath);
    }

    return canvasPaths;
  }

  hexColor2RGBA(hexColor) {
    const hex = hexColor.toLowerCase().split("").slice(1).join("");
    if (hex.length % 2 !== 0)
      console.warn(`invalid hex color string "${hexColor}"`);
    const red = parseInt(hex.substring(0, 2), 16);
    const green = parseInt(hex.substring(2, 4), 16);
    const blue = parseInt(hex.substring(4, 6), 16);
    let alpha = parseInt(hex.substring(6, 8) || "ff", 16) / 255;
    if (alpha < 0.1) alpha = 0.1;
    return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
  }

  #renderInk() {
    let left = Infinity,
      top = Infinity,
      right = -Infinity,
      bottom = -Infinity;

    const canvasPaths = this.fromPdfPaths(this.#stampData.paths);
    let lineWidth = this.#stampData.lineWidth * this.#viewport.scale;

    // found annotation bounds
    canvasPaths.forEach((canvasPath) => {
      canvasPath.forEach((point) => {
        left = Math.min(left, point.x);
        top = Math.min(top, point.y);
        right = Math.max(right, point.x);
        bottom = Math.max(bottom, point.y);
      });
    });
    left -= lineWidth / 2;
    top -= lineWidth / 2;
    right += lineWidth / 2;
    bottom += lineWidth / 2;

    let scaleFactor = 1;
    let canvasWidth = right - left;
    let canvasHeight = bottom - top;
    if (this.#preview.width && this.#preview.height) {
      scaleFactor = Math.min(
        this.#preview.width / (right - left),
        this.#preview.height / (bottom - top)
      );
      canvasWidth *= scaleFactor;
      canvasHeight *= scaleFactor;
      lineWidth *= scaleFactor;
    }

    // make as relative coordinates and use new canvas size
    canvasPaths.forEach((canvasPath) => {
      canvasPath.forEach((point) => {
        point.x = (point.x - left) * scaleFactor * window.devicePixelRatio;
        point.y = (point.y - top) * scaleFactor * window.devicePixelRatio;
      });
    });

    const canvas = document.createElement("canvas");
    canvas.width = canvasWidth * window.devicePixelRatio;
    canvas.height = canvasHeight * window.devicePixelRatio;
    const ctx = canvas.getContext("2d");

    ctx.strokeStyle = this.hexColor2RGBA(this.#stampData.color);
    ctx.lineWidth = lineWidth * window.devicePixelRatio;
    ctx.lineCap = "round";
    ctx.lineJoin = "round";

    canvasPaths.forEach((path) => {
      ctx.save();
      let p1 = path[0];
      let p2 = path[1];
      ctx.beginPath();
      ctx.moveTo(p1.x, p1.y);
      for (let i = 1, len = path.length; i < len; i++) {
        const mp = {
          x: p1.x + (p2.x - p1.x) * 0.5,
          y: p1.y + (p2.y - p1.y) * 0.5,
        };
        ctx.quadraticCurveTo(p1.x, p1.y, mp.x, mp.y);
        p1 = path[i];
        p2 = path[i + 1];
      }
      ctx.lineTo(p1.x, p1.y);
      ctx.stroke();
    });

    if (this.#preview.el)
      this.#preview.el.style.backgroundImage = `url(${canvas.toDataURL()})`;
    return canvas.toDataURL();
  }

  #renderInk_old() {
    let left = Infinity,
      top = Infinity,
      right = -Infinity,
      bottom = -Infinity;

    const canvasPaths = this.fromPdfPaths(this.#stampData.paths);
    let lineWidth = this.#stampData.lineWidth * this.#viewport.scale;

    // found annotation bounds
    canvasPaths.forEach((canvasPath) => {
      canvasPath.forEach((point) => {
        left = Math.min(left, point.x);
        top = Math.min(top, point.y);
        right = Math.max(right, point.x);
        bottom = Math.max(bottom, point.y);
      });
    });
    left -= lineWidth / 2;
    top -= lineWidth / 2;
    right += lineWidth / 2;
    bottom += lineWidth / 2;

    let scaleFactor = 1;
    let canvasWidth = right - left;
    let canvasHeight = bottom - top;
    if (this.#preview.width && this.#preview.height) {
      scaleFactor = Math.min(
        this.#preview.width / (right - left),
        this.#preview.height / (bottom - top)
      );
      canvasWidth *= scaleFactor;
      canvasHeight *= scaleFactor;
      lineWidth *= scaleFactor;
    }

    // make as relative coordinates and use new canvas size
    canvasPaths.forEach((canvasPath) => {
      canvasPath.forEach((point) => {
        point.x = (point.x - left) * scaleFactor * window.devicePixelRatio;
        point.y = (point.y - top) * scaleFactor * window.devicePixelRatio;
      });
    });

    const canvas = document.createElement("canvas");
    canvas.width = canvasWidth * window.devicePixelRatio;
    canvas.height = canvasHeight * window.devicePixelRatio;
    const ctx = canvas.getContext("2d");

    ctx.strokeStyle = this.hexColor2RGBA(this.#stampData.color);
    ctx.lineWidth = lineWidth * window.devicePixelRatio;
    ctx.lineCap = "round";

    canvasPaths.forEach((path) => {
      ctx.save();
      let p1 = path[0];
      let p2 = path[1];
      ctx.beginPath();
      ctx.moveTo(p1.x, p1.y);
      for (let i = 1, len = path.length; i < len; i++) {
        const mp = {
          x: p1.x + (p2.x - p1.x) * 0.5,
          y: p1.y + (p2.y - p1.y) * 0.5,
        };
        ctx.quadraticCurveTo(p1.x, p1.y, mp.x, mp.y);
        p1 = path[i];
        p2 = path[i + 1];
      }
      ctx.lineTo(p1.x, p1.y);
      ctx.stroke();
    });

    if (this.#preview.el)
      this.#preview.el.style.backgroundImage = `url(${canvas.toDataURL()})`;
    return canvas.toDataURL();
  }

  #renderArrow() {
    const sourcePoint = this.fromPdfPoint(this.#stampData.sourcePoint);
    const targetPoint = this.fromPdfPoint(this.#stampData.targetPoint);
    let lineWidth = this.#stampData.lineWidth * this.#viewport.scale;
    const annotationWidth = Math.abs(sourcePoint.x - targetPoint.x);
    const annotationHeight = Math.abs(sourcePoint.y - targetPoint.y);
    const arrowLength = Math.sqrt(
      Math.pow(annotationWidth, 2) + Math.pow(annotationHeight, 2)
    );
    const left2right = sourcePoint.x <= targetPoint.x;
    const top2bottom = sourcePoint.y <= targetPoint.y;

    // prepare arrow head
    const capLength = arrowLength / 4;
    let lineAngle = Math.atan2(
      sourcePoint.y - targetPoint.y,
      sourcePoint.x - targetPoint.x
    );
    let deltaAngle = Math.PI / 6;

    let sourceX = left2right ? 0 : annotationWidth;
    let sourceY = top2bottom ? 0 : annotationHeight;
    let targetX = left2right ? annotationWidth : 0;
    let targetY = top2bottom ? annotationHeight : 0;
    let arrowLeftCapX = targetX + capLength * Math.cos(lineAngle + deltaAngle);
    let arrowLeftCapY = targetY + capLength * Math.sin(lineAngle + deltaAngle);
    let arrowRightCapX = targetX + capLength * Math.cos(lineAngle - deltaAngle);
    let arrowRightCapY = targetY + capLength * Math.sin(lineAngle - deltaAngle);

    let arrowWidth = Math.max(sourceX, targetX, arrowLeftCapX, arrowRightCapX);
    let arrowHeight = Math.max(sourceY, targetY, arrowLeftCapY, arrowRightCapY);

    let corrX = Math.abs(
      Math.min(0, sourceX, targetX, arrowLeftCapX, arrowRightCapX)
    );
    let corrY = Math.abs(
      Math.min(0, sourceY, targetY, arrowLeftCapY, arrowRightCapY)
    );

    sourceX += corrX + lineWidth / 2;
    targetX += corrX + lineWidth / 2;
    arrowLeftCapX += corrX + lineWidth / 2;
    arrowRightCapX += corrX + lineWidth / 2;

    sourceY += corrY + lineWidth / 2;
    targetY += corrY + lineWidth / 2;
    arrowLeftCapY += corrY + lineWidth / 2;
    arrowRightCapY += corrY + lineWidth / 2;

    arrowWidth += corrX + lineWidth;
    arrowHeight += corrY + lineWidth;

    // retina support
    arrowWidth *= window.devicePixelRatio;
    arrowHeight *= window.devicePixelRatio;
    lineWidth *= window.devicePixelRatio;
    sourceX *= window.devicePixelRatio;
    sourceY *= window.devicePixelRatio;
    targetX *= window.devicePixelRatio;
    targetY *= window.devicePixelRatio;
    arrowLeftCapX *= window.devicePixelRatio;
    arrowRightCapX *= window.devicePixelRatio;
    arrowLeftCapY *= window.devicePixelRatio;
    arrowRightCapY *= window.devicePixelRatio;
    // end

    const canvas = document.createElement("canvas");
    canvas.width = arrowWidth;
    canvas.height = arrowHeight;

    const ctx = canvas.getContext("2d");
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    ctx.strokeStyle = this.hexColor2RGBA(this.#stampData.color);
    ctx.lineWidth = lineWidth;

    ctx.beginPath();
    ctx.moveTo(sourceX, sourceY);
    ctx.lineTo(targetX, targetY);
    ctx.lineTo(arrowLeftCapX, arrowLeftCapY);
    ctx.lineTo(targetX, targetY);
    ctx.lineTo(arrowRightCapX, arrowRightCapY);

    ctx.stroke();
    ctx.closePath();
    if (this.#preview.el)
      this.#preview.el.style.backgroundImage = `url(${canvas.toDataURL()})`;
    return canvas.toDataURL();
  }

  #renderSquare() {
    const { color, lineWidth, rect: pdfRect } = this.#stampData;
    const scaledLineWidth = lineWidth * this.#viewport.scale;
    const rect = this.fromPdfRect(pdfRect);
    const left = Math.min(rect[0], rect[2] + rect[0]);
    const top = Math.min(rect[1], rect[3] + rect[1]);
    const relativeRect = [rect[0] - left, rect[1] - top, rect[2], rect[3]];

    const canvas = document.createElement("canvas");
    canvas.width =
      (relativeRect[2] + scaledLineWidth) * window.devicePixelRatio;
    canvas.height =
      (relativeRect[3] + scaledLineWidth) * window.devicePixelRatio;
    const ctx = canvas.getContext("2d");
    ctx.strokeStyle = this.hexColor2RGBA(color);
    ctx.lineWidth = scaledLineWidth * window.devicePixelRatio;

    ctx.strokeRect(
      (scaledLineWidth / 2) * window.devicePixelRatio,
      (scaledLineWidth / 2) * window.devicePixelRatio,
      relativeRect[2] * window.devicePixelRatio,
      relativeRect[3] * window.devicePixelRatio
    );

    if (this.#preview.el)
      this.#preview.el.style.backgroundImage = `url(${canvas.toDataURL()})`;
    return canvas.toDataURL();
  }

  #renderCircle() {
    const { color, lineWidth, rect: pdfRect } = this.#stampData;
    const scaledLineWidth = lineWidth * this.#viewport.scale;
    const rect = this.fromPdfRect(pdfRect);
    const left = Math.min(rect[0], rect[2] + rect[0]);
    const top = Math.min(rect[1], rect[3] + rect[1]);
    const relativeRect = [rect[0] - left, rect[1] - top, rect[2], rect[3]];

    const canvas = document.createElement("canvas");
    canvas.width =
      (relativeRect[2] + scaledLineWidth) * window.devicePixelRatio;
    canvas.height =
      (relativeRect[3] + scaledLineWidth) * window.devicePixelRatio;
    const ctx = canvas.getContext("2d");
    ctx.strokeStyle = this.hexColor2RGBA(color);
    ctx.lineWidth = scaledLineWidth * window.devicePixelRatio;

    const x =
      (relativeRect[2] / 2 + scaledLineWidth / 2) * window.devicePixelRatio;
    const y =
      (relativeRect[3] / 2 + scaledLineWidth / 2) * window.devicePixelRatio;
    const radiusX = x - (scaledLineWidth / 2) * window.devicePixelRatio;
    const radiusY = y - (scaledLineWidth / 2) * window.devicePixelRatio;
    ctx.ellipse(x, y, radiusX, radiusY, 0, 0, 180);
    ctx.stroke();

    if (this.#preview.el)
      this.#preview.el.style.backgroundImage = `url(${canvas.toDataURL()})`;
    return canvas.toDataURL();
  }

  #renderImage() {
    const { content, rect: pdfRect } = this.#stampData;
    const rect = this.fromPdfRect(pdfRect);
    const image = new Image();
    image.src = ` data:image/png;base64,${content}`;
    const canvas = document.createElement("canvas");
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight);

    if (this.#preview.el)
      this.#preview.el.style.backgroundImage = `url(${canvas.toDataURL()})`;
    return canvas.toDataURL();
  }

  #renderTextBox() {
    // console.log("renderTextBox::render", this.#stampData);
    const rect = this.fromPdfRect(this.#stampData.rect);
    const scaleFactor = Math.min(
      this.#preview.width / rect[2],
      this.#preview.height / rect[3]
    );
    const fontFamily = MAP_CONSTANTS[this.#stampData.fontFamily];
    const fontSize =
      this.#stampData.fontSize * this.#viewport.scale * scaleFactor;
    const fontWeight = MAP_CONSTANTS[this.#stampData.fontWeight];
    const textAlignment = MAP_CONSTANTS[this.#stampData.textAlignment];

    const textBox = document.createElement("div");
    textBox.style.left = "0px";
    textBox.style.top = "0px";
    textBox.style.width = `${rect[2] * scaleFactor}px`;
    textBox.style.height = `${rect[3] * scaleFactor}px`;
    // textBox.style.backgroundColor = "rgba(0,0,0,0.1)";
    textBox.style.whiteSpace = "pre-wrap";
    textBox.style.wordWrap = "break-word";
    textBox.style.color = this.#stampData.color;
    textBox.style.fontFamily = fontFamily;
    textBox.style.fontSize = `${fontSize}px`;
    textBox.style.fontWeight = fontWeight;
    textBox.style.textAlign = textAlignment;

    textBox.innerText = this.#stampData.text;
    if (this.#preview.el) this.#preview.el.appendChild(textBox);
    const options = {
      logging: false,
    };
    return html2canvas(textBox, options)
      .then((canvas) => canvas.toDataURL())
      .catch(console.error);
  }

  render(sourceViewport, target, stampData) {
    this.#viewport = {
      width: sourceViewport?.width || 0,
      height: sourceViewport?.height || 0,
      scale: sourceViewport?.scale || 1,
    };
    this.#preview = {
      width: target?.clientWidth || 0,
      height: target?.clientHeight || 0,
      el: target,
    };
    this.#stampData = stampData || {};

    // console.log("render", this.#stampData);
    switch (this.#stampData.__typename) {
      case STAMP_TYPES.INK:
      case ANNO_TYPES.INK:
        return this.#renderInk();
      case STAMP_TYPES.ARROW:
      case ANNO_TYPES.ARROW:
        return this.#renderArrow();
      case STAMP_TYPES.SQUARE:
      case ANNO_TYPES.SQUARE:
        return this.#renderSquare();
      case STAMP_TYPES.CIRCLE:
      case ANNO_TYPES.CIRCLE:
        return this.#renderCircle();
      case STAMP_TYPES.IMAGE:
      case ANNO_TYPES.IMAGE:
        return this.#renderImage();
      case STAMP_TYPES.TEXT_BOX:
      case ANNO_TYPES.TEXT_BOX:
        return this.#renderTextBox();
      default:
        break;
    }
  }
}

export default AnnotationPreviewRenderer;
