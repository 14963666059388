<template>
  <svg width="484" height="184" viewBox="0 0 484 184" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_17587_145911)">
      <rect width="145" height="108" transform="translate(265.146 0.595459) rotate(10)" fill="#F1F5F9"/>
      <rect x="271.636" y="9.86304" width="129" height="92" transform="rotate(10 271.636 9.86304)" fill="#E2E8F0"/>
      <rect x="246.393" y="106.955" width="145" height="48" transform="rotate(10 246.393 106.955)" fill="#F1F5F9"/>
    </g>
    <g clip-path="url(#clip1_17587_145911)">
      <rect width="145" height="108" transform="translate(76.0576 25.7744) rotate(-10)" fill="#F1F5F9"/>
      <rect x="85.3252" y="32.2637" width="129" height="92" transform="rotate(-10 85.3252 32.2637)" fill="#E2E8F0"/>
      <rect x="94.8115" y="132.134" width="145" height="48" transform="rotate(-10 94.8115 132.134)" fill="#F1F5F9"/>
    </g>
    <g clip-path="url(#clip2_17587_145911)">
      <rect width="145" height="108" transform="translate(169.5 28)" fill="white"/>
      <rect x="177.5" y="36" width="129" height="92" fill="#E2E8F0"/>
      <rect x="169.5" y="136" width="145" height="48" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_17587_145911">
        <rect x="265.146" y="0.595459" width="145" height="156" rx="8" transform="rotate(10 265.146 0.595459)" fill="white"/>
      </clipPath>
      <clipPath id="clip1_17587_145911">
        <rect x="76.0576" y="25.7744" width="145" height="156" rx="8" transform="rotate(-10 76.0576 25.7744)" fill="white"/>
      </clipPath>
      <clipPath id="clip2_17587_145911">
        <rect x="169.5" y="28" width="145" height="156" rx="8" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "empty-document-card-image",
};
</script>

<style scoped>

</style>
