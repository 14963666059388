<script>
import { computed, inject, onMounted, reactive, watch } from "vue";
import { BUTTON_ROLES, DEFAULT_PRESETS, TOOL_PRESETS_MAP } from "./constants.js";
import ColorButton from "./ColorButton.vue";
import FontBlock from "./FontBlock.vue";
import StrokeBlock from "./StrokeBlock.vue";

export default {
  components: { FontBlock, ColorButton, StrokeBlock },
  props: ["presets", "activePreset"],
  emits: ["close", "usePreset"],
  setup(props, { emit }) {

    const hasPresets = computed(() => {
      return Array.isArray(props.presets) && props.presets.length > 0;
    });
    const isFontPresets = computed(() => {
      return Array.isArray(props.presets) && props.presets.some(preset => "fontFamily" in preset);
    });
    const isStrokePresets = computed(() => {
      return Array.isArray(props.presets)
          && props.presets.some(preset => "lineWidth" in preset)
          && props.presets.some(preset => !("color" in preset));
    });

    function selectPreset(index) {
      emit("usePreset", index);
    }

    function updatePresetColor(color) {
      // console.log("color", color);
      const preset = props.presets[props.activePreset];
      preset.color = color;
      emit("usePreset", props.activePreset, preset);
    }

    function updatePresetLineWidth(lineWidth) {
      // console.log("lineWidth", lineWidth);
      const preset = props.presets[props.activePreset];
      preset.lineWidth = lineWidth;
      emit("usePreset", props.activePreset, preset);
    }

    function updatePresetFont(font) {
      console.log("font", font);
      const preset = props.presets[props.activePreset];
      for (let [key, value] of Object.entries(font)) {
        preset[key] = value;
      }
      emit("usePreset", props.activePreset, preset);
    }

    return {
      BUTTON_ROLES, hasPresets, isFontPresets, isStrokePresets,

      selectPreset, updatePresetColor, updatePresetLineWidth, updatePresetFont
    }
  }
}
</script>

<template>
  <div class="tool-details-container">
    <slot></slot>
    <div class="presets-container" v-if="hasPresets">
      <div class="preset-box" v-for="(preset, index) of presets">
        <FontBlock
            v-if="isFontPresets"
            :preset="preset"
            :color="preset.color"
            @updateColor="updatePresetColor"
            @updateTextProperties="updatePresetFont"
        />
        <StrokeBlock
            v-else-if="isStrokePresets"
            :preset="preset"
            @updateWidth="updatePresetLineWidth"
        />
        <ColorButton
            v-else
            :preset="preset"
            :color="preset.color"
            :lineWidth="preset.lineWidth"
            :isSelected="index === activePreset"
            @btnOnCLick="selectPreset(index)"
            @updateColor="updatePresetColor"
            @updateWidth="updatePresetLineWidth"
        />
      </div>
    </div>
    <div class="details-divider" v-if="hasPresets"></div>
    <folia-button :data-role="BUTTON_ROLES.CLOSE"
                  size="28"
                  icon="close"
                  tooltip="Close"
                  @click.stop="$emit('close')"
    ></folia-button>
  </div>
</template>

<style scoped lang="scss">
.tool-details-container {
  position: absolute;
  top: 50px;
  z-index: 4000;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 10px 10px;
  gap: 16px;
  //height: 56px;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 100px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);

  .details-divider {
    width: 2px;
    height: 16px;
    background: #e2e8f0;
  }

  .presets-container {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .preset-box {
    display: flex;
    align-items: center;
    justify-items: center;
    gap: 8px;
  }
}
</style>