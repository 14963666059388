<script>
import DialogButton from "./DialogButton.vue";
import { onMounted, ref } from "vue";

export default {
  name: "BaseModalDialog",
  components: { DialogButton },
  props: ["headerTitle", "payload"],
  emits: ["close"],
  setup(props, { emit }) {

    function close() {
      emit("close");
    }

    return {
      close
    }
  }
}
</script>

<template>
<div class="base-modal-overlay" @click.stop="close">
  <div class="base-modal" @click.stop>
    <header class="base-modal-header">
      <span class="base-modal-title">{{headerTitle}}</span>
    </header>
    <main class="base-modal-main">
      <slot name="main"></slot>
    </main>
    <footer class="base-modal-footer">
      <slot name="buttons">
        <DialogButton title="Close" @click="close" />
      </slot>
    </footer>
  </div>
</div>
</template>

<style scoped lang="scss">
.base-modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  .base-modal {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 400px;
    border-radius: 12px;
    border: 1px solid #E2E8F0;
    background: #ffffff;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);

    .base-modal-header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      padding: 16px;
      border-bottom: solid 1px #e5e5e5;

      .base-modal-title {
        font-family: Inter, sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: #334155;
      }
    }

    .base-modal-main {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      padding: 16px;
      min-height: 200px;
    }

    .base-modal-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      padding: 16px;
      border-radius: 0 0 12px 12px;
      gap: 8px;
      background: #F8FAFC;
    }
  }
}
</style>