<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1456 6.35588L13.6435 2.85369L15.112 1.38511C16.0786 0.418505 17.6476 0.418505 18.6142 1.38511C19.5808 2.35171 19.5808 3.92069 18.6142 4.8873L17.1456 6.35588ZM11.9928 4.50439L18.4134 10.9251C18.8699 11.3815 18.8699 12.1193 18.4134 12.5758L13.7439 17.2453C13.2874 17.7018 12.5496 17.7018 12.0932 17.2453C11.6367 16.7889 11.6367 16.0511 12.0932 15.5947L15.9374 11.7504L14.6696 10.4826L7.32317 17.829C7.17375 17.9785 6.98697 18.0847 6.7815 18.1361L2.11192 19.3035C2.01736 19.3268 1.9228 19.3385 1.82824 19.3385C1.52239 19.3385 1.2247 19.2182 1.00289 18.9964C0.71338 18.7058 0.59664 18.2855 0.695869 17.8874L1.86326 13.2178C1.91463 13.0135 2.02086 12.8256 2.17029 12.6762L10.3421 4.50439C10.7985 4.04794 11.5363 4.04794 11.9928 4.50439Z" fill="#64748B"/>
  </svg>
</template>

<script>
export default {
  name: "ink-icon",
};
</script>
