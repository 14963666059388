<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.7084 6.66659C12.7084 8.16236 11.4959 9.37492 10.0001 9.37492C8.50433 9.37492 7.29177 8.16236 7.29177 6.66659C7.29177 5.17081 8.50433 3.95825 10.0001 3.95825C11.4959 3.95825 12.7084 5.17081 12.7084 6.66659Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.70635 16.0416H14.2938C15.2454 16.0416 15.9785 15.2234 15.5341 14.3819C14.8804 13.1441 13.3901 11.6666 10.0001 11.6666C6.61011 11.6666 5.11982 13.1441 4.46613 14.3819C4.02174 15.2234 4.75475 16.0416 5.70635 16.0416Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  name: "personal-nav-item-icon",
};
</script>

<style scoped>

</style>
