<template>
  <div class="vertical-divider invisible"/>
  <folia-drop-down
      name="font-family"
      tooltip="Font family"
      width="103"
      :value="fontFamily"
      @input="updateFontFamily"
  >
    <option value="SANS_SERIF">Sans serif</option>
    <option value="SERIF">Serif</option>
    <option value="MONOSPACE">Monospace</option>
    <option value="SCRIPT">Script</option>
    <option value="FANTASY">Fantasy</option>
  </folia-drop-down>
  <div class="vertical-divider" />
  <folia-drop-down
      name="font-size"
      tooltip="Font size"
      :value="fontSize"
      @input="updateFontSize"
  >
    <option value="8">8</option>
    <option value="10">10</option>
    <option value="12">12</option>
    <option value="14">14</option>
    <option value="16">16</option>
    <option value="18">18</option>
    <option value="20">20</option>
    <option value="24">24</option>
    <option value="36">36</option>
    <option value="48">48</option>
    <option value="64">64</option>
    <option value="72">72</option>
    <option value="96">96</option>
    <option value="144">144</option>
  </folia-drop-down>

  <!--  <select style="border: none; outline: none;" @change="updateFontSize">-->
<!--    <option-->
<!--      v-for="size of fontSizes"-->
<!--      :selected="size === fontSize"-->
<!--      :value="size">{{size}} px&nbsp;&nbsp;</option>-->
<!--  </select>-->
  <div class="vertical-divider" />
  <ColorButton :color="color" isSelected @updateColor="updateColor" />
  <div class="vertical-divider" />
  <folia-button
      :name="CUSTOM_TOOLS.FONT_WEIGHT.name"
      :icon="CUSTOM_TOOLS.FONT_WEIGHT.icon"
      :tooltip="CUSTOM_TOOLS.FONT_WEIGHT.title"
      :shortcut="CUSTOM_TOOLS.FONT_WEIGHT.shortcut"
      :disabled="CUSTOM_TOOLS.FONT_WEIGHT.disabled"
      :selected="fontWeight === FONT_WEIGHT.W600"
      @click="toggleWeight()"
  ></folia-button>
  <div class="vertical-divider" />
  <folia-button
      v-for="item of textAlignmentsButtons"
      :name="item.button.name"
      :icon="item.button.icon"
      :tooltip="item.button.title"
      :shortcut="item.button.shortcut"
      :disabled="item.button.disabled"
      :selected="item.type === textAlignment"
      @click="updateAlignment(item.type)"
  ></folia-button>

<!--  <ToolButton-->
<!--    v-for="item of textAlignmentsButtons"-->
<!--    :tool="item.button"-->
<!--    :selected-tool="item.type === textAlignment ? item.button : null"-->
<!--    @btnOnCLick="updateAlignment(item.type)"-->
<!--  />-->
</template>

<script>
import Color from "color";
import ColorButton from "./ColorButton.vue";
import ToolButton from "../../common/ToolButton.vue";
import {CUSTOM_TOOLS} from "../../common/constants.js";
import {onMounted, ref, watch} from "vue";
import {FONT_FAMILY, FONT_WEIGHT, TEXT_ALIGNMENT} from "../../../core/constants.js";

export default {
  name: "FontBlock",
  components: {ToolButton, ColorButton},
  props: {
    textProps: Object,
    color: String,
  },
  emits: ["updateTextProperties", "updateColor"],
  setup(props, {emit}) {
    const textAlignmentsButtons = [
      { type: TEXT_ALIGNMENT.START, button: CUSTOM_TOOLS.TEXT_ALIGNMENT_LEFT },
      { type: TEXT_ALIGNMENT.CENTER, button: CUSTOM_TOOLS.TEXT_ALIGNMENT_CENTER },
      { type: TEXT_ALIGNMENT.END, button: CUSTOM_TOOLS.TEXT_ALIGNMENT_RIGHT },
    ];

    const textAlignment = ref(null);
    const fontSize = ref(null);
    const color = ref(null);
    const fontWeight = ref(null);
    const fontFamily = ref(null);

    onMounted(() => {
      color.value = Color(props.color).hex();
      fontFamily.value = props.textProps.fontFamily;
      fontSize.value = props.textProps.fontSize;
      fontWeight.value = props.textProps.fontWeight;
      textAlignment.value = props.textProps.textAlignment;
    });

    watch(() => props.color, (_color) => { color.value = Color(_color).hex(); });
    watch(() => props.textProps.fontFamily, (_fontFamily) => { fontFamily.value = _fontFamily; });
    watch(() => props.textProps.fontSize, (_fontSize) => { fontSize.value = _fontSize; });
    watch(() => props.textProps.fontWeight, (_fontWeight) => { fontWeight.value = _fontWeight; });
    watch(() => props.textProps.textAlignment, (_textAlignment) => { textAlignment.value = _textAlignment; });

    function updateColor(value) {
      color.value = value;
      emit("updateColor", Color(value).alpha(1).hexa());
    }
    function updateAlignment(value) {
      textAlignment.value = value;
      emit("updateTextProperties", { textAlignment: value });
    }
    function toggleWeight() {
      fontWeight.value = fontWeight.value === FONT_WEIGHT.W400 ? FONT_WEIGHT.W600 : FONT_WEIGHT.W400;
      emit("updateTextProperties", { fontWeight: fontWeight.value });
    }
    function updateFontFamily(e) {
      fontFamily.value = e.target.value;
      emit("updateTextProperties", { fontFamily: e.target.value });
    }
    function updateFontSize(e) {
      fontSize.value = parseInt(e.target.value, 10);
      emit("updateTextProperties", { fontSize: parseInt(e.target.value, 10) });
    }

    return {
      CUSTOM_TOOLS, FONT_WEIGHT,
      textAlignmentsButtons, textAlignment, color, fontWeight, fontFamily, fontSize,
      updateColor, updateAlignment, toggleWeight, updateFontFamily, updateFontSize,
    }
  }
}
</script>

<style scoped lang="scss">
.vertical-divider {
  width: 0;
  height: 16px;
  border: 1px solid #e2e8f0;

  &.invisible {
    border: solid 1px transparent;
  }
}

</style>