<script>
import BaseModalDialog from "../common/BaseModalDialog.vue";
import DialogButton from "../common/DialogButton.vue";
import DarkDialogButton from "../common/DarkDialogButton.vue";
import SingleLineEditor from "../common/SingleLineEditor.vue";
import { onMounted, ref } from "vue";

export default {
  name: "RenameStampDialog",
  components: { SingleLineEditor, DialogButton, DarkDialogButton, BaseModalDialog },
  props: ["name","isRenamingStamp"],
  emits: ["close"],
  setup(props, { emit }) {
    const stampName = ref("name");
    const isRenamingStamp=ref(false)
    onMounted(() => {
      stampName.value = props.name;
      isRenamingStamp.value= props.isRenamingStamp;
    });

    function submit() {
      if (!stampName.value) return;
      emit('close', stampName.value);
    }

    function cancel() {
      emit('close');
    }

    return {
      stampName, submit, cancel
    }
  }
}
</script>

<template>
  <BaseModalDialog headerTitle="Rename Stamp" @close="cancel">
    <template v-slot:main>
      <SingleLineEditor label="Stamp name"
                        :focus="true"
                        :placeholder-text="'Stamp name'"
                        @on-enter="submit"
                        @on-escape="cancel"
                        v-model="stampName"
      />
    </template>
    <template v-slot:buttons>
      <DialogButton title="Cancel" @click="cancel"></DialogButton>
      <DarkDialogButton title="Save" @click="submit" :disabled="!stampName||isRenamingStamp"></DarkDialogButton>
    </template>
  </BaseModalDialog>
</template>

<style scoped lang="scss">

</style>