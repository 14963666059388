<template>
  <svg
    width="17"
    height="14"
    viewBox="0 0 17 14"
    fill="none"
    stroke="#64748B"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.458 10.5413H25.5413M10.1285 4.58153C11.7478 5.15937 12.9409 6.66826 13.0292 8.49224C13.146 10.9057 11.2842 12.9568 8.87078 13.0736C7.79113 13.1258 6.78398 12.7821 5.99007 12.1702M5.33301 0.958008L0.958008 8.87467H9.70801L5.33301 0.958008Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "circle-icon",
};
</script>
