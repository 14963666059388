<script>
import { inject, onMounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import AuthNameModal from "./AuthNameModal.vue";
import { BROADCAST_MESSAGE, CLOUD_STORAGE, ERROR_TOAST, INFO_TOAST } from "../../../core/constants.js";
import { v4 as uuid } from "uuid";
import GoogleOAuth2Client from "./GoogleOAuth2Client.js";
import MSOAuth2Client from "./MSOAuth2Client.js";
import BoxOAuth2Client from "./BoxOAuth2Client.js";
import DropboxOAuth2Client from "./DropboxOAuth2Client.js";

export default {
  name: "OAuth2StorageProviderCallback",
  components: { AuthNameModal },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const $fetch = inject("$fetch");
    const state = reactive({
      transitedState: {
        provider: null,
        path: "/",
        existsStorage: null
      },
      access_token: null,
      refresh_token: null,
    });

    onMounted(() => {
      try {
        state.transitedState = JSON.parse(window.atob(route.query.state));
      } catch (e) { console.error(e); }

      let oauth2client;
      switch (state.transitedState.provider) {
        case CLOUD_STORAGE.GOOGLE_DRIVE:
          oauth2client = new GoogleOAuth2Client();
          break;
        case CLOUD_STORAGE.ONE_DRIVE:
          oauth2client = new MSOAuth2Client();
          break;
        case CLOUD_STORAGE.BOX_DRIVE:
          oauth2client = new BoxOAuth2Client();
          break;
        case CLOUD_STORAGE.DROPBOX_DRIVE:
          oauth2client = new DropboxOAuth2Client();
          break;
        default: break;
      }
      document.querySelectorAll("#splash-screen").forEach(el => el.remove());
      if (!oauth2client) {
        return $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
          type: ERROR_TOAST,
          title: "Storage can not be added",
          message: "Unknown storage found",
        });
      }

      oauth2client.callbackHandler(route.query)
        .then(auth => {
          state.access_token = auth.access_token;
          state.refresh_token = auth.refresh_token;
        })
        .catch(error => {
          console.error(error.message);
          $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
            type: ERROR_TOAST,
            title: "Storage can not be added",
            message: error.message,
          });
        });
    });

    function authorizeStorage(data) {
      return $fetch.authorizeCloudStorage(data)
        .then(() => {
        });
    }

    function saveStorageName(name) {
      const payload = {
        id: state.transitedState.existsStorage?.id || uuid(),
        name,
        provider: state.transitedState.provider,
        access_token: state.access_token,
        refresh_token: state.refresh_token,
      }

      console.log("authorizeStorage", { existsStorage: state.transitedState.existsStorage, payload });
      authorizeStorage(payload).then(() => {
        $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
            cloudStoragetype: INFO_TOAST,
          title: state.transitedState.existsStorage
            ? "Storage token has been updated"
            : "Storage has been authorized"
        });
        localStorage.setItem("cloudStorage2Open", payload.provider)
        router.replace(state.transitedState.path || "/");
      }).catch(error => {
        console.error(error.message);
        $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
          type: ERROR_TOAST,
          title: "Storage can not be added",
          message: error.message,
        });
      });
    }

    function skipStorageName() {
      router.replace(state.transitedState.path || "/");
    }

    return {
      state, saveStorageName, skipStorageName
    }
  }
};
</script>

<template>
  <AuthNameModal
    :provider="state.transitedState.provider"
    :initialName="state.transitedState.existsStorage?.name"
    @dismissChanges="skipStorageName"
    @saveStorageName="saveStorageName"
  />
</template>

<style scoped lang="scss">
</style>

