<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.95825 12.2918V13.5418C3.95825 14.9225 5.07754 16.0418 6.45825 16.0418H13.5416C14.9223 16.0418 16.0416 14.9225 16.0416 13.5418V12.2918M9.99992 11.8752V3.9585M9.99992 11.8752L7.29159 8.9585M9.99992 11.8752L12.7083 8.9585" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "download-icon",
};
</script>

<style scoped>

</style>
