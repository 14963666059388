<template>
  <div class="upload-monitor-container" :class="{minimized: minimized}">
    <div class="upload-monitor-header">
      <span>{{title}}</span>
      <span class="upload-monitor-header-buttons">
        <ChevronDown v-if="minimized" class="header-button" @click="minimized = false"/>
        <ChevronUp v-else class="header-button" @click="minimized = true"/>
        <CloseIcon class="header-button"  @click="$emit('hide')"/>
      </span>
    </div>
<!--    <div class="cancel-line" v-if="!minimized && tooLong">-->
<!--      <span>This is taking too long</span>-->
<!--      <button @click="cancelRest">Cancel</button>-->
<!--    </div>-->
    <div class="upload-monitor-body" v-if="!minimized">
      <div class="upload-monitor-no-items" v-if="items.length === 0">Nothing to upload</div>
      <div class="upload-monitor-item" v-else v-for="item of items">
        <div class="item-name-box">
          <button class="item-name"
                  :class="{[item.status]: true}"
                  @click="navigateTo(item)"
          >{{item.name}}</button>
          <div class="item-message">{{item.message}}</div>
        </div>
        <span class="item-status"
              :class="{[item.status]: true}"
              :title="item.message"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { getters, mutations } from "../../store/index.js";
import { UploadFiles } from "../../plugins/upload-files.js";
import CloseIcon from "../../assets/vue-icons/tools/close.vue";
import ArrowBottomBtn from "../../assets/vue-icons/arrow-bottom-btn.vue";
import ChevronDown from "../../assets/vue-icons/chevron-down.vue";
import ChevronUp from "../../assets/vue-icons/chevron-up.vue";
import { useRouter } from "vue-router";

export default {
  name: "UploadMonitor",
  components: { ChevronUp, ChevronDown, ArrowBottomBtn, CloseIcon },
  emits: ["hide"],
  setup() {
    const router = useRouter();
    const minimized = ref(false);

    const items = computed(() => {
      const items = getters.uploadedFiles || [];
      return items.map(item => {
        if (`${item.message}`.includes("EXCEEDS_SIZE")) {
          return  {...item, message: "The file exceeds the maximum file size limit of 100 MB. Please reduce file size and try again."}
        } else if (`${item.message}`.includes("UNSUPPORTED_FILE_TYPE")) {
          return  {...item, message: "File format is not supported. File cannot be uploaded."}
        } else {
          return  {...item, message: item.status === UploadFiles.ERROR ? "File cannot be uploaded. Please try again." : ""}
        }
      })
    })

    const tooLong = computed(() => {
      return items.value.some(item => {
        const startedAt = new Date(item.createdAt).getTime();
        const now = new Date().getTime();
        return (item.status === UploadFiles.JUST_ADDED || item.status === UploadFiles.PENDING)
          && now - startedAt > 6000;
      });
    })

    const title = computed(() => {
      const countFilesTotal = getters.uploadedFiles.filter(i => i.status === UploadFiles.DONE).length;
      const filesInProgress = getters.uploadedFiles.filter(doc => {
        return doc.status === UploadFiles.JUST_ADDED || doc.status === UploadFiles.PENDING
      })
      if (filesInProgress.length > 0) {
        return `Uploading ${filesInProgress.length} item${filesInProgress.length > 1 ? "s" : ""}`
      } else {
        return  `${countFilesTotal} upload${countFilesTotal === 1 ? "" : "s"} complete`
      }
    })

    function cancelRest() {
      mutations.cancelWaitingFiles();
    }

    function navigateTo(item) {
      if (!item || item.status !== UploadFiles.DONE) return;
      const {projectId, id} = item;
      minimized.value = true;
      router.push(`/p/${projectId}/d/${id}`)
    }
    return {
      minimized, tooLong, items, title,

      cancelRest, navigateTo
    }
  }
};
</script>

<style scoped lang="scss">
.upload-monitor-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  right: 20px;
  bottom: 30px;
  max-width: 590px;
  min-width: 350px;
  background: #FFFFFF;
  border: 1px solid rgba(51, 65, 85, 0.15);
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.15);
  border-radius: 16px;

  &.minimized {
    border-radius: 26px;
  }

  .upload-monitor-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px 16px;

    font-weight: 600;
    font-size: 18px;
    line-height: 28px;

    .upload-monitor-header-buttons {
      display: flex;
      align-items: center;
      gap: 10px;

      .header-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px;

        width: 24px;
        height: 24px;
        background: #FFFFFF;
        cursor: pointer;
      }
    }
  }

  .cancel-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 20px;
    font-size: 14px;
    font-weight: normal;
    width: 100%;
    background-color: #f5f5f5;

    span {
      color: grey;
    }

    button {
      border: none;
      outline: none;
      color: #0a84ff;
      background-color: transparent;
      cursor: pointer;
    }
  }

  .upload-monitor-body {
    border-top: solid 1px silver;
    width: 100%;
    max-height: 400px;
    overflow: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 5px 0;

    .upload-monitor-item {
      width: 100%;
      display: flex;
      padding: 0 15px;

      .item-name-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 5px;
        margin-right: 10px;

        .item-name {
          white-space: nowrap;
          font-weight: 400;
          font-size: 14px;
          line-height: 12px;
          border: none;
          background: transparent;

          &.done:hover {
            color: dodgerblue;
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .item-message {
          background-color: transparent;
          font-size: 11px;
          padding-left: 5px;
          color: #EF4444;
        }
      }
      .item-status {
        min-width: 30px;
        min-height: 30px;
        max-width: 30px;
        max-height: 30px;

        &.pending, &.just_added {
          background: url("../../assets/images/loading-icon.gif") center no-repeat;
          background-size: 20px;
        }
        &.done {
          background: url("../../assets/images/upload-done.svg") center no-repeat;
          background-size: 24px;
        }
        &.error {
          background: url("../../assets/images/upload-error.svg") center no-repeat;
          background-size: 24px;
          cursor: help;
        }
      }
    }

    .upload-monitor-no-items {
      width: 100%;
      min-height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>