<template>
  <div class="active-project-overlay" v-if="popupProjectShown && projects.arr.length > 1" @click="popupProjectShown = false"/>
  <div class="active-project-name" :class="{'opened-active-project':popupProjectShown && projects.arr.length > 1,'alone-active-project': projects.arr.length <= 1}" v-if="projectName" >
    <span @click="popupProjectShown = !popupProjectShown">{{ projectName }}</span>
    <div
      v-if="projects.arr.length > 1"
      class="arrow-bottom-btn"
    >
      <arrowBottomBtn
        @click="popupProjectShown = !popupProjectShown"
      />
    </div>
    <div v-if="popupProjectShown && projects.arr.length > 1" class="popup-container">
      <PopupProjectProject
        @close="popupProjectShown = !popupProjectShown"
      />
    </div>
  </div>
</template>

<script>
import PopupProjectProject from "./SideBar/PopupProjectProject/PopupProjectProject.vue";
import ArrowBottomBtn from "../../assets/vue-icons/arrow-bottom-btn.vue";
import { computed, inject, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "ActiveProjectContent",
  components: {
    PopupProjectProject,
    ArrowBottomBtn,
  },
  setup() {
    const popupProjectShown = ref(false);
    const $fetch = inject('$fetch');
    const route = useRoute();
    const project = ref();
    const projects = ref({ arr: [] });
    onMounted(() => {
      fetchProjectName();
      fetchProjects();
    })
    const projectName = computed(() => {
      return project.value?.name || route.params.projectId;
    });
    async function fetchProjectName() {
      try {
        if (!route.params.projectId) return;
        project.value = await $fetch.getProject(route.params.projectId);
      } catch (e) {
        console.error(e);
      }
    }
    async function fetchProjects() {
      const data = await $fetch.getProjects();
      projects.value = {
        arr: data,
      };
    }
    return {
      popupProjectShown,
      projectName,
      projects,
    }
  }
};
</script>

<style scoped lang="scss">
.active-project-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 300px;
  width: 100%;
  height: 100%;
  background-color:transparent;
  z-index: 1;
}
.active-project-name {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #e2e8f0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #334155;

  &:hover {
    background-color: #e3e2e2;
    cursor: pointer;
  }

  .popup-container {
    position: fixed;
    z-index: 150;
    left: 240px;
    top: 45px;
    width: 280px;
    height: 427px;
    background-color: white;
    box-shadow: 0 0 10px 2px #e6e6e6;
    border: solid 1px silver;
    border-radius: 5px;
  }
  span {
    color: #334155;
    width: 190px;
    max-width: 190px;
    overflow: hidden;
    cursor: pointer;
    text-overflow: ellipsis;
  }
  .arrow-bottom-btn {
    width: 20px;
    display: flex;
    align-items: center;
    padding-left: 2px;
    cursor: pointer;
    height: 20px;
  }
  .arrow-bottom-btn:hover {
    background-color: #e3e2e2;
    cursor: pointer;
    border-radius: 24px;
  }
}
.opened-active-project {
  background-color: #e3e2e2 !important;
}
.alone-active-project {
  &:hover {
    cursor: default;
    background-color:white;
  }
}
</style>
