const { VITE_DROPBOX_AUTH_CLIENT_ID } = import.meta.env;

class DropboxOAuth2Client {
  static titleName = "Dropbox";
  #clientId = VITE_DROPBOX_AUTH_CLIENT_ID;
  #redirectUri = location.origin + "/dropbox_oauth2_callback";

  constructor() {}

  async getAuthWithRedirect(transitedState) {
    const authUrl = new URL(`https://www.dropbox.com/oauth2/authorize`);
    authUrl.searchParams.set("client_id", this.#clientId);
    authUrl.searchParams.set("redirect_uri", this.#redirectUri);
    authUrl.searchParams.set("response_type", "code");
    authUrl.searchParams.set("token_access_type", "offline");
    // prettier-ignore
    authUrl.searchParams.set("state", window.btoa(JSON.stringify(transitedState)));

    window.open(authUrl.toString(), "_self");
  }

  async callbackHandler(query = {}) {
    const { code, error, error_description } = query;
    if (error) {
      throw new Error(`${error}:${error_description}`);
    }
    return { access_token: code, refresh_token: "" };
  }
}

export default DropboxOAuth2Client;
