<script>
export default {
  name: "DialogMainText",
  setup() {
    return {}
  }
}
</script>

<template>
<span class="base-modal-main-text">
  <slot>empty main section</slot>
</span>
</template>

<style scoped lang="scss">
.base-modal-main-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #334155;
}
</style>