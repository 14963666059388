<template>
  <div class="public-link-access-banner" v-if="onProjectPage && isProjectPublic">
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 7.83203V9.4987" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.41634 4.4987C7.41634 4.72882 7.22979 4.91536 6.99967 4.91536C6.76956 4.91536 6.58301 4.72882 6.58301 4.4987C6.58301 4.26858 6.76956 4.08203 6.99967 4.08203C7.22979 4.08203 7.41634 4.26858 7.41634 4.4987Z" stroke="white"/>
      <path d="M13.0413 6.9987C13.0413 10.3354 10.3364 13.0404 6.99967 13.0404C3.66295 13.0404 0.958008 10.3354 0.958008 6.9987C0.958008 3.66198 3.66295 0.957031 6.99967 0.957031C10.3364 0.957031 13.0413 3.66198 13.0413 6.9987Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <span class="public-link-access-banner-text" v-if="isUserSigned">
      You are viewing a public project. Need to edit?
    </span>
    <span class="public-link-access-banner-text" v-else>
      Create a Folia Account to start annotating documents today.
    </span>

    <button class="public-link-access-banner-button"
            v-if="isUserSigned"
            @click="requestAccess"
    >Request Access</button>
    <button class="public-link-access-banner-button"
            v-else
            @click="signUp"
    >Sign Up</button>
  </div>
</template>

<script>
import { inject, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { BROADCAST_MESSAGE, ERROR_TOAST, PUBLIC_VIEWER, SUCCESS_TOAST } from "../core/constants.js";

export default {
  name: "PublicLinkAccessBanner",
  setup() {
    const $fetch = inject("$fetch");
    const $auth0 = inject("$auth0");
    const route = useRoute();
    const isUserSigned = ref(false);
    const isProjectPublic = ref(false);
    const onProjectPage = ref(false);

    onMounted(() => {
      $fetch.on(BROADCAST_MESSAGE.PROJECT_UPDATED, checkUser);
      checkUser();
    });

    onBeforeUnmount(() => {
      $fetch.off(BROADCAST_MESSAGE.PROJECT_UPDATED, checkUser);
    });

    watch(() => route.params.projectId, () => {
      checkUser();
    })

    async function checkUser() {
      const { params: { projectId } } = route;
      if (!projectId) {
        isProjectPublic.value = false;
        return;
      };
      onProjectPage.value = !!projectId;
      const user = await $auth0.getUser();
      isUserSigned.value = user.isAuthenticated;
      const project = await $fetch.getProject(projectId);
      isProjectPublic.value = project?.publicStatus === "PUBLIC" && project?.userRole === PUBLIC_VIEWER;
    }

    function requestAccess() {
      const { params: { projectId } } = route;
      $fetch.requestProjectAccessRequest(projectId)
        .then((success) => {
          $fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
            type: success ? SUCCESS_TOAST : ERROR_TOAST,
            title: success ? "Request has been sent" : "Error sending the request",
            message: success ? undefined : "Folia encountered an error when attempting to send the request. Please try again."
          })
        })
        .catch(console.error);
    }

    function signUp() {
      $auth0.signIn("signup");
    }

    return {
      onProjectPage, isUserSigned, isProjectPublic,
      requestAccess, signUp
    }
  }
};
</script>

<style scoped lang="scss">
.public-link-access-banner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 24px;
  height: 36px;
  background: #2990FF;
  border-radius: 2px;

  .public-link-access-banner-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    user-select: none;
  }

  .public-link-access-banner-button {
    padding: 0 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    border: solid 1px transparent;
    background: none;

    &:active {
      transform: translate(1px, 1px);
    }

    &:hover {
      border: solid 1px silver;
      border-radius: 15px;
    }
  }
}
</style>
