<script>
import BaseModalDialog from "../common/BaseModalDialog.vue";
import DialogButton from "../common/DialogButton.vue";
import DarkDialogButton from "../common/DarkDialogButton.vue";
import SingleLineEditor from "../common/SingleLineEditor.vue";
import { onMounted, ref } from "vue";
import DialogMainText from "../common/DialogMainText.vue";
import AnnotationService from "../../../../service/annotationService";
import Loader from '../../../common/loaders/defaultLoader.vue';
import { SET_CACHE } from "../../../../store/cacheLayer";
export default {
  name: "DeleteStampDialog",
  components: { DialogMainText, SingleLineEditor, DialogButton, DarkDialogButton, BaseModalDialog,Loader },
  props: {"data":Object},
  emits: ["close"],
  setup(props, { emit }) {
    const name = ref(props.data?.name??'')
    const annotationService = new AnnotationService();
    const isLoading = ref(false)
    function close(confirm = false) {
      emit('close', confirm);
    }

    async function deleteStamp(){
      isLoading.value = true
      const deleted = await annotationService.deleteStamp(props.data.id);
      SET_CACHE.deleteStampFromCache(props.data.id)
      isLoading.value = false
      emit('close',true)
    }
    return { close, deleteStamp ,name, isLoading}
  }
}
</script>

<template>
  <BaseModalDialog headerTitle="Delete Stamp" @close="close()">
    <template v-slot:main>
      <DialogMainText>Are you sure you want to delete <b>{{name}}</b>?</DialogMainText>
      <Loader v-if="isLoading"> </Loader>
    </template>
    <template v-slot:buttons>
      <DialogButton title="Close" @click="close()"></DialogButton>
      <DarkDialogButton title="Delete stamp" @click="deleteStamp">
      </DarkDialogButton>
    </template>
  </BaseModalDialog>
</template>

<style scoped lang="scss">
</style>