<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.83268 12.8333H12.166C12.5342 12.8333 12.8327 12.5349 12.8327 12.1667V3.83317C12.8327 3.46498 12.5342 3.1665 12.166 3.1665H3.83268C3.46449 3.1665 3.16602 3.46498 3.16602 3.83317V12.1667C3.16602 12.5349 3.46449 12.8333 3.83268 12.8333Z" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.16602 3.3335V12.6668" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.83398 3.3335V12.6668" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "table-colums-icon",
};
</script>

<style scoped>

</style>
