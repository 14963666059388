<template>
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" stroke="#64748B" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.75 0.75H10.25M0.75 13.25H10.25M0.75 7H15.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "text-alignment-left",
};
</script>
