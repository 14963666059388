<template>
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.75 6.75V12.25M9.25 6.75V12.25M4.75 3.75V2.75C4.75 1.64543 5.64543 0.75 6.75 0.75H8.25C9.35457 0.75 10.25 1.64543 10.25 2.75V3.75M0.75 3.75H14.25M2.59115 13.4233L1.75 3.75H13.25L12.4088 13.4233C12.319 14.4568 11.4538 15.25 10.4164 15.25H4.58363C3.54622 15.25 2.68102 14.4568 2.59115 13.4233Z" stroke="#F43F5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "table-view-icon"
}
</script>

<style scoped>

</style>
