<template>
  <div class="roles-selector" ref="rolesSelector">
    <div v-if="selectorOpened" class="roles-selector-overlay" @click.stop="closeSelector()"></div>
    <div class="roles-value"
         :class="{
            'roles-value-left':textAlign === 'left',
            'roles-value-right':textAlign === 'right',
            'red-title': isTitleRed
         }"
         @click.stop="openSelector"
    >
      {{selectedRoleTitle}}
      <svg v-if="!selectorOpened" style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
      </svg>
      <svg v-if="selectorOpened" style="width:24px;height:24px" viewBox="0 0 24 24">
        <path fill="currentColor" d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
      </svg>
    </div>
    <div v-if="selectorOpened" class="roles-list" ref="rolesList">
      <div
        class="roles-item"
        :class="{
          'roles-item-left':textAlign === 'left',
          'roles-item-right':textAlign === 'right',
          'selected': id === value,
          'red-text': id === REVOKED || id === REJECTED
        }"
        v-for="{id, title} of roleList"
        @click.stop="closeSelector(id)"
      >{{title}}</div>
    </div>
  </div>
</template>

<script>
import { computed, nextTick, ref, watch } from "vue";
import {
  CONTRIBUTOR, EDITOR, VIEWER, REVOKED, REJECTED
} from "../../../core/constants.js";
export default {
  name: "PermissionSelector",
  props: ['value', 'withReject', 'textAlign','isOwner','isRejectShown'],
  setup(props,{emit}){
    const selectorOpened = ref(false);
    const rolesSelector = ref(null);
    const rolesList = ref(null);
    const roleList = computed(()=> {
      const roles = [
        { id: VIEWER, title: 'Viewer' },
        { id: CONTRIBUTOR, title: 'Contributor' },
        { id: EDITOR, title: 'Editor' },
      ]
      if (props.withReject && (props.isOwner || !props.isRejectShown)){
        roles.push({ id: REVOKED, title: "Remove" })
      } else if (props.isRejectShown)   {
        roles.push({id:REJECTED,title: "Reject"})
      }
      return roles
    })
    watch(selectorOpened,(val)=>{
      if (val) {
        nextTick(()=> {
          if (!rolesSelector.value || !rolesList.value)  return console.warn('No selector or list elements')

          const selectorRect = rolesSelector.value.getBoundingClientRect()
          rolesList.value.style.left = `${selectorRect?.left}px`
          rolesList.value.style.top = `${selectorRect?.top + selectorRect?.height + 5}px`
        })
      }
    })
    const selectedRoleTitle = computed(()=>{
      const role = roleList.value.find(r=> {
        return r.id === props.value
      })
      return role ? role.title : 'unknown role'
    })
    const isTitleRed = computed(()=> {
      const role = roleList.value.find(r=> r.id === props.value)
      return role && (role.id === REVOKED || role.id === REJECTED)
    })
    function closeSelector(selectedItem) {
      const role = roleList.value.find(r => r.id === selectedItem)
      if (role && props.value !== role.id) emit('input', role.id)
      selectorOpened.value = false
    }
    function openSelector() {
      selectorOpened.value = true
    }
    return {
      CONTRIBUTOR,
      EDITOR,
      VIEWER,
      REVOKED,
      REJECTED,
      selectorOpened,
      roleList,
      rolesSelector,
      rolesList,
      isTitleRed,
      selectedRoleTitle,
      openSelector,
      closeSelector,
    }
  },
}
</script>

<style scoped lang="scss">
.roles-selector {

  position: relative;
  display: flex;
  font-weight: 500;
  font-size: 14px;
  color: #484848;
  border-radius: 4px;
  border: 1px solid rgba(182, 182, 182, 0.5);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06);
  background: #FFFFFF;
  height: 39px;

  .roles-selector-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    //background-color: rgba(168, 89, 89, 0.19);
    z-index: 2;
  }

  .roles-value {
    min-width: 130px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 5px;
    cursor: pointer;
  }
  .roles-value-left {
    display: flex;
    justify-content: space-around;
  }
  .roles-value-right {
    justify-content: flex-end;
  }
  .red-title {
    color: red;
  }

  .roles-list {
    position: fixed;
    min-width: 130px;
    left: 0;
    top: 37px;
    padding: 8px 0;
    box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    background: #FFFFFF;
    z-index: 2;

    .roles-item {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #484848;
      padding: 7px 12px;
      background: #FFFFFF;
      cursor: pointer;

      &:hover {
        background-color: #F1F1F1;
      }

      &.selected {
        background-color: #E2E2E2 !important;
      }

      &.red-text {
        color: red;
      }
    }
    .roles-item-left {
      display: flex;
      justify-content: flex-start;
    }
    .roles-item-right {
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
