<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.95801 16.0417L7.49967 15.2083L15.7906 6.91738C16.1161 6.59194 16.1161 6.0643 15.7906 5.73887L14.2608 4.20904C13.9354 3.88361 13.4077 3.88361 13.0823 4.20904L4.79134 12.5L3.95801 16.0417Z" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.6865 5.86591L14.1865 8.36591" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "edit-icon",
};
</script>

<style scoped>

</style>
