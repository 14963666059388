<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.0003 7.70801H11.4587V4.16634M8.12533 12.708H11.8753M8.12533 10.208H11.8753M13.542 16.0413H6.45866C5.53818 16.0413 4.79199 15.2951 4.79199 14.3747V5.62467C4.79199 4.7042 5.53818 3.95801 6.45866 3.95801H11.667L15.2087 7.49967V14.3747C15.2087 15.2951 14.4625 16.0413 13.542 16.0413Z" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  name: "details-icon",
};
</script>

<style scoped>

</style>
