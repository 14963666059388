<script>
import { computed, onMounted, ref, watch } from "vue";
import AnnotationPreviewRenderer from "./AnnotationPreviewRenderer.js";
import BaseModalDialog from "../../common/modals/common/BaseModalDialog.vue";
import { SET_CACHE } from "../../../store/cacheLayer";

export default {
  name: "StampCard",
  components: { BaseModalDialog },
  props: {
    stamp: Object,
    showManagerView: Boolean
  },
  emits: ["useStamp", "showContextMenu"],
  setup(props, { emit }) {
    const previewContainer = ref(null);
    const previewBase64Image = ref(null);
    const contextMenuButton = ref(null);
    const stampName = computed(() => {
      return props?.stamp?.name || "unknown title"
    });
    const stampPreview = computed(() => {
      return `data:image/png;base64,${props.stamp.thumbnail}` ?? ''

    })

    onMounted(() => {
      const renderer = new AnnotationPreviewRenderer();
      const sourceViewport = {
        width: props?.stamp?.pageSize?.[0],
        height: props?.stamp?.pageSize?.[1],
        scale: 1
      };

      previewBase64Image.value = renderer.render(
        sourceViewport,
        previewContainer.value,
        props.stamp
      );

    });
    function onClickPreview() {
      if (!props.showManagerView) {
        if (props.stamp.annotation) {
          const modifiedAnnotation = props.stamp.annotation;
          delete modifiedAnnotation.id;
          SET_CACHE.makeRecentStamp(props.stamp.id);
          try {
            emit("useStamp", {
            stamp: JSON.parse(JSON.stringify(props.stamp.annotation)),
            preview: stampPreview.value
          });
          } catch (error) {
            console.log(error);
          }

        }

      }

    }

    function showMenu(e) {
      emit("showContextMenu", contextMenuButton.value);
    }

    return {
      previewContainer, contextMenuButton, stampName, stampPreview,
      onClickPreview, showMenu
    };
  }
}

</script>

<template>
  <div class="stamp-card">
    <div class="stamp-card-preview" @click.stop="onClickPreview">
      <!-- <div class="stamp-card-preview-image" ref="previewContainer"></div> -->
      <img style="padding: 10px;" :src="stampPreview" alt="Image" />
    </div>
    <span class="stamp-card-title">{{ stampName }}</span>
    <div v-if="showManagerView" class="stamp-card-menu-button" ref="contextMenuButton" @click.stop="showMenu">
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.1001 7C13.1001 7.27614 12.8762 7.5 12.6001 7.5C12.324 7.5 12.1001 7.27614 12.1001 7C12.1001 6.72386 12.324 6.5 12.6001 6.5C12.8762 6.5 13.1001 6.72386 13.1001 7Z"
          stroke="#334155" />
        <path
          d="M13.1001 12C13.1001 12.2761 12.8762 12.5 12.6001 12.5C12.324 12.5 12.1001 12.2761 12.1001 12C12.1001 11.7239 12.324 11.5 12.6001 11.5C12.8762 11.5 13.1001 11.7239 13.1001 12Z"
          stroke="#334155" />
        <path
          d="M13.1001 17C13.1001 17.2761 12.8762 17.5 12.6001 17.5C12.324 17.5 12.1001 17.2761 12.1001 17C12.1001 16.7239 12.324 16.5 12.6001 16.5C12.8762 16.5 13.1001 16.7239 13.1001 17Z"
          stroke="#334155" />
      </svg>
    </div>
  </div>
</template>

<style scoped lang="scss">
.stamp-card {
  width: 112px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;

  .stamp-card-preview {
    width: 112px;
    height: 112px;
    //padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 4px;
    background-color: #F1F5F9;
    cursor: pointer;

    .stamp-card-preview-image {
      width: 100px;
      height: 100px;

      display: flex;
      align-items: center;
      justify-content: center;

      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .stamp-card-title {
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
    max-height: 2rem;
  }

  .stamp-card-menu-button {
    position: absolute;
    right: 1px;
    top: 1px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    width: 34px;
    height: 34px;

    &:hover {
      background-color: #f1f1f1;
      cursor: pointer;
    }

    &:active {
      transform: translate(1px, 1px);
    }
  }
}
</style>