<template>
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" stroke="#64748B" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.58301 17.5833L17.583 3.58334" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "fat-line-icon",
};
</script>
