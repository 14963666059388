<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.2913 3.45703V1.70703H1.70801V3.45703M8.99967 1.9987V16.2904M8.99967 16.2904H7.54134M8.99967 16.2904H10.458" stroke="#64748B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "text-icon",
};
</script>
