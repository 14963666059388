<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.79867 11.3334C5.56298 10.4168 6.71355 9.83341 8.00036 9.83341C9.28718 9.83341 10.4377 10.4168 11.2021 11.3334M12.8337 8.00008C12.8337 10.6695 10.6697 12.8334 8.00033 12.8334C5.33095 12.8334 3.16699 10.6695 3.16699 8.00008C3.16699 5.3307 5.33095 3.16675 8.00033 3.16675C10.6697 3.16675 12.8337 5.3307 12.8337 8.00008ZM9.50033 6.66675C9.50033 7.49517 8.82875 8.16675 8.00033 8.16675C7.1719 8.16675 6.50033 7.49517 6.50033 6.66675C6.50033 5.83832 7.1719 5.16675 8.00033 5.16675C8.82875 5.16675 9.50033 5.83832 9.50033 6.66675Z" stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "owner-icon",
};
</script>

<style scoped>

</style>
