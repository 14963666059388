<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.2497 6.24992L10.4734 4.82683C10.1814 4.2914 9.62019 3.95825 9.01029 3.95825H5.62467C4.7042 3.95825 3.95801 4.70444 3.95801 5.62492V9.16659M16.0413 8.12492V14.3749C16.0413 15.2954 15.2951 16.0416 14.3747 16.0416H5.62467C4.7042 16.0416 3.95801 15.2954 3.95801 14.3749V6.45825H14.3747C15.2951 6.45825 16.0413 7.20444 16.0413 8.12492Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "all-project-nav-icon",
};
</script>

<style scoped>

</style>
