<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.25879 11.0859H14.1284" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.25879 13.5215H12.7588" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 19H5.58359C5.50692 19.0001 5.43099 18.9851 5.36014 18.9558C5.28929 18.9265 5.22492 18.8835 5.17071 18.8293C5.11649 18.7751 5.07351 18.7107 5.04422 18.6399C5.01493 18.569 4.9999 18.4931 5 18.4164V12C5 10.1435 5.7375 8.36301 7.05025 7.05025C8.36301 5.7375 10.1435 5 12 5C12.9193 5 13.8295 5.18106 14.6788 5.53284C15.5281 5.88463 16.2997 6.40024 16.9497 7.05025C17.5998 7.70026 18.1154 8.47194 18.4672 9.32122C18.8189 10.1705 19 11.0807 19 12C19 12.9193 18.8189 13.8295 18.4672 14.6788C18.1154 15.5281 17.5998 16.2997 16.9497 16.9497C16.2997 17.5998 15.5281 18.1154 14.6788 18.4672C13.8295 18.8189 12.9193 19 12 19Z" stroke="#334155" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "comment-search-icon",
};
</script>

<style scoped>

</style>
