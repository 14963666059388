<template>
  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0722 8.21838C12.1758 7.40915 12.8587 6.05923 12.6934 4.55023C12.4674 2.48803 10.5946 0.995117 8.52009 0.995117H0.138672V2.13869L1.79366 2.96619C2.18109 3.1599 2.42581 3.55587 2.42581 3.98902V14.0112C2.42581 14.4444 2.18109 14.8403 1.79366 15.034L0.138672 15.8615V17.0051H9.08444C11.4903 17.0051 13.6363 15.2355 13.8438 12.8385C14.0226 10.7735 12.8281 8.96339 11.0722 8.21838ZM5.85653 3.28226H7.0001C8.26323 3.28226 9.28724 4.3062 9.28724 5.5694C9.28724 6.83253 8.26323 7.85655 7.0001 7.85655H5.85653V3.28226ZM5.85653 10.1437V14.718H8.14367C9.4068 14.718 10.4308 13.694 10.4308 12.4308C10.4308 11.1676 9.4068 10.1437 8.14367 10.1437H5.85653Z" fill="#64748B"/>
  </svg>
</template>

<script>
export default {
  name: "font-weight",
};
</script>
