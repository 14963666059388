import { createAuth0Client } from "@auth0/auth0-spa-js";
import { mutations } from "../store/index.js";

class Auth0Authenticate {
  auth0flow;

  constructor(app) {
    this.app = app;
    this.isAuthenticated = false;
  }

  async __init() {
    let additionalAuthParams = {};
    if (this.app.$route.name === "signup") {
      additionalAuthParams = { screen_hint: "signup" };
    }

    if (!this.authClient || this.auth0flow !== this.app.$route.name) {
      const organization = this.extractSubdomain()

      let authClientId = import.meta.env.VITE_AUTH_CLIENT_ID;
      if (organization === import.meta.env.VITE_B2C_TENANT_NAME) {
         authClientId = import.meta.env.VITE_AUTH_CLIENT_ID_B2C;
      }

      const authPayload = {
        domain: import.meta.env.VITE_AUTH_DOMAIN,
        clientId: authClientId,
        useRefreshTokens: true,
        useRefreshTokensFallback: true,
        cacheLocation: 'localstorage',
        authorizationParams: {
          prompt: "login",
          ...additionalAuthParams,
          scope: import.meta.env.VITE_AUTH_SCOPE,
          audience: import.meta.env.VITE_AUTH_AUDIENCE,
          redirect_uri: window.location.origin
        },
      }
      this.authClient = await createAuth0Client(authPayload);
      this.auth0flow = this.app.$route.name;
    }
  }
  extractSubdomain() {
    let domain = window.location.hostname;
    if (domain.startsWith('dev.')) {
      domain = domain.replace('dev.', 'dev-');
    }
    const subdomainMatch = domain.match(/^([^.]+)\./);

    let org =  subdomainMatch ? subdomainMatch[1] : import.meta.env.VITE_B2C_TENANT_NAME;
    const b2cSet = new Set(["platform", "staging", "app-test", "preview"]);
    return b2cSet.has(org)? import.meta.env.VITE_B2C_TENANT_NAME: org;
  }

  async init() {
    if (!this.authClient) {
      const organization = this.extractSubdomain()

      let authClientId = import.meta.env.VITE_AUTH_CLIENT_ID;
      if (organization === import.meta.env.VITE_B2C_TENANT_NAME) {
         authClientId = import.meta.env.VITE_AUTH_CLIENT_ID_B2C;
      }

      const authPayload = {
        domain: import.meta.env.VITE_AUTH_DOMAIN,
        clientId: authClientId,
        useRefreshTokens: true,
        useRefreshTokensFallback: true,
        cacheLocation: 'localstorage',
        authorizationParams: {
          prompt: "login",
          scope: import.meta.env.VITE_AUTH_SCOPE,
          audience: import.meta.env.VITE_AUTH_AUDIENCE,
          redirect_uri: window.location.origin
        },
      };
      // console.log({authPayload});
      this.authClient = await createAuth0Client(authPayload);
    }
  }

  async getUser() {
    let user = await this.authClient.getUser();
    if (!user) {
      mutations.toggleAuthStatus(false);
      return {
        isAuthenticated: false,
        accessToken: "",
        profile: { email: "guest@folia.com", email_verified: true },
      };
    }
    let accessToken = null;
    try {
       accessToken = await this.authClient.getTokenSilently();   
    } catch (error) {
        await this.authClient?.logout({logoutParams:{returnTo: window.location.origin}});
    }
    localStorage.setItem('accessToken',accessToken)
    mutations.toggleAuthStatus(true);
    return { isAuthenticated: true, accessToken, profile: user };
  }

  async _getUser() {
    console.log("auth0 plugin:: getUser", await this.authClient.getUser());
    const isAuthenticated = (await this.authClient?.isAuthenticated()) || false;
    mutations.toggleAuthStatus(isAuthenticated);
    return {
      isAuthenticated,
      accessToken: isAuthenticated
        ? await this.authClient.getTokenSilently()
        : "",
      profile: isAuthenticated
        ? await this.authClient.getUser()
        : { email: "guest@folia.com", email_verified: true },
    };
  }

  async signIn(authFlow) {
    await this.init();
    await this.authClient.loginWithRedirect({
      authorizationParams:
        authFlow === "signup" ? { screen_hint: "signup" } : {},
    });
  }

  async signInRedirectCallback(url) {
    return await this.authClient.handleRedirectCallback(url);
  }

  async signOut() {
    localStorage.removeItem("user");
    if (!this.authClient) await this.init();
    await this.app.$fetch.deleteUserData();
    await this.authClient.logout({
      openUrl: (url) => {
        localStorage.clear();
        window.location.assign(
          url + "&returnTo=" + encodeURIComponent(window.location.origin)
        );
      },
    });
    mutations.toggleAuthStatus(false);
  }
}

export default {
  install(app) {
    const auth0 = new Auth0Authenticate(app.config.globalProperties);
    app.config.globalProperties.$auth0 = auth0;
    app.provide("$auth0", auth0);
  },
};
