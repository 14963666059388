<template>
    <div v-if="!isSubscriptionRedirectView" class="mt-10 isolate overflow-hidden banner bg-gray-300">
        <div class="mx-auto max-w-9xl px-6 pb-96 pt-24 text-center sm:pt-32 lg:px-8">
            <div class="mx-auto max-w-4xl">
                <h2 class="text-base font-semibold leading-7 text-indigo-400"></h2>
                <p class="p-title mt-2 text-4xl font-bold tracking-tight text-black sm:text-5xl">The right price for you, <br
                        class="p-title hidden sm:inline lg:hidden" />whoever you are</p>
            </div>
            <div class="relative mt-6">
                <p class="mx-auto max-w-2xl text-lg leading-8 text-black/60">description if needed.</p>
                <svg viewBox="0 0 1208 1024"
                    class="absolute -top-10 left-1/2 -z-10 h-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:-top-12 md:-top-20 lg:-top-12 xl:top-0">
                    <ellipse cx="604" cy="512" fill="url(#6d1bd035-0dd1-437e-93fa-59d316231eb0)" rx="604" ry="512" />
                    <defs>
                        <radialGradient id="6d1bd035-0dd1-437e-93fa-59d316231eb0">
                            <stop stop-color="#faab96" />
                            <stop offset="1" stop-color="#faab96" />
                        </radialGradient>
                    </defs>
                </svg>
            </div>
        </div>
        <div class="flow-root bg-white pb-24 sm:pb-32">
            <div class="-mt-80">
                <div class="mx-auto max-w-7xl px-6 lg:px-8">
                    <div class="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        <div v-for="tier in tiers" :key="tier.id"
                            class="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10">
                            <div>
                                <button type="button"
                                    class="custom-btn rounded-md bg-gray-300 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20">
                                    <h3 :id="tier.id" class="text-base font-semibold leading-5 text-gray-600">{{ tier.name
                                    }} </h3>


                                </button>
                                <div class="mt-4 flex items-baseline gap-x-2">
                                    <span class="text-5xl font-bold tracking-tight text-gray-900">{{ tier.price.monthly
                                    }}</span>
                                    <span class="text-base font-semibold leading-7 text-gray-600">/month</span>
                                </div>
                                <p class="mt-6 text-base leading-7 text-gray-600">{{ tier.description }}</p>
                                <ul role="list" class="mt-10 space-y-4 text-sm leading-6 text-gray-600">
                                    <li v-for="feature in tier.features" :key="feature" class="flex gap-x-3">
                                        <CheckIcon class="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                        {{ feature }}
                                    </li>
                                </ul>
                            </div>
                            <a @click="goToPaymentScreen(tier)" :aria-describedby="tier.id"
                                class="folia-btn cursor-pointer mt-8 block rounded-md px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Get
                                started today</a>
                        </div>

                    </div>
                    <div
                        class="mt-20 flex flex-col items-start gap-x-8 gap-y-6 rounded-3xl p-8 ring-1 ring-gray-900/10 sm:gap-y-10 sm:p-10 lg:col-span-2 lg:flex-row lg:items-center">
                        <div class="lg:min-w-0 lg:flex-1">
                            <h3 class="text-lg font-semibold leading-8 tracking-tight text-indigo-600">Do you need folia
                                for your business ?</h3>
                            <p class="mt-1 text-base leading-7 text-gray-600">Contact our sales team for better &
                                customized options</p>
                        </div>
                        <a href="#"
                            class="rounded-md px-3.5 py-2 text-sm font-semibold leading-6 text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Buy
                            discounted license <span aria-hidden="true">&rarr;</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="isSubscriptionRedirectView" class="isolate overflow-hidden success-screen bg-gray-700">
        <div class="relative isolate -z-1 mt-20 mb-20">
            <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div
                    class="mx-auto flex max-w-2xl flex-col gap-16 bg-white/5 px-6 py-16 ring-1 ring-white/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
                    <img class="h-96 w-full flex-none rounded-2xl object-cover shadow-xl lg:aspect-square lg:h-auto lg:max-w-sm"
                        src="/src/assets/svgs/note.svg" alt="" />
                    <div class="w-full flex-auto">
                        <h2 class="text-3xl font-bold tracking-tight text-orange-400 sm:text-4xl">Congratulations!</h2>
                        <p class="mt-6 text-lg leading-8 text-gray-300">You have upgraded your folia subscription to
                            PlanName.</p>
                        <p class="mt-6 text-lg leading-8 text-gray-300">We will send you a payment confirmation in short
                            while. To process your subscription upgrade, it may take few hours in some cases. If you don't
                            receive a confirmation email from us, please contact us.</p>
                        <ul role="list"
                            class="mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 text-white sm:grid-cols-2">

                        </ul>
                        <div class="mt-10 flex">
                            <a href="" class="text-sm font-semibold leading-6 text-orange-400">Contact Us<span
                                    aria-hidden="true">&rarr;</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
                aria-hidden="true">
                <div class="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#ffac80] to-[#e5ab46] opacity-25"
                    style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)" />
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { CheckIcon, CheckCircleIcon } from '@heroicons/vue/20/solid'
export default {
    components: { CheckIcon, CheckCircleIcon },
    setup() {

        const isSubscriptionRedirectView = ref(false)
        onMounted(() => {
            isSubscriptionRedirectView.va
        })
        const tiers = [
            {
                name: 'Free',
                id: 'tier-free',
                href: '#',
                price: { monthly: '$0', annually: '$0' },
                description: 'Limited features to get to know folia',
                features: ['1 projects', 'Up to 1 Document'],
            },
            {
                name: 'Basic',
                id: 'tier-basic',
                href: '#',
                price: { monthly: '$15', annually: '$12' },
                description: 'Everything necessary to get started.',
                features: ['15 projects', 'Up to 1,000 Documents', 'Basic analytics', '48-hour support response time'],
            },
            {
                name: 'Pro',
                id: 'tier-pro',
                href: '#',
                price: { monthly: '$30', annually: '$24' },
                description: 'Everything in Basic, plus pro tools.',
                features: [
                    '25 projects',
                    'Up to 10,000 documents',
                    'Advanced analytics',
                    '24-hour support response time',
                    ,
                ],
            }
        ]
        const benefits = [
            'Competitive salaries',
            'Flexible work hours',
            '30 days of paid vacation',
            'Annual team retreats',
            'Benefits for you and your family',
            'A great work environment',
        ]

        function goToPaymentScreen(tier) {
            console.log({ tier });
            // fetch the payment link from server and redirect

        }
        return {
            tiers, isSubscriptionRedirectView, benefits,

            goToPaymentScreen
        }
    }
}
</script>
<style scoped lang="scss">
.banner {
    border-radius: 25px;
    // background-color: rgb(240 78 35 / 81%);
}

.success-screen {
    min-height: 100svh;
    min-width: 100svw;
}

.folia-btn {
    background-color: rgb(240, 79, 35);
    ;
}

.custom-btn {
    min-width: 120px;
    max-height: 36px;
}

h3 {
    font-family: Inter, sans-serif;
}
.p-title{
    font-size: 48px;
}
</style>