import { BrowserWebSocketClientAdapter, JoinMessage, ProtocolV1 } from "@automerge/automerge-repo-network-websocket";
import {
    type PeerId,
    type PeerMetadata,
  } from "@automerge/automerge-repo"
import { App } from "vue";
import { BROADCAST_MESSAGE, OFFLINE_TOAST, SUCCESS_TOAST } from "../core/constants";


export class FoliaBrowserWebsocketClientAdaptor extends BrowserWebSocketClientAdapter {

    $fetch: any;
    connectionIssueNoticed: boolean = false;
    baseUrl: string = ""

    constructor(url: string, app: App) {
        super(url)
        this.baseUrl = url
        this.$fetch = app.config.globalProperties.$fetch;
    }

    connect(peerId: PeerId, peerMetadata: PeerMetadata) {
        console.log("Connecting automerge repo to sync server.....")
        // console.log("Timer id on connect");
        // console.log(this.timerId);
        this.url = this.baseUrl + "?Authentication=" + localStorage.getItem("accessToken")
        super.connect(peerId, peerMetadata);
    }

    disconnect() {
        console.log("Disconnected sync server")
        super.disconnect()
    }

    onClose = () => {
        console.log("Closed sync connection")

        if (this.remotePeerId) {
            this.emit("peer-disconnected", { peerId: this.remotePeerId })
        }

        if (!this.connectionIssueNoticed) {

            /*if (this.offlineRetryCount > 5) {
                this.$fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
                    type: OFFLINE_TOAST,
                    close_timeout: 30_000,
                    title: "You are now offline",
                    message: "You are no longer connected to Folia servers. Check your network and refresh the page ",
                });
            }*/
            this.connectionIssueNoticed = true;
        }

        if (!this.timerId) {
            if (this.peerId) {
                // console.log("Calling connect on close");
                // console.log(this.timerId);
                this.connect(this.peerId, this.peerMetadata!);
            }
        }
    }

    onOpen = () => {
        console.log("On websocket connection open");
        // console.log(this.timerId)

        clearInterval(this.timerId)
        // console.log("Time id after clear")
        // console.log(this.timerId)
        this.timerId = undefined
        // console.log("Sending a join message " + this.peerId!);
        this.send(this.joinMessage(this.peerId!, this.peerMetadata!))
        if (this.connectionIssueNoticed) {
            /*this.$fetch.dispatch(BROADCAST_MESSAGE.TOAST, {
                type: SUCCESS_TOAST,
                close_timeout: 30_000,
                title: "Connection established",
                message: "You are connected back to Folia servers. Please refresh the page",
            });*/
            this.connectionIssueNoticed = false;
        }
    }


    joinMessage(senderId: PeerId,peerMetadata: PeerMetadata): JoinMessage {
        return {
            type: "join",
            senderId,
            peerMetadata,
            supportedProtocolVersions: ["1"],
        }
    }



}
