<script>
import { computed, ref, useSlots } from "vue";

export default {
  name: "ContextMenu.vue",
  emits: ["select"],
  setup(props, {emit}) {
    const optionsAreShown = ref(false);
    const slots = useSlots();

    const items = computed(() => {
      return Object.keys(slots);
    })
    function showOptions() {
      optionsAreShown.value = true;
    }

    function select(item) {
      optionsAreShown.value = false;
      emit("select", item);
    }

    return {
      optionsAreShown, items,
      showOptions, select
    }
  }
}
</script>

<template>
  <div v-if="optionsAreShown" class="context-menu-overlay" @click.stop="optionsAreShown = false"></div>
  <div class="context-menu" @click.stop="showOptions()">
    <div class="button-icon">
      <svg width="2" height="12" viewBox="0 0 2 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.41732 1.83329C1.41732 2.06341 1.23077 2.24996 1.00065 2.24996C0.770532 2.24996 0.583984 2.06341 0.583984 1.83329C0.583984 1.60317 0.770532 1.41663 1.00065 1.41663C1.23077 1.41663 1.41732 1.60317 1.41732 1.83329Z" stroke="#141414"/>
        <path d="M1.41732 5.99996C1.41732 6.23008 1.23077 6.41663 1.00065 6.41663C0.770532 6.41663 0.583984 6.23008 0.583984 5.99996C0.583984 5.76984 0.770532 5.58329 1.00065 5.58329C1.23077 5.58329 1.41732 5.76984 1.41732 5.99996Z" stroke="#141414"/>
        <path d="M1.41732 10.1666C1.41732 10.3967 1.23077 10.5833 1.00065 10.5833C0.770532 10.5833 0.583984 10.3967 0.583984 10.1666C0.583984 9.93651 0.770532 9.74996 1.00065 9.74996C1.23077 9.74996 1.41732 9.93651 1.41732 10.1666Z" stroke="#141414"/>
      </svg>
    </div>

    <div class="context-menu-options-container" v-if="optionsAreShown">
      <div
        v-for="item of items"
        class="context-menu-option"
        @click.stop="select(item)"
      >
        <slot :name="item">Option #{{item}}}</slot>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.context-menu-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border-radius: 10px;
}

.context-menu {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 4px;
  gap: 4px;
  width: 36px;
  height: 36px;
  border: 1px solid #E2E8F0;
  border-radius: 6px;

  &:hover {
    background-color: #F8FAFC;
  }

  .button-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 4px;
    cursor: pointer;
  }

  .context-menu-options-container {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 150px;
    max-width: 280px;
    background: #FFFFFF;
    border: 1px solid rgba(51, 65, 85, 0.15);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px 0;

    .context-menu-option {
      height: 30px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 5px 15px;
      z-index: 13;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        background-color: #F8FAFC !important;
        cursor: pointer;
      }
    }
  }
}
</style>